import {
  IMatch,
  ITournament,
  IResponseTeam,
  IScore,
  IUser,
  IScoreMatch,
} from "../../../../../interfaces/GeneralInterfaces";
import { MatchControllerStyle, PointsControlerStyle, TeamStyle } from "./style";
import { useState, useContext, useEffect } from "react";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../../../services/api";
import { TournamentContext } from "../../../Context/TournamentContext";
import { AuthContext } from "../../../../../contexts/UserContext";
import Edit from "../../../../../assets/svg/Edit";
import { TournamentStatus } from "../../../../../enums/GeneralEnums";

interface MatchControllerProps {
  tournament: ITournament;
  match: IMatch;
  canEdit: boolean;
  isCurrentRound: boolean;
  isLastRound: boolean;
}

interface ITeamsIdentification {
  id: number;
  isWinner: boolean;
}

const Match = ({
  tournament,
  match,
  canEdit,
  isCurrentRound,
  isLastRound,
}: MatchControllerProps) => {
  const { handleMatches, tournamentStatus, ranking } =
    useContext(TournamentContext);
  const { handleIsLeagueManagerOfThisTournament, user } =
    useContext(AuthContext);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [error, setError] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);
  const [currentMatch, setCurrentMatch] = useState<IMatch>(match);
  const [cloneCurrentMatch, setCloneCurrentMatch] = useState<IMatch>(match);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [managerIsEditing, setManagerIsEditing] = useState<boolean>(false);
  const [displayWinnerBefore, setDisplayWinnerBefore] =
    useState<boolean>(false);
  const [showScore, setShowScore] = useState<boolean>(true);

  useEffect(() => {
    setCurrentMatch(match);
    if (isLastRound) handleDisplayBefore();

    if (match.status === "waitingAnotherTeam" && handleIsButtonVisible())
      setIsButtonVisible(true);
  }, [match]);

  useEffect(() => {
    handleScores();
  }, [currentMatch, cloneCurrentMatch]);

  const handleIsButtonVisible = () => {
    const myTeam = match.teams.find((team: IResponseTeam) =>
      team.users.some((currentUser: IUser) => currentUser.id === user.id)
    );
    if (myTeam) {
      if (myTeam.id !== match.scoreSubmittedByTeamId) return true;
    }
    return false;
  };

  const handleScore = (teamId: number, score: number) => {
    const matches = isEditing ? cloneCurrentMatch : currentMatch;
    const team = matches.teams.filter(
      (team: IResponseTeam) => team.id === teamId
    )[0];
    let index = matches.teams.indexOf(team);

    let newMatch = { ...matches };
    newMatch.teams[index].score = score;
    setCloneCurrentMatch(newMatch);
    setShowScore(true);
  };

  const handleActionButton = async () => {
    const { data }: any = await submitScore();

    if (data.status !== "error") {
      setIsButtonVisible(false);
      toast.info(`Partida finalizada com sucesso`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setError(data.message);
      setIsModalErrorOpen(true);
    }
  };

  const submitScore = async () => {
    let newScores: IScore[] = [];
    if (isEditing) {
      cloneCurrentMatch.teams.map((team: IResponseTeam) => {
        return newScores.push({
          teamId: team.id,
          score: team.score,
          submittedByTeam: team.id,
          roundId: currentMatch.round,
        });
      });
    } else {
      currentMatch.teams.map((team: IResponseTeam) => {
        return newScores.push({
          teamId: team.id,
          score: team.score,
          submittedByTeam: team.id,
          roundId: currentMatch.round,
        });
      });
    }

    const data = {
      matchId: currentMatch.id,
      tournamentId: tournament.id,
      scores: newScores,
    } as IScoreMatch;

    setIsEditing(false);

    try {
      const response = await api.post("matches", data);
      handleMatches();

      return response;
    } catch (e: any) {
      return {
        status: false,
        message: e.response.data.message,
      };
    }
  };

  const Actions = () => {
    if (
      (canEdit &&
        !cloneCurrentMatch.teams.every(
          (team: IResponseTeam) => team.score === 0
        )) ||
      managerIsEditing
    )
      return (
        <Button
          type="button"
          label={
            currentMatch.status === "finished"
              ? "Enviar novo resultado"
              : "Confirmar Resultado"
          }
          eventCallback={() => handleActionButton()}
        />
      );
    else return <></>;
  };

  const handleAddValue = (team: IResponseTeam) => {
    setIsEditing(true);
    const matchs = isEditing ? cloneCurrentMatch : currentMatch;
    let value = matchs.teams.filter((t: IResponseTeam) => t.id === team.id)[0]
      ?.score;
    handleScore(team.id, value + 1);
    handleColorTeam();
  };

  const handleRemoveValue = (team: IResponseTeam) => {
    setIsEditing(true);
    const matchs = isEditing ? cloneCurrentMatch : currentMatch;
    let value = matchs.teams.filter((t: IResponseTeam) => t.id === team.id)[0]
      ?.score;
    if (value !== 0) {
      handleScore(team.id, value - 1);
      handleColorTeam();
    }
  };

  const handleColorTeam = () => {
    let winner = isEditing
      ? [...cloneCurrentMatch.teams]
      : [...currentMatch.teams];
    let teste = winner.sort(
      (team: IResponseTeam, team2: IResponseTeam) => team.score - team2.score
    );
    let teamStatus: ITeamsIdentification[] = [];

    teste.map((team: IResponseTeam, index: number) => {
      return teamStatus.push({
        id: team.id,
        isWinner: index === 0,
      });
    });
  };

  const handleTeamWin = (team: IResponseTeam) => {
    const match = isEditing ? cloneCurrentMatch : currentMatch;
    if (
      match.teams.some((x) => x.score === team.score && x.id !== team.id) ||
      !showScore
    )
      return "";
    else if (match.teams.some((x) => x.score < team.score)) return "winner";
    else if (match.teams.some((x) => x.score > team.score)) return "looser";
    else return "";
  };

  const handleEditMatch = (e: any) => {
    setManagerIsEditing(true);
    setIsButtonVisible(true);
  };

  const handleDisplayBefore = () => {
    const winnerFromThisMatch = cloneCurrentMatch.teams.find(
      (team: IResponseTeam) => team?.id === ranking[0]?.team.id
    );

    let index = -1;
    if (winnerFromThisMatch)
      index = cloneCurrentMatch.teams.indexOf(winnerFromThisMatch);

    setDisplayWinnerBefore(index === 0 ? false : true);
  };

  const handleScores = () => {
    const scoreSubmittedByTeamId = currentMatch.scoreSubmittedByTeamId;
    let teamIndex: number = 0;
    let opponentIndex: number = 1;

    currentMatch.teams.forEach((team, index) => {
      if (team.id === scoreSubmittedByTeamId) {
        teamIndex = index;
      } else {
        opponentIndex = index;
      }
    });

    if (
      currentMatch.status !== "finished" &&
      currentMatch.teams[opponentIndex].users.some((teamUser) => {
        return teamUser.id === user.id;
      }) &&
      !isEditing
    ) {
      cloneCurrentMatch.teams[teamIndex].score = 0;
      cloneCurrentMatch.teams[opponentIndex].score = 0;
      currentMatch.teams[teamIndex].score = 0;
      currentMatch.teams[opponentIndex].score = 0;
      setShowScore(false);
    }
  };

  return (
    <MatchControllerStyle
      matchStatusText={
        isEditing ? cloneCurrentMatch.status : currentMatch.status
      }
      matchStatus={
        currentMatch.status === "finished" ||
        currentMatch.status === "waitingAnotherTeam" ||
        currentMatch.status === "warning"
      }
      fill="#cc8105"
      displayWinner={
        isLastRound &&
        tournamentStatus === TournamentStatus.finished &&
        cloneCurrentMatch.teams.some(
          (team: IResponseTeam) => team?.id === ranking[0]?.team.id
        )
      }
      displayBefore={displayWinnerBefore}
    >
      <ToastContainer />
      <div className="tournamentInfo">
        Mesa:{" "}
        {isEditing
          ? cloneCurrentMatch?.table
          : currentMatch?.table ?? "Não informada"}
        {currentMatch.status === "finished" &&
          isCurrentRound &&
          tournament.status !== TournamentStatus.finished &&
          handleIsLeagueManagerOfThisTournament(tournament.managerEmail) && (
            <span className="edit" onClick={(e: any) => handleEditMatch(e)}>
              <Edit />
            </span>
          )}
      </div>
      <div className="match">
        {isEditing
          ? cloneCurrentMatch.teams.map((team: IResponseTeam) => {
              return (
                <div className="team1" key={team.id}>
                  <PointsControlerStyle>
                    {((managerIsEditing && isCurrentRound) || canEdit) &&
                      tournament.status !== TournamentStatus.finished && (
                        <button onClick={() => handleRemoveValue(team)}>
                          -
                        </button>
                      )}
                    <div className={`team-result ${handleTeamWin(team)}`}>
                      <p>{team.score}</p>
                    </div>
                    {((managerIsEditing && isCurrentRound) || canEdit) &&
                      tournament.status !== TournamentStatus.finished && (
                        <button onClick={() => handleAddValue(team)}>+</button>
                      )}
                  </PointsControlerStyle>

                  <TeamStyle>
                    {team.name === "BYE" && <span>BYE</span>}
                    {team.name !== "BYE" && <span>{team.name}</span>}
                    {team.name !== "BYE" &&
                      team.users.map((user: IUser) => {
                        return <span key={user.id}>{user.username}</span>;
                      })}
                  </TeamStyle>
                </div>
              );
            })
          : currentMatch.teams.map((team: IResponseTeam) => {
              return (
                <div className="team1" key={team.id}>
                  <PointsControlerStyle>
                    {(managerIsEditing || canEdit) &&
                      tournament.status !== TournamentStatus.finished && (
                        <button onClick={() => handleRemoveValue(team)}>
                          -
                        </button>
                      )}
                    <div className={`team-result ${handleTeamWin(team)}`}>
                      <p>{showScore ? team.score : 0}</p>
                    </div>
                    {/* isLoggedUserTeam ? team.score : (currentMatch.status === "waitingAnotherTeam" || currentMatch.status !== "waitingAnotherTeam" && currentMatch.status !== "finished" ) ? 0 : team.score */}
                    {(managerIsEditing || canEdit) &&
                      tournament.status !== TournamentStatus.finished && (
                        <button onClick={() => handleAddValue(team)}>+</button>
                      )}
                  </PointsControlerStyle>

                  <TeamStyle>
                    {team.name === "BYE" && <span>BYE</span>}
                    {team.name !== "BYE" && <span>{team.name}</span>}
                    {team.name !== "BYE" &&
                      team.users.map((user: IUser) => {
                        return <span key={user.id}>{user.username}</span>;
                      })}
                  </TeamStyle>
                </div>
              );
            })}
      </div>
      <div className="actions">
        {isButtonVisible && tournament.status !== TournamentStatus.finished && (
          <Actions />
        )}
      </div>

      {isModalErrorOpen && (
        <Modal
          isOpen={isModalErrorOpen}
          onCloseModal={() => setIsModalErrorOpen(false)}
          classStatus="error"
          title="Erro"
          text={error}
        />
      )}
    </MatchControllerStyle>
  );
};

export default Match;
