import styled from 'styled-components'

export const AnswerQuizStyle = styled.section`
#page-quiz{
    min-height: 100vh;
    background-color: var(--color-gray-thin);

    .wrapper-limiter{
        max-width: 1280px;
        padding: 6rem 0;
    }

    .logos{
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            margin: 0 2rem;
        }
    }

    .common-block{
        padding: 3rem 4rem;
        background-color: var(--color-white);
        margin-bottom: 3rem;

        .title{
            font-size: 2.2rem;
            font-family: var(--font-primary-bold);
            color: var(--primary);
        }
    }

    .quiz-title{
        margin-top: 4rem;

        .title{
            font-size: 3.2rem;
            font-family: var(--font-secondary-bold);
            color: var(--primary);
            margin-bottom: 2rem;
        }
    }

    .submit-button{
        margin-left: auto;
        display: block;
    }
}

`

