import styled from 'styled-components'

export const TicketsStyle = styled.div`
    height: 100%;
    ul {
        margin-top:50px;
        li{
            margin: 20px 0px;
            border-bottom: 1px solid var(--color-gray-thin);
            padding-bottom: 20px;
            a {
                display:flex;
                align-items: center;
                justify-content: space-between;
                color: var(--primary);
                @media(max-width:1024px){
                  flex-direction:column;
                  align-items:flex-start;
                }
                .ticketDate{
                    display: flex;
                    align-items: center;
                    
                }
                span {
                  @media(max-width:1024px){
                      font-size:12px;
                    }
                    &.ticketNumber{
                        font-weight: 700;
                        font-size: 25px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
        td:nth-of-type(1):before { content: "Ticket ID"; }
        td:nth-of-type(2):before { content: "Status"; }
        td:nth-of-type(3):before { content: "Título"; }
        td:nth-of-type(4):before { content: "Autor"; }
        td:nth-of-type(5):before { content: "Data"; }
    }
`
