import styled from 'styled-components'

export const ApprovingCupCopagStyle = styled.div`
    background:#FFF;
    min-height:500px;    

    .list-infos{
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap:10px;
        margin-top:30px;
    }

    .actions_admin{
      display:flex;
      justify-content:center;
      align-items:center;
      width:100%;
      margin-top:50px;
      button{
        margin:0 10px;
      
      }
    }
    @media(max-width:800px){
        .list-infos{
            display: flex;
            flex-direction: column;
        }
    }
`
