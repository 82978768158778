import styled from 'styled-components'

const FidelityProgramStyle = styled.div`
    background:#FFF;
    min-height:500px;

    .pointsHistoric {
        width: 100%;
        display: flex;
    }

    @media(max-width:700px){
        .pointsHistoric {
            display: flex;
            flex-direction: column;
        }
    }
`

export default FidelityProgramStyle;