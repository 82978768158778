import { CreateTournamentStyle } from "./style";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../../contexts/UserContext";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import api from "../../services/api";
import { ITournamentCreateFormValues } from "../../pages/Manager/interface";
import useCommonRequisitions from "../../hooks/useCommonRequisitions";
import { IStates, ICities, ITournament,} from "../../interfaces/GeneralInterfaces";
import InputField from "../InputField";
import Textarea from "../Textarea";
import Button from "../Button";
import SelectField from "../SelectField";
import Modal from "../Modal";
import { useHistory } from "react-router-dom";
import ErrorFocus from "../ErrorFocus";
import DateTimePicker from "../DateTimePicker";

const CreateTournamentSchema = Yup.object().shape({
  name: Yup.string().required("Informe o nome do torneio"),
  modality: Yup.string().required("Informe a modalidade do torneio"),
  teamCount: Yup.number().required("Informe o formato de time"),
  price: Yup.string().required("Informe o preço"),
  visibility: Yup.string().required("Informe o tipo de torneio"), // publico e privado
  date: Yup.date().required("Informe a data do torneio"),
  maxParticipants: Yup.number()
    .required("Informe o limite de participantes")
    .min(1, "O número de participantes deve ser maior que zero"),
  checkin: Yup.date()
    .required("Informe o horário de checkin")
    .max(
      Yup.ref("date"),
      "Data de checkin precisa ser menor que a data do torneio!"
    ),
  description: Yup.string().required("Informe a descrição do torneio"),
  format: Yup.string().required("Informe se é presencial ou online"),
  zipCode: Yup.string(),
  uf: Yup.string().when("format", {
    is: "Presential",
    then: Yup.string().required("Informe seu estado"),
  }),
  city: Yup.string().when("format", {
    is: "Presential",
    then: Yup.string().required("Informe sua cidade").nullable(),
  }),
  neighborhood: Yup.string().when("format", {
    is: "Presential",
    then: Yup.string().required("Informe seu bairro"),
  }),
  street: Yup.string().when("format", {
    is: "Presential",
    then: Yup.string().required("Informe sua rua"),
  }),
  streetNumber: Yup.string().when("format", {
    is: "Presential",
    then: Yup.string().required("Informe seu número"),
  }),
  additionalAddressInfo: Yup.string(),
});

const teamCountOptions = [
  { value: 2, label: "Duplas" },
  { value: 3, label: "Trios" },
];

const modalityOptions = [
  { value: "TorneioMineiro", label: "Torneio Mineiro" },
  { value: "TorneioPaulista", label: "Torneio Paulista" },
];

const visibilityOptions = [
  { value: "Public", label: "Público" },
  { value: "Private", label: "Privado" },
];

const formatOptions = [
  { value: "Presential", label: "Presencial" },
  { value: "Online", label: "Online" },
];

const tournamentTypeOptions = [
  { value: "Swiss", label: "Chaveamento suiço" },
  { value: "Knockout", label: "Mata-mata" },
];

interface createOrEditTournamentProps {
  category: string;
  Description: any;
  Timeline?: any;
  edit: boolean;
  tournamentId?: string;
  displayForm: boolean;
}

const CreateOrEditTournament = ({
  category,
  Description,
  Timeline,
  edit,
  tournamentId,
  displayForm = true,
}: createOrEditTournamentProps) => {
  const { userInfo, loading } = useContext(AuthContext);
  const { getStates, getCities, getAddressByZipcode } = useCommonRequisitions();
  const [states, setStates] = useState<IStates[]>([]);
  const [cities, setCities] = useState<ICities[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateSelected, setStateSelected] = useState<any>("");
  const [returnMessage, setReturnMessage] = useState("");
  const [returnStatus, setReturnStatus] = useState(false);
  const history = useHistory();
  const [tournament, setTournament] = useState<ITournament>({} as ITournament);
  const [tournamentCreatedId, setTournamentCreatedId] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const createTournamentInitialValues: ITournamentCreateFormValues = {
    name: "",
    modality: "",
    teamCount: 2,
    price: "",
    visibility: "",
    date: "",
    checkin: "",
    description: "",
    zipCode: "",
    uf: "",
    city: "",
    street: "",
    streetNumber: "",
    neighborhood: "",
    additionalAddressInfo: "",
    prize: "",
    format: "Presential",
    category: category,
    type: "",
    maxParticipants: 0,
  };

  const editTournamentInitialValues: ITournamentCreateFormValues = {
    name: tournament?.name ?? "",
    modality: tournament?.modality ?? "",
    teamCount: tournament?.teamCount ?? "",
    price: tournament?.price?.toString() ?? "",
    visibility: tournament?.visibility ?? "",
    date: tournament?.date?.split(".")[0] ?? "",
    checkin: tournament?.checkin?.split(".")[0] ?? "",
    description: tournament?.description ?? "",
    zipCode: tournament?.zipCode ?? "",
    uf: tournament?.state?.uf ?? "",
    city: tournament?.city?.name ?? "",
    street: tournament?.street ?? "",
    streetNumber: tournament?.streetNumber?.toString() ?? "",
    neighborhood: tournament?.neighborhood ?? "",
    additionalAddressInfo: tournament?.additionalAddressInfo ?? "",
    prize: tournament?.prize ?? "",
    format: tournament?.format ?? "",
    category: tournament?.category ?? "",
    type: tournament?.type ?? "",
    maxParticipants: tournament?.maxParticipants ?? 0,
  };

  useEffect(() => {
    async function getState() {
      const populatedStates = await getStates();
      setStates(populatedStates);
    }
    getState();
  }, []);

  useEffect(() => {
    async function getCity() {
      const cities = await getCities(stateSelected);
      setCities(cities);
    }

    if (stateSelected) {
      getCity();
    }
  }, [stateSelected]);

  useEffect(() => {
    const getTournamentInformations = async () => {
      const response = await api.get(`tournaments/${Number(tournamentId)}`);
      setTournament(response.data);

      if (response.data.state && response.data.state.uf)
        setStateSelected(response.data.state.id);

      if (response.data.managerEmail !== userInfo().email)
        history.push("/torneios");
    };

    if (!loading && edit) {
      getTournamentInformations();
    }
  }, [loading]);

  const submitCreateTournament = async (values: ITournamentCreateFormValues, callback: Function) => {
    try {
      let response;
      if (edit) {
        if (values.format === "Online") {
          const { category, checkin, date, description, format, modality, name, price, prize, teamCount, visibility, type, maxParticipants } = values;
          const info = { category, checkin, date, description, format, modality, name, price, prize, teamCount, visibility, type, maxParticipants };
          response = await api.put(`tournaments/${tournamentId}`, info);
        } else {
          response = await api.put(`tournaments/${tournamentId}`, values);
        }
      } else {
        if (values.format === "Online") {
          const { category, checkin, date, description, format, modality, name, price, prize, teamCount, visibility, type, maxParticipants } = values;
          const info = { category, checkin, date, description, format, modality, name, price, prize, teamCount, visibility, type, maxParticipants };
          response = await api.post("tournaments", info);
        } else {
          response = await api.post("tournaments", values);
        }
      }
  
      if (response.data.status === "error") {
        setReturnMessage(response.data.message);
        setIsModalOpen(true);
      } else {
        setTournamentCreatedId(response.data.id);
        setReturnStatus(true);
        setReturnMessage(edit ? "Torneio editado com sucesso" : "Torneio criado com sucesso");
        setIsModalOpen(true);
      }
    } catch (err: any) {
      if (err.response) {
        setReturnMessage(err.response.data.message);
      } else if (err.request) {
        console.log(err.request);
      } else {
        setReturnMessage("Erro ao cadastrar");
      }
      setIsModalOpen(true);
    }
  
    callback();
  };
  
  const handleClose = () => {
    setIsModalOpen(false);
    if (returnStatus) {
      history.push(`/torneio/${tournamentCreatedId}`);
    }
  };
  
  return (
    <CreateTournamentStyle>
      <Description />
      {category === "TrucoCup" && <Timeline />}
  
      {displayForm && (
        <Formik
          initialValues={
            edit ? editTournamentInitialValues : createTournamentInitialValues
          }
          validationSchema={CreateTournamentSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
          onSubmit={(
            values: ITournamentCreateFormValues,
            { setSubmitting }: FormikHelpers<ITournamentCreateFormValues>
          ) => {
            submitCreateTournament(values, function () {
              setSubmitting(false);
            });
          }}
        >
          {(formik) => {
            return (
              <div id="register-form">
                <Form className="common-form">
                  <ErrorFocus />
                  <InputField name="name" label="Nome" type="text" />
                  <SelectField
                    label="Modalidade"
                    name="modality"
                    options={modalityOptions}
                    placeholder="Selecione a modalidade do torneio"
                  />
                  <DateTimePicker name="date" label="Data Inicio"/>
                  <DateTimePicker name="checkin" label="Checkin"/>
                  <SelectField
                    label="Tipo de Torneio"
                    name="visibility"
                    options={visibilityOptions}
                    placeholder="Selecione o tipo"
                  />
  
                  <SelectField
                    label="Formato de time"
                    name="teamCount"
                    options={teamCountOptions}
                    placeholder="Selecione o formato de Time"
                  />
  
                  <InputField
                    name="price"
                    label="Preço R$"
                    type="text"
                    money={true}
                  />
                  <SelectField
                    label="Formato"
                    name="format"
                    options={formatOptions}
                    placeholder="Selecione o formato"
                  />
  
                  {formik.values.format === "Presential" && (
                    <>
                      <InputField
                        name="zipCode"
                        label="CEP"
                        type="text"
                        mask="99999-999"
                        onChangeCallback={async (value) => {
                          const zipcodeClean = value.replace(/\D/g, "");
                          formik.setFieldValue("zipCode", zipcodeClean);
                          if (zipcodeClean.length == 8) {
                            let res = await getAddressByZipcode(zipcodeClean);
                            if (res.logradouro) {
                              const state = states.find(
                                (state) => state.label.includes(res.uf) === true
                              );
                              formik.setFieldValue("uf", res.uf);
                              formik.setFieldValue("stateId", state?.value);
                              formik.setFieldValue("city", res.localidade);
                              formik.setFieldValue(
                                "neighborhood",
                                `${res.bairro}`
                              );
                              formik.setFieldValue(
                                "street",
                                `${res.logradouro}`
                              );
                              setStateSelected(state?.value);
                            }
                          }
                        }}
                      />
  
                      <SelectField
                        label="Estado"
                        name="stateId"
                        options={states}
                        placeholder="Selecione seu Estado"
                      />
                      <SelectField
                        id="city"
                        label="Cidade"
                        name="city"
                        options={cities}
                        placeholder="Selecione sua cidade"
                      />
  
                      <InputField
                        name="neighborhood"
                        label="Bairro"
                        type="text"
                      />
  
                      <InputField name="street" label="Rua" type="text" />
                      <InputField
                        name="streetNumber"
                        label="Número"
                        type="text"
                      />
                      <InputField
                        name="additionalAddressInfo"
                        label="Complemento"
                        type="text"
                      />
                    </>
                  )}
  
                  <InputField name="prize" label="Prêmio" type="text" />
  
                  <SelectField
                    id="type"
                    label="Tipo do torneio"
                    name="type"
                    options={tournamentTypeOptions}
                    placeholder="Selecione o tipo do torneio"
                  />
  
                  <InputField
                    name="maxParticipants"
                    label="Limite de Participantes"
                    type="text"
                    onChangeCallback={async (value) => {
                      const maxParticipantsValue = parseInt(
                        value.replace(/\D/g, "")
                      );
  
                      formik.setFieldValue(
                        "maxParticipants",
                        !isNaN(maxParticipantsValue) ? maxParticipantsValue : ""
                      );
                    }}
                  />
  
                  <Textarea
                    name="description"
                    label="Descrição"
                    className="description"
                  />
  
                  {isModalOpen && (
                    <Modal
                      isOpen={isModalOpen}
                      onCloseModal={handleClose}
                      classStatus={returnStatus ? "success" : "error"}
                      title={returnStatus ? "Sucesso!" : "Opss!"}
                      text={returnMessage}
                    />
                  )}
                  <Button
                    classButton="submit-button"
                    hasLoader={true}
                    showLoading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                    label="ENVIAR"
                    type={"submit"}
                  />
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </CreateTournamentStyle>
  );
};

export default CreateOrEditTournament;
