import styled from "styled-components";

interface TableStyleProps {
  hasHandleClick?: boolean;
}

const TableStyle = styled.div`
  padding: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--color-gray-light);
    @media (max-width: 1280px) {
      table-layout: fixed;
    }
    @media (max-width: 1024px) {
      border: none;
    }
  }

  tbody tr {
    cursor: ${(props: TableStyleProps) =>
      props.hasHandleClick ? "pointer" : "auto"};
  }

  tr:nth-of-type(odd) {
    background: var(--color-gray-thin);
  }
  th {
    background-color: var(--primary);
    color: white;
    font-weight: bold;
    @media (min-width: 900px) and (max-width: 1280px) {
      font-size: 11px;
    }
  }
  th {
    div {
      display: flex;
      align-items: center;
    }
    span {
      margin-left: 3px;
      svg {
        width: 10px;
        height: 10px;
        fill: #fff;
      }
    }
  }
  td,
  th {
    padding: 10px;
    text-align: left;
  }

  td {
    @media (min-width: 900px) and (max-width: 1280px) {
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
      margin-bottom: 10px;
    }
    tr:nth-of-type(odd) {
      background: #fff;
    }
    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      position: absolute;
      top: 12px;
      left: 6px;
      width: 35%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
      color: var(--primary);
    }
  }
`;

export default TableStyle;
