import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/UserContext";
import { TableAllPlayersStyle } from "./style";
import LoyaltyPoints from "../../assets/svg/LoyaltyPoints";
import SectionTitle from "../SectionTitle";
import TablePagination from "../TablePagination";
import { IPlayer } from "../../interfaces/GeneralInterfaces";
import Modal from "../../components/Modal";
import ModalRemovePlayer from "../../components/ModalRemovePlayer";
import Button from "../../components/Button";
import api from "../../services/api";

interface IUserForTable {
  id?: string | number;
  name: string;
  userName: string;
  email: string;
  status: string;
  action: [string | undefined, number | undefined] | boolean;
}

interface ITableAllPlayersProps {
  tournamentId: number;
  players: IPlayer[];
  page?: number;
  perPage?: number;
  total?: number;
  setPage(page: any): any;
  setPerPage(page: any): any;
}

const TableAllPlayers = ({
  tournamentId,
  players,
  page = 1,
  perPage = 3,
  total = 1,
  setPage,
  setPerPage,
}: ITableAllPlayersProps) => {
  const { loading, handleLoading } = useContext(AuthContext);
  const [userForTable, setUserForTable] = useState<IUserForTable[]>(
    {} as IUserForTable[]
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState<boolean>(false);
  const [actionConfirmed, setActionConfirmed] = useState<any>({});
  const [currentUserId, setCurrentUserId] = useState(-1);

  const renderFormatedStatus = (values: any) => {
    if (values.value === "approved") {
      return "Aprovado";
    } else if (values.value === "pending") {
      return "Pendente";
    } else if (values.value === "recused") {
      return "Recusado";
    } else if (values.value === "disqualified") {
      return "Desqualificado";
    } else {
      return "Recusado";
    }
  };

  const renderButtonAction = (action: any, values: any) => {
    return (action.value ? (
      <Button
        classButton={"small"}
        label={"Desfazer"}
        eventCallback={() => {
          const currentPlayer = players.find(
            ({ user }) => user.email === action.row.values.email
          );
          const playerId = currentPlayer?.user.id;
          setActionConfirmed({ playerId });
          setIsActionModalOpen(true);
        }}
      ></Button>
    ) : null
    );
  };

  const columnsAllPlayers = [
    {
      Header: "NOME",
      accessor: "name",
    },
    {
      Header: "USERNAME",
      accessor: "userName",
    },
    {
      Header: "E-MAIL",
      accessor: "email",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: renderFormatedStatus,
    },
    {
      Header: "",
      accessor: "action",
      Cell: renderButtonAction,
    },
  ];

  useEffect(() => {
    const prepareDataForTable = () => {
      let data: IUserForTable[] = [];
      if (players && players.length > 0) {
        players.map((user: any) => {
          return data.push({
            id: user.user.id,
            name: user.user.firstName + " " + user.user.lastName,
            userName: user.user.username,
            email: user.user.email,
            status: user.status,
            action: user.status !== "pending"
          });
        });

        setUserForTable(data);
      }
    };

    prepareDataForTable();
  }, [players]);

  // useEffect(() => {}, [actionConfirmed]);

  const handleOpenModal = (userId: number) => {
    setCurrentUserId(userId);
    setIsModalOpen(true);
  };

  return (
    <TableAllPlayersStyle>
      {loading && <div className="">Carregando...</div>}
      {!loading && userForTable.length === 0 && (
        <span>
          Este torneio ainda não possui nenhum jogador inscrito. Para participar
          deste torneio, clique em se inscrever.
        </span>
      )}
      {!loading && userForTable.length > 0 && (
        <div className="next-tournaments">
          <SectionTitle
            icon={<LoyaltyPoints color={"var(--primary)"} />}
            title={"Jogadores"}
          />
          <TablePagination
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={page}
            totalPage={Math.ceil(total / perPage)}
            columnsWithOutMemo={columnsAllPlayers}
            dataWithOutMemo={userForTable}
          />
        </div>
      )}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onCloseModal={() => setIsModalOpen(false)}
          classStatus="success"
          title="Convidar Jogadores"
        >
          <ModalRemovePlayer
            tournamentId={Number(tournamentId)}
            userId={currentUserId}
            visualizeInformations={true}
            closeModal={() => setIsModalOpen(false)}
          />
        </Modal>
      )}
      {isActionModalOpen && (
        <Modal
          isOpen={isActionModalOpen}
          onCloseModal={() => setIsActionModalOpen(false)}
          classStatus="warning"
          title="Erro"
        >
          <span>Deseja desfazer o status atual deste jogador?</span>
          <div className="actions">
            <Button
              eventCallback={async () => {
                handleLoading(true)
                const body = {
                  userId: actionConfirmed.playerId,
                };
                await api.post(`tournaments/${tournamentId}/revert`, body);
                handleLoading(false)
                setIsActionModalOpen(false);
              }}
              type="submit"
              label="SIM"
            />
            <Button
              eventCallback={() => setIsActionModalOpen(false)}
              label="CANCELAR"
            />
          </div>
        </Modal>
      )}
    </TableAllPlayersStyle>
  );
};

export default TableAllPlayers;
