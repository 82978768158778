import styled from 'styled-components'

export const IntroManagerStyle = styled.div`
    background:#FFF;
    display:flex;
    flex-direction:column;

    .video-iframe{
        width:100%;
        max-width:100%;
        height:350px;
    }

    .intro-block{
        padding:20px;
        border:1px solid #A8A8A8;
        margin-top:30px;
        button{
            margin-top:30px;
        }
    }

`;
