import { useContext } from "react";
import { AuthContext } from "../../contexts/UserContext/UserContext";
import { IGlobalRankings } from "../../interfaces/GeneralInterfaces";
import { TableGlobalRankingStyle } from './style';
import LoyaltyPoints from '../../assets/svg/LoyaltyPoints';
import SectionTitle from '../../components/SectionTitle';
import TablePagination from '../../components/TablePagination';

interface ITableGlobalRankingProps {
    globalRanking: IGlobalRankings[];
    page?: number;
    perPage?: number;
    total?: number;
    setPage(page: any): any;
    setPerPage(page: any): any;
}

const TableGlobalRanking = ({ globalRanking, page = 1, perPage = 3, total = 1, setPage, setPerPage }: ITableGlobalRankingProps) => {
    const { loading } = useContext(AuthContext);

    const columnsGlobalRanking = [
        {
            Header: 'USUÁRIO',
            accessor: 'user'
        },
        {
            Header: 'PONTUAÇÃO',
            accessor: 'score'
        },
        {
            Header: 'CIDADE',
            accessor: 'city'
        },
        {
            Header: 'ESTADO',
            accessor: 'state'
        },
    ]

    return (
        <TableGlobalRankingStyle>
            {loading && (
                <div className="">Carregando...</div>
            )}
            {!loading && globalRanking && globalRanking.length > 0 ?
                <div className="next-tournaments">
                    <SectionTitle icon={<LoyaltyPoints color={'var(--primary)'} />} title={'Ranking Geral'} />
                    <TablePagination
                        perPage={perPage}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        currentpage={page}
                        totalPage={Math.ceil(total / perPage)}
                        columnsWithOutMemo={columnsGlobalRanking}
                        dataWithOutMemo={globalRanking}
                    />
                </div>
                :
                <span>Nenhum registro encontrado.</span>
            }
        </TableGlobalRankingStyle>
    )
}

export default TableGlobalRanking;