import { useContext } from 'react';
import { AuthContext } from './../../contexts/UserContext';
import { TableAnsweredQuizStyle } from './style'
import Table from '../../components/Table';

const columnsEarnedPoints = [
    {
        Header: 'QUESTIONÁRIO',
        accessor: 'name'
    },
    {
        Header: 'PONTOS',
        accessor: 'points'
    },
    {
        Header: 'DATA',
        accessor: 'createdAt'
    }
];

interface IQuizResponseForTable {
    name: string;
    points: number;
    createdAt: string;
}

interface ITableAnsweredQuizProps {
    data: IQuizResponseForTable[]; 
}

const TableAnsweredQuiz = ({data}:ITableAnsweredQuizProps) => {
    const { loading } = useContext(AuthContext);

    return (
        <TableAnsweredQuizStyle>
            {loading && (
                <div>Carregando...</div>
            )}
            {!loading && (
                data.length > 0 && (
                    <div className="eaner-points">
                        <Table columnsWithOutMemo={columnsEarnedPoints} dataWithOutMemo={data} />
                    </div>
                )
            )}
        </TableAnsweredQuizStyle>
    )
}

export default TableAnsweredQuiz;