import styled from 'styled-components'

const RegisterStyle = styled.section`
    min-height: 100vh;
    background-color: var(--primary);


    /* #register-form .common-form{
      display:grid;
      grid-template-columns: 1fr 1fr;
      grid-gap:20px;
    } */

    .wrapper-limiter{
        min-height: 100vh;
        max-width: 555px;
        width:100%;
        position: relative;
        padding: 7rem 0;

        @media(max-width:1024px){
          width:95%;
        }

        &.show-form{
            #container-form-register{
                height: auto;
                opacity: 1;
            }
        }
    }

    .register-email{
        width: 100%;
    }

    .register-header{
        .logos{
            display: flex;
            align-items: center;
            justify-content: center;
            @media(max-width:1024px){
              flex-direction:column;
            }

            img{
                margin: 0 2rem;
                filter: brightness(0) invert(100%);
                @media(max-width:1024px){
                margin-bottom:30px;
              }
            }
        }

        .title-form{
            font-size: 3.2rem;
            color: var(--color-white);
            text-align: center;
            text-transform: uppercase;
            font-family: var(--font-secondary-bold);
            margin-top: 7rem;
        }

        .subtitle-form{
            font-size: 1.1rem;
            color: var(--color-green);
            text-align: center;
            font-family: var(--font-primary-regular);
        }
    }

    #container-form-register{
        overflow: hidden;
        height: 0px;
        opacity: 0;
        transition: height .3s, opacity .3s;
        margin-top: 2rem;

        .input-select{
            label{
                color: var(--color-white);
            }
           
            .error-message{
                height: 14px;
                opacity: 1;
                color: var(--danger);
            }
            

        }
        .input-block{
            label{
                color: var(--color-white);
            }

            [type="checkbox"]:checked + label:before,
            [type="checkbox"]:not(:checked) + label:before{
                border: 1px solid var(--color-white);
            }

            [type="checkbox"]:checked + label:after{
                background-color: var(--color-white);
            }

            &.input-error{
                input{
                    border-color: var(--danger);
                }
        
                .error-message{
                    height: 14px;
                    opacity: 1;
                    color: var(--danger);
                }
            }
        }

        .submit-button{
            width: 100%;
            margin-top: 3rem;
        }
    }
`

export { RegisterStyle };