import React from 'react';

import SocialItemStyle from './style';

import { ISocialItem } from '../../../interfaces/GeneralInterfaces';

const SocialItem: React.FC<ISocialItem> = ({
    icon,
    link,
    label
}) => {
    return (
        <SocialItemStyle href={link} target="_blank" rel="noreferrer" className="social-item" title={label}>
            {icon}
        </SocialItemStyle>
    );
}

export default SocialItem;