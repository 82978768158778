import styled from 'styled-components'

export const TournamentsStyle = styled.div`
    .points-widgets{
        margin-bottom:10px;
    }
    
    .container-accordion {
        margin: 30px 0;
    }

    .fc .fc-button-primary{
      background:var(--primary);
    }

    .toggle_calendar_others{
      display:flex;
      justify-content:flex-end;
      margin-bottom:15px;
      @media(max-width:600px){
        display:none;
      }

      button{
        width:41px;
        height:34px;
        
        display:flex;
        align-items:center;
        justify-content:center;
        background:var(--color-green);
        svg{
          fill:var(--color-purple);
        }
        &.active{
          background:var(--color-purple);
          svg{
            fill:var(--color-green);
          }
        }
      }
    }

    .clear_button {
      margin-left: 15px;
    }

    .tournamentEventOnCalendar{
      display:flex;
      align-items:center;
      color:var(--primary);
      cursor:pointer;

      @media(max-width:1280px){
        font-size:10px;
      }

      .circle{
        width:17px;
        height:17px;
        border-radius:50%;
        margin-right:5px;
        @media(max-width:1280px){
            width:10px;
            height:10px;
            margin-right:3px;
          }

        &.subscribed{
          background:var(--color-green);
        }
        &.not_subscribed{
          background:var(--color-purple);
        }
      }
    }

    
`
