import { IoWarningOutline } from "react-icons/io5";
import Button from "../Button";
import Modal from "../Modal";
import { ModalConfirmStyle } from "./style";

interface ModalConfirmProps {
    handleConfirm(): any;
    handleCloseConfirm(): any;
    title: string;
    isOpen: boolean;

}

const ModalConfirm = ({ handleConfirm, title, isOpen, handleCloseConfirm }: ModalConfirmProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onCloseModal={() => handleCloseConfirm()}
            classStatus={'success'}
            title={title}
        >
            <div className={`modal-icon warning`}>
                <IoWarningOutline />
            </div>
            <div className="modal-subtitle">{title}</div>
            <ModalConfirmStyle>
                <div className="actions-buttons">
                    <Button classButton="submit-button" label="Cancelar" eventCallback={() => handleCloseConfirm()} />
                    <Button classButton="submit-button" label="Confirmar" eventCallback={() => handleConfirm()} />
                </div>
            </ModalConfirmStyle>
        </Modal>
    )
}

export default ModalConfirm;