import styled from 'styled-components'

export const LeagueManagerStyle = styled.section`
    background:#FFF;
    display:flex;
    flex-direction:column;
    word-break: normal; 
    
    h2{
        margin-top:15px;
    }

    .league-manager-text{
        margin-top:10px;
    }

    .fundador {
        word-break: break-all; 
    }

    .list-infos{
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap:10px;
        margin-top:30px;
    }

    @media(max-width:800px){
        .list-infos{
            display: flex;
            flex-direction: column;
        }
    }

    .points-widgets{
        margin-top:30px;
    }

    .tournaments {
        margin-top: 2rem;
    }

    .container-accordion:not(:first-of-type) {
        margin-top: 30px;
    }

    .accordion-body {
        button {
            float: right;
        }
    }
`
