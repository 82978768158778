import styled from 'styled-components'

const SocialItemStyle = styled.a`
    margin-right: 2rem;

    svg {
        fill: var(--color-pink);
        width: 2rem;
        height: 2rem;
    }
`
export default SocialItemStyle;