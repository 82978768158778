import styled from 'styled-components'

export const AnswersStyle = styled.div`
    table{
        margin-bottom: 5.5rem;
        thead{
            th{
                &:last-of-type{
                    div{
                        display: flex-end;
                        margin: right;
                        justify-content:flex-end;
                    } 
                }
            }
        }
        tbody{
            td{
                &:last-of-type{
                    display:block;
                    justify-content:flex-end;
                    button{
                        margin-left:15px;
                    }

                }
            }
        }
        @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
            td:nth-of-type(1):before { content: "Questionário"; }
            td:nth-of-type(2):before { content: "Categoria"; }
            td:nth-of-type(3):before { content: "Jogador"; }
            td:nth-of-type(4):before { content: "Pontos"; }
            td:nth-of-type(5):before { content: "Data"; }
        }
    }
`