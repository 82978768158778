import styled from 'styled-components'

export const Step2Style = styled.section`
    form{
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap:10px;

       .twoColumns{
           grid-column:span 2;
       }

       .submit-button{
           grid-column:span 2;
           max-width:150px;
           margin:0 auto;
       }

       .grid-photos{
           display:flex;
           margin:20px 0px;
           grid-column:span 2;
           justify-content:center;
           flex-wrap:wrap;


           .item{
               width:150px;
               height:150px;
               padding:10px;
               margin:0 10px;
               border:1px solid rgba(0,0,0,0.2);
               margin-bottom:20px;
               position:relative;
               &:hover{
                span{
                    opacity:1;
                }
               }    
               span{
                   position:absolute;
                   top:-10px;
                   right:-10px;
                   cursor:pointer;
                   opacity:0;
                   transition:all 200ms;
                   svg{
                       fill:var(--danger);
                   }
               }
               img{
                   width:100%;
                   height:100%;
                   object-fit:cover;
               }
           }

       }
    }
`