import { useState, useEffect, useContext, useCallback } from 'react'
import BasePage from '../../components/BasePage';
import { AuthContext } from './../../contexts/UserContext';
import { Link, useHistory } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle'
import Button from '../../components/Button';
import { TicketsStyle } from './style';
import TablePagination from '../../components/TablePagination';
import api from '../../services/api';
import { ITicket } from '../../interfaces/GeneralInterfaces';
import Text from '../../components/Text';
import { renderDateFormated, dateFormated } from '../../utils/formatData';
import ticket_active from '../../assets/images/ticket_active.png';

interface ITicketsForTable {
    id: number;
    status: string;
    subject: string;
    author: string;
    createdAt: string;
}

const Tickets = () => {
    const history = useHistory();
    const [tickets, setTickets] = useState<ITicket[]>({} as ITicket[]);
    const [ticketsForTable, setTicketForTable] = useState<ITicketsForTable[]>({} as ITicketsForTable[])
    const { userInfo, loading, user } = useContext(AuthContext);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const columnsTicketsTable = [
        {
            Header: 'TICKET ID',
            accessor: 'id',
        },
        {
            Header: 'STATUS',
            accessor: 'status',
            sortType: 'caseInsensitive'
        },
        {
            Header: 'TÍTULO',
            accessor: 'subject',
            sortType: 'caseInsensitive'
        },
        {
            Header: 'AUTOR',
            accessor: 'author',
            sortType: 'caseInsensitive'
        },
        {
            Header: 'DATA',
            accessor: 'createdAt',
        },
    ];

    const getStatus = (status: string) => {
        return status === 'finished' ? 'Finalizado' : 'Pendente'
    }

    const handleClickRow = (row: any) => {
        history.push(`/ticket/${row.id}`)
    }

    const getTicketsAdmin = useCallback(async () => {
        const response = await api.get(`tickets/admin?page=${page}&limit=${perPage}`);
        setTotal(response.data.total);
        setTickets(response.data.tickets);
        console.log(response.data.tickets);
    }, [page])

    const getTickets = useCallback(async () => {
        const response = await api.get('tickets')
        setTickets(response.data);
    }, [page])

    useEffect(() => {
        if (!loading)
            userInfo().isAdmin ? getTicketsAdmin() : getTickets()

    }, [loading, getTicketsAdmin, getTickets])

    useEffect(() => {
        const prepareDateForTicketsTable = () => {
            let data: ITicketsForTable[] = [];
            if (tickets && tickets.length > 0) {
                tickets.map((ticket: ITicket) => {
                    return data.push({
                        id: ticket.id,
                        status: getStatus(ticket.status),
                        author: `${ticket.user.firstName} ${ticket.user.lastName}`,
                        createdAt: dateFormated(ticket.createdAt),
                        subject: ticket.subject
                    })
                })

                setTicketForTable(data);
            }
        }

        if (userInfo().isAdmin) {
            prepareDateForTicketsTable();
        }

    }, [tickets])

    const RenderTickets = () => {
        if (userInfo().isAdmin) {
            return (
                <>
                    {ticketsForTable && ticketsForTable.length > 0 &&
                        <TablePagination
                            perPage={perPage}
                            setPage={setPage}
                            setPerPage={setPerPage}
                            currentpage={page}
                            totalPage={Math.ceil(total / perPage)}
                            columnsWithOutMemo={columnsTicketsTable}
                            dataWithOutMemo={ticketsForTable}
                            handleClick={handleClickRow}
                        />
                    }
                </>
            )
        }
        else {
            return (
                <>
                    <Text>Precisa esclarecer uma dúvida ou quer fazer uma reclamação? <br />
                        Abra um ticket e acompanhe toda interação aqui. A equipe Copag vai responder você. </Text>
                    <ul>
                        {tickets.length > 0 && tickets?.map((ticket: ITicket) => {
                            return (
                                <li key={ticket.id}>
                                    <Link to={`/ticket/${ticket.id}`}>
                                        <div className="ticketDate">
                                            <span className="ticketNumber">{ticket.id} - </span> <span> {ticket.subject}</span>
                                        </div>
                                        <span>{renderDateFormated(ticket.createdAt)} - {getStatus(ticket.status)}</span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                    <Link to={"/novo-ticket"}>
                        <Button label="Novo Ticket" type="button" />
                    </Link>
                </>
            )
        }
    }

    return (
        <BasePage title="Tickets">
            <TicketsStyle>
                <SectionTitle title="Meus Tickets" icon={<img src={ticket_active} alt="ticket active"/>} />
                <RenderTickets />
            </TicketsStyle>
        </BasePage>
    )
}

export default Tickets;