import React from 'react';
import { Link } from 'react-router-dom';

import { IMenuLink } from '../../../interfaces/GeneralInterfaces';

import './styles.scss';

const MenuLink: React.FC<IMenuLink> = ({
    route,
    name,
    currentRoute,
    icon,
    activeIcon,
    onClick,
}) => {
    return (
        <Link to={route} className={`menu-link${currentRoute ? ' current' : ''}`} title={name} onClick={onClick}>
            <div className="icon">{currentRoute ? activeIcon : icon}</div>
            <span>{name}</span>
        </Link>
    );
}

export default MenuLink;