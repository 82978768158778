import { useState, useContext, useEffect } from 'react';
import { AuthContext } from './../../contexts/UserContext';
import { TablePastTournamentsStyle } from './style';
import SectionTitle from '../../components/SectionTitle';
import TablePagination from '../../components/TablePagination';
import { ITournament } from '../../interfaces/GeneralInterfaces';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import Text from '../../components/Text';
import { formatDate, formatHour } from '../../utils/formatData';
import tournament_active from '../../assets/images/tournament_active.png';

interface IPastTournamentsDataForTable {
    description: [string, number];
    date: string;
    category: string;
    modality: string;
    state: string;
    hour: string;
}

interface TablePastTournamentsProps {
    isWithoutRound: boolean;
    page?: number;
    perPage?: number;
    total?: number;
    tournaments?: ITournament[];
    setPage(page: any): any;
    setPerPage(page: any): any;
}

const renderDescription = (values: any) => {
    if (values.value) 
        return values.value[0];
}

const TablePastTournaments = ({ tournaments = [], isWithoutRound, page = 1, perPage = 3, total = 1, setPage, setPerPage }: TablePastTournamentsProps) => {
    const { loading } = useContext(AuthContext);
    const [pastTournaments, setPastTournaments] = useState<ITournament[]>({} as ITournament[])
    const [pastTournamentsDataForTable, setPastTournamentsDataForTable] = useState<IPastTournamentsDataForTable[]>({} as IPastTournamentsDataForTable[]);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();

    const columnsPastTournaments = [
        {
            Header: 'DESCRIÇÃO',
            accessor: 'description',
            Cell: renderDescription
        },
        {
            Header: 'DATA',
            accessor: 'date',
            Cell: formatDate
        },
        {
            Header: 'HORA',
            accessor: 'hour',
            Cell: formatHour
        },
        {
            Header: 'CATEGORIA',
            accessor: 'category',
        },
        {
            Header: 'MODALIDADE',
            accessor: 'modality',
        },
        {
            Header: 'ESTADO',
            accessor: 'state',
        },
    ];

    const handleClickRow = (row: any) => {
        isWithoutRound
            ? history.push(`/torneio-ranking/${row.description[1]}`)
            : history.push(`/torneio/${row.description[1]}`)
    }

    useEffect(() => {
        const prepareDataForTable = () => {
            let data: IPastTournamentsDataForTable[] = [];
            if (tournaments && tournaments.length > 0) {
                tournaments.sort((tournament: ITournament, tournament2:ITournament) => {
                    const dateA = new Date(tournament.date).getTime()
                    const dateB = new Date(tournament2.date).getTime()
                     return dateA - dateB
                })

                tournaments.map((tournament: ITournament) => {
                    return data.push({
                        description: [tournament.name, tournament.id],
                        date: tournament.date,
                        category: tournament.category === 'Tournament' ? 'Torneio' : 'Copa Truco',
                        modality: tournament.modality === 'TorneioMineiro' ? 'Torneio Mineiro' : 'Torneio Paulista',
                        state: tournament.state?.name ?? 'Online',
                        hour: tournament.date
                    })
                })

                setPastTournamentsDataForTable(data);
            }
        }

        prepareDataForTable();
    }, [pastTournaments, tournaments])

    useEffect(() => {
        setPastTournaments(tournaments);
    }, [tournaments])

    return (
        <TablePastTournamentsStyle>
            {loading && (
                <div className="">Carregando...</div>
            )}
            {!loading && !loadingData && (
                pastTournamentsDataForTable?.length > 0 && (
                    <>
                        <div className="next-tournaments">
                            <SectionTitle icon={<img src={tournament_active} alt="ativar torneio"/>} title={'Histórico de Participação'} />
                            <Text>Neste espaço você encontra todos os Torneios dos quais participou, bem como dados relacionados.<br /><br /> </Text>
                            <TablePagination
                                perPage={perPage}
                                setPage={setPage}
                                setPerPage={setPerPage}
                                currentpage={page}
                                totalPage={Math.ceil(total / perPage)}
                                columnsWithOutMemo={columnsPastTournaments}
                                dataWithOutMemo={pastTournamentsDataForTable}
                                handleClick={handleClickRow}
                            />
                        </div>
                    </>
                )
            )}
        </TablePastTournamentsStyle>
    )
}

export default TablePastTournaments;