import styled from 'styled-components'

export const TicketStyle = styled.section`
    
    .iterations{
        display:flex;
        flex-direction:column;
        width:100%;
        margin-top:50px;
        word-break: break-word;
        .item{
            display:flex;
            align-items:flex-start;
            width:100%;
            margin-bottom:42px;
            .avatar{
                width: 77px;
                height: 77px;
                background: #EBEBEB;
                border-radius:50%;
                border: 7px solid var(--primary);
                margin-right:20px;
                min-width: 77px;
                min-height: 77px;
                @media(max-width:700px){
                    display: none;
                }
            }
            .content{
                display:flex;
                flex-direction:column;
                width:100%;
                border: 1px solid var(--color-gray-thin);
                padding:25px;

                .name_date{
                    display:flex;
                    justify-content:space-between;
                    margin-bottom:20px;
                    span:first-of-type{
                        font-weight:bold;
                    }
                    @media(max-width:1024px){
                      flex-direction:column;

                      span:last-of-type{
                        font-size:12px;
                        margin-top:5px;
                      }
                    }
                }
                .description{
                    font-size:16px;
                    line-height:23px;
                    white-space: normal;
                }
            }
        }
    }

    form{
        display:flex;
        width:100%;
        flex-direction:column;
        justify-content:flex-end;
        .item{
            display:flex;
            align-items:flex-start;
            width:100%;
            margin-bottom:42px;
            .avatar{
                width: 77px;
                height: 77px;
                background: #EBEBEB;
                border-radius:50%;
                border: 7px solid var(--primary);
                margin-right:20px;
                @media(max-width:700px){
                    display: none;
                }
            }
            .description{
                display:flex;
                flex-direction:column;
                width:100%;
                border: 1px solid var(--color-gray-thin);
                justify-content:flex-end;
                label{
                    display:none;
                }
                textarea{
                    border:0;
                    padding:0;
                    margin:0;
                    padding-left:20px;
                }
            }
        }
        .buttons {
            display: flex;
            align-self:center;
            justify-content: center;
            button {
                margin: 0 5px;
            }
        }
    }
    
`
