import { useContext, useEffect, useState, createRef, useCallback } from "react";
import { AuthContext } from "./../../contexts/UserContext";
import { TournamentsStyle } from "./style";
import BasePage from "../../components/BasePage";
import Tabs from "../../components/Tabs/Tabs";
import TabPane from "../../components/Tabs/TabPane";
import Select from "react-select";
import useCommonRequisitions from "../../hooks/useCommonRequisitions";
import {
  IGlobalRankings,
  IStates,
  ITournament,
  ICities,
} from "../../interfaces/GeneralInterfaces";
import { selectStyles } from "../../assets/styles/selectStyles";
import Button from "../../components/Button";
import TableAllTournaments from "../../components/TableAllTournaments";
import api from "../../services/api";
import TableGlobalRanking from "../../components/TableGlobalRanking";
import TablePastTournaments from "../../components/TablePastTournaments";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useHistory } from "react-router-dom";
import CalendarIcon from "../../assets/svg/CalendarIcon";
import PairIcon from "../../assets/svg/PairIcon";
import {
  ITournamentFilter,
  ITournamentForCalendar,
} from "../../interfaces/TournamentsInterface";
import SectionTitle from "../../components/SectionTitle";
import Text from "../../components/Text";
import ranking from "../../assets/images/ranking.png";
import Modal from "../../components/Modal";
import ResetPassword from "../ResetPassword";

interface IRankingFilter {
  search?: string;
  stateId?: number;
  city?: string;
}

const visibilityOptions = [
  { value: "Public", label: "Público" },
  { value: "Private", label: "Privado" },
];

const formatOptions = [
  { value: "Presential", label: "Presencial" },
  { value: "Online", label: "Online" },
];

const categoryOptions = [
  { value: "Tournament", label: "Torneio" },
  { value: "TrucoCup", label: "Copa Truco" },
  { value: "StateCup", label: "Estadual Copag" },
];

const initialState: ITournamentFilter = {
  search: "",
  visibility: "",
  stateId: 0,
  city: "",
  format: "",
  category: "",
};

const Tournaments = () => {
  const history = useHistory();
  const { user, loading } = useContext(AuthContext);
  const [filteredOptions, setFilteredOptions] =
    useState<ITournamentFilter>(initialState);
  const [rankingFilterOptions, setRankingFilterOptions] =
    useState<IRankingFilter>({} as IRankingFilter);
  const [tournaments, setTournaments] = useState<ITournament[]>(
    {} as ITournament[]
  );
  const [globalRanking, setGlobalRanking] = useState<IGlobalRankings[]>(
    {} as IGlobalRankings[]
  );
  const [historyTournaments, setHistoryTournaments] = useState<ITournament[]>(
    {} as ITournament[]
  );
  const { getStates, getCities } = useCommonRequisitions();
  const [states, setStates] = useState<IStates[]>([]);
  const [cities, setCities] = useState<ICities[]>([]);
  const [stateSelected, setStateSelected] = useState<any>("");
  const [visibilitySelected, setVisibilitySelected] = useState<any>("");
  const [formatSelected, setFormatSelected] = useState<any>("");
  const [categorySelected, setCategorySelected] = useState<any>("");
  const [citySelected, setCitySelected] = useState<any>("");
  const [tournamentPage, setTournamentPage] = useState<number>(1);
  const [tournamentPerPage, setTournamentPerPage] = useState<number>(10);
  const [tournamentTotal, setTournamentTotal] = useState<number>(0);
  const [historyPage, setHistoryPage] = useState<number>(1);
  const [historyPerPage, setHistoryPerPage] = useState<number>(10);
  const [historyTotal, setHistoryTotal] = useState<number>(0);
  const [rankingPage, setRankingPage] = useState<number>(1);
  const [rankingPerPage, setRankingPerPage] = useState<number>(10);
  const [rankingTotal, setRankingTotal] = useState<number>(0);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const calendarComponentRef = createRef<FullCalendar>();
  const [tourtnamentsForCalendar, setTourtnamentsForCalendar] = useState<
    ITournamentForCalendar[]
  >({} as ITournamentForCalendar[]);
  const [returnMessage, setReturnMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clearFilter, setClearFilter] = useState<ITournamentFilter>(
    {} as ITournamentFilter
  );

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const fetchStateData = useCallback(async () => {
    const populatedStates = await getStates();
    //populatedStates.push({ value: 0, label: "Selecione estado..." });
    populatedStates.sort((a: any, b: any) => a.value - b.value);
    setStates(populatedStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCityData = useCallback(async (state) => {
    const cities = await getCities(state);
    setCities(cities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchStateData().catch(console.error);
  }, [fetchStateData]);

  useEffect(() => {
    if (stateSelected) {
      fetchCityData(stateSelected.value).catch(console.error);
    }
  }, [stateSelected, fetchCityData]);

  useEffect(() => {
    if (!loading) handleSearch();
  }, [loading, tournamentPage]);

  useEffect(() => {
    if (!loading) handleRankingSearch();
  }, [loading, rankingPage]);

  useEffect(() => {
    if (!loading) handleHistorySearch();
  }, [loading, historyPage]);

  const handleChangeInput = (selectedOption: any) => {
    if (!isNaN(selectedOption.value)) {
      setStateSelected(selectedOption);
      let newValue = { ...filteredOptions };
      newValue.stateId = selectedOption.value;
      setFilteredOptions(newValue);
    } else if (
      selectedOption.value === "Public" ||
      selectedOption.value === "Private"
    ) {
      setVisibilitySelected(selectedOption);
      let newValue = { ...filteredOptions };
      newValue.visibility = selectedOption.value;
      setFilteredOptions(newValue);
    } else if (
      selectedOption.value === "Presential" ||
      selectedOption.value === "Online"
    ) {
      setFormatSelected(selectedOption);
      let newValue = { ...filteredOptions };
      newValue.format = selectedOption.value;
      setFilteredOptions(newValue);
    } else if (
      selectedOption.value === "Tournament" ||
      selectedOption.value === "TrucoCup" ||
      selectedOption.value === "StateCup"
    ) {
      setCategorySelected(selectedOption);
      let newValue = { ...filteredOptions };
      newValue.category = selectedOption.value;
      setFilteredOptions(newValue);
    }

    // setFilteredOptions((prevState) => (
    //   {
    //   ...prevState,
    //   stateId: stateSelected.value,
    //   category: categorySelected.value,
    //   visibility: visibilitySelected.value,
    //   format: formatSelected.value,
    // }));
  };

  const handleChangeInputRanking = (selectedOption: any) => {
    if (!isNaN(selectedOption.value)) {
      setStateSelected(selectedOption);
      let newValue = { ...rankingFilterOptions };
      newValue.stateId = selectedOption.value;
      setRankingFilterOptions(newValue);
    } else {
      setCitySelected(selectedOption);
      let newValue = { ...rankingFilterOptions };
      newValue.city = selectedOption.value;
      setRankingFilterOptions(newValue);
    }
  };

  useEffect(() => {}, [filteredOptions]);

  const submitSearch = () => handleSearch();

  const submitRankingSearch = () => handleRankingSearch();

  const handleRankingSearch = async () => {
    try {
      const params = { filterOptions: rankingFilterOptions };
      console.log(params);
      const response = await api.post(
        `rankings/global?page=${rankingPage}&limit=${rankingPerPage}`,
        params
      );
      if (response.data.ranking && response.data.ranking.length > 0) {
        setGlobalRanking(response.data.ranking);
        setRankingTotal(response.data.total);
      } else {
        setIsModalOpen(true);
        setReturnMessage("Não há ranking disponível para esse local");
        setTourtnamentsForCalendar({} as ITournamentForCalendar[]);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleHistorySearch = async () => {
    try {
      const params = { filterOptions: rankingFilterOptions };
      const response = await api.post(
        `tournaments/past?page=${historyPage}&limit=${historyPerPage}`,
        params
      );
      if (response.data.tournaments && response.data.tournaments.length > 0) {
        setHistoryTournaments(response.data.tournaments);
        setHistoryTotal(response.data.total);
      } else {
        setHistoryTournaments({} as ITournament[]);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleSubscribed = (text: string) => {
    if (text === "approved") {
      return "Inscrito";
    } else if (text === "rejected") {
      return "Rejeitado";
    } else if (text === "pending") {
      return "Pendente";
    } else if (text === "banned") {
      return "Recusado";
    } else if (text === "notSubscribed") {
      return "Não inscrito";
    } else {
      return "Não inscrito";
    }
  };

  const handleSearch = async () => {
    try {
      const params = { filterOptions: filteredOptions };
      const response = await api.post(
        `tournaments/all?page=${tournamentPage}&limit=${tournamentPerPage}`,
        params
      );
      let tournamentsForCalendar: ITournamentForCalendar[] = [];

      if (response.data.tournaments && response.data.tournaments.length > 0) {
        let tournamentDate = response.data.tournaments;
        tournamentDate.sort(
          (tournament: ITournament, tournament2: ITournament) => {
            const dateA = new Date(tournament.date).getTime();
            const dateB = new Date(tournament2.date).getTime();
            return dateB - dateA;
          }
        );
        console.log("tournamentDate", tournamentDate);
        setTournaments(tournamentDate);
        setTournamentTotal(response.data.total);
        tournamentDate.map((tournament: ITournament) => {
          return tournamentsForCalendar.push({
            title: tournament.name,
            date: tournament.date.split("T")[0],
            extendedProps: {
              id: tournament.id,
              subscribed: handleSubscribed(tournament.subscribed!),
            },
          });
        });
      } else {
        setIsModalOpen(true);
        setReturnMessage("Não há torneios para essa pesquisa");
        setTourtnamentsForCalendar({} as ITournamentForCalendar[]);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const cleaningFilter = async () => {
    const response = await api.post(
      `tournaments/all?page=${tournamentPage}&limit=${tournamentPerPage}`
    );

    setStateSelected("");
    setVisibilitySelected("");
    setFormatSelected("");
    setCategorySelected("");
    setTournamentTotal(response.data.total);
    setTournaments(response.data.tournaments);
  };

  const cleaningFilterRanking = async () => {
    const response = await api.post(
      `rankings/global?page=${rankingPage}&limit=${rankingPerPage}`
    );

    setStateSelected("");
    setCitySelected("");
    setRankingTotal(response.data.total);
    setGlobalRanking(response.data.ranking);
  };

  const handleEvent = (id: number) => history.push(`torneio/${id}`);

  const renderEventContent = (eventInfo: any) => {
    return (
      <>
        <div
          onClick={() => handleEvent(eventInfo.event.extendedProps.id)}
          className="tournamentEventOnCalendar"
        >
          <div
            className={`circle ${
              eventInfo.event.extendedProps.subscribed === "Inscrito" ||
              eventInfo.event.extendedProps.subscribed === "Pendente"
                ? "subscribed"
                : "not_subscribed"
            }`}
          ></div>
          {eventInfo.event.title}
        </div>
      </>
    );
  };

  const handleChangeMonth = async (dateCalendar: Date) => {
    const year = dateCalendar.getFullYear();
    const month = dateCalendar.getMonth() + 1;
    try {
      const params = { filterOptions: filteredOptions };
      const response = await api.post(
        `tournaments/dates?month=${month}&year=${year}`,
        params
      );
      let tournamentsForCalendar: ITournamentForCalendar[] = [];
      if (response.data.tournaments && response.data.tournaments.length > 0) {
        response.data.tournaments.map((tournament: ITournament) => {
          return tournamentsForCalendar.push({
            title: tournament.name,
            date: tournament.date.split("T")[0],
            extendedProps: {
              id: tournament.id,
              subscribed: handleSubscribed(tournament.subscribed!),
            },
          });
        });
        setTourtnamentsForCalendar(tournamentsForCalendar);
      } else {
        setTourtnamentsForCalendar({} as ITournamentForCalendar[]);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <BasePage title={"Torneios"}>
      <TournamentsStyle>
        <Tabs>
          <TabPane label="TORNEIOS">
            <>
              {tournaments.length > 0 && (
                <div className="filter_tournaments">
                  <input
                    name="name"
                    type="text"
                    className="search"
                    placeholder="Pesquisar por nome"
                    onChange={(e: any) => {
                      let newValue = { ...filteredOptions };
                      newValue.search = e.target.value;
                      setFilteredOptions(newValue);
                    }}
                  />
                  <div className="filter_selects">
                    <Select
                      options={states}
                      placeholder="Estado"
                      value={stateSelected}
                      styles={selectStyles}
                      onChange={handleChangeInput}
                    />
                    <Select
                      options={visibilityOptions}
                      placeholder="Tipo de torneio"
                      value={visibilitySelected}
                      styles={selectStyles}
                      onChange={handleChangeInput}
                    />
                    <Select
                      options={formatOptions}
                      placeholder="Selecione o formato"
                      value={formatSelected}
                      styles={selectStyles}
                      onChange={handleChangeInput}
                    />
                    <Select
                      options={categoryOptions}
                      placeholder="Selecione a categoria"
                      value={categorySelected}
                      styles={selectStyles}
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div className="search_button_area">
                    <div className="search_button">
                      <Button label="Pesquisar" eventCallback={submitSearch} />
                      {isModalOpen && (
                        <Modal
                          isOpen={isModalOpen}
                          onCloseModal={handleClose}
                          classStatus={"error"}
                          text={returnMessage}
                        />
                      )}
                    </div>
                    <div className="clear_button">
                      <Button label="Limpar" eventCallback={cleaningFilter} />
                    </div>
                  </div>
                </div>
              )}

              <div className="toggle_calendar_others">
                <button
                  className={!showCalendar ? "active" : ""}
                  onClick={() => setShowCalendar(false)}
                >
                  <PairIcon />
                </button>
                <button
                  className={showCalendar ? "active" : ""}
                  onClick={() => setShowCalendar(true)}
                >
                  <CalendarIcon />
                </button>
              </div>

              {showCalendar && (
                <FullCalendar
                  ref={calendarComponentRef}
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  locale="pt"
                  height="auto"
                  eventContent={renderEventContent}
                  events={tourtnamentsForCalendar}
                  handleWindowResize={true}
                  customButtons={{
                    next_button: {
                      text: "Próximo",
                      click: function () {
                        let calendarApi =
                          calendarComponentRef.current!.getApi();
                        calendarApi?.next();
                        handleChangeMonth(calendarApi.getDate());
                      },
                    },
                    prev_button: {
                      text: "Anterior",
                      click: function () {
                        let calendarApi =
                          calendarComponentRef.current!.getApi();
                        calendarApi?.prev();
                        handleChangeMonth(calendarApi?.getDate());
                      },
                    },
                  }}
                  eventColor="transparent"
                  headerToolbar={{
                    start: "title", // will normally be on the left. if RTL, will be on the right
                    center: "",
                    end: "prev_button next_button", // will normally be on the right. if RTL, will be on the left
                  }}
                />
              )}

              {!showCalendar &&
                (tournaments.length > 0 ? (
                  <TableAllTournaments
                    tournaments={tournaments}
                    isWithoutRound={false}
                    page={tournamentPage}
                    perPage={tournamentPerPage}
                    total={tournamentTotal}
                    setPage={setTournamentPage}
                    setPerPage={setTournamentPerPage}
                  />
                ) : (
                  <span>Nenhum registro encontrado.</span>
                ))}
            </>
          </TabPane>

          <TabPane label="RANKING">
            <>
              <SectionTitle
                icon={<img src={ranking} alt="" />}
                title={"Ranking Geral"}
              />
              <Text>
                Confira aqui sua colocação e performance em relação a todos os
                jogadores cadastrados na Truco Oficial. <br />
              </Text>
              {globalRanking.length > 0 ? (
                <>
                  <div className="filter_tournaments">
                    <input
                      name="name"
                      type="text"
                      className="search"
                      placeholder="Pesquisar por nome"
                      onChange={(e: any) => {
                        let newValue = { ...rankingFilterOptions };
                        newValue.search = e.target.value;
                        setRankingFilterOptions(newValue);
                      }}
                    />
                    <div className="filter_selects">
                      <Select
                        options={states}
                        placeholder="Estado"
                        value={stateSelected}
                        styles={selectStyles}
                        onChange={handleChangeInputRanking}
                      />
                      <Select
                        options={cities}
                        placeholder="Cidade"
                        value={citySelected}
                        styles={selectStyles}
                        onChange={handleChangeInputRanking}
                      />
                    </div>
                    <div className="search_button_area">
                      <div className="search_button">
                        <Button
                          label="Pesquisar"
                          eventCallback={submitRankingSearch}
                        />
                        {isModalOpen && (
                          <Modal
                            isOpen={isModalOpen}
                            onCloseModal={handleClose}
                            classStatus={"error"}
                            text={returnMessage}
                          />
                        )}
                      </div>
                      <div className="clear_button">
                        <Button
                          label="Limpar"
                          eventCallback={cleaningFilterRanking}
                        />
                      </div>
                    </div>
                  </div>
                  <TableGlobalRanking
                    globalRanking={globalRanking}
                    page={rankingPage}
                    perPage={rankingPerPage}
                    total={rankingTotal}
                    setPage={setRankingPage}
                    setPerPage={setRankingPerPage}
                  />
                </>
              ) : (
                <>
                  <span>Nenhum registro encontrado.</span>
                </>
              )}
            </>
          </TabPane>

          <TabPane label="HISTÓRICO">
            <TablePastTournaments
              tournaments={historyTournaments}
              isWithoutRound={false}
              page={historyPage}
              perPage={historyPerPage}
              total={historyTotal}
              setPage={setHistoryPage}
              setPerPage={setHistoryPerPage}
            />
          </TabPane>
        </Tabs>
      </TournamentsStyle>
    </BasePage>
  );
};

export default Tournaments;
