import React, { useState } from 'react';
import { Formik, Form, FormikHelpers, FormikProps, Field } from 'formik';
//Components
import Button from '../../components/Button';
import QuizStyle from './style';
import api from '../../services/api';
import Modal from '../../components/Modal';
import { ptBR } from 'date-fns/locale'
import { parseISO, format, addDays } from 'date-fns'
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

interface QuizFormProps {
    quizId: number;
    callback?(step: number): void;
    quizList: IQuestion[];
    isManagerTest: boolean;
}

interface IQuestion {
    id: string;
    name: string;
    answers: Ianswer[];
}

interface Ianswer {
    name: string;
    isCorrect: boolean;
}

interface IResponseTest {
    result: boolean;
    message: string;
    totalHits: number;
    totalQuestions: number;
    date: string;
}

const QuizForm = ({ callback, quizList, quizId, isManagerTest }: QuizFormProps) => {
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isResultModalOpen, setIsResultModalOpen] = useState<boolean>(false);
    const [resultTest, setResultTest] = useState<IResponseTest>();
    const questions: IQuestion[] = quizList

    const QuizSchema = Yup.object().shape({
        answers: Yup.array().of(
            Yup.object().shape({
                answer: Yup.string()
                    .required('Selecione a resposta')
            })
        )
    });

    const handleSubmit = async (values: any, callBackFormik: any) => {
        try {
            await api.post(`forms/save-answers/${quizId}`, values);
            setIsModalOpen(true);
        } catch (err: any) {
            console.log(err);
        }
    }

    const handleSubmitManagerTest = async (values: any, callBackFormik: any) => {
        try {
            const response = await api.post(`forms/save-bemanager-answers/${quizId}`, values);
            setResultTest(response.data);
            setIsResultModalOpen(true);

        } catch (err: any) {
            console.log(err);
        }
        callBackFormik();
    }

    const initialValues = {
        answers: questions.map((question: IQuestion) => {
            return {
                questionId: question.id,
                answer: ''
            }
        })
    };

    const handleResultModalClose = (result: boolean) => {
        setIsResultModalOpen(false);

        if (result) {
            callback && callback(2);
        } else {
            callback && callback(0);
        }

        if (!callback)
            history.push(`/questionarios`)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
        history.push(`/questionarios`)
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={QuizSchema}
            enableReinitialize
            onSubmit={(
                values: any,
                { setSubmitting }: FormikHelpers<any>
            ) => {
                if (isManagerTest) {
                    handleSubmitManagerTest(values, function () {
                        setSubmitting(false);
                    });
                } else {
                    handleSubmit(values, function () {
                        setSubmitting(false);
                    });
                }
            }}
        >
            {(props: FormikProps<any>) => {
                const {
                    isSubmitting,
                } = props
                return (
                    <QuizStyle>
                        <Form className="common-form">
                            {questions && questions.map((question: IQuestion, indexQuestion: number) => {
                                return (
                                    <div key={indexQuestion} className="group-options common-block">
                                        <div className="title">{question.name}</div>
                                        <Field type="hidden" name={`answers.${indexQuestion}.questionId`} value={question.id} />
                                        {question && question.answers.map((answer: Ianswer, indexAnswer: number) => {
                                            return (
                                                <div className="answer" key={indexAnswer}>
                                                    <Field id={`answer_${indexQuestion}_${indexAnswer}`} type="radio" name={`answers.${indexQuestion}.answer`} value={answer.name} />
                                                    <label htmlFor={`answer_${indexQuestion}_${indexAnswer}`}>{answer.name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                );
                            })}

                            <Button classButton="submit-button" hasLoader={true} showLoading={isSubmitting} disabled={isSubmitting} label="Enviar" type={'submit'} />
                        </Form>

                        {isModalOpen &&
                            <Modal
                                isOpen={isModalOpen}
                                onCloseModal={() => { handleCloseModal() }}
                                classStatus="success"
                                title="Sucesso"
                                text={'Você finalizou o questionário.'}
                            />
                        }

                        {isResultModalOpen && resultTest &&
                            <Modal
                                isOpen={isResultModalOpen}
                                onCloseModal={() => handleResultModalClose(resultTest.result)}
                                classStatus={resultTest.result ? 'success' : 'error'}>
                                {
                                    !resultTest.result &&
                                    <>
                                        <div className={`modal-icon error`}>
                                            <IoCloseCircle />
                                        </div>
                                        <div className="modal-title">Reprovado</div>
                                        <div className="modal-text">
                                            Você não passou =/,<br />
                                            {resultTest?.totalHits} acertos de {resultTest.totalQuestions} questões.<br />
                                            Você poderá realizar o teste novamente a partir do dia {resultTest.date && format(addDays(parseISO(resultTest.date), 30), "dd/MM/yyyy ", { locale: ptBR })}
                                        </div>
                                    </>
                                }
                                {resultTest.result &&
                                    <>
                                        <div className={`modal-icon success`}>
                                            <IoCheckmarkCircle />
                                        </div>
                                        <div className="modal-title">Aprovado</div>
                                        <div className="modal-text">
                                            Você passou =),<br />
                                            {resultTest.totalHits} acertos de {resultTest.totalQuestions} questões.<br />
                                        </div>
                                    </>
                                }
                            </Modal>
                        }
                    </QuizStyle>
                )
            }}
        </Formik>
    );
}

export default QuizForm;