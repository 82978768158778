import React from 'react';

import logo from './../../assets/images/copag.png';
import logoTruco from './../../assets/images/plataforma_truco.png';
import { LogoStyle } from './style';

import { ILogoProps } from '../../interfaces/GeneralInterfaces';

const Logo: React.FC<ILogoProps> = ({ customClass }) => {
    return (
        <LogoStyle className={`logo ${customClass}`}>
            <img src={logoTruco} width="256" height="80" alt="Plataforma de Truco" />
            <img src={logo} width="72" height="46" alt="Copag" />
        </LogoStyle>
    );
}

export default Logo;