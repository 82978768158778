
import { IntroManagerStyle } from './style'
import { YoutubeVideo } from '../../../components/Video';
import Text from '../../../components/Text';
import Title from '../../../components/Title';
import Button from '../../../components/Button';

interface IntroProps {
    callback(step: number): void;
}

const IntroManager = ({ callback }: IntroProps) => {
    return (
        <IntroManagerStyle>
            <YoutubeVideo videoId="https://www.youtube.com/watch?v=uP-as3ETsWo" />
            <div className="intro-block">
                <Title>
                    Institucional
                </Title>
                <Text>
                    Como Organizador da Truco Oficial, você poderá: <br /><br />
                    <ul>
                        <li>Agendar encontros de Liga [Torneios] e, após cumprir alguns requisitos, agendar uma Copa Truco</li>
                        <li>Acessar todos os jogadores cadastrados na plataforma e convidá-los para seus eventos</li>
                        <li>Aprovar, recusar e banir participantes em seus eventos</li>
                        <li>Ter divulgação com alcance nacional de sua Liga e Torneios</li>
                        <li>Ter atribuída a mesma pontuação [programa de pontos] dos jogadores de seus eventos [Ligas ou Torneios] para resgate</li>
                        <li>Acesso a ferramentas e materiais específicos</li>
                    </ul>
                    <br />
                    <br />

                    Para se tornar um organizador é preciso: <br /><br />

                    1º: Ser aprovado no Teste para Organizadores, com 70% de aproveitamento – acertos;<br />
                    2º: Preencher formulário adicional sobre o evento que pretende realizar e aguardar a aprovação da Copag.<br />

                    Ao ser aprovado, o acesso às ferramentas de organizador será liberadas no sistema e sua Liga receberá ID único, que será disponibilizado no Menu de Organizador.<br /><br />

                    <strong>Visite abaixo os materiais de estudos, prepare-se e faça a prova para ser um Organizador na Truco Oficial. <br /><br /></strong>

                </Text>
                <Button classButton="modal-btn" label="ACESSE A PROVA" eventCallback={() => callback(1)} />
            </div>
            <div className="intro-block">
                <Title>
                    Material para estudo
                </Title>
                <Text>
                    <ul>
                        <li>Organizadores de torneios de Truco com interesse em aplicar a esses cargos por meio da Plataforma Truco Oficial precisarão realizar prova online para obtenção de título. A prova será composta por 10 (dez) questões de múltipla escolha, contendo 4 (quatro) alternativas cada, das quais apenas 1 (uma) resposta será correta.  </li>
                        <li>Cada questão terá o valor de 1 (um) ponto, que corresponde a 10% da pontuação total da prova. As questões serão escolhidas de forma randômica pelo sistema.</li>
                        <li>Para obter aprovação, o usuário precisará obter pontuação igual ou superior a 7 (sete) pontos, o que equivale ao mínimo de 70% de acertos no teste realizado.</li>
                        <li>Caso o usuário não obtenha pontuação igual ou superior à 7 (sete) pontos – 70% de acertos – este poderá realizar nova avaliação, após o período de 30 (trinta) dias corridos.</li>
                        <li>Vale ressaltar que o banco de questões da avaliação para Organizadores de Truco é composto por 50 (cinquenta) questões de múltipla escolha, selecionadas de forma randômica. Dessa forma, é imprescindível que o usuário estude todo o material disponibilizado para ter mais chances de aprovação. </li>
                        <li>O material para estudos, assim como a prova, serrão disponibilizados gratuitamente na Plataforma de Truco Copag. </li>
                    </ul> <br />
                    Acesse os materiais disponíveis e conheça as regras, de acordo com a modalidade de Truco escolhida:<br /><br />
                    <ul>
                        <li>Truco Paulista</li>
                        <li>Truco Mineiro</li>
                        <li>Truco Gaudério</li>
                        <li>Truco Goiano</li>
                        <li>Formato de Torneio</li>
                    </ul>
                    <br />
                    <strong>Demais Documentos</strong><br />
                    <ul>
                        <li><a href="https://bit.ly/3okJUAe" target="_blank" rel="noreferrer">Padrão de Conduta de Jogo</a></li>
                        <li><a href="https://bit.ly/2Ye5n3h" target="_blank" rel="noreferrer">Manual sobre a Truco Oficial</a></li>
                        <li><a href="https://bit.ly/2YhZwKk" target="_blank" rel="noreferrer">Política de igualdade, equidade, diversidade e inclusão da Truco Oficial</a></li>
                    </ul>
                </Text>
            </div >
        </IntroManagerStyle >
    )
}

export default IntroManager;