import { useState, useEffect, useContext, useRef } from "react";
import api from "../../services/api";
import BasePage from "../../components/BasePage";
import useCommonRequisitions from "../../hooks/useCommonRequisitions";
import { IStates, ICities } from "../../interfaces/GeneralInterfaces";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { AuthContext } from "./../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";
import UserIcon from "../../assets/svg/UserIcon";
import AddressIcon from "../../assets/svg/AddressIcon";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import SelectField from "../../components/SelectField";
import Modal from "../../components/Modal";
import AccountRequisitions from "./services";
import { AccountStyle } from "./style";
import ProfilePicture from "../../assets/images/profile.jpg";
import Edit from "../../assets/svg/Edit";

interface IAccountFormValues {
  firstName: string;
  lastName: string;
  zipCode?: string;
  uf: string;
  stateId?: string;
  city: string;
  neighborhood: string;
  street: string;
  streetNumber: string;
  additionalAddressInfo?: string;
  username: string;
  email: string;
  doc: string;
  birthDate: string;
  phone: string;
  gender: string;
  appUsername?: string;
}

const AccountSchema = Yup.object().shape({
  firstName: Yup.string().required("Informe seu primeiro nome"),
  lastName: Yup.string().required("Informe seu último nome"),
  zipCode: Yup.string(),
  username: Yup.string().required("Informe seu nome de usuário"),
  email: Yup.string().required("Informe seu email"),
  doc: Yup.string()
    .required("Informe seu CPF")
    .test("doc", "Cpf precisa ser válido", function (doc: any) {
      if (doc && doc.length) {
        let res = doc.replace(/\D/g, "");
        if (res.length < 11) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    }),
  birthDate: Yup.date()
    .required("Informe sua data de nascimento")
    .test(
      "age",
      "Você precisa ter no mínimo 16 anos",
      function (birthDate: any) {
        const cutoff = new Date();
        cutoff.setFullYear(cutoff.getFullYear() - 16);
        return birthDate <= cutoff;
      }
    ),
  phone: Yup.string().required("Informe seu Celular"),
  gender: Yup.string().required("Informe seu gênero"),
  uf: Yup.string().required("Informe seu estado"),
  city: Yup.string().required("Informe sua cidade").nullable(),
  neighborhood: Yup.string().required("Informe seu bairro"),
  street: Yup.string().required("Informe sua rua"),
  streetNumber: Yup.string().required("Informe o número do seu endereço"),
  additionalAddressInfo: Yup.string(),
  appUsername: Yup.string(),
});

const genderOptions = [
  { value: "female", label: "Feminino" },
  { value: "male", label: "Masculino" },
  { value: "notInformed", label: "Prefiro não informar" },
];

const Account = () => {
  const { loading } = useContext(AuthContext);
  const { SubmitUpdateAccount } = AccountRequisitions();
  const { getStates, getCities, getAddressByZipcode } = useCommonRequisitions();
  const [user, setUser] = useState<any>({} as any);
  const [states, setStates] = useState<IStates[]>([]);
  const [cities, setCities] = useState<ICities[]>([]);
  const [stateSelected, setStateSelected] = useState<any>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const imageRef = useRef<HTMLInputElement>(null);
  const [defaultUserImage, setDefaultUserImage] = useState("");

  const [selectedFile, setSelectedFile] = useState();

  const types = ["image/png", "image/jpg", "image/jpeg"];

  const accountInitialValues: IAccountFormValues = {
    firstName: user.firstName ?? "",
    lastName: user.lastName ?? "",
    zipCode: user.zipCode ?? "",
    uf: user.state?.uf ?? "",
    stateId: user.stateId ?? "",
    city: user.city?.name ?? "",
    neighborhood: user.neighborhood ?? "",
    street: user.street ?? "",
    streetNumber: user.streetNumber ?? "",
    additionalAddressInfo: user.additionalAddressInfo ?? "",
    username: user.username ?? "",
    email: user.email ?? "",
    doc: user.doc ?? "",
    birthDate: user.birthDate?.split("T")[0] ?? "",
    phone: user.phone ?? "",
    gender: user.gender ?? "",
    appUsername: user.appUsername ?? "",
  };

  const submitUpdateAccount = async (
    values: IAccountFormValues,
    callback: Function
  ) => {
    console.log(values);
    const response = await SubmitUpdateAccount(values);
    if (response.status) {
      setIsModalOpenSuccess(true);
    } else {
      setIsModalOpen(true);
      setError(response.message);
    }
    callback();
  };

  const showOpenFileDialog = () => {
    if (imageRef) {
      imageRef?.current?.click();
    }
  };

  const handleChange = async (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    try {
        let data = new FormData();
        data.append('avatar',file);
        
        await api.post('users/avatar', data);

    } catch(e: any) {
        console.log(e);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function submitAvatar(selectedFile: any) {
      let formData = new FormData();
      formData.append("avatar", selectedFile, "test");
      api.post("users/avatar", formData);
    }

    if (selectedFile) {
        const objectURL = URL.createObjectURL(selectedFile);
        setDefaultUserImage(objectURL);
        return () => URL.revokeObjectURL(objectURL);
    }
  }, [selectedFile]);

  useEffect(() => {
    async function getUser() {
      const response = await api.get("users/profile");
      const user = response.data;
      setUser(user);
      setStateSelected(user.stateId);
      setDefaultUserImage(
        !user.avatar
          ? ProfilePicture
          : `${process.env.REACT_APP_API_URL}/media/avatar/${user?.avatar}`
      );     
    }
    if (!loading) {
      getUser();
    }
  }, [loading]);

  useEffect(() => {
    async function getState() {
      const populatedStates = await getStates();
      setStates(populatedStates);
    }
    getState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getCity() {
      const cities = await getCities(stateSelected);
      setCities(cities);      
    }

    if (stateSelected) {
      getCity();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSelected]);

  const handlePassword = () => {
    history.push("/trocar-senha");
  };

  return (
    <BasePage
      title="Perfil"
    >
      <AccountStyle>
        {loading && <div className="">Carregando ...</div>}
        {!loading && (
          <>
            <Formik
              initialValues={accountInitialValues}
              validationSchema={AccountSchema}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize
              onSubmit={(
                values: IAccountFormValues,
                { setSubmitting }: FormikHelpers<IAccountFormValues>
              ) => {
                submitUpdateAccount(values, function () {
                  setSubmitting(false);
                });
              }}
            >
              {(formik) => {
                return (
                  <div id="register-form">
                    <Form className="common-form">
                      <SectionTitle title="Foto" icon={<UserIcon />} />
                      <div className="image-change">
                        <div className="user-image">
                          <img
                            src={defaultUserImage}
                            alt={user?.firstName}
                            width="145"
                            height="145"
                          />
                          <span onClick={() => showOpenFileDialog()}>
                            <Edit />
                          </span>
                        </div>
                        <input
                          ref={imageRef}
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={handleChange}
                        />
                      </div>

                      <SectionTitle
                        title="Dados Cadastrais"
                        icon={<UserIcon />}
                      />
                      <div className="form-block">
                        <InputField
                          name="username"
                          label="Nome de usuário"
                          type="text"
                          disabled
                        />
                        <InputField
                          name="email"
                          label="E-mail"
                          type="email"
                          disabled
                        />
                        <InputField name="firstName" label="Nome" type="text" />

                        <InputField
                          name="lastName"
                          label="Sobrenome"
                          type="text"
                        />

                        <InputField
                          name="doc"
                          label="CPF"
                          type="text"
                          mask="999.999.999-99"
                          disabled
                        />

                        <InputField
                          name="birthDate"
                          label="Data de nascimento"
                          type="date"
                          disabled
                        />

                        <InputField
                          name="phone"
                          label="Celular"
                          type="text"
                          mask="(99) 9 9999-9999"
                        />

                        <SelectField
                          label="Gênero"
                          name="gender"
                          options={genderOptions}
                          placeholder="Selecione o seu gênero"
                        />
                      </div>
                      <SectionTitle
                        title="Dados de endereço"
                        icon={<AddressIcon />}
                      />
                      <div className="form-block">
                        <InputField
                          name="zipCode"
                          label="CEP"
                          type="text"
                          mask="99999-999"
                          onChangeCallback={async (value) => {
                            const zipcodeClean = value.replace(/\D/g, "");
                            formik.setFieldValue('zipCode', zipcodeClean);
                            if (zipcodeClean.length === 8) {
                              let res = await getAddressByZipcode(zipcodeClean);
                              const state = states.find(
                                (state: any) => state.label.includes(res.uf) === true
                              );
                              if (res.logradouro) {     
                                console.log(cities)                           
                                console.log(res.logradouro)
                                formik.setFieldValue('uf', res.uf);
                                formik.setFieldValue('stateId', state?.value);
                                formik.setFieldValue('city', res.localidade);
                                console.log(user.city.name)
                                formik.setFieldValue('neighborhood', `${res.bairro}`);
                                formik.setFieldValue('street', `${res.logradouro}`);
                                console.log(state?.value)
                                setStateSelected(state?.value);
                              }
                            }
                          }}
                        />

                        <SelectField
                          label="Estado"
                          name="stateId"
                          options={states}
                          placeholder="Selecione seu Estado"
                          isDisabled={true}                          
                        />

                        <SelectField
                          id="city"
                          label="Cidade"
                          name="city"
                          options={cities}
                          placeholder="Selecione sua cidade"
                          isDisabled={true}  
                        />

                        <InputField
                          name="neighborhood"
                          label="Bairro"
                          type="text"
                          disabled  
                        />

                        <InputField name="street" label="Rua" type="text" disabled/>
                        <InputField
                          name="streetNumber"
                          label="Número"
                          type="text"
                        />
                        <InputField
                          name="additionalAddressInfo"
                          label="Complemento"
                          type="text"
                        />
                      </div>

                      <div className="buttons_account">
                        <Button
                          themeColor="green"
                          eventCallback={handlePassword}
                          label="ALTERAR SENHA"
                        />
                        <Button
                          classButton="submit-button"
                          hasLoader={true}
                          showLoading={formik.isSubmitting}
                          disabled={formik.isSubmitting}
                          label="ATUALIZAR DADOS"
                          type={"submit"}
                        />
                      </div>

                      {isModalOpen && (
                        <Modal
                          isOpen={isModalOpen}
                          onCloseModal={() => setIsModalOpen(false)}
                          classStatus="error"
                          title="Erro"
                          text={error}
                        />
                      )}

                      {isModalOpenSuccess && (
                        <Modal
                          isOpen={isModalOpenSuccess}
                          onCloseModal={() => setIsModalOpenSuccess(false)}
                          classStatus="success"
                          title="=)"
                          text={"Perfil atualizado com sucesso"}
                        />
                      )}
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </>
        )}
      </AccountStyle>
    </BasePage>
  );
};

export default Account;
