import React, { createContext } from 'react';
import UserHooks from './UserHooks';

interface IUserResponse {
  status: boolean,
  message: string
}
interface userResponseAuth {
  name: string;
  email: string;
  isAdmin: boolean;
  isLeagueManager: boolean;
  leagueStatus: string;
}

export interface AuthContextData {
  loading: boolean;
  handleLogin(userData: object): Promise<boolean>;
  handleRegister(userData: object): Promise<IUserResponse>;
  handleLogout(): void;
  managerStatus: string;
  user: any;
  handleManagerStatus(status: string): void;
  handleLoading(status: boolean): void;
  userInfo(): userResponseAuth;
  authed(): boolean;
  handleIsLeagueManagerOfThisTournament(managerEmail: string): boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const {
    loading,
    managerStatus,
    handleManagerStatus,
    authed,
    handleLogin,
    handleLogout,
    handleLoading,
    handleRegister,
    userInfo,
    user,
    handleIsLeagueManagerOfThisTournament
  } = UserHooks();

  return (
    <AuthContext.Provider value={{
      loading,
      authed,
      managerStatus,
      handleManagerStatus,
      handleLogin,
      handleLoading,
      userInfo,
      user,
      handleRegister,
      handleLogout,
      handleIsLeagueManagerOfThisTournament
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };