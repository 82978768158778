import styled from 'styled-components'

export const InputFieldStyle = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    position: relative;

    /* &:first-child{
        margin-top: 0rem;
    } */

    // &.input-checkbox{
    //     label{
    //         order: 2;
    //     }

    //     input{
    //         order: 1;
    //     }
    // }

    label{
        color: var(--primary);
        font-size: 1.5rem;
        font-family: var(--font-primary-bold);
        transition: color .3s;
    }

    input:not([type="checkbox"]){
        width: 100%;
        height: 5rem;
        background-color: var(--color-white);
        margin: .9rem 0 .5rem;
        padding: 0 2rem;
        font-family: var(--font-primary-regular);
        border: 1px solid rgba(204,204,204,.80);
        transition: border-color .3s;
        color: var(--color-gray);
        font-size: 1.5rem;
        font-family: var(--font-primary-regular);

        &::-webkit-input-placeholder {
            color: var(--color-gray);
            font-size: 1.5rem;
            font-family: var(--font-primary-regular);
        }
        &:-moz-placeholder {
            color: var(--color-gray);
            font-size: 1.5rem;
            font-family: var(--font-primary-regular);
        }
        &::-moz-placeholder {
            color: var(--color-gray);
            font-size: 1.5rem;
            font-family: var(--font-primary-regular);
        }
        &:-ms-input-placeholder {
            color: var(--color-gray);
            font-size: 1.5rem;
            font-family: var(--font-primary-regular);
        }

        &:focus{
            border-color: var(--primary);
        }
    }

    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked) {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }

    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label{
        position: relative;
        padding-left: 2.5rem;
        font-size: 1.4rem;
        font-weight: 500;
        cursor: pointer;
        display: inline-block;
        color: #707070;
    }

    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        transition: border-color .3s;
        border: 1px solid #707070;
    }

    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:after {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 3px;
        background: #707070;
        position: absolute;
        top: 4px;
        left: 4px;
        transition: all .3s cubic-bezier(.04,.96,.65,1.3);
    }

    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    [type="checkbox"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    .error-message{
        color: var(--danger);
        font-size: 1.2rem;
        height: 0rem;
        opacity: 0;
        transition: height .3s, opacity .3s;
    }

    &.input-error{
        input{
            border-color: var(--danger);
        }

        .error-message{
            height: 14px;
            opacity: 1;
        }
    }

    input::-webkit-calendar-picker-indicator{
        display: none;
        } 
    }
        @media only screen and (max-device-width: 567px) {
            input::-webkit-inner-spin-button,
            input::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
    }

        input[type="date"]::-webkit-input-placeholder{ 
            visibility: hidden !important;
    } 
`