import React, { useEffect, useContext, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { parseISO, isValid } from "date-fns";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Modal from "../../components/Modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCommonRequisitions from "../../hooks/useCommonRequisitions";
import { validateCpf } from "../../utils/validateCpf";

//context
import { AuthContext } from "./../../contexts/UserContext";
import { IRegisterFormValues } from "../../interfaces/GeneralInterfaces";

//Components
import InputField from "../../components/InputField";
import CheckboxField from "../../components/CheckboxField";
import Button from "../../components/Button";
import SelectField from "../../components/SelectField";

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required("Informe seu primeiro nome"),
  lastName: Yup.string().required("Informe seu último nome"),
  zipCode: Yup.string(),
  username: Yup.string().required("Informe seu nome de usuário"),
  email: Yup.string().required("Informe seu email"),
  doc: Yup.string()
    .required("Informe seu CPF")
    .test("doc", "Cpf precisa ser válido", function (doc: any) {
      if (doc && doc.length) {
        return validateCpf(doc.replace(/\D/g, ""));
      } else {
        return false;
      }
    }),
  birthDate: Yup.string()
    .required("Informe sua data de nascimento")
    .test(
      "age",
      "Você precisa ter no mínimo 16 anos ou data está no formato errado",
      function (birthDate: any) {
        if (birthDate && birthDate.length) {
          const newBirthArray = birthDate.split("/");
          const day = newBirthArray[0];
          const month = newBirthArray[1];
          const year = newBirthArray[2];
          const newDate = parseISO(year + "-" + month + "-" + day);

          if (isValid(newDate)) {
            const cutoff = new Date();
            cutoff.setFullYear(cutoff.getFullYear() - 16);
            return newDate <= cutoff;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    ),
  phone: Yup.string().required("Informe seu celular"),
  gender: Yup.string().required("Informe seu gênero"),
  uf: Yup.string().required("Informe seu estado"),
  city: Yup.string().required("Informe sua cidade").nullable(),
  neighborhood: Yup.string().required("Informe seu bairro"),
  street: Yup.string().required("Informe sua rua"),
  streetNumber: Yup.string().required("Informe o número do seu endereço"),
  additionalAddressInfo: Yup.string(),
  linkApp: Yup.boolean(),
  appUsername: Yup.string(),
  password: Yup.string()
    .required("Informe sua senha")
    .min(8, "Senha muito curta. Informe uma senha com mais de 8 caracteres\n")
    .matches(
      /^.{8,}$/,
      "A senha informada deve conter entre 8 e 20 caracteres\n"
    ),
  confirmPassword: Yup.string()
    .required("Confirme sua senha")
    .oneOf([Yup.ref("password"), null], "Senhas não combinam"),
  confidentiality: Yup.boolean()
    .required("Os termos de confidencialidade devem ser lidos e aceitos.")
    .oneOf([true], "Os termos de confidencialidade devem ser lidos e aceitos."),
});

const genderOptions = [
  { value: "female", label: "Feminino" },
  { value: "male", label: "Masculino" },
  { value: "notInformed", label: "Prefiro não informar" },
];

interface States {
  value: string;
  label: string;
}

interface Cities {
  value: string;
  name: string;
}

const Register = () => {
  const { handleRegister } = useContext(AuthContext);
  const { getStates, getCities, getAddressByZipcode } = useCommonRequisitions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  // const [hasAppLinked, setHasAppLinked] = useState(false);
  const [states, setStates] = useState<States[]>([]);
  const [cities, setCities] = useState<Cities[]>([]);
  // const [stateSelected,setStateSelected] = useState<States>({} as States)
  const [stateSelected, setStateSelected] = useState<any>("");

  const history = useHistory();

  const registerInitialValues: IRegisterFormValues = {
    firstName: "",
    lastName: "",
    zipCode: "",
    uf: "",
    city: "",
    street: "",
    streetNumber: "",
    neighborhood: "",
    additionalAddressInfo: "",
    username: "",
    email: "",
    doc: "",
    birthDate: "",
    phone: "",
    gender: "",
    password: "",
    confirmPassword: "",
    linkApp: false,
    appUsername: "",
    confidentiality: false,
  };

  const submitRegister = async (
    values: IRegisterFormValues,
    callback: Function
  ) => {
    const newBirthArray = values.birthDate.split("/");
    const day = newBirthArray[0];
    const month = newBirthArray[1];
    const year = newBirthArray[2];
    const newDate = parseISO(year + "-" + month + "-" + day);

    const newValues = { ...values, birthDate: newDate };

    const response = await handleRegister(newValues);

    if (response.status) {      
      setTimeout(() => {
        history.push({ pathname: "/home", state: { isFromRegister: true } });
      }, 500);
    } else {
      setIsModalOpen(true);
      setError(response.message);
    }
    callback();
  };

  useEffect(() => {
    async function getState() {
      const populatedStates = await getStates();
      setStates(populatedStates);
    }
    getState();
  }, []);

  useEffect(() => {
    async function getCity() {
      const cities = await getCities(stateSelected);
      setCities(cities);
    }

    if (stateSelected) {
      getCity();
    }
  }, [stateSelected]);

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={registerInitialValues}
        validationSchema={RegisterSchema}
        enableReinitialize
        // onSubmit={(values : IRegisterFormValues)=> {
        //     submitRegister(values);
        //     console.log(values);
        // }}
        onSubmit={(
          values: IRegisterFormValues,
          { setSubmitting }: FormikHelpers<IRegisterFormValues>
        ) => {
          submitRegister(values, function () {
            setSubmitting(false);
          });
        }}
      >
        {(formik) => {
          return (
            <div id="register-form">
              <Form className="common-form">
                <InputField name="firstName" label="Nome" type="text" />

                <InputField name="lastName" label="Sobrenome" type="text" />

                <InputField
                  name="zipCode"
                  label="CEP"
                  type="tel"
                  mask="99999-999"
                  onChangeCallback={async (value) => {
                    // onChangeZipcode(value)
                    const zipcodeClean = value.replace(/\D/g, "");
                    formik.setFieldValue("zipCode", zipcodeClean);
                    if (zipcodeClean.length === 8) {
                      let res = await getAddressByZipcode(zipcodeClean);
                      if (res.logradouro) {
                        const state = states.find(
                          (state) => state.label.includes(res.uf) === true
                        );
                        formik.setFieldValue("uf", res.uf);
                        formik.setFieldValue("stateId", state?.value);
                        formik.setFieldValue("city", res.localidade);
                        formik.setFieldValue("neighborhood", `${res.bairro}`);
                        formik.setFieldValue("street", `${res.logradouro}`);
                        setStateSelected(state?.value);
                      }
                    }
                  }}
                />

                <SelectField
                  label="Estado"
                  name="stateId"
                  options={states}
                  placeholder="Selecione seu Estado"
                />

                <SelectField
                  id="city"
                  label="Cidade"
                  name="city"
                  options={cities}
                  placeholder="Selecione sua cidade"
                />

                <InputField name="neighborhood" label="Bairro" type="text" />

                <InputField name="street" label="Rua" type="text" />
                <InputField
                  name="streetNumber"
                  label="Número"
                  type="number"
                  pattern="\d*"
                />
                <InputField
                  name="additionalAddressInfo"
                  label="Complemento"
                  type="text"
                />

                <InputField
                  name="username"
                  label="Nome de usuário"
                  type="text"
                />

                <InputField name="email" label="E-mail" type="email" />

                <InputField
                  name="doc"
                  label="CPF"
                  type="tel"
                  mask="999.999.999-99"
                />

                <InputField
                  name="birthDate"
                  label="Data de nascimento"
                  type="tel"
                  mask="99/99/9999"
                />

                <InputField
                  name="phone"
                  label="Celular"
                  type="tel"
                  mask="(99) 9 9999-9999"
                />

                <SelectField
                  label="Gênero"
                  name="gender"
                  options={genderOptions}
                  placeholder="Selecione o seu gênero"
                />

                <InputField
                  name="password"
                  label="Digite sua senha"
                  type="password"
                />

                <InputField
                  name="confirmPassword"
                  label="Confirmar"
                  type="password"
                />

                <br />
                <CheckboxField
                  name="confidentiality"
                  label=" "
                  labelWithLink={
                    <>
                      <span>Li e aceito os </span>
                      <a
                        href={`${process.env.REACT_APP_APP_URL}/termo.pdf`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        termos de confidencialidade.
                      </a>
                    </>
                  }
                  type="checkbox"
                  onChangeCallbackBoolean={(value: boolean) =>
                    formik.setFieldValue("confidentiality", value)
                  }
                />

                {/* <CheckboxField
                                    name="linkApp"
                                    label="Deseja vincular à conta da plataforma com a do aplicativo de truco?"
                                    type="checkbox"
                                    onChangeCallbackBoolean={(value: boolean) => setHasAppLinked(value)}
                                />

                                 {hasAppLinked && (
                                <InputField
                                    name="appUsername"
                                    label="Usuário do aplicativo"
                                    type="text"
                                />
                                )} */}

                {isModalOpen && (
                  <Modal
                    isOpen={isModalOpen}
                    onCloseModal={() => setIsModalOpen(false)}
                    classStatus="error"
                    title="Erro"
                    text={error}
                  />
                )}
                {/* <Button classButton="submit-button" hasLoader={true} label="FAZER CADASTRO" type={'submit'} /> */}
                <Button
                  classButton="submit-button"
                  hasLoader={true}
                  showLoading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  label="FAZER CADASTRO"
                  type={"submit"}
                />
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default Register;
