import React from 'react';

import { PointsWidgetStyle, PointsContentStyled } from './style';

import { IPointsWidget } from '../../interfaces/GeneralInterfaces';

const PointsWidget: React.FC<IPointsWidget> = ({
    icon,
    points,
    text
}) => {
    return (
        <PointsWidgetStyle>
            <div className="icon">{icon}</div>
            <PointsContentStyled>
                <div className="points-number">{points}</div>
                <div className="points-text">{text}</div>
            </PointsContentStyled>
        </PointsWidgetStyle>
    );
}

export default PointsWidget;