import styled from 'styled-components'

export const PrintStyle = styled.div`

.div-badge-user {
    position: relative;
    flex-direction: column;
    display: flex;
    align-items: center;
    .circle-image {
        border: 1px solid var(--color-purple);
        border-radius: 50%;
        width: 90px;
        height: 90px;
        position: absolute;
        margin-top: 30px;
    }
    .user-image {
        border: 1px solid var(--color-white);
        border-radius: 50%;
        width: 80px;
        height: 80px;
        position: absolute;
        margin-top: 4px;
        margin-left: 4px;
    }
    .user-name {
        position: absolute;
        margin-top: 120px;
    }
    .user-fullname {
        position: absolute;
        margin-top: 150px;
    }
    .user {
        position: absolute;
        margin-top: 170px;
    }
    .ranking {
        color: var(--color-purple);
        font-weight: 600;
        position: absolute;
        margin-top: 195px;
        .icon-ranking {
            margin-right: 10px;
        }
    }
}

`