import React from 'react';

import Select from "react-select";
import SelectFieldStyle from './style';
import { ErrorMessage, useField } from 'formik'

const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        fontSize: state.isSelected ? 'bold' : 'regular',
        padding: 10,
    }),
    singleValue: (provided: any, state: any) => {
        const opacity = 1;
        const borderRadius = 0;

        return { ...provided, opacity, borderRadius };
    },
    control: (provided: any, state: any) => {
        const minHeight = 50;

        return { ...provided, minHeight};
    },
    indicatorSeparator: (provided: any, state: any) => {
        const display = 'none';

        return { ...provided, display};
    },
    noOptionsMessage: (provided: any, state: any) => {
        const color = 'var(--primary)';

        return { ...provided, color};
    },
    dropdownIndicator: (provided: any, state: any) => {
        const color = 'var(--primary)';

        return { ...provided, color};
    },
}

const customThemes = {
    primary: 'var(--primary)',
    primary75: 'var(--primary75)',
    primary50: 'var(--primary50)',
    primary25: 'var(--primary25)',
    danger: 'var(--danger)',
    dangerLight: 'var(--dangerLight)',
}

interface PropsType {
    [x: string]: any;
    name: string;
  }


const SelectField: React.FC<PropsType> = ({ label,onChangeCallback, ...props }) => {
    const [field, meta, helpers] = useField(props);

    const getValue = () => {
        if (props.options && props.options.length > 0) {
          return  props.options.find((option : any) => option.value === field.value);
        } else {
          return ("" as any);
        }
      };

    return (
        <SelectFieldStyle className={'input-select' + (meta.error ? ' input-error' : '')}>
            <label htmlFor={`select-${props.name}`}>{label}</label>
            <Select
                {...props}
                options={props.options}
                name={field.name}
                value={getValue()}
                onChange={(option: any) => {
                    helpers.setValue(option.value);
                    onChangeCallback && onChangeCallback(option.value);
                }}
                styles={customStyles}
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        ...customThemes
                    },
                })}
            />
            { meta.error && <div className="error-message"><ErrorMessage name={field.name} /> </div>  }
        </SelectFieldStyle>
    );
}

export default SelectField;