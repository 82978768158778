import styled from 'styled-components'

export const HomeStyle = styled.section`
    background:#FFF;
    min-height:500px;
    width: 100%;

    .information-top {
        display: flex;
        justify-content: space-between;

        @media(max-width:900px){
          flex-direction: column-reverse;
        }
    }

    .left-information{
        width: 100%;
        @media(max-width:1280px){
          width:70%;
        }
        @media(max-width:900px){
          width:100%;
        }
    }
    
    .right-information {
        margin-left: 3rem;
        word-break: break-all;
        @media(max-width:1280px){
          width:30%;
        }

        @media(max-width:900px){
          margin-left: 0rem;
          width:100%;
        }
    }

    .tournaments {
        margin: 3rem 0;
    }

    .blog-section-title{
      margin-bottom: 0.5rem;
    }

`