import { useState, useEffect, useContext, useCallback } from "react";
import api from "../../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../../../contexts/UserContext";
import { BuildTeamsStyle, TeamStyle, Wrapper } from "./style";
import SectionTitle from "../../../components/SectionTitle";
import PairIcon from "../../../assets/svg/PairIcon";
import CloseIcon from "../../../assets/svg/CloseIcon";
import Select from "react-select";
import TableStyle from "../../../components/Table/style";
import { InputFieldStyle } from "../../../components/InputField/style";
import {
  IUser,
  ITeam,
  IApprovedResponse,
} from "../../../interfaces/GeneralInterfaces";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import ModalRemovePlayer from "../../../components/ModalRemovePlayer";
import { TournamentContext } from "../Context/TournamentContext";
import { TournamentStatus } from "../../../enums/GeneralEnums";

interface IBuildTeamsProps {
  tournamentId: string;
  teamCount: number;
  withoutRound: boolean;
  refProp?: any;
}

const BuildTeams = ({
  tournamentId,
  teamCount,
  withoutRound = false,
  refProp,
}: IBuildTeamsProps) => {
  const { loading } = useContext(AuthContext);
  const { handleStartGame, matchResponse, tournamentStatus } =
    useContext(TournamentContext);
  const [approvedPlayers, setApprovedPlayers] = useState<IUser[]>(
    {} as IUser[]
  );
  const [cloneApprovedPlayers, setCloneApprovedPlayers] = useState<IUser[]>(
    {} as IUser[]
  );
  const [teams, setTeams] = useState<ITeam[]>({} as ITeam[]);
  const [cloneTeams, setCloneTeams] = useState<ITeam[]>(teams);
  const [loadingData, setLoadingData] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [isModalRemoveOpen, setIsModalRemoveOpen] = useState(false);
  const [teamToModal, setTeamToModal] = useState<ITeam>({} as ITeam);
  const [userToModal, setUserToModal] = useState<IUser>({} as IUser);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSelectTeamModalOpen, setIsSelectTeamModalOpen] =
    useState<boolean>(false);
  const [teamConfirmed, setTeamConfirmed] = useState<any>({});
  const [isEditingTeamName, setIsEditingTeamName] = useState<boolean>(false);
  const [newTeamName, setNewTeamName] = useState<string>("");
  const hasStarted = !!matchResponse?.rounds;

  useEffect(() => {
    async function getApprovedPlayers() {
      const response = await api.get(
        `tournaments/${tournamentId}/users?status=approved&limit=900`
      );
      let users: IUser[] = [];
      response.data.subscribedPlayers.forEach((data: IApprovedResponse) => {
        users.push(data.user);
      });
      setApprovedPlayers(users);
      setCloneApprovedPlayers(users);
    }
    if (!loading) {
      getApprovedPlayers();
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      getTeams();
    }
  }, [approvedPlayers, loading]);

  const submitPlayerToTeam = async (team: ITeam, user: IUser) => {
    try {
      const newTeam = {
        teamId: team.id ?? null,
        teamName: team.name,
        userId: user.id,
        tournamentId: tournamentId,
      };
      const response = await api.post("/teams", newTeam);

      let teamData: ITeam = {
        id: response.data.id,
        name: response.data.name,
        label: response.data.name,
        visible: response.data.users.length >= teamCount ? false : true,
        value: response.data.name,
        users: response.data.users,
      };

      const index = teams.findIndex((x) => x.id === newTeam.teamId);
      teams[index] = teamData;

      setLoadingData(true);
      setTeams(teams);
      setCloneTeams(teams);
      setLoadingData(false);

      toast.info(
        `${
          user.firstName + " " + user.lastName
        } Adicionado com sucesso ao time ${team.name}`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } catch (e: any) {
      if (team.users.length === teamCount) {
        toast.error(`🦄 Este time já possui ${teamCount} jogadores`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      console.log(e);
    }
  };

  async function getTeams() {
    const response = await api.get(`teams/${tournamentId}`);
    let newTeams: ITeam[] = [];

    response.data.forEach((team: any) => {
      if (team.id && team.name !== "BYE")
        newTeams.push({
          id: team.id,
          name: team.name,
          label: team.name,
          visible: team.users.length >= teamCount ? false : true,
          value: team.name,
          users: team.users,
        });
    });

    setLoadingData(true);
    setTeams(newTeams);
    setCloneTeams(newTeams);
    setLoadingData(false);
  }

  const removerPlayerFromTeam = async (team: ITeam, user: IUser) => {
    try {
      const newTeam = {
        userId: user.id,
        tournamentId: tournamentId,
      };

      if (team.id) {
        const response = await api.patch(`/teams/${team.id}/remove`, newTeam);
        const index = teams.findIndex((x) => x.id === team.id);

        let teamData: ITeam = {
          id: response.data.id,
          name: response.data.name,
          label: response.data.name,
          visible: response.data.users.length >= teamCount ? false : true,
          value: response.data.name,
          users: response.data.users,
        };

        teams[index] = teamData;

        setLoadingData(true);
        setTeams(teams);
        setCloneTeams(teams);
        setLoadingData(false);
      }

      toast.warn(
        `${user.firstName + " " + user.lastName} removido com sucesso do time ${
          team.name
        }`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleFilterTable = (e: React.FormEvent<HTMLInputElement>) => {
    const typedValues = e.currentTarget.value;
    const newPlayers =
      typedValues === ""
        ? approvedPlayers
        : approvedPlayers.filter((user: IUser) => {
            return (
              user.firstName
                .toLowerCase()
                .includes(typedValues.toLowerCase()) ||
              user.lastName.toLowerCase().includes(typedValues.toLowerCase()) ||
              user.doc.includes(typedValues) ||
              user.phone.includes(typedValues)
            );
          });

    setCloneApprovedPlayers(newPlayers);

    let filteredTeams = teams.filter((team) => {
      return team.users.some((user) => {
        return (
          user.firstName.includes(typedValues) ||
          user.lastName.includes(typedValues) ||
          user.doc.includes(typedValues)
        );
      });
    });
    const newTeams = typedValues === "" ? teams : filteredTeams;
    setCloneTeams(newTeams);
  };

  const handleValidationStartGame = () => {
    const validTeams = teams.filter((team: ITeam) => team.users.length > 1);

    if (validTeams.length >= 2) {
      handleStartGame();
      setTimeout(() => {
        refProp.current.scrollIntoView({ block: "end", behavior: "smooth" });
      }, 500);
    } else {
      setIsModalErrorOpen(true);
    }
  };

  const handleCloseModalRemovePlayer = () => {
    setIsModalOpen(true);
    setIsModalRemoveOpen(false);
  };

  const handleRemovePlayer = (team: ITeam, user: IUser) => {
    setTeamToModal(team);
    setUserToModal(user);
    setIsModalRemoveOpen(true);
  };

  const handleEditTeamName = async (teamId: number) => {
    const params: any = {
      newTeamName,
    };

    await api.put(`/teams/${teamId}`, params);

    getTeams();
  };

  return (
    <>
      <BuildTeamsStyle>
        <ToastContainer />
        <InputFieldStyle>
          <label htmlFor={"filter"}>Filtro</label>
          <input
            type="text"
            placeholder="Pesquise pelos dados do jogador"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleFilterTable(e)
            }
            name="filter"
          />
        </InputFieldStyle>
        <TableStyle>
          <table className="table-team">
            <thead>
              <tr>
                <th>Id</th>
                <th>Jogador</th>
                <th>Telefone</th>
                <th>Selecionar Time</th>
              </tr>
            </thead>
            <tbody>
              {teams.length > 0 &&
                cloneApprovedPlayers?.length > 0 &&
                cloneApprovedPlayers?.map((user: IUser) => {
                  var hasTeam = false;
                  teams.forEach((team) => {
                    team.users.forEach((u) => {
                      if (u.id === user.id) {
                        hasTeam = true;
                      }
                    });
                  });
                  if (!hasTeam) {
                    return (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.firstName}</td>
                        <td>{user.phone}</td>
                        <td>
                          {" "}
                          {teams && (
                            <Select
                              options={teams?.filter((team) => team.visible)}
                              placeholder="Selecione o time"
                              onChange={(team: any) => {
                                setTeamConfirmed({ team, user });
                                setIsSelectTeamModalOpen(true);
                              }}
                            />
                          )}{" "}
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
            </tbody>
          </table>
        </TableStyle>
      </BuildTeamsStyle>
      <TeamStyle>
        {!loadingData &&
          cloneTeams?.length > 0 &&
          cloneTeams.map((team: ITeam, index: number) => {
            if (team.users.length > 0) {
              return (
                <div key={team.name} className="team">
                  <SectionTitle
                    title={team.name}
                    isEditable={
                      tournamentStatus === TournamentStatus.notStarted ||
                      tournamentStatus === TournamentStatus.None
                    }
                    icon={<PairIcon />}
                    onChangeProps={setNewTeamName}
                    handleEditTitle={() => handleEditTeamName(Number(team.id))}
                  />
                  <div className="players">
                    {team.users?.map((user: IUser, key: number) => {
                      return (
                        <span key={key} className="player_name">
                          {user.firstName}
                          {!hasStarted && (
                            <button
                              onClick={() => removerPlayerFromTeam(team, user)}
                            >
                              <CloseIcon />
                            </button>
                          )}
                          {hasStarted && (
                            <button
                              onClick={() => handleRemovePlayer(team, user)}
                            >
                              <CloseIcon />
                            </button>
                          )}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            }
            // times bye
            // else {
            //   return (
            //     <div key={team.name} className="team white">
            //       <SectionTitle title={team.name} icon={<PairIcon />} />
            //       <div className="players">
            //         {team.users?.length > 0 ? (
            //           team.users?.map((user: IUser, key: number) => (
            //             <span key={key}>
            //               {user.firstName + " " + user.lastName}
            //               {tournamentStatus !== TournamentStatus.finished && (
            //                 <button
            //                   onClick={() => removerPlayerFromTeam(team, user)}
            //                 >
            //                   <CloseIcon />
            //                 </button>
            //               )}
            //             </span>
            //           ))
            //         ) : (
            //           <>
            //             <span>Usuário 1</span>
            //             <span>Usuário 2</span>
            //             {teamCount === 3 ? <span>Usuário 3</span> : <></>}
            //           </>
            //         )}
            //       </div>
            //     </div>
            //   );
            // }
          })}
      </TeamStyle>
      <Wrapper>
        {!withoutRound &&
          (tournamentStatus === TournamentStatus.None ||
            tournamentStatus === TournamentStatus.notStarted) && (
            <div className="start-game">
              <Button
                type="button"
                eventCallback={handleValidationStartGame}
                themeColor="green"
                label="INICIAR RODADA"
              />
            </div>
          )}
        {withoutRound && (
          <div className="orientation">
            Após organizar os times, acesse a aba de <strong>Ranking</strong>{" "}
            para informar os resultados.
          </div>
        )}

        {isModalErrorOpen && (
          <Modal
            isOpen={isModalErrorOpen}
            onCloseModal={() => setIsModalErrorOpen(false)}
            classStatus="error"
            title="Erro"
            text={"Número de times ou jogadores aprovados muito baixo"}
          />
        )}

        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onCloseModal={() => setIsModalOpen(false)}
            classStatus="success"
            title="Sucesso"
            text={"Usuário recusado do time com sucesso."}
          />
        )}

        {isModalRemoveOpen && (
          <Modal
            isOpen={isModalRemoveOpen}
            onCloseModal={() => setIsModalRemoveOpen(false)}
            classStatus="success"
            title="Convidar Jogadores"
          >
            <ModalRemovePlayer
              tournamentId={Number(tournamentId)}
              team={teamToModal}
              user={userToModal}
              closeModal={() => handleCloseModalRemovePlayer()}
            />
          </Modal>
        )}
        {isSelectTeamModalOpen && (
          <Modal
            isOpen={isSelectTeamModalOpen}
            onCloseModal={() => setIsSelectTeamModalOpen(false)}
            classStatus="warning"
            title="Erro"
          >
            <span>
              Deseja inserir o jogador {`${teamConfirmed.user.username}`} em{" "}
              {`${teamConfirmed.team.label}`}?
            </span>
            <div className="actions">
              <Button
                eventCallback={() => {
                  submitPlayerToTeam(teamConfirmed.team, teamConfirmed.user);
                  setIsSelectTeamModalOpen(false);
                }}
                type="submit"
                label="SIM"
              />
              <Button
                eventCallback={() => setIsSelectTeamModalOpen(false)}
                label="CANCELAR"
              />
            </div>
          </Modal>
        )}
      </Wrapper>
    </>
  );
};

export default BuildTeams;
