import styled from 'styled-components'

export const QuizStyle = styled.div`

    table{
        thead{
            th{
                &:last-of-type{
                    div{
                        text-align:right;
                        justify-content:flex-end;
                    }
                    
                }
            }
        }
        tbody{
            td{
                &:last-of-type{
                    display:flex;
                    justify-content:flex-end;
                    button{
                        margin-left:15px;
                    }

                }
            }
        }
    
        @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
            td:nth-of-type(1):before { content: "Nome"; }
            td:nth-of-type(2):before { content: "Categoria"; }
            td:nth-of-type(3):before { content: "Status"; }
        }
    }
`