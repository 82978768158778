import { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import SectionTitle from "../../../components/SectionTitle";
import Cards from "../../../assets/svg/Cards";
import TablePagination from "../../../components/TablePagination";
import Button from "../../../components/Button";

import { AuthContext } from "./../../../contexts/UserContext";
import api from "../../../services/api";

import { LeaguesStyle, ActionsStyle } from "./style";
import ModalConfirm from "../../../components/ModalConfirm";

interface IManagerBadgeDataTable {
  id: string;
  name: string;
  order: number;
  actions?: any;
}

interface IManagerBagdeFilter {
  search?: string;
}

const Leagues = () => {
  const { loading } = useContext(AuthContext);
  const [total, setTotal] = useState<number>(0);
  const [managerBadgesData, setManagerBadgesData] = useState<
    IManagerBadgeDataTable[]
  >({} as IManagerBadgeDataTable[]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showDeleteBadgeModal, setShowDeleteBadgeModal] = useState(false);
  const [deleteBadgeId, setDeleteBadgeId] = useState<number>(0);
  const [filterOptions, setFilterOptions] = useState<IManagerBagdeFilter>(
    {} as IManagerBagdeFilter
  );
  const history = useHistory();

  const renderButtonActions = (values: any) => {
    console.log("values", values);
    return (
      <ActionsStyle>
        <Button
          classButton={"small"}
          label={"Editar"}
          eventCallback={(e: any) => {
            alert('1')
            history.push(
              `administrador/status-de-organizador/${values.value[2]}`
            );
          }}
        />
        <Button
          classButton={"small"}
          label={"Deletar"}
          eventCallback={(e: any) => {
            alert(2)
            handleConfirmRemoveModal(values.value[2])
          }}
        />
      </ActionsStyle>
    );
  };

  const columnsManagerBadgeTable = [
    {
      Header: "ID DO STATUS",
      accessor: "id",
    },
    {
      Header: "ORDER",
      accessor: "order",
      sortType: "caseInsensitive",
    },
    {
      Header: "NOME",
      accessor: "name",
      sortType: "caseInsensitive",
    },
    {
      Header: "",
      accessor: "actions",
      Cell: renderButtonActions,
    },
  ];

  const handleSearch = () => getManagerBadges();

  const handleClickRow = (row: any) => {
    history.push(`/administrador/status-de-organizador/${row.id}`);
  };

  const handleRemoveBadge = async (userId: number) => {
    const response = await api.delete(`manager/badges/${userId}`);
    if (response.data.length > 0) {
      getManagerBadges();
    }

    setShowDeleteBadgeModal(false);
  };

  const getManagerBadges = async () => {
    const response = await api.post(
      `manager/badges/list?page=${page}&limit=${perPage}`,
      { filterOptions }
    );
    console.log("response", response);
    setTotal(response.data.total);

    if (response.data.managerBadges) {
      setManagerBadgesData(
        response.data.managerBadges.map(
          (badgeItem: IManagerBadgeDataTable) => ({
            ...badgeItem,
            actions: ["Editar", "Excluir", badgeItem.id],
          })
        )
      );
    }
  };

  const handleConfirmRemoveModal = (userId: number) => {
    setDeleteBadgeId(userId);
    setShowDeleteBadgeModal(true);
  };

  useEffect(() => {
    if (!loading) {
      getManagerBadges();
    }
  }, [loading, page]);

  return (
    <LeaguesStyle>
      {loading && <div className="">Carregando...</div>}
      {!loading && (
        <>
          <div className="filter_tournaments">
            <input
              name="search"
              type="text"
              className="search"
              placeholder="Pesquise por nome"
              onChange={(e: any) => {
                let newValue = { ...filterOptions };
                newValue.search = e.target.value;
                setFilterOptions(newValue);
              }}
            />
            <div className="search_button_area">
              <Button label="Pesquisar" eventCallback={handleSearch} />
            </div>
          </div>
        </>
      )}
      {!loading && managerBadgesData.length > 0 ? (
        <>
          <SectionTitle icon={<Cards />} title={"Ligas"} />

          <TablePagination
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={page}
            totalPage={Math.ceil(total / perPage)}
            columnsWithOutMemo={columnsManagerBadgeTable}
            dataWithOutMemo={managerBadgesData}
            // handleClick={handleClickRow}
          />
        </>
      ) : (
        <span>Nenhum registro encontrado.</span>
      )}

      <div className="buttons">
        <Link to={"/administrador/status-de-organizador"}>
          <Button label="Novo Status de Organizador" type="button" />
        </Link>
      </div>

      <ModalConfirm
        title="Deseja excluir o usuário?"
        isOpen={showDeleteBadgeModal}
        handleConfirm={() => handleRemoveBadge(deleteBadgeId)}
        handleCloseConfirm={() => setShowDeleteBadgeModal(false)}
      />
    </LeaguesStyle>
  );
};

export default Leagues;
