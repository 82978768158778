import { useContext } from "react";
import { AuthContext } from "../../../contexts/UserContext";
import BasePage from '../../../components/BasePage';
import SectionTitle from '../../../components/SectionTitle';
import Cards from '../../../assets/svg/Cards';
import CreateOrEditTournament from "../../../components/CreateOrEditTournament";
import { useParams } from "react-router-dom";

interface ParamTypes {
    id: string;
}

const description = () => {
    return (
        <>
            <SectionTitle customClass="title-create-tournament" title="Agendar Torneio" icon={<Cards />} />
            {/* <Text customClass="league-manager-text">
                Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit. Nullam hendrerit maximus fringilla.</strong> Etiam vitae egestas ante. Proin vestibulum nisl est, at feugiat odio porta vel. In sit amet nisi enim. Cras porta diam a nisi malesuada, vel volutpat mi feugiat. Nulla scelerisque pellentesque nibh, non lacinia tortor dignissim et.
            <br />
            Morbi imperdiet fermentum ex. Pellentesque id nibh mollis, accumsan velit ut, scelerisque quam. Praesent semper felis euismod ante efficitur, ullamcorper tempor ipsum fermentum. Nullam quis ex mi. Maecenas rutrum, lacus id volutpat rutrum, magna ex maximus tortor, quis scelerisque sem nisi eu lorem.
        </Text> */}
        </>
    )
}

const EditTournament = () => {
    let { id } = useParams<ParamTypes>();
    const { user } = useContext(AuthContext);

    return (
        <BasePage title={'Torneios'}>
            <CreateOrEditTournament category='Tournament' Description={description} edit={true} tournamentId={id} displayForm={true} />
        </BasePage>
    )
}

export default EditTournament;