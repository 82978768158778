import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './../contexts/UserContext';
import { IRoutes } from './../interfaces/GeneralInterfaces';

const PrivateRoute: React.FC<IRoutes> = ({
    component: Component,
    path,
    exact = true
}) => {
    const { authed } = useContext(AuthContext);

    return (
        <Route path={path} exact={exact} render={props => (
            authed() ?
                <Component {...props} />
                : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;