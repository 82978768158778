import { useEffect, useState, useContext } from "react";
import { RoundStyle, NextRoundStyle, FinishRoundStyle } from "./style";
import {
  IMatch,
  IResponseTeam,
  ITournament,
  IUser,
} from "../../../../interfaces/GeneralInterfaces";
import { AuthContext } from "../../../../contexts/UserContext";
import { TournamentContext } from "../../Context/TournamentContext";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import Match from "./Match";
import { TournamentStatus } from "../../../../enums/GeneralEnums";
import ReactLoading from "react-loading";

interface IRoundProps {
  tournament: ITournament;
  matches: IMatch[];
  refProp?: any;
  isCurrentRound: boolean;
  isLastRound: boolean;
  tournamentRef: any;
}

const Round = ({
  matches,
  refProp,
  tournament,
  isCurrentRound,
  isLastRound,
  tournamentRef,
}: IRoundProps) => {
  const [matchesEditables, setMatchesEditables] = useState<IMatch[]>(
    [] as IMatch[]
  );
  const { userInfo, handleLoading, handleIsLeagueManagerOfThisTournament } =
    useContext(AuthContext);
  const { handleStartGame, handleFinishGame, tournamentStatus } =
    useContext(TournamentContext);
  const [allMatchesFinished, setAllMatchesFinished] = useState<boolean>(false);
  const [rearrangedMatches, setRearrangedMatches] = useState<IMatch[]>(
    [] as IMatch[]
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [returnStatus, setReturnStatus] = useState<boolean>(false);
  const [returnMessage, setReturnMessage] = useState<string>("");
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

  useEffect(() => {
    const filteredEditableMatches = matches.filter((match: IMatch) => {
      return match.teams.some((team: IResponseTeam) => {
        return team.users.some(
          (user: IUser) => user.email === userInfo().email
        );
      });
    });

    setMatchesEditables(filteredEditableMatches);

    setAllMatchesFinished(
      !matches.some((match: IMatch) => match.status !== "finished")
    );
  }, [matches, matches]);

  useEffect(() => {
    if (matchesEditables.length > 0) {
      let oldMatches = [...matches];
      const currentMatch = oldMatches.filter(
        (match: IMatch) => match.id === matchesEditables[0].id
      );
      const index = oldMatches.indexOf(currentMatch[0]);
      oldMatches.splice(index, 1);
      oldMatches.unshift(matchesEditables[0]);
      setRearrangedMatches(oldMatches);
    } else {
      setRearrangedMatches([...matches]);
    }
  }, [matchesEditables, matches]);

  const handleFinishTheGame = async () => {
    handleLoading(true);
    setIsLoadingButton(true);

    const response = await handleFinishGame();
    if (response.status) {
      setReturnStatus(true);
      setReturnMessage("Torneio finalizado com sucesso!");
      setIsModalOpen(true);
    } else {
      setReturnStatus(false);
      setReturnMessage(response.message);
      setIsModalOpen(true);
    }
    setIsLoadingButton(false);
    handleLoading(false);
  };

  const handleOnClick = () => {
    handleStartGame();
    setTimeout(() => {
      tournamentRef.current.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }, 500);
  };

  return (
    <>
      <RoundStyle>
        {rearrangedMatches?.sort((a, b) => {
          if (typeof a.table === 'undefined' || typeof b.table === 'undefined') {
            return 0;
          }

          return (a.table > b.table ? 1 : -1)
        }).map((match: IMatch) => {
          if (
            (handleIsLeagueManagerOfThisTournament(tournament.managerEmail) ||
              matchesEditables.some((m: IMatch) => m.id === match.id)) &&
            match.status !== "finished"
          )
          return (
              <Match
                key={match.id}
                tournament={tournament}
                match={match}
                canEdit={true}
                isCurrentRound={isCurrentRound}
                isLastRound={isLastRound}
              />
            );
          else
            return (
              <Match
                key={match.id}
                tournament={tournament}
                match={match}
                canEdit={false}
                isCurrentRound={isCurrentRound}
                isLastRound={isLastRound}
              />
            );
        })}
      </RoundStyle>
      <NextRoundStyle>
        {allMatchesFinished &&
          handleIsLeagueManagerOfThisTournament(tournament.managerEmail) &&
          isCurrentRound &&
          !isLastRound && (
            <div className="start-game">
              <Button
                type="button"
                eventCallback={handleOnClick}
                themeColor="green"
                label="PRÓXIMA RODADA"
              />
            </div>
          )}
      </NextRoundStyle>
      <FinishRoundStyle>
        {isLastRound &&
          (!matches.some((match) => match.status === "pending") && !matches.some((match) => match.status === "warning")) &&
          handleIsLeagueManagerOfThisTournament(tournament.managerEmail) &&
          tournamentStatus !== TournamentStatus.finished && (
            <div className="finish-game">
              <Button
                type="button"
                disabled={isLoadingButton}
                eventCallback={handleFinishTheGame}
                label={isLoadingButton ? "CARREGANDO..." : "FINALIZAR TORNEIO"}
              />
              )
            </div>
          )}
      </FinishRoundStyle>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onCloseModal={() => setIsModalOpen(false)}
          classStatus={returnStatus ? "success" : "error"}
          title={returnStatus ? "Sucesso!" : "Opss!"}
          text={returnMessage}
        />
      )}
    </>
  );
};

export default Round;
