import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 50.091}
            height={props.height ? props.height : 69.45}
            viewBox="0 0 50.091 69.45"
            {...props}
        >
            <g data-name="bonus(2)" fill={props.color ? 'var(--primary)' : props.color}>
                <path
                    data-name="Caminho 824"
                    d="M49.374 40.619a1.06 1.06 0 00-.542-.937l-17-9.564a1.066 1.066 0 00-1.023-.01l-5.874 3.148-5.873-3.146a1.063 1.063 0 00-1.023.01l-17 9.564a1.062 1.062 0 00.018 1.862l4.2 2.252-4.2 2.252a1.062 1.062 0 00-.018 1.863l5.834 3.281v7.481a1.066 1.066 0 00.542.927l17 9.564a1.056 1.056 0 001.04 0h0l17-9.564a1.065 1.065 0 00.542-.927v-7.481l5.834-3.281a1.063 1.063 0 00-.018-1.863l-4.2-2.252 4.2-2.252a1.063 1.063 0 00.561-.927zm-25.5 7.869l-2.613 1.469-11.5-6.161 14.114-7.562zm1.063 1.84l1.455.818-1.455.78-1.454-.78zm3.676-.371l-2.622-1.469V36.234l14.115 7.562zM3.762 40.582l14.807-8.329 4.114 2.2-15.178 8.13zm3.743 4.416l15.177 8.131-4.114 2.2-14.807-8.321zm1.486 13.054v-5.664l9.044 5.087a1.063 1.063 0 001.023.009l4.811-2.577V66.42zm31.88 0l-14.88 8.369V54.908l4.811 2.577a1.063 1.063 0 001.023-.009l9.043-5.087zm5.231-11.045l-14.811 8.329-4.114-2.2 15.178-8.13zm-3.743-4.416L27.182 34.46l4.114-2.2 14.808 8.322zm0 0"
                    stroke="#fff"
                    strokeWidth={0.3}
                />
                <path
                    data-name="Caminho 829"
                    d="M44.25 21.07a.834.834 0 00-.834.834 4.177 4.177 0 01-4.172 4.172.834.834 0 100 1.669 4.177 4.177 0 014.172 4.172.834.834 0 101.669 0 4.177 4.177 0 014.172-4.172.834.834 0 100-1.669 4.177 4.177 0 01-4.172-4.172.835.835 0 00-.835-.834zm2 5.841a5.888 5.888 0 00-2 2 5.888 5.888 0 00-2-2 5.887 5.887 0 002-2 5.888 5.888 0 002 2zm0 0"
                />
                <path
                    data-name="Caminho 830"
                    d="M5.841 21.07a.834.834 0 00-.834.834 4.177 4.177 0 01-4.172 4.172.834.834 0 100 1.669 4.177 4.177 0 014.172 4.172.834.834 0 101.669 0 4.177 4.177 0 014.172-4.172.834.834 0 100-1.669 4.177 4.177 0 01-4.172-4.172.835.835 0 00-.835-.834zm2 5.841a5.888 5.888 0 00-2 2 5.888 5.888 0 00-2-2 5.887 5.887 0 002-2 5.888 5.888 0 002 2zm0 0"
                />
                <path
                    data-name="Caminho 831"
                    d="M24.931 25.263a12.482 12.482 0 10-12.482-12.481 12.481 12.481 0 0012.482 12.481zM13.697 12.782a11.191 11.191 0 01.329-2.651l5.182 4.364-2.167 6.272a11.2 11.2 0 01-3.344-7.985zm13.746-4.414a.623.623 0 00.5.374l6.7.753-5.13 4.321a.624.624 0 00-.188.681l2.124 6.137-6.372-1.593a.629.629 0 00-.3 0l-6.372 1.593 2.12-6.137a.624.624 0 00-.188-.681L15.21 9.5l6.7-.753a.623.623 0 00.5-.374l2.512-5.86zm-9.024 13.55l6.512-1.628 6.512 1.628a11.157 11.157 0 01-13.024 0zm14.4-1.151l-2.17-6.273 5.182-4.364a11.123 11.123 0 01-3.011 10.637zM35.227 8.3l-6.778-.758L25.898 1.6a11.25 11.25 0 019.329 6.7zM23.963 1.6l-2.549 5.945-6.78.755a11.25 11.25 0 019.329-6.7zm0 0"
                    stroke={props.color ? 'var(--primary)' : props.color}
                    strokeWidth={0.6}
                />
            </g>
        </svg>
    )
}

export default SvgComponent
