import styled from 'styled-components'

export const TableNextsTournamentsStyle = styled.div`
    margin-top:30px;
    display: block;

    @media (max-width: 1088px), (min-width: 1025px)  {
      .small {
        width: 100%; 
        display: inline;
      }
    }
    
    @media (max-width: 760px) {
      .small {
        width: 55%;
      }
    }

  @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
    td:nth-of-type(1):before { content: "Data"; }
    td:nth-of-type(2):before { content: "Hora"; }
    td:nth-of-type(3):before { content: "Local"; }
    td:nth-of-type(4):before { content: "Categoria"; }
    td:nth-of-type(5):before { content: "Liga"; }
    td:nth-of-type(6):before { content: "Nome do Evento"; } 
  }
`
export const NoTournaments = styled.div`
  width: 100%;
  margin: 20px auto 50px;
 
  h1 {
    color: #5a5a5a;
    font-size: 16px;
  }
`;