import styled from 'styled-components'

const QuizStyle = styled.div`
    margin: 3rem 0;

    .group-options.common-block{
        margin: 2rem 0;
        border: 1px solid var(--color-gray-light);
        padding: 2rem;

        .title{
            font-size: 2.5rem;
            font-family: var(--font-secondary-bold);
            color: var(--primary);
            margin-bottom: 2rem;
        }
    }
    .answer{
        display:flex;
        align-items:center;
        label{
            cursor:pointer;
        }
        input{
            margin-right:5px;
            cursor:pointer;
        }
    }
`

export default QuizStyle;