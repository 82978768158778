import { useState, useEffect, useContext } from 'react';
import api from '../../../services/api';

//Assets
import { AnswerQuizStyle } from './style';

//Form
import Quiz from '../../../components/Quiz';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { AuthContext } from '../../../contexts/UserContext';
import BasePage from '../../../components/BasePage';
import { useHistory, useParams } from 'react-router-dom';

interface IQuizResponse {
    id: string;
    name: string;
    category: string;
    points: number;
    createdAt: string;
    status: boolean;
}


interface IQuiz {
    id: number;
    name: string;
    description: string;
    category: string;
    points: number;
    status: boolean | string;
    questions: IQuestion[];
}

interface IQuestion {
    id: string;
    name: string;
    answers: Ianswer[];
}

interface Ianswer {
    name: string;
    isCorrect: boolean;
}

interface ParamTypes {
    id: string;
}

const AnswerQuiz = () => {
    const history = useHistory();
    const { id } = useParams<ParamTypes>();
    const [quiz, setQuiz] = useState<IQuiz>();
    const { loading } = useContext(AuthContext);

    const checkFormIsAnswered = async () => {
        const response = await api.get('forms/list-forms-answered')
        const result: IQuizResponse[] = response.data;

        if (result.some(x => x.id === id))
            history.push(`/questionarios`)
    }

    const getQuiz = async () => {
        const response = await api.get(`forms/${id}`);
        setQuiz(response.data);
    }

    useEffect(() => {
        if (!loading)
            checkFormIsAnswered();

    }, [loading])

    useEffect(() => {
        if (!loading)
            getQuiz();

    }, [loading])

    return (
        <BasePage title="Questionários" subtitle={``}>
            <AnswerQuizStyle id="page-quiz">
                <main className="wrapper-limiter">
                    {quiz &&
                        <div className="common-block quiz-title">
                            <Title>{quiz.name}</Title>
                            <Text>
                                {quiz.description}
                            </Text>
                        </div>
                    }
                    {quiz &&
                        <div id="container-form">
                            <Quiz quizId={quiz.id} isManagerTest={false} quizList={quiz.questions} />
                        </div>
                    }
                </main>
            </AnswerQuizStyle>
        </BasePage>
    );
}

export default AnswerQuiz;