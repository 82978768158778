import styled from 'styled-components'

const TitleStyle = styled.div`
        font-family: var(--font-secondary-bold);
        color: var(--primary);
        font-size: 6rem;
        word-break: break-word;

        &.be-a-manager{
                margin-top: 5rem;
                font-size: 3rem;
        }

        &.tournament-title {
                font-size: 5rem;
        }

        @media(max-width:1024px){
            font-size: 4rem;
        }
`
export default TitleStyle;
