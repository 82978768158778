import styled from 'styled-components'

const TabContentStyle = styled.div`

    margin-top: 2.5rem;

    &.tab-content-enter {
        opacity: 0;
        height: 0px;
    }

    &.tab-content-enter-active {
        opacity: 1;
        height: auto;
        transition: opacity .6s;
    }

    &.tab-content-enter-done{
        height: auto;
        opacity: 1;
    }

    &.tab-content-exit, &.tab-content-exit-done {
        height: auto;
        opacity: 1;
    }

    &.tab-content-exit-active {
        opacity: 0;
        height: 0px;
        transition: opacity .6s;
    }
`

export default TabContentStyle