import { useState, useContext, useEffect } from 'react';
import { AuthContext } from './../../contexts/UserContext';
import { TableMyTournamentsManagerStyle } from './style';
import LoyaltyPoints from '../../assets/svg/LoyaltyPoints';
import SectionTitle from '../../components/SectionTitle';
import { ITournament } from '../../interfaces/GeneralInterfaces';
import { useHistory } from 'react-router-dom';
import TablePagination from '../TablePagination';
import { formatDate, formatHour } from '../../utils/formatData';

interface ITournamemtsValuesForTable {
    date: string;
    category: string;
    state: string;
    hour: string;
    description: [string, number];
    visibility: string;
}

const renderCategory = (values: any) => {
    if (values.value === 'Tournament') {
        return 'Torneio';
    } else if (values.value === 'TrucoCup') {
        return 'Copa Truco';
    } else {
        return 'Estadual Copag';
    }
}

const renderVisibility = (values: any) => {
    if (values.value === 'Public') {
        return 'Público';
    } else {
        return 'Privado';
    }
}

const renderDescription = (values: any) => {
    return values.value[0];
}

interface TableMyTournamentsManagerProps {
    tornamentsInformation: ITournament[];
    page?: number;
    perPage?: number;
    total?: number;
    setPage(page: any): any;
    setPerPage(page: any): any;
}

const TableMyTournamentsManager = ({ tornamentsInformation, page = 1, perPage = 3, total = 1, setPage, setPerPage }: TableMyTournamentsManagerProps) => {
    const history = useHistory();
    const { loading } = useContext(AuthContext);
    const [tournamentDataForTable, setTournamentDataForTable] = useState<ITournamemtsValuesForTable[]>({} as ITournamemtsValuesForTable[])

    const columnsMyTounaments = [
        {
            Header: 'DATA',
            accessor: 'date',
            Cell: formatDate
        },
        {
            Header: 'HORA',
            accessor: 'hour',
            Cell: formatHour
        },
        {
            Header: 'LOCAL',
            accessor: 'state',
        },
        {
            Header: 'CATEGORIA',
            accessor: 'category',
            Cell: renderCategory
        },
        {
            Header: 'VISIBILIDADE',
            accessor: 'visibility',
            Cell: renderVisibility
        },
        {
            Header: 'NOME',
            accessor: 'description',
            Cell: renderDescription
        },
    ];

    const handleClickRow = (row: any) => history.push(`/torneio/${row.description[1]}`)

    useEffect(() => {
        const prepareDataForTable = () => {
            let data: ITournamemtsValuesForTable[] = [];
            if (tornamentsInformation && tornamentsInformation.length > 0) {
                tornamentsInformation.sort((tournament: ITournament, tournament2:ITournament) => {
                    const dateA = new Date(tournament.date).getTime()
                    const dateB = new Date(tournament2.date).getTime()
                     return dateB - dateA
                })

                tornamentsInformation.map((tournament: ITournament) => {
                    return data.push({
                        date: tournament.date,
                        category: tournament.category,
                        visibility: tournament.visibility,
                        state: tournament.state?.name ?? 'Torneio Online',
                        hour: tournament.date,
                        description: [tournament.name, tournament.id]
                    })
                })

                setTournamentDataForTable(data);
            }
        }
        if (tornamentsInformation)
            prepareDataForTable();
    }, [tornamentsInformation])

    return (
        <TableMyTournamentsManagerStyle>
            {loading && (
                <div className="">Carregando...</div>
            )}
            {!loading && (
                tournamentDataForTable.length > 0 && (
                    <div className="next-tournaments">
                        <SectionTitle icon={<LoyaltyPoints color={'var(--primary)'} />} title={'Meus Torneios'} />
                        <TablePagination
                            perPage={perPage}
                            setPage={setPage}
                            setPerPage={setPerPage}
                            currentpage={page}
                            totalPage={Math.ceil(total / perPage)}
                            columnsWithOutMemo={columnsMyTounaments}
                            dataWithOutMemo={tournamentDataForTable}
                            handleClick={handleClickRow}
                        />
                    </div>
                )
            )}
        </TableMyTournamentsManagerStyle>
    )
}

export default TableMyTournamentsManager;