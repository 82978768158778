import React, { useState,useEffect } from 'react';

import { ITabs } from '../../interfaces/GeneralInterfaces';
import TabPane from './TabPane';
import TabContent from './TabContent';
import { TabsStyle } from './style';

const Tabs: React.FC<ITabs> = ({ children, tabActive }) => {
    const [activeTab, setActiveTab] = useState(children && children[0].props.label);
    
    const onClickTab = (tabLabel: any, action?: any) => {
        setActiveTab(tabLabel);
        if(action !== undefined){
            action();
        }
    }
    
    const renderTab = (tab: any) => {
        if (tab.props.children){
            return (
                <TabPane
                    key={tab.props.label}
                    label={tab.props.label}
                    activeTab={activeTab}
                    onClick={() => onClickTab(tab.props.label, tab.props.action)}
                />
            )
        }
    }

    const renderTabContent = (content: any, key: any) => {
        return (
            <TabContent key={key} isActive={content.props.label === activeTab}>
                {content.props.children}
            </TabContent>
        )
    }

    useEffect(() => {
      if(tabActive)
        setActiveTab(tabActive);

    },[tabActive])

    return (
        <TabsStyle data-current={activeTab}>
            <div className="tab-list">
                {children && children.map((children) => renderTab(children))}
            </div>

            <div className="container-content">
                {children && children.map((children, index) => renderTabContent(children, index))}
            </div>
        </TabsStyle>
    );
}

export default Tabs;