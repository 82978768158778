import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/UserContext';
import { useHistory } from 'react-router-dom';
import { HeaderActionsStyle } from './style';
import Modal from '../../Modal';
import Button from '../../Button';
import tournamentTop from '../../../assets/images/tournamentTop.png';
import logout_active from '../../../assets/images/logout_active.png';
import profileTop from '../../../assets/images/profileTop.png';

const HeaderActions: React.FC = ({ ...props }) => {
    const { handleLogout, managerStatus } = useContext(AuthContext);
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const Logout = () => {
        handleLogout();
        history.push("/login");
    }

    const handleMenuItem = () => {
        if (managerStatus === "approved") {
            history.push("/organizador?section=3")
        } else { 
            history.push("/torneios")
        };
    }

    return (
        <HeaderActionsStyle>
            <button type="button" onClick={() => handleMenuItem()} className="tournament" title="Torneios">
                 <img src={tournamentTop} alt="torneio"/> 
            </button>

            <button onClick={() => history.push("/perfil")} type="button" className="profile" title="Perfil">
                <img src={profileTop} alt="perfil"/>
            </button>

            <button onClick={() => setIsModalOpen(true)} type="button" className="auth" title="Sair">
                <img src={logout_active} alt="sair"/> 
            </button>

            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    onCloseModal={() => setIsModalOpen(false)}
                    classStatus='warning'
                    title="Erro"
                >
                    <span>Deseja se desconectar da plataforma? </span>
                    <div className="actions">
                        <Button eventCallback={() => Logout()} label="SIM" /> 
                        <Button eventCallback={() => setIsModalOpen(false)} label="CANCELAR" /> 
                    </div>
                </Modal>
            )}
        </HeaderActionsStyle>
    );
}

export default HeaderActions;