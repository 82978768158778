import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../../components/InputField';
import Button from '../../../../components/Button';

import { useState } from 'react';
import { ModalInvitePlayersStyle } from './style';
import api from '../../../../services/api'

interface IInvitePlayerEmail {
    email: string;
}

const emailsInitialValue: IInvitePlayerEmail = {
    email: "",
};

interface ModalInvitePlayersProps {
    tournamentId: number;
    closeModal(): void;
    shareQRCode?: boolean;
}

const ModalInvitePlayers = ({ tournamentId, closeModal, shareQRCode = false }: ModalInvitePlayersProps) => {
    const [allEmails, setAllEmails] = useState<string[]>([] as string[]);

    const InvitePlayersSchema = Yup.object().shape({
        email: Yup.string().required('Informe seu email'),
    });

    const submitIteration = async (values: IInvitePlayerEmail, callback: Function) => {
        let newAllEmails = [...allEmails];
        newAllEmails.push(values.email);
        setAllEmails(newAllEmails);

        callback();
    }

    const handleSubmitEmails = async () => {
        try {
            if (shareQRCode) {
                await api.post('opasjisdhsdhsuhisjdsjdsd', { emails: allEmails });
            }
            else {
                await api.post(`tournaments/${tournamentId}/invite`, { emails: allEmails });
                closeModal();
            }
        } catch (err: any) {
            console.log(err);
        }
    }

    const handleRemoveEmail = (email: string) => {
        let newAllEmails = [...allEmails];
        const index = newAllEmails.indexOf(email);
        newAllEmails.splice(index, 1);
        setAllEmails(newAllEmails);
    }

    return (
        <ModalInvitePlayersStyle>
            <Formik
                initialValues={emailsInitialValue}
                validationSchema={InvitePlayersSchema}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
                onSubmit={(
                    values: IInvitePlayerEmail,
                    { setSubmitting, resetForm }: FormikHelpers<IInvitePlayerEmail>,
                ) => {
                    submitIteration(values, function () {
                        setSubmitting(false);
                        resetForm({})
                    });
                }}
            >
                {formik => {
                    return (
                        <Form className="formInvitePlayers">
                            <InputField
                                name="email"
                                label="E-mail"
                                type="email"
                            />
                            <Button label="Adicionar" hasLoader={true} showLoading={formik.isSubmitting} disabled={formik.isSubmitting} themeColor="green" type={'submit'} />
                        </Form>
                    )
                }}
            </Formik>
            <ul>
                {allEmails && allEmails.length > 0 && allEmails.map((email: string, index: number) =>
                    <li key={index}>
                        <div className="item">
                            <span>{email}</span>
                            <span className="removeEmail" onClick={() => handleRemoveEmail(email)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" /></svg></span>
                        </div>
                    </li>
                )}
            </ul>

            {allEmails && allEmails.length > 0 && <Button label="Enviar convites" type={'button'} eventCallback={handleSubmitEmails} />}
        </ModalInvitePlayersStyle>
    );
}

export default ModalInvitePlayers;