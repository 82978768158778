import { useHistory, useParams } from "react-router-dom";
import BasePage from "../../../components/BasePage";
import SectionTitle from "../../../components/SectionTitle";
import { AuthContext } from "../../../contexts/UserContext";
import { EditQuizStyle } from "./style";
import { useEffect, useContext, useState } from 'react';
import api from "../../../services/api";
import * as Yup from 'yup';
import { IQuizFormValues } from "../../../interfaces/GeneralInterfaces";
import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";
import CheckboxField from "../../../components/CheckboxField";
import RemoveAnswer from "../../../assets/svg/RemoveAnswer";
import RemoveIcon from "../../../assets/svg/RemoveIcon";
import UpIcon from "../../../assets/svg/UpIcon";
import DownIcon from "../../../assets/svg/DownIcon";
import AddItem from "../../../assets/svg/AddItem";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Textarea from "../../../components/Textarea";

interface ParamTypes {
    id: string;
}

const QuizSchema = Yup.object().shape({
    name: Yup.string().required('Digite o nome do questionário'),
    category: Yup.string().required('Selecione a categoria do questionário'),
    points: Yup.number().required('Digite os pontos').typeError("Digite os pontos"),
    status: Yup.string().required("Informe se o formulário está habilitado").typeError("Informe se o formulário está habilitado"),
    questions: Yup.array().of(
        Yup.object().shape({
            name: Yup.string()
                .required('Digite a pergunta'),
            answers: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string()
                        .required('Digite a resposta')
                })
            )
        })
    )
});

interface Ianswer {
    name: string;
    isCorrect: boolean;
}

const EditQuiz = () => {
    const { id } = useParams<ParamTypes>();
    const { loading } = useContext(AuthContext);
    const history = useHistory();

    const [showEditQuizModal, setShowEditQuizModal] = useState(false);
    const [quiz, setQuiz] = useState<IQuizFormValues>();
    const [errorMessageQuiz, setErrorMessageQuiz] = useState<string>();
    const [errorQuiz, setErrorQuiz] = useState<boolean>();

    const submitQuiz = async (values: IQuizFormValues, setSubmitting: any, resetForm: any) => {
        try {
            const response = await api.put(`forms/${id}`, values)
            setQuiz(response.data);
            resetForm({});
            setErrorQuiz(false);
        } catch (error: any) {
            setErrorQuiz(true)
            setErrorMessageQuiz(error.response.data.message);
        }
        setSubmitting(false);
        setShowEditQuizModal(true)
    }

    const addQuestion = (values: any, setValues: any) => {
        let questions = [...values.questions];
        questions.push({ name: "", answers: [{ name: "", isCorrect: false }] });
        setValues({ ...values, questions });
    }

    const addAnswer = (indexQuestion: any, values: any, setValues: any) => {
        let questions = [...values.questions];
        questions[indexQuestion].answers.push({ name: "", isCorrect: false })
        setValues({ ...values, questions });
    }

    const removeQuestion = (indexQuestion: any, values: any, setValues: any) => {
        let questions = [...values.questions];
        questions.splice(indexQuestion, 1);
        setValues({ ...values, questions });
    }

    const removeAnswer = (indexQuestion: any, indexAnswer: any, values: any, setValues: any) => {
        let questions = [...values.questions];
        questions[indexQuestion].answers.splice(indexAnswer, 1);
        setValues({ ...values, questions });
    }


    useEffect(() => {
        const getTournament = async () => {
            try {
                const response = await api.get(`forms/${id}`)
                setQuiz(response.data)
            } catch (e: any) {
                history.push('/pagina-nao-encontrada')
            }
        }

        if (!loading) {
            getTournament();
        }

    }, [loading]);

    return (
        <BasePage title="Questionários" subtitle={``}>
            <EditQuizStyle>
                <SectionTitle title="Edição de Questionário" />
                {quiz &&
                    <Formik
                        initialValues={quiz}
                        validationSchema={QuizSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize
                        onSubmit={(
                            values: IQuizFormValues,
                            { setSubmitting, resetForm }: FormikHelpers<IQuizFormValues>,
                        ) => {
                            submitQuiz(values, setSubmitting,resetForm);
                        }}
                    >
                        {formik => {
                            return (
                                <Form>
                                    <div className="form-block">
                                        <InputField
                                            name="name"
                                            label="Nome do questionário"
                                            type="text"

                                        />
                                        <Textarea
                                        name="description"
                                        label="Descrição"
                                        className="description"
                                        />
                                        {quiz.category !== "BeManagerTest" &&
                                        <SelectField
                                            label="Categoria"
                                            name="category"
                                            options={[
                                                {
                                                    value: "",
                                                    label: 'Selecione a categoria'
                                                },
                                                {
                                                    value: 'Test',
                                                    label: 'Prova'
                                                },
                                                {
                                                    value: 'Quiz',
                                                    label: 'Questionário'
                                                },
                                                {
                                                    value: 'ManagerQuiz',
                                                    label: 'Questionários de Organizador'
                                                }]}
                                            placeholder="Selecione"
                                        />
                                        }
                                        <InputField
                                            name="points"
                                            label="Pontos"
                                            type="number"
                                        />
                                        <SelectField
                                            label="Visível"
                                            name="status"
                                            options={[
                                                {
                                                    value: "",
                                                    label: 'Selecione'
                                                },
                                                {
                                                    value: true,
                                                    label: 'Sim'
                                                },
                                                {
                                                    value: false,
                                                    label: 'Não'
                                                }]}
                                            placeholder="Selecione"
                                        />
                                        <FieldArray name="questions">
                                            {({ move }) => (formik.values.questions.map((question, indexQuestion) => {
                                                return (
                                                    <div className="form-row" key={indexQuestion}>
                                                        <div className="form-row-block">
                                                            <InputField
                                                                name={`questions.${indexQuestion}.name`}
                                                                label={`Pergunta`}
                                                                type="text"
                                                            />
                                                        </div>
                                                        {question.answers?.map((answer: Ianswer, indexAnswer: number) => {
                                                            return (
                                                                <div key={indexAnswer} className="form-row-block row-answer">
                                                                    <div className="checkIsCorrect">
                                                                        <CheckboxField
                                                                            name={`questions.${indexQuestion}.answers.${indexAnswer}.isCorrect`}
                                                                            label=""
                                                                            type="checkbox"
                                                                            onChangeCallbackBoolean={(value: boolean) => formik.setFieldValue(`questions.${indexQuestion}.answers.${indexAnswer}.isCorrect`, value)}
                                                                        />
                                                                    </div>
                                                                    <InputField
                                                                        name={`questions.${indexQuestion}.answers.${indexAnswer}.name`}
                                                                        label={`${indexAnswer > 0 ? '' : 'Respostas'}`}
                                                                        type="text"
                                                                        className={answer.isCorrect ? 'isCorrect' : ''}
                                                                        placeholder={`Opção ${indexAnswer + 1}`}
                                                                    />
                                                                    <div className="button-content">
                                                                        <div className="btn-remove-answer" onClick={() => removeAnswer(indexQuestion, indexAnswer, formik.values, formik.setValues)}>
                                                                            <RemoveAnswer />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        <div className="button-controls">
                                                            <div className="control-question">
                                                                <div className="btn-remove-question" onClick={() => removeQuestion(indexQuestion, formik.values, formik.setValues)}>
                                                                    <RemoveIcon /> Remover Pergunta
                                                                </div>
                                                                {formik.values.questions.length > 1 && indexQuestion !== 0 &&
                                                                    <div className="btn-move" onClick={() => move(indexQuestion, indexQuestion - 1)}>
                                                                        <UpIcon />
                                                                    </div>
                                                                }
                                                                {formik.values.questions.length > 1 && (indexQuestion < formik.values.questions.length - 1) &&
                                                                    <div className="btn-move" onClick={() => move(indexQuestion, indexQuestion + 1)}>
                                                                        <DownIcon />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="btn_add_answer" onClick={() => addAnswer(indexQuestion, formik.values, formik.setValues)}>
                                                                <AddItem />
                                                                Adicionar Resposta
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }))}
                                        </FieldArray>
                                        <div className="align-right">
                                            <div className="btn_add" onClick={() => addQuestion(formik.values, formik.setValues)}>
                                                <AddItem />
                                                Pergunta
                                            </div>
                                        </div>

                                    </div>
                                    <div className="buttons">
                                        <Button classButton="submit-button" hasLoader={true} showLoading={formik.isSubmitting} disabled={formik.isSubmitting} label="ENVIAR" type={'submit'} />
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                }
                {showEditQuizModal && (
                    <Modal
                        isOpen={showEditQuizModal}
                        onCloseModal={() => setShowEditQuizModal(false)}
                        classStatus={errorQuiz ? 'error' : 'success'}
                        title={errorQuiz ? 'Erro' : 'Sucesso'}
                        text={errorQuiz ? errorMessageQuiz : 'Questionário editado com sucesso!'}
                    />
                )}
            </EditQuizStyle>
        </BasePage>
    )
}

export default EditQuiz;