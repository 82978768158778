import React, { useRef, useState, useEffect, useContext } from 'react';
import SectionTitle from "../SectionTitle";
import Text from "../Text";
import UserInformationStyle from "./style";
import Table from "../Table";
import api from "../../services/api";
import Card from "../../assets/svg/Card";
import ReactToPrint from "react-to-print";
import { Print } from "../Print";
import Modal from "../Modal";
import { AuthContext } from '../../contexts/UserContext';
import ProfilePicture from '../../assets/images/profile.jpg'

interface IUserInformation {
  icon?: any;
  name: string;
  id: string;
  image: string;
  points: number;
  columns: any;
  data: any;
}

const UserInformation: React.FC<IUserInformation> = ({
  name,
  id,
  points,
  icon,
  columns,
  data,
  image,
}) => {
  const imageRef = useRef<HTMLInputElement>(null);
  const cardPrint = useRef(null);
  const [defaultUserImage, setDefaultUserImage] = useState(image);
  const [selectedFile, setSelectedFile] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loading } = useContext(AuthContext);

  const showOpenFileDialog = () => {
    if (imageRef) {
      imageRef?.current?.click();
    }
  };

  const handleChange = async (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    try {
      let data = new FormData();
      data.append('avatar',file);
      
      await api.post('users/avatar', data);

    } catch(e: any) {
        console.log(e);
    }
  };

  useEffect(() => {
    if (selectedFile) {
        const objectURL = URL.createObjectURL(selectedFile);
        setDefaultUserImage(objectURL);
        return () => URL.revokeObjectURL(objectURL);
    }
  }, [selectedFile]);

  useEffect(() => {
    async function getUser() {
      const response = await api.get("users/profile");
      const user = response.data;
      setDefaultUserImage(
        !user.avatar
          ? ProfilePicture
          : `${process.env.REACT_APP_API_URL}/media/avatar/${user?.avatar}`
      );
    }
    if (!loading) {
      getUser();
    }
  }, [loading]);

  useEffect(() => {
    const submitAvatar = async (selectedFile: File) => {
      let formData = new FormData();
      formData.append("avatar", selectedFile, "test");
      api.post("users/avatar", formData);
    };
    if (selectedFile) {
      const objectURL = URL.createObjectURL(selectedFile);
      setDefaultUserImage(objectURL);
      //submitAvatar(selectedFile);
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [selectedFile]);

  return (
    <UserInformationStyle>
      <div className="user-image">
        <img src={defaultUserImage} alt={name} width="145" height="145" />
        <span onClick={() => setIsModalOpen(true)}>
          <Card />
        </span>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onCloseModal={() => setIsModalOpen(false)}
            classStatus="success"
            title="Crachá"
          >
            <div className="div-badge">
              <ReactToPrint
                trigger={() => <button className="span-badge">IMPRIMA SEU CRACHÁ DE JOGADOR</button>}
                content={() => cardPrint.current}
              />
              <div ref={cardPrint}><Print name={name} fullname={name} id={id} image={defaultUserImage} points={0} columns={data} data={data} /></div>
            </div>
          </Modal>
        )}
      </div>
      <SectionTitle customClass="user-name" title={name} />
      <Text customClass="user">ID: {id?.toString().padStart(6, "0")}</Text>
      {/* <div className="points-info">
                {icon && icon}
                <Text customClass="user-points">{points}</Text>
                <Text customClass="user">Pontos em Torneios</Text>
            </div> */}
      <br />
      <SectionTitle icon={icon} title="Ranking" />
      {data.length > 0 ? (
        <Table columnsWithOutMemo={columns} dataWithOutMemo={data} />
      ) : (
        <span>Ainda não há registros de ranking para o seu usuário!</span>
      )}
      <input
        ref={imageRef}
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleChange}
      />
    </UserInformationStyle>
  );
};

export default UserInformation;
