import styled from 'styled-components'

const EditingTextsStyle = styled.div`
    .tox.tox-silver-sink.tox-tinymce-aux{
        .tox-notifications-container {
            display: none !important;
        }
    }
    
`

export default EditingTextsStyle;