import styled from 'styled-components'

export const QuizPageStyle = styled.div`
    table{
        margin-bottom: 5.5rem;
        thead{
            th{
                &:last-of-type{
                    div{
                        text-align:right;
                        justify-content:flex-end;
                    }
                }
            }
        }
        tbody{
            td{
                &:last-of-type{
                    display:flex;
                    justify-content:flex-end;
                    button{
                        margin-right:15px;
                    }

                }
            }
        }

        @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
            td:nth-of-type(1):before { content: "Questionário"; }
            td:nth-of-type(2):before { content: "Pontos"; }
        }
    }
`