import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "./../../contexts/UserContext";
import { TableManagerApproveStyle } from "./style";
import LoyaltyPoints from "../../assets/svg/LoyaltyPoints";
import SectionTitle from "../../components/SectionTitle";
import Table from "../../components/Table";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import { ILeagueFormValues } from "../../interfaces/LeagueInterfaces";

interface ILeagueFormData {
  leagueName: [string, number];
  leagueManagerName: string;
  maxPlayers: number;
  state: string;
  city: string;
  status: string;
}

const TableManagerApprove = () => {
  const history = useHistory();
  const { loading } = useContext(AuthContext);
  const [leagueInformation, setLeagueInformation] = useState<
    ILeagueFormValues[]
  >({} as ILeagueFormValues[]);
  const [leaguesInformationsDataForTable, setLeaguesInformationsDataForTable] =
    useState<ILeagueFormData[]>({} as ILeagueFormData[]);
  const [loadingData, setLoadingData] = useState(false);

  const renderFormatedStatus = (values: any) => {
    if (values.value === "approved") {
      return "Aprovado";
    } else if (values.value === "pending") {
      return "Pendente";
    } else if (values.value === "recused") {
      return "Recusado";
    } else if (values.value === "disqualified") {
      return "Desqualificado";
    } else {
      return "Recusado";
    }
  };

  const columnsLeaguesPending = [
    {
      Header: "NOME DA LIGA",
      accessor: "leagueName",
    },
    {
      Header: "ORGANIZADOR",
      accessor: "leagueManagerName",
    },
    {
      Header: "NÚMERO MÁXIMO DE JOGADORES",
      accessor: "maxPlayers",
    },
    {
      Header: "ESTADO",
      accessor: "state",
    },
    {
      Header: "CIDADE",
      accessor: "city",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: renderFormatedStatus,
    },
  ];

  useEffect(() => {
    const prepareDataForTable = () => {
      let data: ILeagueFormData[] = [];
      if (leagueInformation && leagueInformation.length > 0) {
        leagueInformation.map((league: ILeagueFormValues) => {
          return data.push({
            leagueName: [league.leagueName, league.id],
            leagueManagerName:
              league.leagueManager?.username ?? "Nome do Organizador",
            maxPlayers: league.maxPlayers,
            state: league?.state?.name ?? "Estado não encontrado",
            city: league?.city?.name ?? "Cidade não encontrada",
            status: league?.approved ?? "Status indefinido",
          });
        });

        setLeaguesInformationsDataForTable(data);
      }
    };

    prepareDataForTable();
  }, [leagueInformation]);

  useEffect(() => {
    async function getLeagueInformation() {
      const leaguesPendingResponse = await api.get("leagues/pending");
      setLeagueInformation(leaguesPendingResponse.data);

      setLoadingData(false);
    }

    getLeagueInformation();
  }, []);

  const handleClickRow = (row: any) => {
    history.push(`/solicitacao-organizador/${row.leagueName[1]}`);
  };

  return (
    <TableManagerApproveStyle>
      {loading && <div className="">Carregando...</div>}
      {!loading && !loadingData && leaguesInformationsDataForTable.length > 0 && (
        <div className="next-tournaments">
          <SectionTitle
            icon={<LoyaltyPoints color={"var(--primary)"} />}
            title={"Solicitações"}
          />
          <Table
            columnsWithOutMemo={columnsLeaguesPending}
            dataWithOutMemo={leaguesInformationsDataForTable}
            handleClick={handleClickRow}
          />
        </div>
      )}
    </TableManagerApproveStyle>
  );
};

export default TableManagerApprove;
