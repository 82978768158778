import React, { useEffect, useState } from 'react';

import './styles.scss';

import Loader from '../../../assets/svg/Loader';

import { IYoutubeVideo } from '../../../interfaces/GeneralInterfaces';
import { getYoutubeId } from '../../../utils/Converter';
import { getScript } from '../../../utils/LoadScript';

declare global {
    interface Window {
        YT: any;
        onYouTubeIframeAPIReady: any;
    }
}

const YoutubeVideo: React.FC<IYoutubeVideo> = ({ videoId }) => {
    const youtubeIframeApi = 'https://www.youtube.com/iframe_api';
    const [loading, setLoading] = useState(true);
    const [videoLinkId] = useState(getYoutubeId(videoId));

    useEffect(() => {
        const loadVideo = () => {
            return new window.YT.Player(`youtube-player-${videoLinkId}`, {
                videoId: videoLinkId,
                color: '#2C1266',
                modestbranding: 0,
                rel: 0,
                enablejsapi: 1,
                origin: window.location.origin,
                events: {
                    onReady:() => setLoading(false),
                },
            });
        };

        if(typeof window.YT !== undefined){
            getScript(youtubeIframeApi, () => {
                window.onYouTubeIframeAPIReady = loadVideo;
            });
        }

    }, [youtubeIframeApi, videoLinkId]);


    return (
        <div className="video-iframe">
            {loading && (
                <div className="loader">
                    <Loader width="60" height="60" stroke="#FFF" />
                </div>
            )}

            <div id={`youtube-player-${videoLinkId}`}></div>
        </div>
    );
}

export default YoutubeVideo;