import api from '../services/api';
import axios from 'axios'

export default function useCommonRequisitions() {

    async function getAddressByZipcode(zipcode: string) {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json`)

            if (response.data.erro) {
                throw Error("Cep nao existe");
            }
            return response.data;
        }
        catch (error: any) {
            if (error.response) {
                return error.response;
            } else if (error.request) {
                return error.request;
            } else {
                return error.message;
            }

        }
    }

    const getStates = async () => {
        try {
            const response = await api.get(`states`)            
            const states = response.data.map((item: any) => {
                let state = {
                    value: item.id,
                    label: item.name + " - " + item.uf
                }
                return state;
            })
            return states;
        }
        catch (error: any) {
            if (error.response) {
                return error.response;
            } else if (error.request) {
                return error.request;
            } else {
                return error.message;
            }
        }
    }

    const getCities = async (stateId: number) => {
        try {
            const response = await api.get(`cities/${stateId}`);
            const cities = response.data.map((item: any) => {
                let city = {
                    value: item.name,
                    label: item.name
                }
                return city;
            })
            return cities;
        }
        catch (error: any) {
            if (error.response) {
                return error.response;
            } else if (error.request) {
                return error.request;
            } else {
                return error.message;
            }
        }
    }

    return {
        getAddressByZipcode,
        getStates,
        getCities
    };
}