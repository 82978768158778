import styled from "styled-components";

export const LeaguesStyle = styled.section`
  background: #fff;
  min-height: 500px;

  .container-accordion {
    margin-bottom: 30px;
  }

  .actions {
    display: flex;
    row-gap: 10px;
  }

  .buttons {
    margin-top: 20px;
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    word-break: break-all;

    /*
      Label the data
      */
    td:nth-of-type(1):before {
      content: "ID liga";
    }
    td:nth-of-type(2):before {
      content: "Nome";
    }
    td:nth-of-type(3):before {
      content: "Máx. jogadores";
    }
    td:nth-of-type(4):before {
      content: "CEP";
    }
    td:nth-of-type(5):before {
      content: "Status";
    }
  }
`;

export const ActionsStyle = styled.div`
  display: flex;
  column-gap: 10px;
`;