import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/UserContext';
import { AccordionBase } from '../../../components/Accordion';
import Timeline from '../../../components/Timeline';
import SectionTitle from '../../../components/SectionTitle';
import Text from '../../../components/Text';
import Cards from '../../../assets/svg/Cards';
import { WaitingForCopagStyle } from './style';
import api from '../../../services/api';

const timelineSteps = [
    {
        name: 'Responder questionário',
        active: true,
    },
    {
        name: 'Envio de informações da Liga',
        active: true
    },
    {
        name: 'Aprovação da Liga na Copag',
        active: false,
    },
];

interface ILeagueFormValues {
    maxPlayers: number;
    leagueManagerName: string;
    alreadyManager: boolean;
    timeManaging: string;
    leagueName: string;
    zipCode: string;
    neighborhood: string;
    street: string;
    streetNumber: string;
    additionalAddressInfo?: string;
    photos?: any[];
    description: string;
    createdAt?: string;
    id: number;
    approved: string;
    state: state;
    city: city;
}

interface state {
    id: number;
    name: string;
    uf: string;
}
interface city {
    id: number;
    name: string;
}

const WaitingForCopag = () => {
    const { userInfo, loading } = useContext(AuthContext);
    const [leagueInformation, setLeagueInformation] = useState<ILeagueFormValues>({} as ILeagueFormValues);

    useEffect(() => {
        async function getLeagueInfo() {
            const response = await api.get('leagues/managed');
            setLeagueInformation(response.data);
        }

        if (!loading) {
            getLeagueInfo();
        }
    }, [loading])

    return (
        <WaitingForCopagStyle>
            {leagueInformation && (
                <AccordionBase sectionTitle={<SectionTitle title="Liga" icon={<Cards />} />} isOpen={true}>
                    <h2>{leagueInformation.leagueName}</h2>
                    <Text customClass="league-manager-text">
                        {leagueInformation.description}
                    </Text>

                    <ul className="list-infos">
                        <li><strong>NÚMERO MÁXIMO DE JOGADORES:</strong> {leagueInformation.maxPlayers}</li>
                        <li><strong>ESTADO:</strong> {leagueInformation?.state?.name}</li>
                        <li><strong>CIDADE:</strong> {leagueInformation?.city?.name}</li>
                        <li><strong>FUNDADOR(A):</strong> {userInfo().name}</li>
                    </ul>
                </AccordionBase>
            )}

            <br />

            <AccordionBase sectionTitle={<SectionTitle title="Etapas de criação e aprovação de liga" icon={<Cards />} />} isOpen={true}>
                <br />
                <Timeline steps={timelineSteps} />
            </AccordionBase>

        </WaitingForCopagStyle>
    )
}

export default WaitingForCopag;