import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});
export const localToken = "@Copag:token";
export const localUser = "@Copag:user";

api.interceptors.response.use((response) => response,
    async (error) => {
        let response = error.response;
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.removeItem(localToken);
                localStorage.removeItem(localUser);
                api.defaults.headers.Authorization = undefined;
                window.location.href = '/login';
            }
        }

        return response;
    })

export default api;