import { useState, useEffect, useContext } from 'react';
import api from '../../../services/api';

//Assets
import { Step1Style } from './style';

//Form
import Quiz from '../../../components/Quiz';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import { AuthContext } from '../../../contexts/UserContext';
import Modal from '../../../components/Modal';

interface Step1Props {
    callback(step: number): void;
}


interface IQuiz{
    id: number;
    name: string;
    description:string;
    category:string;
    points: number;
    status: boolean | string;
    questions:IQuestion[];
  }

interface IQuestion {
    id: string;
    name: string;
    answers: Ianswer[];
}

interface Ianswer {
    name: string;
    isCorrect: boolean;
}

const Step1 = ({callback}: Step1Props) => {
    const [quiz, setQuiz] = useState<IQuiz>();
    const [questions, setQuestions] = useState<IQuestion[]>();
    const { loading } = useContext(AuthContext);
    const [modalError, setModalError] = useState<boolean>(false);
    const [errorMessageModal, setErrorMessageModal] = useState<string>();

    const getQuiz = async () => {
        try{
            const response = await api.get(`forms/be-manager-test`);
            if(response.data?.name && response.data?.questions) {
                setQuiz(response.data);
            }
            else {
                setErrorMessageModal(response.data.message)
                setModalError(true);
            }
        }catch(error: any){
            if(error.response.status === 401){
                callback(2);
            }else{
                setErrorMessageModal(error.response.data.message)
                setModalError(true);
            }
        }
    }

    const handleModalClose = () => {
        setModalError(false);
        callback(0);
    }

    useEffect(() => {
        if(!loading) {
            getQuiz();
            window.scrollTo({top: 0, behavior: 'smooth'}); 
        }

    },[loading]);

    useEffect(() => {
        if(quiz){
            let sortQuestions = shuffleAndSlice(quiz?.questions, 10);
            setQuestions(sortQuestions);
        }
        
    },[quiz])

    function shuffleAndSlice(array: IQuestion[], qtd: number) {
        let newArray = [...array];
        newArray.sort(() => Math.random() - 0.5);
        newArray = newArray.slice(0,qtd);
        return newArray;
    }

    return (
        <Step1Style id="page-quiz">
            <main className="wrapper-limiter">
            {quiz && 
                <div className="common-block quiz-title">
                    <Title>{quiz.name}</Title>
                    <Text>
                        {quiz.description}
                    </Text>
                </div>
            }
            {quiz && questions && quiz.questions.length > 0 && 
                <div id="container-form">
                    <Quiz callback={callback} quizId={quiz.id} isManagerTest={true} quizList={questions} />
                </div>
            }    
            </main>

            {!quiz && !modalError && <h2>Questionário para ser organizador ainda não foi criado.</h2>}

            {modalError &&
                <Modal
                    isOpen={modalError}
                    onCloseModal={() => handleModalClose()}
                    classStatus="warning"
                    title="Aguarde"
                    text={errorMessageModal}
                />
            }
        </Step1Style>
    );
}

export default Step1;