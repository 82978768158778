import { useState, useContext, useEffect } from 'react';
import { AuthContext } from './../../contexts/UserContext';
import { TableApprovingCupCopagStyle } from './style';
import LoyaltyPoints from '../../assets/svg/LoyaltyPoints';
import SectionTitle from '../../components/SectionTitle';
import Table from '../../components/Table';
import { ITournament } from '../../interfaces/GeneralInterfaces';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';

interface ITournamentsData {
    name: [string, number],
    modality: string,
    format: string,
    visibility: string,
}

const TableApprovingCupCopag = () => {
    const history = useHistory();
    const { loading } = useContext(AuthContext);
    const [tournaments, setTournaments] = useState<ITournament[]>({} as ITournament[])
    const [tournamentsDataForTable, setTournamentsDataForTable] = useState<ITournamentsData[]>({} as ITournamentsData[]);
    const [loadingData, setLoadingData] = useState(false);

    const columnsPendingTournaments = [
        {
            Header: 'NOME DO TORNEIO',
            accessor: 'name'
        },
        {
            Header: 'MODALIDADE',
            accessor: 'modality',
        },
        {
            Header: 'FORMATO',
            accessor: 'format',
        },
        {
            Header: 'VISIBILIDADE',
            accessor: 'visibility',
        }
    ];

    useEffect(() => {
        const prepareDataForTable = () => {
            let data: ITournamentsData[] = [];
            if (tournaments && tournaments.length > 0) {
                tournaments.map((tournament: ITournament) => {
                    return data.push({
                        name: [tournament.name, tournament.id],
                        format: tournament.format,
                        modality: tournament.modality,
                        visibility: tournament.visibility
                    })
                })

                setTournamentsDataForTable(data);
            }
        }

        prepareDataForTable();
    }, [tournaments])

    useEffect(() => {
        async function getPendingTournaments() {
            const pendingTournamentsResponse = await api.get('tournaments/pending')
            setTournaments(pendingTournamentsResponse.data)
            setLoadingData(false);
        }

        getPendingTournaments();
    }, [])

    const handleClickRow = (row: any) => {
        history.push(`/aprovar-torneio/${row.name[1]}`)
    }

    return (
        <TableApprovingCupCopagStyle>
            {loading && (
                <div className="">Carregando...</div>
            )}
            {!loading && !loadingData && (
                tournamentsDataForTable.length > 0 && (
                    <div className="next-tournaments">
                        <SectionTitle icon={<LoyaltyPoints color={'var(--primary)'} />} title={'Torneios pendentes'} />
                        <Table columnsWithOutMemo={columnsPendingTournaments} dataWithOutMemo={tournamentsDataForTable} handleClick={handleClickRow} />
                    </div>
                )
            )}
        </TableApprovingCupCopagStyle>
    )
}

export default TableApprovingCupCopag;