import React from 'react';

import TextStyle from './style'
import { IText } from '../../interfaces/GeneralInterfaces';

const Text: React.FC<IText> = ({ customClass, children }) => {
    return (
        <TextStyle className={customClass}>
            {children}
        </TextStyle>
    );
}

export default Text;