import React, { useCallback, useState } from "react";

import SectionTitleStyled from "./style";

import { ISectionTitle } from "../../interfaces/GeneralInterfaces";

import { FiEdit2, FiCheck } from "react-icons/fi";

const SectionTitle: React.FC<ISectionTitle> = ({
  customClass,
  children,
  title,
  icon,
  isEditable,
  onChangeProps,
  handleEditTitle,
}) => {

  const [isEditingTitle, setIsEditingTitle] = useState<boolean>();

  const handleEditingTitle = useCallback(() => {

    setIsEditingTitle(prevState => !prevState);

  }, [])

  return (
    <SectionTitleStyled className={customClass && customClass}>
      {children ? (
        children
      ) : (
        <>
          {icon && <div className="icon">{icon}</div>}
          {isEditingTitle ? <input name="title" id="title" onChange={(event) => {onChangeProps && onChangeProps(event.target.value)}}/> : <span>{title}</span>}
          {isEditable && 
            <button onClick={handleEditingTitle}>
              {isEditingTitle ? <button onClick={handleEditTitle}><FiCheck /></button> : <FiEdit2 />}
            </button>
          }
        </>
      )}
    </SectionTitleStyled>
  );
};

export default SectionTitle;
