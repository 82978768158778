import styled from 'styled-components'

export const PointsWidgetStyle = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: left;
    border: 1px solid var(--color-gray-light);
    padding: 1.5rem;

    .icon{
        svg{
            fill: var(--primary);
        }
    }

    @media(max-width:800px){
        margin-top: 1rem;
    }
`
export const PointsContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 2rem;

    .points-number{
        font-size: 4.8rem;
        line-height: 4rem;
        font-family: var(--font-primary-bold);
        color: var(--primary);
    }

    .points-text{
        font-size: 4.8rem;
        font-size: 1.4rem;
        color: var(--primary);
    }


`