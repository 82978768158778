import React from "react";

import { ButtonStyle, LinkStyle } from "./style";

import Loader from "../../assets/svg/Loader";

import { IButtonItemProps } from "../../interfaces/GeneralInterfaces";

const Button = ({
  label,
  leftIcon,
  link,
  classButton,
  type = "button",
  becameIcon = false,
  themeColor = "purple",
  sizeItems = "normal",
  disabled = false,
  hasLoader = false,
  showLoading = false,
  eventCallback,
}: IButtonItemProps) => {
  const renderButtonContent = () => {
    return (
      <>
        {leftIcon && <div className="left-icon">{leftIcon}</div>}

        <span className="text-button">{label}</span>
      </>
    );
  };

  const onClickEvent = () => {
    eventCallback();
  };

  return link ? (
    <LinkStyle
      to={link}
      className={`container-button ${themeColor} ${sizeItems} ${classButton}`}
      title={label}
    >
      <div className="content">{renderButtonContent()}</div>
    </LinkStyle>
  ) : (
    <ButtonStyle
      type={type}
      className={`container-button ${becameIcon} ${themeColor} ${sizeItems} ${classButton} ${
        showLoading ? "loading" : ""
      }`}
      title={label}
      disabled={disabled}
      onClick={() => (!disabled && eventCallback ? onClickEvent() : null)}
    >
      {hasLoader && (
        <div className="loader">
          <Loader />
        </div>
      )}
      <div className="content">{renderButtonContent()}</div>
    </ButtonStyle>
  );
};

export default Button;
