import styled from 'styled-components'
export const TabPaneStyle = styled.button`
    font-size: 1.8rem;
    font-family: var(--font-secondary-bold);
    color: var(--color-white);
    background-color: var(--primary);
    padding: .9rem 3rem;
    margin-right: 1rem;
    transition: background-color .3s;

    &:hover, &.current-tab{
        background-color: var(--color-purple);
    }
`

export default TabPaneStyle;