// import React from 'react';
import { UploadAdapter, FileLoader } from '@ckeditor/ckeditor5-upload/src/filerepository';
import axios from 'axios';
import api from '../../../services/api'
import { Editor } from '@ckeditor/ckeditor5-core';

export function UploadImageEditor(editor: Editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader);
    };
};

  class CustomUploadAdapter {
    loader: FileLoader;

    constructor(loader: FileLoader) {
        this.loader = loader;
    }

    async upload(): Promise<any> {
        try {
            const file = await this.loader.file;
            console.log(file);
            const formData = new FormData();
            
            if (file !== null) {
                formData.append('file', file);
            } else {
                console.log('Nenhum arquivo selecionado');
            }

            const response = await api.post('playersTraining/upload_files_training_players', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return {
                default: window.location.origin + `/media/trainingPlayers/${response.data.filename}`
            };
        } catch (error) {
            console.log(error);
            throw new Error("Erro ao fazer upload do arquivo");
        }
    }

    abort(): void {
    }
  }