import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { AccordionBaseStyle } from './style';

import { IAccordion } from '../../../interfaces/GeneralInterfaces';

const AccordionBase: React.FC<IAccordion> = ({
    isOpen,
    sectionTitle,
    onClick,
    children,
}) => {
    const [collapsed, setCollapsed] = useState(isOpen);
    const accordionRef = React.useRef(null);

    const onClickToggle = () => {
        setCollapsed(!collapsed);
    }

    return (
        <AccordionBaseStyle className={`container-accordion${collapsed ? ' collapsed' : ''}`}>
            <button type="button" className="accordion-header" onClick={onClickToggle}>
                {sectionTitle && (sectionTitle)}
            </button>

            <CSSTransition in={collapsed} nodeRef={accordionRef} timeout={600} classNames="accordion-body">
                <div className="accordion-body" ref={accordionRef}>
                    {collapsed && children}
                </div>
            </CSSTransition>
        </AccordionBaseStyle>
    );
}

export default AccordionBase;