import { ptBR } from 'date-fns/locale'
import { parseISO, format } from 'date-fns'

const dateFormated = (date: string) => {
    if (date) { 
        const parsedDate = parseISO(date);
        return format(parsedDate, "dd'.'MM'.'yy' | ' cccc", { locale: ptBR })
    }
    return "Data não formatada"
}

const renderDateFormated = (date: string) => {
    if (date) {
        const parsedDate = parseISO(date);
        const formatedDate = format(parsedDate, "dd'.'MM'.'yy' | ' cccc", { locale: ptBR })
        return <p> {formatedDate} </p>
    }
    return <p>Data não formatada</p>
}

const renderFormatedHour = (date: string) => {
    if (date) {
        const splitDate = date.split("T")[1];
        const substring = splitDate.substring(0, 5)

        return <p> {substring} </p>
    }
    return <p>Hora não formatada</p>
}

const formatDate = (values: any) => {
    if(values.value)
        return renderDateFormated(values.value);
    else
        return <p>Data não formatada</p>
}

const formatHour = (values: any) => {
    if(values.value)
        return renderFormatedHour(values.value);
    else
        return <p>Hora não formatada</p>

}

export { renderDateFormated, renderFormatedHour, formatDate, formatHour, dateFormated }