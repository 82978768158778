
import { useContext,useState } from 'react'
import api from '../../../services/api'
import BasePage from '../../../components/BasePage';
import {AuthContext} from '../../../contexts/UserContext'
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import SectionTitle from '../../../components/SectionTitle'
import UserIcon from '../../../assets/svg/UserIcon';
import Modal from '../../../components/Modal';
import InputField from '../../../components/InputField';
import Button from '../../../components/Button';
import { ChangePasswordStyle } from './style';
import {useHistory} from 'react-router-dom';


interface IPasswordFormValues {
  oldPassword: string;
  newPassword:string;
  confirmPassword:string;
}

const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .required('Informe sua senha'),
    newPassword: Yup.string()
        .required('Informe sua senha')
        .min(8, 'Senha muito curta. Informe uma senha com mais de 8 caracteres\n')
        .matches(/^.{8,}$/, 'A senha informada deve conter entre 8 e 20 caracteres\n'),
    confirmPassword: Yup.string()
        .required('Confirme sua senha')
        .oneOf([Yup.ref('newPassword'), null], 'Senhas não combinam.')
    
});
const ChangePassword = () => {
    const { loading } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);
    const [error, setError] = useState('Erro');
    const history = useHistory();

    const passwordInitial: IPasswordFormValues = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    const handleChangePassword = async(values: IPasswordFormValues, callback: Function) => {
        try{
          await api.put('users/update_password',values);
          setIsModalOpenSuccess(true);
          callback();
        }catch(err: any){
          if(err.response){
            setError(err.response.data.message);
          }
          setIsModalOpen(true);
          callback();
        }
    }

    const handleCloseModal = () => {
      setIsModalOpenSuccess(false);
      history.push('/perfil')
    }
    return (
            <BasePage title="Trocar senha">
                <ChangePasswordStyle>
                {loading && (
                    <div className="">Carregando ...</div>
                )}
                {!loading && (
                    <>
                        <Formik
                            initialValues={passwordInitial}
                            validationSchema={ChangePasswordSchema}
                            validateOnBlur={false}
                            validateOnChange={false}
                            enableReinitialize
                            onSubmit={(
                                values: any,
                                { setSubmitting }: FormikHelpers<any>
                            ) => {
                              handleChangePassword(values, function () {
                                     setSubmitting(false);
                                 });
                            }}
                        >
                            {formik => {

                                return (
                                    <div id="register-form">
                                        <Form className="common-form">
                                            <SectionTitle title="Dados Cadastrais" icon={<UserIcon />} />
                                            <div className="form-block">
                                                <InputField
                                                    name="oldPassword"
                                                    label="Senha antiga"
                                                    type="password"
                                                />
                                                <InputField
                                                    name="newPassword"
                                                    label="Nova senha"
                                                    type="password"
                                                />
                                                <InputField
                                                    name="confirmPassword"
                                                    label="Confirmar"
                                                    type="password"
                                                />
                                                <Button classButton="submit-button" hasLoader={true} showLoading={formik.isSubmitting} disabled={formik.isSubmitting} label="ATUALIZAR SENHA" type={'submit'} />
                                            </div>
                                        </Form>
                                    </div>
                                  )
                                }}
                            </Formik>

                            {isModalOpen && (
                              <Modal
                                      isOpen={isModalOpen}
                                      onCloseModal={() => setIsModalOpen(false)}
                                      classStatus="error"
                                      title="Erro"
                                      text={error}
                                  />
                              )}

                              {isModalOpenSuccess && (
                                  <Modal
                                      isOpen={isModalOpenSuccess}
                                      onCloseModal={handleCloseModal}
                                      classStatus="success"
                                      title="=)"
                                      text={"Senha atualizada com sucesso"}
                                  />
                              )}
                        </>
                    )}
                </ChangePasswordStyle>
            </BasePage>
    )}

export default ChangePassword;