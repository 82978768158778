import styled from 'styled-components'

export const AccordionBaseStyle = styled.div`
 &.container-accordion{
    border: 1px solid var(--color-gray-light);
    padding: 2.5rem 2rem;

    @media(max-width:1024px){
        padding: 2.5rem 5px;
    }

    .accordion-header{
        display: flex;
        width: 100%;
        position: relative;
        
        &:before, &:after{
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 1.8rem;
            height: .2rem;
            background-color: var(--color-gray);
            transform: translate(-50%, 0);
            transition: transform .3s;
        }

        &:after{
            transform: translate(-50%, 0) rotateZ(90deg);
        }
    }

    .accordion-body{
        // padding: 0 2rem 2rem 2rem;
        padding: 0 2rem;

        @media(max-width:1024px){
            padding:0 10px;
        }

        &.accordion-body-enter {
            opacity: 0;
            height: 0px;
        }

        &.accordion-body-enter-active {
            opacity: 1;
            height: auto;
            transition: opacity .6s;
        }

        &.accordion-body-exit {
            height: auto;
            opacity: 1;
        }

        &.accordion-body-exit-active {
            opacity: 0;
            height: 0px;
            transition: opacity .6s;
        }
    }

    &.collapsed{
        .accordion-header{
            &:after{
                transform: translate(-50%, 0) rotateZ(0deg);
            }
        }
    }
}
`