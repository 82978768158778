import styled from 'styled-components'

const RoundStyle = styled.div`
    display:grid;
    grid-template-columns:repeat(3,1fr);
    grid-gap:30px;
    margin-top:30px;

    @media(max-width:1024px){
        grid-template-columns:repeat(1,1fr);
    }
`

const NextRoundStyle = styled.div`
    .start-game {
        margin-top: 3rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }
`

const FinishRoundStyle = styled.div`
    .finish-game{
        margin-top: 3rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }
`

export { RoundStyle, NextRoundStyle, FinishRoundStyle }