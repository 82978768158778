import React from 'react';

import { AccordionBase } from '..';
import SectionTitle from '../../SectionTitle';
import Cards from '../../../assets/svg/Cards';
import { AccordionChampStyle } from './style';
import Text from '../../Text';
import Button from '../../Button';

import { IAccordionChamp } from '../../../interfaces/GeneralInterfaces';

import RoutesConstants from '../../../constants/RoutesConstants';
import LinkButton from '../../LinkButton';

const {
    STYLEGUIDES
} = RoutesConstants;

const AccordionChamp: React.FC<IAccordionChamp> = ({
    onClick,
    isOpen,
}) => {
    const onClickToggle = () => {
        if(onClick)
            onClick(isOpen);
    }

    return (
        <AccordionBase
            sectionTitle={<SectionTitle title="Copa Truco" icon={<Cards />} />}
            onClick={onClickToggle}
            isOpen={isOpen}
        >
            <AccordionChampStyle>
                <h3 className="accordion-title">Copag Copag Truco</h3>
                <LinkButton label="LIGA TRUCADORES PAULISTA" link={STYLEGUIDES.route} />

                <Text customClass="accordion-text">
                    Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit. Nullam hendrerit maximus fringilla.</strong> Etiam vitae egestas ante. Proin vestibulum nisl est, at feugiat odio porta vel. In sit amet nisi enim. Cras porta diam a nisi malesuada, vel volutpat mi feugiat. Nulla scelerisque pellentesque nibh, non lacinia tortor dignissim et.
                    <br/>
                    Morbi imperdiet fermentum ex. Pellentesque id nibh mollis, accumsan velit ut, scelerisque quam. Praesent semper felis euismod ante efficitur, ullamcorper tempor ipsum fermentum. Nullam quis ex mi. Maecenas rutrum, lacus id volutpat rutrum, magna ex maximus tortor, quis scelerisque sem nisi eu lorem.
                </Text>

                <div className="about-champ">
                    <ul className="list-infos">
                        <li><strong>DATA:</strong> 12.02 | Sexta-feira</li>
                        <li><strong>TIPO DE PARTIDA:</strong> Pública</li>
                        <li><strong>HORÁRIO:</strong> 18:00</li>
                        <li><strong>LOCAL:</strong> Online</li>
                        <li><strong>ESTADO:</strong> São Paulo</li>
                        <li><strong>PARTICIPANTES:</strong> 8 jogadores</li>
                    </ul>

                    <Button classButton="inv-player" label="Convidar Jogador" themeColor="green" link={STYLEGUIDES.route} />
                </div>
            </AccordionChampStyle>
        </AccordionBase>
    );
}

export default AccordionChamp;