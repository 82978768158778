
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BasePage from "../../components/BasePage";
import Button from "../../components/Button";
import SectionTitle from "../../components/SectionTitle";
import Table from "../../components/Table";
import { AuthContext } from "../../contexts/UserContext";
import api from "../../services/api";
import { QuizPageStyle } from "./style";
import { ptBR } from 'date-fns/locale'
import { parseISO, format } from 'date-fns'
import Text from '../../components/Text';
import TableAnsweredQuiz from "../../components/TableAnsweredQuiz";

interface IQuizResponse {
    id: string;
    name: string;
    category: string;
    points: number;
    createdAt: string;
    status: boolean;
}

interface IQuizResponseForTable {
    name: string;
    points: number;
    createdAt: string;
    actions?: any;
}

const QuizPage = () => {
    const history = useHistory();
    const [quizNotAnswered, setQuizNotAnswered] = useState<IQuizResponse[]>([]);
    const [quizNotAnsweredForTable, setQuizNotAnsweredForTable] = useState<IQuizResponseForTable[]>([])

    const [quizAnswered, setQuizAnswered] = useState<IQuizResponse[]>([]);
    const [quizAnsweredForTable, setQuizAnsweredForTable] = useState<IQuizResponseForTable[]>([])

    const { loading } = useContext(AuthContext);

    const renderButtonActions = (values: any) => {

        if (values.value)
            return (
                <>
                    <Button
                        classButton={'small'}
                        label={values.value[0]}
                        eventCallback={(e: any) => { history.push(`/questionario/${values.value[1]}`) }}
                    />
                </>
            )

    }

    const quizTable = [
        {
            Header: 'QUESTIONÁRIO',
            accessor: 'name',
        },
        {
            Header: 'PONTOS',
            accessor: 'points'
        },
        {
            Header: '',
            accessor: 'actions',
            Cell: renderButtonActions
        }
    ];

    const getQuizNotAnswered = useCallback(async () => {
        const response = await api.get('forms/list-forms-not-answered')
        setQuizNotAnswered(response.data);
    }, [])

    const getQuizAnswered = useCallback(async () => {
        const response = await api.get('forms/list-forms-answered')
        setQuizAnswered(response.data);
    }, [])

    useEffect(() => {
        if (!loading) {
            getQuizNotAnswered()
            getQuizAnswered()
        }

    }, [loading, getQuizNotAnswered, getQuizAnswered])

    useEffect(() => {
        const prepareDateForTicketsTable = () => {
            let data: IQuizResponseForTable[] = [];
            if (quizNotAnswered) {
                quizNotAnswered.map((quiz: IQuizResponse) => {
                    return data.push({
                        name: quiz.name,
                        points: quiz.points,
                        createdAt: '',
                        actions: ['Responder', quiz.id],
                    })
                })

                setQuizNotAnsweredForTable(data);
            }
        }
        prepareDateForTicketsTable();
    }, [quizNotAnswered])

    useEffect(() => {
        const prepareDateForTicketsTable = () => {
            let data: IQuizResponseForTable[] = [];
            if (quizAnswered) {
                quizAnswered.map((quiz: IQuizResponse) => {
                    return data.push({
                        name: quiz.name,
                        points: quiz.points,
                        createdAt: format(parseISO(quiz.createdAt), "dd/MM/yyyy ", { locale: ptBR }),
                    })
                })

                setQuizAnsweredForTable(data);
            }
        }
        prepareDateForTicketsTable();
    }, [quizAnswered])

    return (
        <BasePage title="Questionários" subtitle={``}>
            <QuizPageStyle>
                <SectionTitle customClass="quiz-title" title="Questionários Não Respondidos" />
                {quizNotAnsweredForTable.length > 0 &&
                    <Table
                        columnsWithOutMemo={quizTable}
                        dataWithOutMemo={quizNotAnsweredForTable} />
                }
                {quizNotAnsweredForTable.length === 0 &&
                    <>
                        <Text>Todos os questionários respondidos com sucesso.<br /><br /></Text>
                    </>
                }
                <SectionTitle customClass="quiz-title" title="Questionários Respondidos" />
                {quizAnsweredForTable.length > 0 &&
                    <TableAnsweredQuiz data={quizAnsweredForTable} />
                }
                {quizAnsweredForTable.length === 0 &&
                    <>
                        <Text>Nenhum questionário foi respondido até o momento.<br /><br /></Text>
                    </>
                }
            </QuizPageStyle>
        </BasePage >
    )
}
export default QuizPage;