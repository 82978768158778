import { useState, useContext, useEffect } from 'react';
import { AuthContext } from './../../../contexts/UserContext';
import { LeaguesStyle } from './style';
import api from '../../../services/api'
import SectionTitle from '../../../components/SectionTitle';
import Cards from '../../../assets/svg/Cards';
import TablePagination from '../../../components/TablePagination'
import Button from '../../../components/Button'
import Select from "react-select";
import useCommonRequisitions from '../../../hooks/useCommonRequisitions';
import { IStates, ICities } from '../../../interfaces/GeneralInterfaces'
import { selectStyles } from '../../../assets/styles/selectStyles'
import { useHistory } from 'react-router-dom';

const columnsLeaguesTable = [
    {
        Header: 'ID DA LIGA',
        accessor: 'id',
    },
    {
        Header: 'NOME',
        accessor: 'leagueName',
        sortType: 'caseInsensitive'
    },
    {
        Header: 'MÁX. JOGADORES',
        accessor: 'maxPlayers',
        sortType: 'caseInsensitive'
    },
    {
        Header: 'CEP',
        accessor: 'zipCode',
    },
    {
        Header: 'Número',
        accessor: 'streetNumber'
    }
];

interface ILeague {
    id: string;
    leagueName: string;
    maxPlayers: number;
    zipCode: string;
    streetNumber: string;
}

interface ILeagueDataTable {
    id: string;
    leagueName: string;
    maxPlayers: number;
    zipCode: string;
    streetNumber: string;
}

interface ILeagueFilter {
    id?: number;
    search?: string;
    state?: string;
    city?: string;
}

const Leagues = () => {
    const { loading } = useContext(AuthContext);
    const [leagues, setLeagues] = useState<ILeague[]>([{}] as ILeague[]);
    const [total, setTotal] = useState<number>(0);
    const [leaguesData, setLeaguesData] = useState<ILeagueDataTable[]>({} as ILeagueDataTable[]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [filterOptions, setFilterOptions] = useState<ILeagueFilter>({} as ILeagueFilter);
    const { getStates, getCities } = useCommonRequisitions();
    const [states, setStates] = useState<IStates[]>([]);
    const [cities, setCities] = useState<ICities[]>([]);
    const [stateSelected, setStateSelected] = useState<any>("");
    const history = useHistory();

    useEffect(() => {
        async function getState() {
            const populatedStates = await getStates();
            setStates(populatedStates);
        }
        getState();
    }, [])

    useEffect(() => {
        async function getCity() {
            const cities = await getCities(stateSelected);
            setCities(cities);
        }

        if (stateSelected) {
            getCity();
        }
    }, [stateSelected])

    const handleSearch = () => getLeagues();

    const handleClickRow = (row: any) => {
        history.push(`/editar-liga/${row.id}`)
    }

    useEffect(() => {
        const prepareDateForLeaguesTable = () => {
            let data: ILeagueDataTable[] = [];
            if (leagues && leagues.length > 0) {
                leagues.map((league: ILeague) => {
                    return data.push({
                        id: league.id,
                        leagueName: league.leagueName,
                        maxPlayers: league.maxPlayers,
                        zipCode: (league?.zipCode?.length === 8 && league?.zipCode?.indexOf('-') === -1) ? (league?.zipCode.substring(0, 5) + '-' + league?.zipCode.substring(5)) : league?.zipCode,
                        streetNumber: league.streetNumber
                    })
                })
                setLeaguesData(data);
            } else {
                setLeaguesData({} as ILeagueDataTable[]);
            }
        }
        prepareDateForLeaguesTable();

    }, [leagues])

    const getLeagues = async () => {
        const response = await api.post(`leagues/list?page=${page}&limit=${perPage}`, { filterOptions });
        setTotal(response.data.total);
        setLeagues(response.data.leagues);
    }

    useEffect(() => {
        if (!loading) {
            getLeagues();
        }

    }, [loading, page])


    return (
        <LeaguesStyle>
            {loading && (
                <div className="">Carregando...</div>
            )}
            {!loading &&
                <>
                    <div className="filter_tournaments">
                        <input
                            name="id"
                            type="text"
                            className="search"
                            placeholder="Id da Liga"
                            onChange={(e: any) => {
                                let newValue = { ...filterOptions }
                                newValue.id = e.target.value;
                                setFilterOptions(newValue);
                            }}
                        />
                        <input
                            name="search"
                            type="text"
                            className="search"
                            placeholder="Pesquise por nome, descrição, CEP, etc..."
                            onChange={(e: any) => {
                                let newValue = { ...filterOptions }
                                newValue.search = e.target.value;
                                setFilterOptions(newValue);
                            }}
                        />

                        <div className="filter_selects">
                            <Select options={states} placeholder="Estado" styles={selectStyles} onChange={(state: any) => {
                                setStateSelected(state.value)
                                let newValue = { ...filterOptions }
                                newValue.state = state.value;
                                setFilterOptions(newValue);
                            }} />
                            <Select options={cities} placeholder="Cidade" styles={selectStyles} onChange={(city: any) => {
                                let newValue = { ...filterOptions }
                                newValue.city = city.value;
                                setFilterOptions(newValue);
                            }} />
                        </div>
                        <div className="search_button_area">
                            <Button label="Pesquisar" eventCallback={handleSearch} />
                        </div>

                    </div>
                </>
            }
            {!loading && leaguesData.length > 0 ?
                <>
                    <SectionTitle icon={<Cards />} title={'Ligas'} />

                    <TablePagination perPage={perPage} setPage={setPage} setPerPage={setPerPage} currentpage={page} totalPage={Math.ceil(total / perPage)} columnsWithOutMemo={columnsLeaguesTable} dataWithOutMemo={leaguesData} handleClick={handleClickRow} />
                </>
                : <span>Nenhum registro encontrado.</span>
            }
        </LeaguesStyle>
    )
}

export default Leagues;
