import React, { useRef } from 'react';
import { EditorStyle } from "./style";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadImageEditor } from './UploadImageEditor';

interface EditorProps {
    data: string;
    onChange: (event: any, editor: any) => void;
    onBlur?: (event: any, editor: any) => void;
    onFocus?: (event: any, editor: any) => void;
    onSave?: (editor: any) => Promise<void>;
    onReady?: (editor: any) => void;
}

const CustomEditor: React.FC<EditorProps> = ({
    data = '',
    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
    onSave = () => {},
    onReady = () => {} 
}) => {
    const editorRef = useRef<any>();

    const handleReady = (editor: any) => {
        console.log('Editor is ready to use!', editor);
        editorRef.current = editor;
        onReady(editor); // Chama a função onReady passando o editor como argumento
    };

    return (
        <EditorStyle>
            <CKEditor
                editor={ClassicEditor}
                data={data}
                config={{
                    extraPlugins: [UploadImageEditor],
                    mediaEmbed: {
                        previewsInData: true
                    }
                }}
                onReady={handleReady}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            />
        </EditorStyle>
    );
};

export default CustomEditor;
