import styled from 'styled-components'

export const WaitingForCopagAnswerStyle = styled.div`
    @media(max-width:800px){
        .list-infos{
            display: flex;
            flex-direction: column;
        }
    }

    .tournaments-informations {
        display: flex;
        align-items: flex-start;
    }

    .list-infos {
        column-count:2;
        flex-grow: 2;
        li {
            padding-top: 8px;
        }
    }
`

