import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24.497}
      height={22.547}
      viewBox="0 0 24.497 22.547"
      {...props}
    >
      <path
        d="M24.472 6.737l-4.088 15.26a.741.741 0 01-.908.524l-10-2.679a.742.742 0 01-.524-.908l.108-.4a.476.476 0 11.919.246l-.054.2 9.594 2.571L23.5 6.697l-4.07-1.091a.476.476 0 01.246-.919l4.272 1.15a.742.742 0 01.524.9zM8.778 1.985a.476.476 0 00-.476.476v.453a.476.476 0 10.951 0v-.453a.476.476 0 00-.475-.476zm6.064 6.379a.476.476 0 010 .549l-2.2 3.117a.476.476 0 01-.777 0l-2.2-3.117a.476.476 0 010-.549l2.2-3.117a.476.476 0 01.777 0zm-.971.275l-1.622-2.294-1.622 2.292 1.622 2.3zm4.253 10.5l-.117.437a.476.476 0 10.919.246l.117-.437a.476.476 0 10-.919-.246zM5.938 10.437a1.835 1.835 0 01.394-.063V.737a.742.742 0 01.741-.741h10.352a.742.742 0 01.741.741v9.628a1.833 1.833 0 01.394.063 1.64 1.64 0 011.159 2.009 3.814 3.814 0 01-1.552 1.923v2.177a.742.742 0 01-.741.741H7.073a.742.742 0 01-.741-.741v-2.175a3.814 3.814 0 01-1.553-1.925 1.638 1.638 0 011.159-2zm12.229 2.7a2.083 2.083 0 00.633-.942.688.688 0 00-.486-.841.96.96 0 00-.147-.028zM7.283 16.326h9.933v-2.2-3.181V.951H7.283V14.123zM5.7 12.193a2.084 2.084 0 00.633.942v-1.812a.955.955 0 00-.148.028.687.687 0 00-.486.841zm3.473 8.232l-4.195 1.123L1 6.697l4.07-1.091a.476.476 0 00-.246-.919L.55 5.837a.742.742 0 00-.524.908l4.088 15.252a.743.743 0 00.715.55.745.745 0 00.193-.025l4.4-1.178a.476.476 0 00-.246-.919zM2.711 7.308a.476.476 0 00-.336.582l.117.437a.476.476 0 10.919-.246l-.117-.437a.476.476 0 00-.583-.336zm13.01 6.58a.476.476 0 00-.476.476v.453a.476.476 0 10.951 0v-.453a.476.476 0 00-.476-.475z"
        fill="#8d109a"
      />
    </svg>
  )
}

export default SvgComponent
