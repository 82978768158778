import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { ITabContent } from '../../../interfaces/GeneralInterfaces';
import TabContentStyle from './style';

const TabContent: React.FC<ITabContent> = ({
    children,
    isActive,
}) => {
    // const [activeTab, setActiveTab] = useState(isActive);
    const tabContentRef = React.useRef(null);

    return (
        <CSSTransition
            in={isActive}
            timeout={600}
            nodeRef={tabContentRef}
            classNames="tab-content"
        >
            <TabContentStyle ref={tabContentRef}>
                {isActive && children}
            </TabContentStyle>
        </CSSTransition>
    );
}

export default TabContent;