import styled from 'styled-components'

export const TableRedeemedPointsStyle = styled.div`
margin-top: 2rem;
width: 100%;

    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
    td:nth-of-type(1):before { content: "Descrição"; }
    td:nth-of-type(2):before { content: "Status"; }
    td:nth-of-type(3):before { content: "Pontos"; }
}
`
