import styled from 'styled-components'

export const AnsweredStyle = styled.div`
    .quiz{
        display:flex;
        flex-direction:column;
        margin-top:30px;

        .questions{
            margin-bottom:30px;
            padding:20px;
            border:1px solid rgba(0,0,0,0.2);
            h2{
                margin-bottom:10px;
                color:var(--primary);
            }
            .answers{
                display:flex;
                flex-direction:column;
                
               > div{
                    margin-bottom:10px;
                    display:flex;
                    align-items:center;
                }
                .icon-answer{
                    width:20px;
                    height:20px;
                    border:1px solid var(--primary);
                    border-radius: 50%;
                    margin-right:5px;
                    display:flex;
                    align-items:center;
                    justify-content:center;
                    svg{
                        fill:var(--primary);
                        width:13px;
                        opacity:0;
                    }
                }
               
                .active{
                    font-weight: bold;
                    position:relative;
                    .icon-answer svg{
                        opacity:1;
                    }
                    .wrong{
                        color:var(--danger);
                    }
                }

                .correct{
                    color:var(--color-green);
                }
                
            }
        }
    }
`