import React from 'react';
import { HeaderActions } from '..';
import { HeaderStyle } from './style';


const Header: React.FC = () => {
    return (
        <HeaderStyle>
            <HeaderActions />
        </HeaderStyle>
    );
}

export default Header;