import { useState, useContext, useEffect } from 'react'
import Cards from '../../../assets/svg/Cards';
import { LeagueManagerStyle } from './style'
import LoyaltyPoints from '../../../assets/svg/LoyaltyPoints';
import { selectStyles } from '../../../assets/styles/selectStyles'

import { AccordionBase } from '../../../components/Accordion'
import SectionTitle from '../../../components/SectionTitle';
import Text from '../../../components/Text'
import PointsWidget from '../../../components/PointsWidget';
import TableMyTournamentsManager from '../../../components/TableMyTournamentsManager';
import Button from '../../../components/Button';
import { ITournamentFilter } from '../../../interfaces/TournamentsInterface';

import api from '../../../services/api';
import { AuthContext } from '../../../contexts/UserContext';
import { IStates, ITournament } from '../../../interfaces/GeneralInterfaces';
import Select from "react-select";
import { ILeague } from '../../../interfaces/LeagueInterfaces';
import { useHistory } from 'react-router-dom';
import useCommonRequisitions from '../../../hooks/useCommonRequisitions';
import Modal from '../../../components/Modal';

interface LeagueManagerProps {
    league: ILeague
}

const visibilityOptions = [
    { value: 'Public', label: 'Público' },
    { value: 'Private', label: 'Privado' }
];

const formatOptions = [
    { value: 'Presential', label: 'Presencial' },
    { value: 'Online', label: 'Online' }
];

const LeagueManager = ({ league }: LeagueManagerProps) => {
    const { getStates, getCities } = useCommonRequisitions();
    const [filter, setFilter] = useState<ITournamentFilter>({} as ITournamentFilter);
    const [states, setStates] = useState<IStates[]>([]);
    const [tornamentsInformation, setTornamentsInformation] = useState<ITournament[]>({} as ITournament[]);
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [totalTournaments, setTotalTournaments] = useState(0);
    const { userInfo, loading } = useContext(AuthContext);
    const history = useHistory();
    const [returnMessage, setReturnMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClose = () => {
        setIsModalOpen(false);
    }

    useEffect(() => {
        if (!loading) {
            handleSearch();
            handleGetTotalTournaments();
        }

    }, [loading, page])

    useEffect(() => {
        async function getState() {
          const populatedStates = await getStates();
          setStates(populatedStates);
        }
        getState();
      }, []);

    const submitSearch = () => handleSearch();

    const handleSearch = async () => {
        try {
            const params = { filterOptions: filter }
            var stringLeague = 'tournaments/league/';
            const response = await api.post(`${stringLeague}${league.league.id}?page=${page}&limit=${perPage}`, params);
            
            if (response.data.tournaments && response.data.tournaments.length > 0) {
                setTornamentsInformation(response.data.tournaments);
            setTotal(response.data.total);
            } else {
                setIsModalOpen(true);
        setReturnMessage('Não há torneios para essa pesquisa')
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const handleGetTotalTournaments = async () => {
        try {
            const response = await api.post(`tournaments/league/${league.league.id}`);
            setTotalTournaments(response.data.total);
        } catch (e: any) {
            console.log(e);
        }
    }

    const MessageToCreateTournaments = () => {
        return <span>Nenhum torneio criado até agora, agende o seu primeiro torneio clicando na aba <strong>Agendar Torneio</strong>.</span>
    }

    const handleEditLeague = () => history.push(`/editar-liga/${league?.league?.id}`);

    return (
        <LeagueManagerStyle>
            <AccordionBase sectionTitle={<SectionTitle title="Liga" icon={<Cards />} />} isOpen={true}>
                <h2>{league.league.leagueName}</h2>
                <Text customClass="league-manager-text">
                    {league.league.description}
                </Text>

                <ul className="list-infos">
                    <li><strong>ID DA LIGA:</strong> {league?.league?.id ?? ''}</li>
                    <li><strong>NOME DA LIGA:</strong> {league?.league?.leagueName ?? ''}</li>
                    <li><strong>NÚMERO MÁXIMO DE JOGADORES:</strong> {league?.league?.maxPlayers ?? ''}</li>
                    <li><strong>ESTADO:</strong> {league?.league?.state?.name ?? 'Online'}</li>
                    <li><strong>CIDADE:</strong> {league?.league?.city?.name ?? 'Online'}</li>
                    <li className="fundador"><strong>FUNDADOR(A):</strong> {userInfo().name}</li>
                </ul>
                {userInfo().isAdmin && <Button label={"EDITAR"} eventCallback={handleEditLeague} />}
                {tornamentsInformation && tornamentsInformation.length === 0 && <> <br /><br /><MessageToCreateTournaments /> </>}
            </AccordionBase>
            {
                tornamentsInformation.length > 0 &&
                <div className="points-widgets">
                    <PointsWidget
                        icon={<LoyaltyPoints />}
                        points={totalTournaments}
                        text="torneios no total"
                    />

                    {/* <PointsWidget
                        icon={<RedemPoints />}
                        points={league?.points ?? 0}
                        text="Pontos acumulados"
                    /> */}
                </div>
            }

            {tornamentsInformation && tornamentsInformation.length > 0 &&
                <>
                    <div className="filter_tournaments">
                        <input
                            name="name"
                            type="text"
                            className="search"
                            placeholder="Pesquisar por nome"
                            onChange={(e: any) => {
                                let newValue = { ...filter }
                                newValue.search = e.target.value;
                                setFilter(newValue);
                            }}
                        />
                        <div className="filter_selects">
                            <Select options={states} placeholder="Estado" styles={selectStyles} onChange={(state: any) => {
                                let newValue = { ...filter }
                                newValue.stateId = state.value;
                                setFilter(newValue);
                            }} />
                            <Select options={visibilityOptions} placeholder="Tipo de torneio" styles={selectStyles} onChange={(visibility: any) => {
                                let newValue = { ...filter }
                                newValue.visibility = visibility.value;
                                setFilter(newValue);
                            }} />

                            <Select options={formatOptions} placeholder="Selecione o formato" styles={selectStyles} onChange={(formatOp: any) => {
                                let newValue = { ...filter }
                                newValue.format = formatOp.value;
                                setFilter(newValue);
                            }} />
                        </div>
                        <div className="search_button_area">
                            <Button label="Pesquisar" eventCallback={submitSearch} />
                            {(isModalOpen && (
                        <Modal
                            isOpen={isModalOpen}
                            onCloseModal={handleClose}
                            classStatus={'error'}
                            text={returnMessage}
                        />
                    ))}
                        </div>
                    </div>
                    <div className="tournaments">
                        <TableMyTournamentsManager tornamentsInformation={tornamentsInformation} page={page} perPage={perPage} total={total} setPage={setPage} setPerPage={setPerPage} />
                    </div>
                </>
            }
        </LeagueManagerStyle>
    )
}

export default LeagueManager;