import { useContext, useEffect, useState } from 'react';
import { IMatch, IRoundResponse, ITournament } from '../../../interfaces/GeneralInterfaces';
import { MatchesStyle } from './style';
import AccordionBase from '../../../components/Accordion/AccordionBase';
import SectionTitle from '../../../components/SectionTitle';
import Cards from '../../../assets/svg/Cards';
import Round from './Round';
import { AuthContext } from '../../../contexts/UserContext';
import { TournamentContext } from '../Context/TournamentContext';
import useInterval from '../../../hooks/useInterval';
import { array } from 'yup/lib/locale';

interface MatchesProps {
  tournament: ITournament;
  tournamentRef: any;
}

interface AccordionOpen {
  round: number;
  open: boolean;
}

const Matches = ({ tournament, tournamentRef }: MatchesProps) => {
  const [maxRounds, setMaxRounds] = useState<number>(-1);
  const { handleMatches, matchResponse, handleRanking } =
    useContext(TournamentContext);
  const [isOpen, setIsOpen] = useState<AccordionOpen[]>([] as AccordionOpen[]);
  const { loading } = useContext(AuthContext);
  const isPlaying = true;
  const delay = 5000;

  useEffect(() => {
    if (!loading) {
      handleMatches();
      handleRanking();
    }
  }, [loading]);

  useInterval(
    () => {
      handleMatches();
    },
    isPlaying ? delay : null
  );

  useEffect(() => {
    if (matchResponse?.rounds?.length > 0 && matchResponse?.maxrounds) {
      setMaxRounds(matchResponse.maxrounds);
      handleAccordionIsOpen();
    }
  }, [loading, matchResponse]);

  const handleAccordionIsOpen = () => {
    const handleAccordion: AccordionOpen[] = [];
    const newMatches = [...matchResponse.rounds];
    newMatches.map((round: IRoundResponse) => {
      if (round.matches.some((match: IMatch) => match.status !== "finished")) {
        handleAccordion.push({ round: round.round, open: true });
      } else {
        if (
          newMatches.find(
            (currentRound: IRoundResponse) =>
              currentRound.round === round.round + 1
          )
        ) {
          handleAccordion.push({ round: round.round, open: false });
        } else {
          handleAccordion.push({ round: round.round, open: true });
        }
      }
      return null;
    });

    setIsOpen(handleAccordion);
  };

  return (
    <MatchesStyle>
      {isOpen.length > 0 &&
        matchResponse?.rounds &&
        matchResponse?.rounds?.map((round: IRoundResponse, index: number) => {
          return (
            <AccordionBase
              key={index}
              sectionTitle={
                <SectionTitle
                  title={`Rodada ${round.round}`}
                  icon={<Cards />}
                />
              }
              isOpen={
                isOpen?.find(
                  (accordion: AccordionOpen) => accordion.round === round.round
                )?.open ?? true
              }
            >
              <Round
                matches={round.matches}
                tournamentRef={tournamentRef}
                tournament={tournament}
                isCurrentRound={matchResponse?.rounds.length - 1 === index}
                isLastRound={index + 1 === maxRounds}
              />
            </AccordionBase>
          );
        })}
    </MatchesStyle>
  );
};

export default Matches;
