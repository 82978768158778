import styled from 'styled-components'

export const CreateTournamentStyle = styled.section`
    background:#FFF;
    display:flex;
    flex-direction:column;

    .title-create-tournament{
        margin-top:15px;
        margin-bottom:10px;
    }
    form{
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap:10px;
        margin-top:30px;
        @media(max-width:1024px){
            grid-template-columns:1fr;
        }

        .description{
            grid-column:2 span;
            @media(max-width:1024px){
                grid-column:1 span;
            }
        }
        button{
            grid-column:2 span;
            margin:0 auto;
            @media(max-width:1024px){
                grid-column:1 span;
            }
        }
    }

`
