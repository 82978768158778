
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import SectionTitle from "../../../components/SectionTitle";
import { AuthContext } from "../../../contexts/UserContext";
import api from "../../../services/api";
import { ptBR } from 'date-fns/locale'
import { parseISO, format } from 'date-fns'
import Text from '../../../components/Text';
import { AnswersStyle } from "./style";
import { AccordionBase } from "../../../components/Accordion";
import Button from "../../../components/Button";
import TablePagination from "../../../components/TablePagination";


interface IForm {
    id: number;
    name: string;
    category: string;
    points: number;
}

interface IUser {
    firstName: string;
    lastName: string;
}

interface IQuizResponse {
    id: number;
    form: IForm;
    user: IUser;
    category: string;
    points: number;
    createdAt: string;
    status: boolean;
}

interface IQuizResponseForTable {
    name: string;
    category: [string, number];
    player: string;
    points: number;
    createdAt: string;
}


interface IQuizFilter {
    search?: string;
}


const Answers = () => {
    const history = useHistory();
    const [quizAnswered, setQuizAnswered] = useState<IQuizResponse[]>([]);
    const [quizAnsweredForTable, setQuizAnsweredForTable] = useState<IQuizResponseForTable[]>([])
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const [total, setTotal] = useState<number>(0);
    const [filterOptions, setFilterOptions] = useState<IQuizFilter>({} as IQuizFilter);
    const { loading } = useContext(AuthContext);

    const handleClickRow = (row: any) => {
        history.push(`/questionario-resposta/${row.category[1]}`)
    }
    const renderCategory = (values: any) => {
        if (values.value) {
            if (values.value[0] === "Test") {
                return "Prova" 
            } else if (values.value[0] === "BeManagerTest") {
                return "Prova de Organizador"
            } else if (values.value[0] === "ManagerQuiz") {
                return "Questionário do Organizador"
            } else {
                return "Questionário" 
            }
        }
    }

    const answeredQuizTable = [
        {
            Header: 'QUESTIONÁRIO',
            accessor: 'name'
        },
        {
            Header: 'CATEGORIA',
            accessor: 'category',
            Cell: renderCategory,
        },
        {
            Header: 'JOGADOR',
            accessor: 'player'
        },
        {
            Header: 'PONTOS',
            accessor: 'points'
        },
        {
            Header: 'DATA',
            accessor: 'createdAt'
        }
    ];

    const getQuizAnswered = async () => {
        const response = await api.post(`forms/list-forms-answered-for-admin?page=${page}&limit=${perPage}`, { filterOptions });
        setTotal(response.data.total);
        setQuizAnswered(response.data.formsAnswers);
    }

    const handleSearch = () => {
        setPage(1);
        setPerPage(3);
        getQuizAnswered();
    }

    useEffect(() => {

        if (!loading) {
            getQuizAnswered();
        }

    }, [loading, page]);

    useEffect(() => {
        const prepareDateForAnswersTable = () => {
            let data: IQuizResponseForTable[] = [];
            if (quizAnswered) {
                quizAnswered.map((quiz: IQuizResponse) => {
                    return data.push({
                        name: quiz.form.name,
                        category: [quiz.form.category, quiz.id],
                        points: quiz.form.points,
                        player: `${quiz.user.firstName} ${quiz.user.lastName}`,
                        createdAt: format(parseISO(quiz.createdAt), "dd/MM/yyyy ", { locale: ptBR }),
                    })
                })
                setQuizAnsweredForTable(data);
            }
        }
        prepareDateForAnswersTable();
    }, [quizAnswered])

    return (
        <AnswersStyle>
            <AccordionBase sectionTitle={<SectionTitle title="Filtros" />} isOpen={false}>
                <div className="filter_tournaments">
                    <input
                        name="search"
                        type="text"
                        className="search"
                        placeholder="Pesquise por nome, cpf, telefone, etc..."
                        onChange={(e: any) => {
                            let newValue = { ...filterOptions }
                            newValue.search = e.target.value;
                            setFilterOptions(newValue);
                        }}
                    />
                    <div className="search_button_area">
                        <Button label="Pesquisar" eventCallback={handleSearch} />
                    </div>

                </div>
            </AccordionBase>
            <br /><br />
            <SectionTitle customClass="quiz-title" title="Questionários Respondidos" />
            {quizAnsweredForTable.length > 0 &&
                <TablePagination
                    handleClick={handleClickRow}
                    perPage={perPage}
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={page}
                    totalPage={Math.ceil(total / perPage)}
                    columnsWithOutMemo={answeredQuizTable}
                    dataWithOutMemo={quizAnsweredForTable} />
            }
            {quizAnsweredForTable.length === 0 &&
                <>
                    <Text>Nenhum questionário foi respondido até o momento.<br /><br /></Text>
                </>
            }
        </AnswersStyle>
    )
}
export default Answers;
