import styled from "styled-components";

export const Wrapper = styled.div`
  .start-game {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .start-game button {
    margin: 30px auto;
  }

  .orientation {
    margin-top: 2rem !important;
  }
`;

export const BuildTeamsStyle = styled.div`
  table {
    /* display: inline-block;
        overflow: auto; */
    td {
      padding: 3px;
    }
  }

  > div {
    overflow-y: visible;
    overflow-x: visible;
  }

  @media (min-width: 1410px) {
    table {
      display: inline-table;
      overflow: auto;
    }
  }
`;

export const TeamStyle = styled.div`
  display: grid;
  grid-gap: 10px;
  max-width: 100%;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .team {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.white {
      svg {
        fill: var(--color-gray-thin);
      }
      span {
        color: var(--color-gray-thin);
      }
      .players {
        border: 3px dotted rgba(0, 0, 0, 0.3);
      }
    }
    &:hover {
      .players {
        span {
          button {
            svg {
              fill: var(--danger);
            }
          }
        }
      }
    }

    .players {
      display: flex;
      margin-top: 3px;
      flex-direction: column;
      border: 2px solid var(--primary);
      border-radius: 4px;
      padding: 20px;
      font-size: 11px;
      transition: all 200ms;
      width: 100%;
      height: 110px;
      justify-content: space-between;

      .player_name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 2px;
        margin-bottom: 3px;

        .user-doc {
          display: flex;
          flex-direction: column;

          span {
            &:last-of-type {
              font-size: 11px;
              color: var(--primary);
            }
          }
        }

        button {
          margin-left: 5px;
          transition: all 200ms;
          width: 15px;
          height: 15px;

          svg {
            fill: var(--color-gray-thin);
            width: 15px;
            height: 15px;
          }
        }
      }

      @media (max-width: 800px) {
        .user-doc {
          display: none;
        }
      }
    }
    &.entrou {
      background: green;
    }
    margin: 0 10px;
  }
`;
