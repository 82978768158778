import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../contexts/UserContext";
import { TableRankingMatchesStyle } from "./style";
import Table from "../../../components/Table";
import { IRanking } from "../../../interfaces/GeneralInterfaces";
import { TournamentContext } from "../Context/TournamentContext";

interface IRankingForTable {
  team: string;
  score: number;
  players: string;
  position?: number;
  victories: number;
  loses: number;
  ties: number;
}

interface ITableRankingMatchesProps {
  tournamentId: number;
}

const TableRankingMatches = ({ tournamentId }: ITableRankingMatchesProps) => {
  const { loading } = useContext(AuthContext);
  const { ranking, handleRanking } = useContext(TournamentContext);
  const [rankingForTable, setRankingForTable] = useState<IRankingForTable[]>(
    [] as IRankingForTable[]
  );

  const columnsRanking = [
    {
      Header: "TIME",
      accessor: "team",
    },
    {
      Header: "JOGADORES",
      accessor: "players",
    },
    {
      Header: "COLOCAÇÃO",
      accessor: "position",
    },
    {
      Header: "PONTOS",
      accessor: "score",
    },
    {
      Header: "VITÓRIAS",
      accessor: "victories",
    },
    {
      Header: "DERROTAS",
      accessor: "loses",
    },
    {
      Header: "EMPATES",
      accessor: "ties",
    },
  ];

  useEffect(() => {
    if (!loading) handleRanking();
  }, [loading]);

  useEffect(() => {
    const prepareDataForTable = () => {
      let dataForTable: IRankingForTable[] = [];

      let rankings = ranking.filter((rank) => rank.team.name != "BYE");

      rankings.map((r: IRanking) => {
        return dataForTable.push({
          team: r.team.name,
          players:
            (r.team.users[0]?.username === undefined
              ? "Desclassificado"
              : r.team.users[0]?.username) +
            " | " +
            (r.team.users[1]?.username === undefined
              ? "Desclassificado"
              : r.team.users[1]?.username),
          loses: r.loses,
          score: r.score,
          victories: r.victories,
          position: r.placement,
          ties: r.ties,
        });
      });
      setRankingForTable(dataForTable);
    };

    if (ranking.length > 0) prepareDataForTable();
  }, [ranking]);

  return (
    <TableRankingMatchesStyle>
      {loading && <div className="">Carregando...</div>}
      {!loading && rankingForTable.length > 0 && (
        <div className="next-tournaments">
          <Table
            columnsWithOutMemo={columnsRanking}
            dataWithOutMemo={rankingForTable}
          />
        </div>
      )}
    </TableRankingMatchesStyle>
  );
};

export default TableRankingMatches;
