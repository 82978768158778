import styled from 'styled-components'

const TooltipStyle = styled.div`
    display: inline-block;
    position: relative;

    &.tooltip-information{
        display: flex;
        .dot {
            margin-right: 1rem;
        }
    }

    .tooltip{
        min-height: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity .3s;
        pointer-events: none;
        opacity: 0;
        z-index: 1;

        span{
            display: block;
            padding: .4rem 1rem;
            color: var(--color-white);
            font-size: 1.3rem;
            font-family: var(--font-primary-bold);
            position: relative;
            z-index: 2;
            white-space: nowrap;
        }

        &:before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(5px, 5px);
            transition: transform .3s;
            background-color: var(--primary);
            z-index: 1;
        }

        &.primary span{
            background-color: var(--color-green);
        }

        &.secondary span{
            background-color: var(--color-yellow);
        }

        &.tertiary span{
            background-color: var(--color-purple);
        }
    }

    &:hover{
        .tooltip{
            pointer-events: all;
            opacity: 1;
        }
    }

`
export default TooltipStyle;
