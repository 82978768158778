import styled from 'styled-components'

export const ModalStyle = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 100;

    &.modal-anim-enter {
        opacity: 0;
    }

    &.modal-anim-enter-active {
        opacity: 1;
        transition: opacity .3s;
    }

    &.modal-anim-enter-done{
        opacity: 1;
    }

    &.modal-anim-exit, &.modal-anim-exit-done {
        opacity: 1;
    }

    &.modal-anim-exit-active {
        opacity: 0;
        transition: opacity .3s;
    }

    &.modal-anim-enter {
        opacity: 0;
    }
    &.modal-anim-enter-active {
        opacity: 1;
        transition: opacity .3s;
    }
    &.modal-anim-exit {
        opacity: 1;
    }
    &.modal-anim-exit-active {
        opacity: 0;
        transition: opacity .3s;
    }

    .buttons-style {
        button {
            margin: 2rem 2rem 0 2rem;
        }
    }

    .overlay{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-color: rgba(0,0,0,.5); 
    }

    .span-badge {
        font-size: 2rem;
        font-family: var(--font-secondary-bold);
        color: var(--color-white);
        background-color: var(--primary);
        padding: .9rem 3rem;
        margin-right: 1rem;
        margin-bottom: 2rem;
        transition: background-color .3s;
    
        &:hover, &.current-tab{
            background-color: var(--color-purple);
        }
    }

    .div-badge {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-body{
        max-width: 900px;
        min-width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 3rem;
        background-color: var(--color-white);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 1rem 1rem 7rem rgba(0, 0, 0, .16);

        @media(max-width:900px){
                max-width:95%;
            }

        .video-background{
            width:600px;
            height:400px;
            @media(max-width:900px){
                width:100%;
                max-width:360px;
                max-height:100%;
            }
         }

        .close{
            position: absolute;
            right: 0;
            top: 0;
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;

            svg{
                transition: transform .3s;
            }

            &:hover{
                svg{
                    transform: rotate(180deg);
                }
            }
        }

        .modal-icon{
            svg{
                width: 8rem;
                height: 8rem;
            }

            &.success svg{
                fill: var(--color-success);
                stroke: var(--color-success);
            }

            &.warning svg {
                fill: var(--color-warning);
                stroke: var(--color-warning);
            }

            &.error svg{
                fill: var(--danger);
                stroke: var(--danger);
            }
        }

        .modal-title{
            font-size: 3.2rem;
            font-family: var(--font-secondary-bold);
            // margin-top: 2rem;
        }

        .modal-subtitle{
            font-size: 2.2rem;
            font-family: var(--font-secondary-bold);
            margin-top: 2rem;
        }

        .modal-text{
            font-size: 1.6rem;
            font-family: var(--font-primary-regular);
            margin-top: 3rem;
        }

        .close-modal{
            // width: 100%;
            margin-top: 2rem;
        }

        .actions{
            display: flex;
            justify-content: center;
            margin-top: 2rem;
            button {
                margin: 0 1rem;
            }
        }
    }
`