import styled from 'styled-components'
import Winner from '../../../../../assets/svg/WinnerIcon';
import { createElement } from "react";
import { renderToStaticMarkup } from "react-dom/server";

interface MatchControllerStyleProps {
    matchStatus?: boolean;
    matchStatusText: string;
    fill: string;
    displayWinner: boolean;
    displayBefore: boolean;
}

const reactSvgComponentToMarkupString = (Component: any, props: any) =>
    `data:image/svg+xml,${encodeURIComponent(
        renderToStaticMarkup(createElement(Component, props))
    )}`;

const MatchControllerStyle = styled.div`
    display: flex;
    flex-direction:column;
    align-items:center;
    padding:20px;
    border:1px solid var(--color-gray-thin);
    max-width:100%;
    position:relative;
    &:after{
        content:${(props: MatchControllerStyleProps) => {
        if (props.matchStatusText === "finished") {
            return "'Finalizado'"
        } else if (props.matchStatusText === "waitingAnotherTeam") {
            return "'Esperando...'"
        } else {
            return "'Divergência'"
        }
    }};
        color:${(props: MatchControllerStyleProps) => {
        if (props.matchStatusText === "finished") {
            return 'var(--color-purple)'
        } else if (props.matchStatusText === "waitingAnotherTeam") {
            return 'var(--color-purple)'
        } else {
            return "#fff"
        }
    }};
        padding:5px;
        border-radius:5px;
        background:${(props: MatchControllerStyleProps) => {
        if (props.matchStatusText === "finished") {
            return 'var(--color-green)'
        } else if (props.matchStatusText === "waitingAnotherTeam") {
            return 'var(--color-yellow)'
        } else {
            return 'var(--danger)'
        }
    }};
        transform:rotate(-45deg);
        position:absolute;
        top:0;
        left:-26px;
        display:${(props: MatchControllerStyleProps) => props.matchStatus ? 'flex' : 'none'};
    }
    
    @media(max-width:1024px){
        padding:10px;
        .tournamentInfo {
            .edit{
                opacity:1;
            }
        }
    }

    .match{
        display:grid;
        grid-template-columns:100px 100px;
        grid-gap:50px;
        > div {
            display:flex;
            flex-direction:column;
            align-items:center;
        }
        .versus{
            font-size:30px;
            margin-top:7px;
        }
        &:after {
            display:${(props: MatchControllerStyleProps) => props.displayWinner && !props.displayBefore ? 'flex' : 'none'};
            transform:rotate(-15deg);
            position:absolute;
            top:20px;
            content: ${({ fill }: MatchControllerStyleProps) =>
        `url(${reactSvgComponentToMarkupString(Winner, {
            fill
        })})`};
        }
        &::before {
            display:${(props: MatchControllerStyleProps) => props.displayWinner && props.displayBefore ? 'flex' : 'none'};
            transform:rotate(15deg);
            position:absolute;
            padding-left: 225px;
            top:-8px;
            content: ${({ fill }: MatchControllerStyleProps) =>
        `url(${reactSvgComponentToMarkupString(Winner, {
            fill
        })})`};
        }
    }

    .actions {
        width: 100%;
        display: flex;
        justify-content: center;

        > div:first-of-type{
            width:100%;
        }
    }

    .tournamentInfo {
        display: flex;
        justify-content: space-around;
        .edit{
            svg {
                height: 20px;
                width: 18px;
                fill: var(--color-green);
            }
            padding-left: 12px;
            cursor: pointer;
            opacity: 0;
        }
    }

    &:hover{
        .tournamentInfo {
            .edit{
                opacity:1;
            }
        }
    }
`

const PointsControlerStyle = styled.div`
    display: flex;
    align-items: center;

    button {
        border: 1px solid var(--color-gray-thin);
        border-radius: 50%;
        height: 25px;
        width: 25px;
        color: var(--color-gray-dark);
        font-size: 20px;
        /* opacity:0; */
    }

    .winner{
        background:var(--color-green);
        p{
            color:var(--primary);
        }
        
    }
    .looser{
        background:var(--danger);
        p{
            color:#FFF;
        }
    }
    .team-result {
        margin: 0.5rem;
        height: 40px;
        width: 40px;
        border: 1px solid var(--color-gray-thin);
        border-radius: 50%;
        color: var(--color-gray-dark);
        font-size: 20px;
        justify-content:center;
        display:flex;
        align-items:center;
    }
`

const TeamStyle = styled.div`
    max-width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position:relative;
    
    &:first-of-type{
        &:after{
            content: "X";
            position: absolute;
            top: 7px;
            font-size: 30px;
            right: 50px;
         }
    }
    span {
        margin: 0.2rem;
        font-weight: 700;
        min-height:45px;
        display:flex;
        align-items:center;
        justify-content:center;
        max-width:100px;
    }
`

export { MatchControllerStyle, PointsControlerStyle, TeamStyle };