import { useState, useEffect, useContext } from "react";
import { ApprovingCupCopagStyle } from "./style";
import BasePage from "../../components/BasePage";
import SectionTitle from "../../components/SectionTitle";
import Cards from "../../assets/svg/Cards";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import { AuthContext } from "../../contexts/UserContext";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { ITournament } from "../../interfaces/GeneralInterfaces";
import { ptBR } from "date-fns/locale";
import { parseISO, format } from "date-fns";
import { useHistory } from "react-router-dom";

interface ParamTypes {
  id: string;
}

const ApprovingCupCopag = () => {
  const [tournament, setTournament] = useState<ITournament>({} as ITournament);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isModalFailOpen, setIsModalFailOpen] = useState<boolean>(false);
  const { loading } = useContext(AuthContext);
  let { id } = useParams<ParamTypes>();
  const history = useHistory();

  useEffect(() => {
    const getTournament = async () => {
      try {
        const response = await api.get(`tournaments/${id}`);
        setTournament(response.data);
      } catch (e: any) {
        history.push("/pagina-nao-encontrada");
      }
    };

    if (!loading) {
      getTournament();
    }
  }, [loading]);

  const handleManager = async (option: string) => {
    try {
      const body = {
        tournamentId: Number(id),
        status: "Approved",
      };
      await api.post(`tournaments/approve/`, body);

      if (option === "recused") {
        setSuccessMessage("Copa Copag recusada");
      } else if (option === "approved") {
        setSuccessMessage("Copa Copag aprovada com sucesso");
      }

      setIsModalSuccessOpen(true);

      history.push("/administrador");
    } catch (err: any) {
      setIsModalFailOpen(true);
    }
  };

  const renderDateFormated = (value: string) => {
    const parsedDate = parseISO(value);
    const formatedDate = format(parsedDate, "dd'.'MM ' | ' cccc", {
      locale: ptBR,
    });
    return formatedDate ?? "Data não encontrada";
  };

  const renderModality = (value: string) => {
    return value === "TorneioPaulista" ? "Torneio Paulista" : "Torneio Mineiro";
  };

  const renderVisibility = (value: string) => {
    return value === "Public" ? "Público" : "Privado";
  };

  const renderFormat = (value: string) => {
    return value === "Online" ? "Online" : "Presencial";
  };

  return (
    <BasePage title={"Aprovação de Copa Copag"}>
      <ApprovingCupCopagStyle>
        {tournament && tournament?.name && (
          <>
            <div className="playerData">
              <SectionTitle title="Dados do torneio" icon={<Cards />} />
              <ul className="list-infos">
                <li>
                  <strong>Nome do torneio: </strong> {tournament.name}
                </li>
                <li>
                  <strong>Modalidade: </strong>{" "}
                  {renderModality(tournament.modality)}
                </li>
                <li>
                  <strong>Preço </strong> R${tournament.price}
                </li>
                <li>
                  <strong>Formato: </strong> {renderFormat(tournament.format)}
                </li>
                <li>
                  <strong>Visibilidade:</strong>{" "}
                  {renderVisibility(tournament.visibility)}
                </li>
                <li>
                  <strong>Data:</strong> {renderDateFormated(tournament.date)}
                </li>
                <li>
                  <strong>Check-in:</strong>{" "}
                  {renderDateFormated(tournament.checkin)}
                </li>
                <li>
                  <strong>Formato de time:</strong>{" "}
                  {tournament.teamCount === 2 ? "Duplas" : "Trios"}
                </li>
                {tournament.format !== "Online" && (
                  <li>
                    <strong>Estado:</strong>{" "}
                    {tournament?.state?.name ?? "Estado não encontrado"}
                  </li>
                )}
                {tournament.format !== "Online" && (
                  <li>
                    <strong>Cidade:</strong>{" "}
                    {tournament?.city?.name ?? "Cidade não encontrada"}
                  </li>
                )}
                <li>
                  <strong>Descrição: </strong> {tournament.description}
                </li>
              </ul>
            </div>
            <div className="actions_admin">
              <Button
                classButton="finish-button"
                eventCallback={() => handleManager("recused")}
                label="RECUSAR"
                type={"button"}
              />
              <Button
                classButton="finish-button"
                eventCallback={() => handleManager("approved")}
                label="boiola"
                type={"button"}
              />
            </div>
          </>
        )}
        {isModalSuccessOpen && (
          <Modal
            isOpen={isModalSuccessOpen}
            onCloseModal={() => setIsModalSuccessOpen(false)}
            classStatus="success"
            title="Sucesso"
            text={successMessage}
          />
        )}

        {isModalFailOpen && (
          <Modal
            isOpen={isModalFailOpen}
            onCloseModal={() => setIsModalFailOpen(false)}
            classStatus="error"
            title="Erro"
            text={"Não foi possível Aprovar/Recusar o torneio"}
          />
        )}
      </ApprovingCupCopagStyle>
    </BasePage>
  );
};

export default ApprovingCupCopag;
