import styled from 'styled-components'

export const NewTicketStyle = styled.section`
    background:#FFF;
    min-height:500px;

    form{
        display:flex;
        flex-direction:column;
        margin-top:30px;

        .description{
            grid-column:2 span;
        }
        button{
            grid-column:2 span;
            margin:0 auto;
            margin-top:30px;
        }
    }
`
