import styled from "styled-components";

interface TablePaginationStyleProps {
  hasHandleClick?: boolean;
}

const TablePaginationStyle = styled.div`
  padding: 0.5rem;
  overflow-y: hidden;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--color-gray-light);
    @media (max-width: 1024px) {
      border: none;
    }
  }

  tbody tr {
    cursor: ${(props: TablePaginationStyleProps) =>
      props.hasHandleClick ? "pointer" : "auto"};
  }

  tr:nth-of-type(odd) {
    background: var(--color-gray-thin);
  }

  th {
    div {
      display: flex;
      align-items: center;
    }
    span {
      margin-left: 3px;
      svg {
        width: 10px;
        height: 10px;
        fill: #fff;
      }
    }
  }
  th {
    background-color: var(--primary);
    color: white;
    font-weight: bold;
  }
  td,
  th {
    padding: 10px;
    text-align: left;
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
      margin-bottom: 10px;
    }
    tr:nth-of-type(odd) {
      background: #fff;
    }
    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 12px;
      left: 6px;
      width: 35%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: bold;
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    @media (max-width: 1024px) {
      justify-content: center;
      word-break: normal;
    }
    button {
      display: flex;
      align-items: center;
      margin: 0 5px;

      &.first_last {
        background: var(--color-purple);
        padding: 5px;
        font-size: 12px;
        color: #fff;
        border-radius: 10px;
        &:disabled {
          background: #ccc;
        }
      }

      svg {
        fill: var(--color-green);
      }
    }
  }
`;

export default TablePaginationStyle;
