import React from 'react';
import Button from '../../../components/Button';
import { useHistory } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';

import Logo from '../../../components/Logo';
import Title from '../../../components/Title';
import Text from '../../../components/Text';

const ModalBeAManager = () => {
    const history = useHistory();

    const handleClick = ():void => {
        history.push('home');
    }

    return (
        <>
            <Logo customClass="be-a-manager" />

            <Title customClass="be-a-manager">SEJA UM ORGANIZADOR</Title>
            <h3>Solicitação foi enviada para a aprovaçao da Copag.</h3>

            <Text customClass="be-a-manager">
                Solicitação foi enviada para aprovação da Copag. Será feito o envio de notificação da sua solicitaç!ao por e-mail para o usuário com o resultado da solicitação; O organizador aprovado receberá acesso a página de organizador. A sua liga receberá um id de liga único.
            </Text>

            <Button label="VOLTAR PARA A PLATAFORMA" eventCallback={() => handleClick()} leftIcon={FaChevronLeft}/>
        </>
    )
}

export default ModalBeAManager