import { useEffect, useState, useContext, useRef } from "react";
import { TournamentStyle } from "./style";
import { AccordionBase } from "../../components/Accordion";
import Flowchart from "../../components/Flowchart";
import SectionTitle from "../../components/SectionTitle";
import BasePage from "../../components/BasePage";
import Cards from "../../assets/svg/Cards";
import { IPlayer, ITournament } from "../../interfaces/GeneralInterfaces";
import { useHistory, useParams } from "react-router-dom";
import api from "../../services/api";
import { AuthContext } from "../../contexts/UserContext";
import TournamentInformations from "./TournamentInformations";
import PendingPlayers from "./PendingPlayers";
import BuildTeams from "./BuildTeams";
import Winner from "../../assets/svg/WinnerIcon";
import TableRankingMatches from "./TableRankingMatches";
import TabPane from "../../components/Tabs/TabPane";
import Tabs from "../../components/Tabs/Tabs";
import TableAllPlayers from "../../components/TableAllPlayers";
import { TournamentStatus } from "../../enums/GeneralEnums";
import Matches from "../Tournament/Matches";
import { TournamentProvider } from "./Context/TournamentContext";
import * as S from "../../components/Tabs/TabPane/style";
import { NoTournaments } from "../../components/TableNextsTournaments/style";
import tournament_active from "../../assets/images/tournament_active.png";

interface ParamTypes {
  tournamentId: string;
}

const Tournament = () => {
  const history = useHistory();
  const { loading, user, handleIsLeagueManagerOfThisTournament, userInfo } =
    useContext(AuthContext);
  let { tournamentId } = useParams<ParamTypes>();
  const [tournament, setTournament] = useState<ITournament>({} as ITournament);
  const [pendingUsers, setPendingUsers] = useState<any[]>([]);
  const [users, setUsers] = useState<IPlayer[]>([] as IPlayer[]);
  const tournamentRef = useRef({} as any);
  const [tournamentStatus, setTournamentStatus] = useState<TournamentStatus>(
    TournamentStatus.None
  );
  const [myUserIsSubscribed, setMyUserIsSubscribed] = useState<boolean>(false);
  const [myUserIsSubscribedAndApproved, setMyUserIsSubscribedAndApproved] =
    useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [isActiveFlowchart, setIsActiveFlowchart] = useState<boolean>(false);
  const [isModalFlowchart, setIsModalFlowchart] = useState<boolean>(false);

  useEffect(() => {
    async function getTournament() {
      try {
        const response = await api.get(`tournaments/${tournamentId}`);
        console.log("response.data", response.data);
        setTournament(response.data);
        setTournamentStatus(response.data.status as TournamentStatus);
      } catch (e: any) {
        history.push("/pagina-nao-encontrada");
      }
    }
    if (!loading) {
      getTournament();
    }
  }, [loading]);

  useEffect(() => {
    async function getSubscribedUsers() {
      const response = await api.get(
        `tournaments/${tournamentId}/users?status=pending`
      );
      setPendingUsers(response.data.subscribedPlayers);
    }

    async function getAllPlayers() {
      try {
        const allplayers = await api.get(
          `tournaments/${tournamentId}/users?page=${page}&limit=${perPage}`
        );

        if (
          allplayers.data &&
          allplayers.data.subscribedPlayers &&
          allplayers.data.total
        ) {
          setUsers(allplayers.data.subscribedPlayers);
          setTotal(allplayers.data.total);
        }
      } catch (e: any) {
        console.log(e);
      }
    }

    if (!loading && tournament) {
      getAllPlayers();
      if (handleIsLeagueManagerOfThisTournament(tournament.managerEmail))
        getSubscribedUsers();
    }
  }, [tournament, loading, page]);

  useEffect(() => {
    async function getAllPlayers() {
      try {
        const allplayers = await api.get(
          `tournaments/${tournamentId}/users?page=1&limit=9999`
        );
        if (allplayers.data && allplayers.data.subscribedPlayers) {
          setMyUserIsSubscribed(
            allplayers.data.subscribedPlayers.some(
              (player: IPlayer) => player.user.email === userInfo().email
            )
          );
          const myUser = allplayers.data.subscribedPlayers.find(
            (player: IPlayer) => player.user.email === userInfo().email
          );
          if (myUser)
            setMyUserIsSubscribedAndApproved(myUser.status === "approved");
        }
      } catch (e: any) {
        console.log(e);
      }
    }

    if (!loading) getAllPlayers();
  }, [users]);

  const handleSetStatus = (tournamentStatus: TournamentStatus) => {
    if (tournamentStatus === TournamentStatus.notStarted) {
      return "Não iniciado";
    } else if (tournamentStatus === TournamentStatus.finished) {
      return "Finalizado";
    } else {
      return "Em andamento";
    }
  };

  const handleCloseModalFlowchart = () => {
    setIsModalFlowchart((prevState) => !prevState);
  };
  return (
    <BasePage title={"Torneios"}>
      <TournamentProvider tournament={tournament}>
        <TournamentStyle ref={tournamentRef}>
          <AccordionBase
            sectionTitle={
              <SectionTitle
                title={`Torneio - ${handleSetStatus(tournamentStatus)}`}
                icon={<Cards />}
              />
            }
            isOpen={true}
          >
            {tournament && (
              <TournamentInformations
                tournament={tournament}
                players={users}
                tournamentStatus={tournamentStatus}
                subscription={myUserIsSubscribed}
                approvedAndSubscribed={myUserIsSubscribedAndApproved}
              />
            )}
          </AccordionBase>
          <Tabs>
            <TabPane label="JOGADORES">
              {users.length === 0 ? (
                <NoTournaments>
                  <SectionTitle
                    icon={<img src={tournament_active} alt="cartas" />}
                    title={"Não há registro de jogadores no momento"}
                  />
                  <h1>Volte mais tarde</h1>
                </NoTournaments>
              ) : (
                <TableAllPlayers
                  tournamentId={Number(tournamentId)}
                  players={users}
                  page={page}
                  perPage={perPage}
                  total={total}
                  setPage={setPage}
                  setPerPage={setPerPage}
                />
              )}
            </TabPane>

            <TabPane label="PARTIDA">
              <>
                {pendingUsers?.length > 0 &&
                  handleIsLeagueManagerOfThisTournament(
                    tournament.managerEmail
                  ) && (
                    <AccordionBase
                      sectionTitle={
                        <SectionTitle
                          title="Jogadores aguardando aprovação"
                          icon={<Cards />}
                        />
                      }
                      isOpen={false}
                    >
                      <PendingPlayers tournamentId={tournamentId} />
                    </AccordionBase>
                  )}

                {handleIsLeagueManagerOfThisTournament(
                  tournament.managerEmail
                ) && (
                  <AccordionBase
                    sectionTitle={
                      <SectionTitle
                        title="Montagem de times"
                        icon={<Cards />}
                      />
                    }
                    isOpen={pendingUsers.length > 0 ? true : false}
                  >
                    <BuildTeams
                      tournamentId={tournamentId}
                      refProp={tournamentRef}
                      teamCount={tournament.teamCount}
                      withoutRound={false}
                    />
                  </AccordionBase>
                )}

                <Matches
                  tournament={tournament}
                  tournamentRef={tournamentRef}
                />

                <Flowchart
                  isOpen={isActiveFlowchart}
                  onRequestClose={() => setIsActiveFlowchart(false)}
                />
              </>
            </TabPane>

            <TabPane label="RANKING">
              <>
                <SectionTitle title="Ranking" icon={<Winner />} />
                <TableRankingMatches tournamentId={Number(tournamentId)} />
              </>
            </TabPane>
            {tournament.type === "Knockout" ? (
              <TabPane label="CHAVEAMENTO" action={handleCloseModalFlowchart}>
                <>
                  <Flowchart
                    isOpen={isModalFlowchart}
                    onRequestClose={() => setIsModalFlowchart(false)}
                  />
                </>
              </TabPane>
            ) : (
              <></>
            )}
          </Tabs>
        </TournamentStyle>
      </TournamentProvider>
    </BasePage>
  );
};

export default Tournament;
