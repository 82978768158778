import { TextareaProps } from '../../interfaces/GeneralInterfaces';
import React, {ChangeEvent}  from 'react';
import { ErrorMessage, useField } from 'formik'
import { InputFieldStyle } from './style';

const Textarea: React.FC<TextareaProps> = ({ label,className, onChangeCallback, ...props }) => {

    const [field, meta,helpers] = useField(props);
    const onChangeValue = (e: ChangeEvent<any>) => {
        const val = e.target.value;
        helpers.setValue(val);
        onChangeCallback && onChangeCallback(val);
    }
    
    return (
        <InputFieldStyle className={(className ? className : '') + ' input-block' + (meta.error ? ' input-error' : ' ')}>
            <label htmlFor={field.name}>{label}</label>
            <textarea {...field} {...props} onChange={onChangeValue}></textarea> 
            { meta.error && <div className="error-message"><ErrorMessage name={field.name} /> </div>  }
        </InputFieldStyle>
    )
}

export default Textarea;