import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./../contexts/UserContext";
import { IRoutes } from "./../interfaces/GeneralInterfaces";

const AdminRoute: React.FC<IRoutes> = ({
  component: Component,
  path,
  exact = true,
}) => {
  const { authed, userInfo } = useContext(AuthContext);

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        authed() && userInfo().isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default AdminRoute;
