import { useState, useContext, useEffect } from 'react';
import BasePage from '../../../components/BasePage';
import { AnsweredStyle } from './style';
import api from '../../../services/api';
import { AuthContext } from '../../../contexts/UserContext';
import { useParams } from 'react-router-dom';
import SectionTitle from '../../../components/SectionTitle';
import CheckedIcon from '../../../assets/svg/CheckedIcon';

interface ParamTypes {
    id: string;
}

interface IForm {
    id: number;
    name: string;
    category: string;
    questions: IQuestion[];
    points: number;
}

interface IQuestion {
    id: string;
    name: string;
    answers: Ianswer[];
}

interface Ianswer {
    name: string;
    isCorrect: boolean;
}

interface IUser {
    firstName: string;
    lastName: string;
}

interface IQuizResponse {
    id: number;
    form: IForm;
    user: IUser;
    category: string;
    questionsAnswers: IQuestionsAnsers;
    points: number;
    createdAt: string;
    status: boolean;
}

interface IQuestionsAnsers {
    answers: IAnswerFormsAnswers[]
}

interface IAnswerFormsAnswers {
    answer: string;
    questionId: string;
}
interface IQuizResponseManager {
    id: number;
    form: IForm;
    user: IUser;
    category: string;
    questionsAnswers: IAnswerFormsAnswers[];
    points: number;
    createdAt: string;
    status: boolean;
}

const AnsweredQuiz = () => {
    const [quizAnswered, setQuizAnswered] = useState<IQuizResponse>();
    const [quizAnsweredManager, setQuizAnsweredManger] = useState<IQuizResponseManager>();
    const [questions, setQuestions] = useState<any>();
    const { id } = useParams<ParamTypes>();
    const { loading } = useContext(AuthContext);

    const getFormAnswered = async () => {
        const response = await api.get(`forms/get-form-answered-for-admin/${id}`);
        
        if(response.data.form.category === 'BeManagerTest'){
            setQuizAnsweredManger(response.data);
        }else{
            setQuizAnswered(response.data);
        }
        
    }

    useEffect(() => {
        if (!loading) {
            getFormAnswered();
        }
    }, [loading]);

    useEffect(() => {

        if (quizAnsweredManager) {
            let newArray = quizAnsweredManager.form.questions.filter((question: IQuestion) => quizAnsweredManager.questionsAnswers.some((a: IAnswerFormsAnswers) => a.questionId === question.id));
            setQuestions(newArray);
        }

    }, [quizAnsweredManager])

    return (
        <BasePage title="Questionário" subtitle={`${quizAnswered?.form.name}`}>
            <AnsweredStyle>
            {quizAnswered && <SectionTitle title={`${quizAnswered?.form.name} respondido por ${quizAnswered?.user.firstName}`} /> }
            {quizAnsweredManager && <SectionTitle title={`${quizAnsweredManager?.form.name} respondido por ${quizAnsweredManager?.user.firstName}`} /> }
                <div className="quiz">
                    {
                        quizAnswered && quizAnswered.form.questions.map((question: IQuestion) => {
                            return (
                                <div className="questions" key={question.id}>
                                    <h2>{question.name}</h2>
                                    <div className="answers">
                                        {question.answers?.map((answer: Ianswer, index: number) => {
                                            return (
                                                <div key={index} className={quizAnswered.questionsAnswers.answers.some((a: IAnswerFormsAnswers) => a.answer === answer.name && a.questionId === question.id) ? 'active' : ''}>
                                                    {answer.name}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })

                    }
                    {
                        quizAnsweredManager && questions && questions.map((question: IQuestion) => {
                            return (
                                <div className="questions" key={question.id}>
                                    <h2>{question.name}</h2>
                                    <div className="answers">
                                        {question.answers?.map((answer: Ianswer, index: number) => {
                                            return (
                                                <div key={index} className={(quizAnsweredManager.questionsAnswers.some((a: IAnswerFormsAnswers) => a.answer === answer.name && a.questionId === question.id) ? 'active' : '')}>
                                                    <div className="icon-answer">
                                                        <CheckedIcon />
                                                    </div>
                                                    <span className={(quizAnsweredManager.questionsAnswers.some((a: IAnswerFormsAnswers) => a.questionId === question.id && answer.isCorrect) ? 'correct' : 'wrong')}>
                                                        {answer.name}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </AnsweredStyle>
        </BasePage>
    )
}

export default AnsweredQuiz;