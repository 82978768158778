import styled from 'styled-components'

export const CheckboxFieldStyle = styled.div`
    .labelWithLink {
        color: var(--color-white); 

        a {
            color: var(--color-green); 
        }
        a:hover {
            color: var(--color-purple); 
            transition: 0.3s;
        }
        a:visited {
            color: var(--color-purple);
        }
        a:active {
            color: var(--color-white); 
            transition: 0.1s;
        }
    }

    .error-message {
        color: var(--danger);
        font-size: 1.2rem;
        height: 0rem;
        transition: height .3s, opacity .3s; 
    }

    .input-block{
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        position: relative;

        &:first-child{
            margin-top: 0rem;
        }

        // &.input-checkbox{
        //     label{
        //         order: 2;
        //     }

        //     input{
        //         order: 1;
        //     }
        // }

        label{
            color: var(--primary);
            font-size: 1.5rem;
            font-family: var(--font-primary-bold);
            transition: color .3s;
        }

        input:not([type="checkbox"]){
            width: 100%;
            height: 5rem;
            background-color: var(--color-white);
            margin: .9rem 0 .5rem;
            padding: 0 2rem;
            font-family: var(--font-primary-regular);
            border: 1px solid rgba(var(--color-gray-light), .33);
            transition: border-color .3s;
            color: var(--color-gray);
            font-size: 1.5rem;
            font-family: var(--font-primary-regular);

            &::-webkit-input-placeholder {
                color: var(--color-gray);
                font-size: 1.5rem;
                font-family: var(--font-primary-regular);
            }
            &:-moz-placeholder {
                color: var(--color-gray);
                font-size: 1.5rem;
                font-family: var(--font-primary-regular);
            }
            &::-moz-placeholder {
                color: var(--color-gray);
                font-size: 1.5rem;
                font-family: var(--font-primary-regular);
            }
            &:-ms-input-placeholder {
                color: var(--color-gray);
                font-size: 1.5rem;
                font-family: var(--font-primary-regular);
            }

            &:focus{
                border-color: var(--primary);
            }
        }

        input:-webkit-autofill, .error:-webkit-autofill, input:-webkit-autofill:focus, .error:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset !important;
            background-color: var(--color-white) !important;
            -webkit-text-fill-color: var(--color-gray) !important;
        }

        [type="checkbox"]:checked,
        [type="checkbox"]:not(:checked) {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }

        [type="checkbox"]:checked + label,
        [type="checkbox"]:not(:checked) + label{
            position: relative;
            padding-left: 2.5rem;
            font-size: 1.4rem;
            font-weight: 500;
            cursor: pointer;
            display: inline-block;
            color: #707070;
        }

        [type="checkbox"]:checked + label:before,
        [type="checkbox"]:not(:checked) + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0px;
            width: 16px;
            height: 16px;
            border-radius: 3px;
            transition: border-color .3s;
            border: 1px solid #707070;
        }

        [type="checkbox"]:checked + label:after,
        [type="checkbox"]:not(:checked) + label:after {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 3px;
            background: #707070;
            position: absolute;
            top: 4px;
            left: 4px;
            transition: all .3s cubic-bezier(.04,.96,.65,1.3);
        }

        [type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
            transform: scale(0);
        }

        [type="checkbox"]:checked + label:after {
            opacity: 1;
            transform: scale(1);
        }

        &.input-error{
            input{
                border-color: var(--danger);
            }
        }
    }
`