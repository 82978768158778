import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './../../contexts/UserContext';
import { TableSubscribedTournamentsStyle } from './style';
import SectionTitle from '../../components/SectionTitle';
import Table from '../../components/Table';
import { ISubscribedTournamentDataTable, ITournament } from '../../interfaces/GeneralInterfaces';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import { formatDate, formatHour } from '../../utils/formatData';
import tournament_active from '../../assets/images/tournament_active.png'; 

const renderCategory = (values: any) => {
    if (values.value === 'Tournament') {
        return 'Torneio';
    } else if (values.value === 'TrucoCup') {
        return 'Copa Truco';
    } else {
        return 'Estadual Copag';
    }
}

const TableSubscribedTournaments = () => {
    const history = useHistory();
    const { loading } = useContext(AuthContext);
    const [subscribedTournaments, setSubscribedTournaments] = useState<ITournament[]>({} as ITournament[])
    const [subscribedTournamentsData, setSubscribedTournamentsData] = useState<ISubscribedTournamentDataTable[]>({} as ISubscribedTournamentDataTable[]);
    const [loadingData, setLoadingData] = useState(true);

    const columnsSubscribedTournaments = [
        {
            Header: 'ID',
            accessor: 'id'
        },
        {
            Header: 'DATA',
            accessor: 'date',
            Cell: formatDate
        },
        {
            Header: 'HORA',
            accessor: 'hour',
            Cell: formatHour
        },
        {
            Header: 'LOCAL',
            accessor: 'state',
        },
        {
            Header: 'CATEGORIA',
            accessor: 'category',
            Cell: renderCategory
        },
        {
            Header: 'LIGA',
            accessor: 'league',
        },
        {
            Header: 'NOME DO EVENTO',
            accessor: 'description',
        },
    ]

    const handleClickRow = (row: any) => {
        history.push(`/torneio/${row.id}`)
    }

    useEffect(() => {
        const prepareDateForSubscribedTable = () => {
            let data: ISubscribedTournamentDataTable[] = [];
            if (subscribedTournaments && subscribedTournaments.length > 0) {
                subscribedTournaments.sort((tournament: ITournament, tournament2:ITournament) => {
                   const dateA = new Date(tournament.date).getTime()
                   const dateB = new Date(tournament2.date).getTime()
                    return dateA - dateB
                })
                subscribedTournaments.map((tournament: ITournament, index: number) => {
                    if (index < 6) {
                        data.push({
                            id: tournament.id,
                            date: tournament.date,
                            category: tournament.category,
                            state: tournament.state?.name ?? 'Online',
                            hour: tournament.date,
                            league: tournament.league.leagueName,
                            description: tournament.name
                        })
                    }
                    return null;
                })

                setSubscribedTournamentsData(data);
            }
        }

        prepareDateForSubscribedTable();
    }, [subscribedTournaments])

    useEffect(() => {
        async function getTournaments() {
            const subscribedTournamentsResponse = await api.get('tournaments/subscribed')
            setSubscribedTournaments(subscribedTournamentsResponse.data)

            setLoadingData(false);
        }

        if (!loading)
            getTournaments();
    }, [loading])

    return (
        <TableSubscribedTournamentsStyle>
            {loading && (
                <div className="">Carregando...</div>
            )}
            {!loading && !loadingData && (
                subscribedTournamentsData.length > 0 && (
                    <div className="next-tournaments">
                        <SectionTitle icon={<img src={tournament_active} alt="torneio"/>} title={'Torneios – Minhas Inscrições'} />
                        <Table columnsWithOutMemo={columnsSubscribedTournaments} dataWithOutMemo={subscribedTournamentsData} handleClick={handleClickRow} />
                    </div>
                )
            )}
        </TableSubscribedTournamentsStyle>
    )
}

export default TableSubscribedTournaments;