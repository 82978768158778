import styled from 'styled-components'

export const TabsStyle = styled.div`
  .tab-list {
    button{
      margin-bottom:1rem;
    }
  }
    @media(max-width:600px){
        .tab-list {
            display: flex;
            flex-direction: column;
        }
    }
`