import { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "./../../../contexts/UserContext";
import { UsersStyle } from "./style";
import api from "../../../services/api";
import SectionTitle from "../../../components/SectionTitle";
import UserIcon from "../../../assets/svg/UserIcon";
import TablePagination from "../../../components/TablePagination";
import Button from "../../../components/Button";
import Select from "react-select";
import useCommonRequisitions from "../../../hooks/useCommonRequisitions";
import { IStates, ICities } from "../../../interfaces/GeneralInterfaces";
import { selectStyles } from "../../../assets/styles/selectStyles";
import ModalConfirm from "../../../components/ModalConfirm";

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  doc: string;
  phone: string;
  isLeagueManager: boolean;
  managerBadgeName: string;
  managerBadgeOrder: number;
  managerBadge: IManagerBadge;
}

interface IManagerBadge {
  id: number;
  name: string;
  order: number;
}

interface IUserDataTable {
  id: string;
  name: string;
  username: string;
  email: string;
  doc: string;
  phone: string;
  isLeagueManager: string;
  actions: any;
  managerBadgeName: string;
  managerBadgeOrder: number | string;
}

interface IUserFilter {
  id?: number;
  search?: string;
  state?: string;
  city?: string;
  isLeagueManager?: boolean;
}

const Users = () => {
  const history = useHistory();
  const { loading } = useContext(AuthContext);
  const [users, setUsers] = useState<IUser[]>([{}] as IUser[]);
  const [total, setTotal] = useState<number>(0);
  const [usersData, setUsersData] = useState<IUserDataTable[]>(
    {} as IUserDataTable[]
  );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [filterOptions, setFilterOptions] = useState<IUserFilter>(
    {} as IUserFilter
  );
  const { getStates, getCities } = useCommonRequisitions();
  const [states, setStates] = useState<IStates[]>([]);
  const [cities, setCities] = useState<ICities[]>([]);
  const [stateSelected, setStateSelected] = useState<any>("");
  const [showDeleteQuizModal, setShowDeleteQuizModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState<number>(0);

  const isLeagueManagerOption = [
    { value: true, label: "Sim" },
    { value: false, label: "Não" },
  ];

  const renderButtonActions = (values: any) => {
    return (
      <>
        <Button
          classButton={"small"}
          label={"Editar"}
          eventCallback={(e: any) => {
            history.push(`administrador/usuario/${values.value[2]}`);
          }}
        />
        <Button
          classButton={"small"}
          label={"Deletar"}
          eventCallback={(e: any) => handleConfirmRemoveModal(values.value[2])}
        />
      </>
    );
  };

  const columnsUsersTable = [
    {
      Header: "ID DO JOGADOR",
      accessor: "id",
    },
    {
      Header: "USERNAME",
      accessor: "username",
      sortType: "caseInsensitive",
    },
    {
      Header: "JOGADOR",
      accessor: "name",
      sortType: "caseInsensitive",
    },
    {
      Header: "E-MAIL",
      accessor: "email",
      sortType: "caseInsensitive",
    },
    {
      Header: "TELEFONE",
      accessor: "phone",
    },
    {
      Header: "DOCUMENTO",
      accessor: "doc",
    },
    {
      Header: "ORGANIZADOR",
      accessor: "isLeagueManager",
    },
    {
      Header: "Nome do Status do Organizador",
      accessor: "managerBadgeName",
    },
    {
      Header: "Ordem do Status do Organizador",
      accessor: "managerBadgeOrder",
    },
    {
      Header: "",
      accessor: "actions",
      Cell: renderButtonActions,
    },
  ];

  useEffect(() => {
    async function getState() {
      const populatedStates = await getStates();
      setStates(populatedStates);
    }
    getState();
  }, []);

  useEffect(() => {
    async function getCity() {
      const cities = await getCities(stateSelected);
      setCities(cities);
    }

    if (stateSelected) {
      getCity();
    }
  }, [stateSelected]);

  const handleSearch = () => getUsers();

  useEffect(() => {
    const prepareDateForUsersTable = () => {
      let data: IUserDataTable[] = [];
      if (users && users.length > 0) {
        users.map((user: IUser) => {
          return data.push({
            id: user.id,
            username: user.username && user.username.toLowerCase(),
            name: user.firstName + " " + user.lastName,
            email: user.email,
            doc: user.doc,
            phone: user.phone,
            isLeagueManager: user.isLeagueManager ? "Sim" : "Não",
            managerBadgeName: user.managerBadge ? user.managerBadge.name : '',
            managerBadgeOrder: user.managerBadge ? user.managerBadge.order : '',
            actions: ["Editar", "Excluir", user.id],
          });
        });
        setUsersData(data);
      } else {
        setUsersData({} as IUserDataTable[]);
      }
    };
    prepareDateForUsersTable();
  }, [users]);

  const getUsers = async () => {
    const response = await api.post(
      `users/list?page=${page}&limit=${perPage}`,
      { filterOptions }
    );
    setTotal(response.data.total);
    setUsers(response.data.users);
  };

  const handleRemoveUser = async (userId: number) => {
    const response = await api.delete(`users/${userId}`);
    if (response.status === 200) {
      getUsers();
    }

    setShowDeleteQuizModal(false);
  };

  const handleConfirmRemoveModal = (userId: number) => {
    setDeleteUserId(userId);
    setShowDeleteQuizModal(true);
  };

  useEffect(() => {
    if (!loading) {
      getUsers();
    }
  }, [loading, page]);

  return (
    <UsersStyle>
      {loading && <div className="">Carregando...</div>}
      {!loading && (
        <>
          <div className="filter_tournaments">
            <input
              name="id"
              type="text"
              className="search"
              placeholder="Id do Jogador"
              onChange={(e: any) => {
                let newValue = { ...filterOptions };
                newValue.id = e.target.value;
                setFilterOptions(newValue);
              }}
            />
            <input
              name="search"
              type="text"
              className="search"
              placeholder="Pesquise por nome, cpf, telefone, etc..."
              onChange={(e: any) => {
                let newValue = { ...filterOptions };
                newValue.search = e.target.value;
                setFilterOptions(newValue);
              }}
            />

            <div className="filter_selects">
              <Select
                options={states}
                placeholder="Estado"
                styles={selectStyles}
                onChange={(state: any) => {
                  setStateSelected(state.value);
                  let newValue = { ...filterOptions };
                  newValue.state = state.value;
                  setFilterOptions(newValue);
                }}
              />
              <Select
                options={cities}
                placeholder="Cidade"
                styles={selectStyles}
                onChange={(city: any) => {
                  let newValue = { ...filterOptions };
                  newValue.city = city.value;
                  setFilterOptions(newValue);
                }}
              />
              <Select
                options={isLeagueManagerOption}
                placeholder="Organizador"
                styles={selectStyles}
                onChange={(visibility: any) => {
                  let newValue = { ...filterOptions };
                  newValue.isLeagueManager = visibility.value;
                  setFilterOptions(newValue);
                }}
              />
            </div>
            <div className="search_button_area">
              <Button label="Pesquisar" eventCallback={handleSearch} />
            </div>
          </div>
        </>
      )}
      {!loading && usersData.length > 0 ? (
        <>
          <SectionTitle icon={<UserIcon />} title={"Usuários"} />

          <TablePagination
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={page}
            totalPage={Math.ceil(total / perPage)}
            columnsWithOutMemo={columnsUsersTable}
            dataWithOutMemo={usersData}
          />

          <ModalConfirm
            title="Deseja excluir o usuário?"
            isOpen={showDeleteQuizModal}
            handleConfirm={() => handleRemoveUser(deleteUserId)}
            handleCloseConfirm={() => setShowDeleteQuizModal(false)}
          />
        </>
      ) : (
        <span>Nenhum registro encontrado.</span>
      )}
    </UsersStyle>
  );
};

export default Users;
