import styled from 'styled-components'

export const WaitingForCopagStyle = styled.div`
    .list-infos{
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap:10px;
        margin-top:30px;
    }

    @media(max-width:800px){
        .list-infos{
            display: flex;
            flex-direction: column;
        }
    }
`