import React from 'react';

import TooltipStyle from './style';

import { ITooltip } from '../../interfaces/GeneralInterfaces';

const Tooltip: React.FC<ITooltip> = ({
    label,
    children,
    customClass,
    themeColor = 'primary',
}) => {
    return (
        <TooltipStyle className={customClass}>
            {children}
            <div className={`tooltip ${themeColor}`}>
                <span>{label}</span>
            </div>
        </TooltipStyle>
    );
}

export default Tooltip;