import { useState, useEffect, useContext } from 'react'
import BasePage from '../../components/BasePage';
import { BeManagerStyle } from './style';
import IntroManager from './IntroManager';
import Step2 from './Step2';
import Step1 from './Step1';
import { AuthContext } from '../../contexts/UserContext';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';

const BeManager = () => {
    const [step, setStep] = useState<number>(0);
    const { managerStatus, user } = useContext(AuthContext);
    const history = useHistory();
    const handleStep = (step: number) => {
        setStep(step);
    }

    useEffect(() => {
        if (managerStatus === "pending" || managerStatus === "approved") {
            history.push(`/organizador`)
        }

        api.get(`/forms/be-manager-test`).then((res) => {
            if (
              res.data &&
              res.data.message ===
                "Você já foi aprovado no teste, agora pode criar uma liga."
            ) {
              handleStep(2);
            }
        });
    }, [])

    return (
        <BasePage title="Seja um organizador">
            <BeManagerStyle>
                {step === 0 && <IntroManager callback={handleStep} />}
                {step === 1 && <Step1 callback={handleStep} />}
                {step === 2 && <Step2 />}
            </BeManagerStyle>
        </BasePage>
    )
}

export default BeManager;
