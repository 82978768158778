import { useMemo } from 'react';

import { useTable, useSortBy } from 'react-table'
import ArrowDownIcon from '../../assets/svg/ArrowDownIcon'
import ArrowUpIcon from '../../assets/svg/ArrowUpIcon'
import TableStyle from './style';

interface TableProps {
    columnsWithOutMemo: any;
    dataWithOutMemo: any;
    handleClick?(row: any): void;
}

const Table = ({ columnsWithOutMemo, dataWithOutMemo, handleClick }: TableProps) => {

    const columns = useMemo(() => columnsWithOutMemo, []);
    const data = useMemo(() => dataWithOutMemo, [dataWithOutMemo]);

    const defaultColumn = useMemo(() => {
        return { sortType: 'caseInsensitive' }
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        defaultColumn,
        sortTypes: useMemo(
            () => ({
                caseInsensitive: (a: any, b: any, id) => {
                    const valueA = isNaN(a.values[id]) ? a.values[id].toLowerCase() : a.values[id];
                    const valueB = isNaN(b.values[id]) ? b.values[id].toLowerCase() : b.values[id];
                    return (valueB > valueA) ? 1 : ((valueB < valueA) ? -1 : 0)
                },
            }),
            []
        ),
    }, useSortBy);

    return (
        <TableStyle hasHandleClick={handleClick ? true : false}>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <div>
                                        {column.render("Header")}
                                        <span>{column.isSorted ? (column.isSortedDesc ? <ArrowDownIcon /> : <ArrowUpIcon />) : ''}</span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} onClick={() => handleClick && handleClick(row.original)}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </TableStyle>
    )
};

export default Table;