import styled from 'styled-components'

export const BasePageStyle = styled.div`
    #container-page{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    &.sidebar-fixed{
        padding: 0 8rem 0 28rem;
    }
    .btnMobile{
        display:none;
        position:fixed;
        left:10px;
        top:11px;
        z-index:999;
        width:5rem;
        height:5rem;
        background:#FFF;
        border-radius:50%;
        align-items:center;
        justify-content:center;
        svg{
            fill:var(--danger);
        }
    }
    @media(max-width:1024px){
        &.sidebar-fixed{
            padding: 0 1rem 0 1rem;
        }
        padding-bottom:80px !important;
        .btnMobile{
            display:flex;
        }
    }    

    .teste{
        height:2000px;
    }
    &.sidebar-relative{
        display: grid;
        padding-right: 8rem;
        grid-template-rows: 14rem;
        grid-template-columns: 28rem auto;
        grid-template-areas:
            'sidebar header'
            'sidebar content';

        #page-content{
            grid-area: content;
        }
    }

    #page-content{
        width: 100%;
        height: 100%;
        position: relative;

        .page-content{
            margin-top: 3.5rem;
            border-radius: 4rem 4rem 0 0;
            padding: 4rem;
            box-shadow: 1rem 1rem 7rem rgba(0, 0, 0, .16);
            background-color: var(--color-white);
            position: relative;
            z-index: 0;
        }

        @media(max-width:1024px){
            padding-top: 7rem;
            .page-content{
                padding: 4rem 2rem;
                word-break: normal; 
            }
        }
        
        .wave1{
            position: absolute;
            top: 0;
            left: 36%;
            transform: translate(-50%, -20rem);
            z-index: -1;
        }

        .wave2{
            position: absolute;
            right: -10.5rem;
            top: 20rem;
            z-index: -1;
        }

        .wave3{
            display:none;
            position: absolute;
            left: -12rem;
            bottom: 0;
            z-index: -1;
        }

        @media(max-width:1024px){
            .wave1,.wave2,.wave3{
               display:none;
            }
         }

    }
}
`