import { useState, useContext } from 'react'

import BasePage from '../../components/BasePage';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle'
import UserIcon from '../../assets/svg/UserIcon';
import InputField from '../../components/InputField';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { NewTicketStyle } from './style';
import api from '../../services/api'
import { AuthContext } from '../../contexts/UserContext';

interface ITicketFormValues {
    subject: string;
    message: string;
}

const AccountSchema = Yup.object().shape({
    subject: Yup.string().required('Informe o assunto'),
    message: Yup.string().required('Digite uma mensagem'),
});

const NewTicket = () => {
    const history  = useHistory();
    const {user} = useContext(AuthContext);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState<boolean>(false);
    const [isModalFailOpen, setIsModalFailOpen] = useState<boolean>(false);

    const accountInitialValues: ITicketFormValues = {
        subject: "",
        message: "",
    };

    const submitTicket = async (values: ITicketFormValues, callback: Function) => {
        const response = await api.post('/tickets',values);

        if(response.status === 201){
          setIsModalSuccessOpen(true);
          history.push(`/ticket/${response.data.ticketId}`);
        }else{
          setIsModalFailOpen(true);
        }
        callback();

    }
    return (
        <BasePage title="Tickets">
            <NewTicketStyle>
                <SectionTitle title="Enviar mensagem" icon={<UserIcon />} />

                <Formik
                    initialValues={accountInitialValues}
                    validationSchema={AccountSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    enableReinitialize
                    onSubmit={(
                        values: ITicketFormValues,
                        { setSubmitting }: FormikHelpers<ITicketFormValues>
                    ) => {
                        submitTicket(values, function () {
                            setSubmitting(false);
                        });
                    }}
                >
                    {formik => {
                        return (
                            <div id="register-form">
                                <Form className="common-form">
                                    <InputField
                                        name="subject"
                                        label="Digite aqui o assunto"
                                        type="text"
                                    />

                                    <Textarea
                                        name="message"
                                        label="Digite aqui sua mensagem"
                                    />

                                    <Button classButton="submit-button" hasLoader={true} showLoading={formik.isSubmitting} disabled={formik.isSubmitting} label="ENVIAR" type={'submit'} />
                                </Form>
                            </div>
                        )
                    }}
                </Formik>

                {isModalSuccessOpen && (
                <Modal
                    isOpen={isModalSuccessOpen}
                    onCloseModal={() => setIsModalSuccessOpen(false)}
                    classStatus='success'
                    title="Sucesso"
                    text={'Ticket cadastrado com sucesso!'}
                />
               )}

              {isModalFailOpen && (
                <Modal
                    isOpen={isModalFailOpen}
                    onCloseModal={() => setIsModalFailOpen(false)}
                    classStatus='error'
                    title="Erro"
                    text={'Algo de errado aconteceu!'}
                />
               )}
            </NewTicketStyle>
        </BasePage>

    )
}

export default NewTicket;