import { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";

import BasePage from "../../../components/BasePage";
import InputField from "../../../components/InputField";
import Modal from "../../../components/Modal";

import api from "../../../services/api";

import { BadgesStyle } from "./style";
import Button from "../../../components/Button";

interface IBadge {
  id: string;
  name: string;
  order: number;
  createdAt: Date;
}

interface IBadgeFormValues {
  name: string;
  order: number;
}

interface ParamTypes {
  id: string;
}

const AccountSchema = Yup.object().shape({
  name: Yup.string().required("Informe o nome"),
  order: Yup.number().required("Informe a ordem"),
});

const Badge = () => {
  let { id } = useParams<ParamTypes>();
  let history = useHistory();
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<any>({} as any);
  const [badgeData, setBadgeData] = useState<IBadge>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);
  const [error, setError] = useState("");
  const [badgeInitialValues, setBadgeInitialValues] = useState<IBadgeFormValues>({
    name: "",
    order: 0,
  });

  // const badgeInitialValues: IBadgeFormValues = {
  //   name: "",
  //   order: 0,
  // };

  const handleSubmit = async (
    values: IBadgeFormValues,
    callback: Function
  ) => {
    console.log(values);
    setLoading(true);

    try {
      let response: any = null;

      if(id) {
        response = await api.put(`manager/badges/${id}`, (
          {
            ...values,
            order: parseInt(values.order.toString())
          }
        ));
      } else {
        response = await api.post('manager/badges', (
          {
            ...values,
            order: parseInt(values.order.toString())
          }
        ));
      }
      console.log('response', response);

      if (response.status) {
        setIsModalOpenSuccess(true);
      } else {
        setIsModalOpen(true);
        setError('Ocorreu um erro inesperado!');
      }

      callback();
    } catch (err: any) {
      console.log('err', err);
      if (err.response) {
        return {
          status: false,
          message: err.response.data.message,
        };
      } else if (err.request) {
        console.log(err.request);
      }
      return {
        status: false,
        message: "Erro ao criar",
      };
    }
  };

  useEffect(() => {
    if(id) {
      (async () => {
        try {
          const response = await api.get(`manager/badges/${id}`)
          console.log('response', response);
          setBadgeInitialValues(response.data);
        } catch (err: any) {
          if (err.response) {
            return {
              status: false,
              message: err.response.data.message,
            };
          } else if (err.request) {
            console.log(err.request);
          }
          return {
            status: false,
            message: "Ocorreu um erro inesperado!",
          };
        }
      })()
    }
  }, [id])
  

  return (
    <BasePage title="Status de Organizador">
      <BadgesStyle>
        {loading && <div className="">Carregando ...</div>}
        {!loading && (
          <>
            <Formik
              initialValues={badgeInitialValues}
              validationSchema={AccountSchema}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize
              onSubmit={(
                values: IBadgeFormValues,
                { setSubmitting }: FormikHelpers<IBadgeFormValues>
              ) => {
                handleSubmit(values, function () {
                  setSubmitting(false);
                  setLoading(false);
                });
              }}
            >
              {(formik) => {
                return (
                  <div id="register-form">
                    <Form className="common-form">
                      <div className="form-block">
                        <InputField
                          name="name"
                          label="Nome"
                          type="text"
                        />
                        <InputField
                          name="order"
                          label="Ordem"
                          type="number"
                        />
                      </div>

                      <div className="buttons">
                        <Button
                          classButton="submit-button"
                          hasLoader={true}
                          showLoading={formik.isSubmitting}
                          disabled={formik.isSubmitting}
                          label="Enviar"
                          type={"submit"}
                        />
                      </div>

                      {isModalOpen && (
                        <Modal
                          isOpen={isModalOpen}
                          onCloseModal={() => {
                            setIsModalOpen(false)
                          }}
                          classStatus="error"
                          title="Erro"
                          text={error}
                        />
                      )}

                      {isModalOpenSuccess && (
                        <Modal
                          isOpen={isModalOpenSuccess}
                          onCloseModal={() => {
                            history.push('/administrador');
                            // setIsModalOpenSuccess(false)
                          }}
                          classStatus="success"
                          title="=)"
                          text={"Status de organizador atualizado com sucesso"}
                        />
                      )}
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </>
        )}
      </BadgesStyle>
    </BasePage>
  );
};

export default Badge;
