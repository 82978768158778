import styled from 'styled-components'

export const TournamentInformationsStyle = styled.div`
    @media(max-width:800px){
        .list-infos{
            display: flex;
            flex-direction: column;
        }
        .tournaments-informations {
            display: flex;
            flex-direction: column;
        }
    }

    .tournaments-informations {
        display: flex;
        align-items: flex-start;
    }

    .list-infos {
        flex-grow: 2;
    }

    .qr-code {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        button:first-of-type{
          position:relative;
          z-index:10;
        }
        svg {
            margin: auto;
        }
        .actions {
            margin-bottom: 1rem;
            flex-direction: row;
            justify-content: space-around;
            svg{
                fill: var(--color-purple);
            }
        }
    }

    .actions {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
    }
`