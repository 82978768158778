import styled from 'styled-components'

export const ChangePasswordStyle = styled.section`
    background:#FFF;
    min-height:500px;
    form{
        display:flex;
        flex-direction:column;
    }
    .form-block{
        display:grid;
        grid-template-columns:1fr;
        grid-gap:10px;
        margin-bottom:30px;

        button{
            grid-column:1 span;
            margin:0 auto;
        }
    }
`
