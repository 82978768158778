import styled from 'styled-components'

const TimelineStyle = styled.div`
    display: flex;
    flex-direction: column;

    .step-item{
        display: flex;
        padding-bottom: 1.6rem;
        position: relative;

        &:before{
            content: '';
            width: 2px;
            height: calc(100% - 2.3rem);
            background-color: var(--color-gray);
            position: absolute;
            left: 1.1rem;
            bottom: 0;
        }

        .step-dot{
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 50%;
            border: 2px solid var(--color-gray);
            position: relative;
            flex: none;

            &:before{
                content: '';
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 50%;
                background-color: var(--color-secondary);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
            }
        }

        .name{
            margin-left: 1.4rem;
            font-size: 1.6rem;
            font-family: var(--font-primary-bold);
        }

        &:last-child{
            &:before{
                display: none;
            }
        }

        &.current{
            .step-dot{
                &:before{
                    opacity: 1;
                }
            }
        }
    }
`
export default TimelineStyle;
