import api from '../../../services/api';
import { ILeagueFormValues } from '../interface';

export default function BeManagerRequisitions() {

    async function SubmitNewLeague(league: ILeagueFormValues) {
        try {

            await api.post('leagues', league);

            return {
                status: true,
                message: "Liga criada com sucesso"
            }
        }
        catch (err: any) {
            if (err.response) {
                return {
                    status: false,
                    message: err.response.data.message
                }
            } else if (err.request) {
                console.log(err.request);
            }
            return {
                status: false,
                message: "Erro ao cadastrar"
            }
        }
    }


    return { SubmitNewLeague }
}