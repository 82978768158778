import { useState, useContext, useEffect } from 'react';
import { AnsweredTestStyle } from './style';
import api from '../../../services/api';
import { AuthContext } from '../../../contexts/UserContext';
import CheckedIcon from '../../../assets/svg/CheckedIcon';
import SectionTitle from '../../../components/SectionTitle';
import Text from '../../../components/Text';

interface IForm {
    id: number;
    name: string;
    category: string;
    questions: IQuestion[];
    points: number;
}

interface IQuestion {
    id: string;
    name: string;
    answers: Ianswer[];
}

interface Ianswer {
    name: string;
    isCorrect: boolean;
}

interface IUser {
    firstName: string;
    lastName: string;
}

interface IQuizResponse {
    id: number;
    form: IForm;
    user: IUser;
    category: string;
    questionsAnswers: IAnswerFormsAnswers[];
    points: number;
    totalQuestions: number;
    totalHits: number;
    createdAt: string;
    status: boolean;
}

interface IAnswerFormsAnswers {
    answer: string;
    questionId: string;
}

interface AnsweredTestProps {
    userId: number;
}

const AnsweredTest = ({ userId }: AnsweredTestProps) => {
    const [quizAnswered, setQuizAnswered] = useState<IQuizResponse>();
    const [questions,setQuestions] = useState<any>();

    const { loading } = useContext(AuthContext);

    const getFormAnswered = async () => {
        const response = await api.get(`forms/get-manager-test-answered-for-admin/${userId}`);
        setQuizAnswered(response.data);
        console.log(response.data);
    }

    useEffect(() => {
        if (!loading && userId) {
            getFormAnswered();
        }

    }, [loading, userId]);

    useEffect(() => {

        if(quizAnswered){
            let newArray = quizAnswered.form.questions.filter((question: IQuestion) =>  quizAnswered.questionsAnswers.some((a: IAnswerFormsAnswers) => a.questionId === question.id));
            setQuestions(newArray);
        }

    },[quizAnswered])

    return (
        <AnsweredTestStyle>
            <SectionTitle customClass="quiz-title" title="Usuário aprovado" />
            <Text>Acertou {quizAnswered?.totalHits} de {quizAnswered?.totalQuestions} </Text>
            <div className="quiz">
                {
                   quizAnswered && questions && questions.map((question: IQuestion) => {
                        return (
                            <div className="questions" key={question.id}>
                                <h2>{question.name}</h2>
                                <div className="answers">
                                    {question.answers?.map((answer: Ianswer, index: number) => {
                                        return (
                                            <div key={index} className={(quizAnswered.questionsAnswers.some((a: IAnswerFormsAnswers) => a.answer === answer.name && a.questionId === question.id) ? 'active' : '')}>
                                                <div className="icon-answer">
                                                    <CheckedIcon />
                                                </div>
                                                <span className={(quizAnswered.questionsAnswers.some((a: IAnswerFormsAnswers) => a.questionId === question.id && answer.isCorrect) ? 'correct' : 'wrong')}>
                                                    {answer.name}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </AnsweredTestStyle>
    )
}

export default AnsweredTest;