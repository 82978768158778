import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/UserContext';
import { TableRedeemedPointsStyle } from './style';
import Table from '../Table';
import api from '../../services/api';
import SectionTitle from '../SectionTitle';
import LoyaltyPoints from '../../assets/svg/LoyaltyPoints';

const columnsRedeemedPoints = [
    {
        Header: 'DESCRIÇÃO',
        accessor: 'description'
    },
    {
        Header: 'STATUS',
        accessor: 'status',
    },
    {
        Header: 'PONTOS',
        accessor: 'orders',
    }
];

interface IOrdersDataForTable {
    description: string;
    status: string;
    orders: string;
}

interface IItem {
    numero_pedido: string;
    status: string;
    motivo_cancelamento?: string;
    descricao: string;
    quantidade: number;
    total_item: number
    data_cadastro: string;
    complemento: number;
}

interface IOrders {
    itens: IItem[];
    total_resgates: number;
}

interface IResponseResgate {
    resgates: IOrders[];
    erro: boolean;
}

const TablePointsRedeemed = () => {
    const { loading } = useContext(AuthContext);
    const [orders, setOrders] = useState<IResponseResgate[]>({} as IResponseResgate[])
    const [ordersDataForTable, setOrdersDataForTable] = useState<IOrdersDataForTable[]>({} as IOrdersDataForTable[]);

    useEffect(() => {
        const prepareDataForTable = () => {
            let data: IOrdersDataForTable[] = [];
            if (orders[0] && orders[0].resgates && orders[0].resgates.length > 0) {
                orders[0].resgates.map((order: IOrders) => {
                    order.itens.map((item: IItem) => {
                        return data.push({
                            description: item.descricao,
                            orders: item.total_item.toString(),
                            status: item.status
                        })
                    })
                    return null;
                })

                setOrdersDataForTable(data);
            }
        }

        if (!loading)
            prepareDataForTable();

    }, [orders, loading])

    useEffect(() => {
        async function getTournaments() {
            try {
                const responseOrders = await api.get('users/trocapontos/orders')
                setOrders(responseOrders.data)
            } catch(e: any) {
                console.log(e);
            }
        }

        if (!loading)
            getTournaments();

    }, [loading])

    return (
        <TableRedeemedPointsStyle>
            {loading && (
                <div className="">Carregando...</div>
            )}
            {!loading && (
                ordersDataForTable.length > 0 && (
                    <div className="eaner-points">
                        <SectionTitle icon={<LoyaltyPoints color={'var(--primary)'} />} title={'Histórico de Pontos Trocados'} />
                        <Table columnsWithOutMemo={columnsRedeemedPoints} dataWithOutMemo={ordersDataForTable} />
                    </div>
                )
            )}
        </TableRedeemedPointsStyle>
    )
}

export default TablePointsRedeemed;