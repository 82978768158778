import styled from 'styled-components'

export const TableMyTournamentsManagerStyle = styled.div`
    margin-top:30px;

    @media 
    only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px)  {
      
      /*
      Label the data
      */
      td:nth-of-type(1):before { content: "Data"; }
      td:nth-of-type(2):before { content: "Hora"; }
      td:nth-of-type(3):before { content: "Local"; }
      td:nth-of-type(4):before { content: "Categoria"; }
      td:nth-of-type(5):before { content: "Nome"; }
}
`
