import styled from 'styled-components'
const Page404Style = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    button {
        margin-top: 2rem;
    }
`

export default Page404Style;