import styled from 'styled-components'

const TextStyle = styled.div`
    color: var(--color-gray);
    font-size: 1.6rem;
    font-family: var(--font-primary-regular);
    line-height: 2.3rem;

    b, strong{
        font-family: var(--font-primary-regular);
    }

    ul{
        padding-left: 20px;
        li{
            list-style-type: disc;
        }
    }

    &.be-a-manager{
        margin: 5rem;
        text-align: center;
    }

    &.user-points{
        font-size: 3.5rem;
        margin-right: 5px;
    }

    &.user {
        font-size: 1.3rem;
    }
`
export default TextStyle;


