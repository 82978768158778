import { InputProps } from "../../interfaces/GeneralInterfaces";
import React from "react";
import { ErrorMessage, useField } from "formik";
import UploadImageStyle from "./style";
import { useDropzone } from "react-dropzone";

const UploadImageField: React.FC<InputProps> = ({
  label,
  className,
  onChangeCallbackImage,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  // const onDrop = useCallback((acceptedFiles) => {
  //     acceptedFiles.forEach((file:any) => {
  //       const reader = new FileReader()

  //       reader.onabort = () => console.log('file reading was aborted')
  //       reader.onerror = () => console.log('file reading has failed')
  //       reader.onload = () => {
  //       // Do whatever you want with the file contents
  //         const binaryStr = reader.result
  //         console.log(binaryStr)
  //       }
  //       reader.readAsArrayBuffer(file)
  //       helpers.setValue(file)
  //     })

  //   }, [])

  //   const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      helpers.setValue(acceptedFiles);
      onChangeCallbackImage && onChangeCallbackImage(acceptedFiles);
    },
  });

  return (
    <UploadImageStyle
      className={
        className ?? " input-block" + (meta.error ? " input-error" : " ")
      }
    >
      <label htmlFor={field.name}>{label}</label>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <span>Insira aqui as imagens do local</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M16 16h-3v5h-2v-5h-3l4-4 4 4zm3.479-5.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.5v-2h-3.5c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-3.5v2h3.5c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z" />
        </svg>
      </div>
      {meta.error && (
        <div className="error-message">
          <ErrorMessage name={field.name} />{" "}
        </div>
      )}
    </UploadImageStyle>
  );
};

export default UploadImageField;
