import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            data-name="LoyaltyPoints"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : 58.985}
            height={props.height ? props.height : 58.985}
            viewBox="0 0 58.985 58.985"
            {...props}
        >
            <path
                data-name="Caminho 763"
                d="M29.493 49.155A19.662 19.662 0 109.831 29.493a19.662 19.662 0 0019.662 19.662zm-17.7-19.662a17.629 17.629 0 01.518-4.177l8.163 6.875-3.413 9.88a17.637 17.637 0 01-5.264-12.578zm21.653-6.953a.982.982 0 00.793.589l10.562 1.186-8.081 6.807a.984.984 0 00-.3 1.073l3.34 9.667-10.029-2.509a.991.991 0 00-.477 0l-10.037 2.509 3.34-9.667a.984.984 0 00-.3-1.073l-8.081-6.807 10.566-1.184a.982.982 0 00.793-.589l3.957-9.231zM19.231 43.884l10.258-2.564 10.258 2.564a17.575 17.575 0 01-20.516 0zm22.685-1.813l-3.413-9.88 8.163-6.875a17.522 17.522 0 01-4.745 16.755zm3.792-19.632l-10.681-1.2-4.015-9.365a17.722 17.722 0 0114.701 10.565zM27.969 11.874l-4.015 9.365-10.681 1.2a17.722 17.722 0 0114.696-10.565zm0 0"
                fill={props.color ? 'var(--primary)' : props.color}
            />
            <path
                data-name="Caminho 764"
                d="M4.915 29.492A24.577 24.577 0 1029.492 4.915 24.577 24.577 0 004.915 29.492zM29.492 6.881A22.611 22.611 0 116.881 29.492 22.611 22.611 0 0129.492 6.881zm0 0"
                fill={props.color ? 'var(--primary)' : props.color}
            />
            <path
                data-name="Caminho 766"
                d="M.983 7.865A4.921 4.921 0 015.9 12.78a.983.983 0 101.966 0 4.921 4.921 0 014.914-4.915.983.983 0 100-1.966A4.921 4.921 0 017.865.983a.983.983 0 10-1.965 0A4.921 4.921 0 01.983 5.9a.983.983 0 100 1.966zm5.9-3.34a6.938 6.938 0 002.356 2.357 6.938 6.938 0 00-2.357 2.357 6.938 6.938 0 00-2.357-2.357 6.938 6.938 0 002.357-2.357zm0 0"
                fill={props.color ? 'var(--primary)' : props.color}
            />
            <path
                data-name="Caminho 767"
                d="M52.104 45.222a.983.983 0 00-.983.983 4.922 4.922 0 01-4.915 4.915.983.983 0 100 1.966 4.921 4.921 0 014.915 4.915.983.983 0 101.966 0 4.921 4.921 0 014.915-4.915.983.983 0 100-1.966 4.921 4.921 0 01-4.915-4.915.983.983 0 00-.983-.983zm2.357 6.882a6.937 6.937 0 00-2.357 2.357 6.937 6.937 0 00-2.357-2.357 6.936 6.936 0 002.357-2.357 6.937 6.937 0 002.357 2.357zm0 0"
                fill={props.color ? 'var(--primary)' : props.color}
            />
        </svg>
    )
}

export default SvgComponent
