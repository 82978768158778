import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import {
  Bracket,
  Seed,
  SeedItem,
  SeedTeam,
  RenderSeedProps,
} from "react-brackets";
import api from "../../services/api";
import { useParams } from "react-router-dom";

import Dots from "../Dots";

import "./style.scss";

interface FlowchartProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

interface ParamTypes {
  tournamentId: string;
}

interface Rounds {
  title: string;
  seeds: [
    {
      id: string;
      status: string;
      teams: [
        {
          name: string;
        },
        {
          name: string;
        }
      ];
    }
  ];
}

interface Props {
  round: number;
  matches: [
    {
      id: number;
      round: number;
      status: string;
      teams: [
        {
          id: number;
          name: string;
          score: number;
        },
        {
          id: number;
          name: string;
          score: number;
        }
      ];
    }
  ];
}

const Flowchart = ({ isOpen, onRequestClose }: FlowchartProps) => {
  let { tournamentId } = useParams<ParamTypes>();
  const [rounds, setRounds] = useState<Rounds[]>([]);
  const [roundIndex, setRoundIndex] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`matches/${tournamentId}/bracket`);
      if (data.status === "error") {
        console.log(data.message);
      } else {
        const response = data.map((info: Props) => ({
          title: `${info.round}º round`,
          seeds: info.matches.map((teams) => ({
            id: teams.id,
            
            status: teams?.status,
            teams: teams.teams.map((team) => ({
              name: team.name,
              score: team.score,
            })),
          })),
        }));

        setRounds(response);
        setRoundIndex(0);
      }
    })();
  }, [tournamentId]);

  const handleChangeIndex = (index: number) => {
    setRoundIndex(index);
  }

  const CustomSeed = ({ seed, breakpoint }: RenderSeedProps) => {
    const scoreTeam1 = seed.teams[0]?.score;
    const scoreTeam2 = seed.teams[1]?.score;
    return (
      <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12, pointerEvents: 'none' }}>
        <SeedItem style={{ width: '300px' }}>
            <SeedTeam
              style={{
                backgroundColor: `${
                  scoreTeam1 > scoreTeam2 ? "#00CE8A" : "#A8A8A8"
                }`,
              }}
              className={`${seed.status !== "finished" && "finished"}`}
            >
              <p>{seed.teams[0]?.name || "NO TEAM "}</p>
              <span>{seed.teams[0]?.score}</span>
            </SeedTeam>
            <SeedTeam
              style={{
                backgroundColor: `${
                  scoreTeam1 < scoreTeam2 ? "#00CE8A" : "#A8A8A8"
                }`,
              }}
              className={`${seed.status !== "finished" && "finished"}`}
            >
              <p>{seed.teams[1]?.name || "NO TEAM "}</p>
              <span>{seed.teams[1]?.score}</span>
            </SeedTeam>
        </SeedItem>
      </Seed>
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="react-modal-flowchart"
      appElement={document.getElementById("root") as HTMLElement}
    >
      <a onClick={onRequestClose}>X</a>

      {rounds.length ? (
        <>
          <Bracket
            rounds={rounds}
            mobileBreakpoint={10000}
            renderSeedComponent={CustomSeed}
            swipeableProps={{
              axis: 'x',
              enableMouseEvents: true,
              threshold: 0.1,
              index: roundIndex,
              onChangeIndex: handleChangeIndex
            }}
          />
          <Dots quantity={rounds.length} selected={roundIndex} onClick={handleChangeIndex}/>
          <p style={{ marginTop: '10px', textAlign: 'center'}}>Arraste para o lado para visualizar os rounds.</p>
        </>
      ) : (
        <h1>Nenhuma partida foi iniciada.</h1>
      )}
    </ReactModal>
  );
};

export default Flowchart;
