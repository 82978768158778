import React, { useState } from 'react';
import ResetPasswordStyle from './style'
import { useParams, useHistory } from "react-router-dom";


import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import api from '../../services/api'
import * as Yup from 'yup';

import Modal from '../../components/Modal';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import logoTruco from './../../assets/images/plataforma_truco.png';

interface IResetPassword {
  password: string;
}
interface ParamTypes {
  token: string;
}

const ResetPassword = () => {
  let { token } = useParams<ParamTypes>();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showFailPasswordModal, setShowFailPasswordModal] = useState(false);
  const history = useHistory();
  const recoverInitialValues: IResetPassword = {
    password: '',
  };

  const RecoverPassSchema = Yup.object().shape({
    password: Yup.string().required('Informe sua senha'),
  });


  const submitNewPassword = async (values: IResetPassword) => {

    try {
      const password = values.password;

      const response = await api.post(`users/reset_password/${token}`, { password });

      if (response.status === 200) {
        setShowPasswordModal(true);
        setTimeout(() => {
          history.push('/login');
        }, 1000);

      } else {
        setShowFailPasswordModal(true);
      }

    } catch (err: any) {
      setShowFailPasswordModal(true);
    }


  }

  return (
    <ResetPasswordStyle>
      <div className="form-content">
        <img src={logoTruco} width="256" height="80" alt="Plataforma de Truco" />
        <h2 className="title-form">Preencha o campo abaixo para alterar sua senha</h2>

        <Formik
          initialValues={recoverInitialValues}
          validationSchema={RecoverPassSchema}
          onSubmit={(
            values: IResetPassword,
            { setSubmitting }: FormikHelpers<IResetPassword>,
            // { isSubmitting }: FormikState<IRecoverFormValues>,
          ) => {
            submitNewPassword(values);
            setSubmitting(false);
          }}
        >
          {(props: FormikProps<IResetPassword>) => {
            const {
              touched,
              errors,
              isSubmitting,
              setFieldValue,
            } = props
            return (
              <Form className="common-form">
                <InputField
                  name="password"
                  label="Digite sua nova senha"
                  type="password"
                  onChangeCallback={(value: string) => setFieldValue('password', value)}
                  errorMessage={errors.password && touched.password ? errors.password : ''}
                />

                <Button classButton="submit-button" hasLoader={true} showLoading={isSubmitting} disabled={isSubmitting} label="Alterar senha" type={'submit'} />
              </Form>
            )
          }}
        </Formik>
      </div>

      {showPasswordModal && (
        <Modal
          isOpen={showPasswordModal}
          onCloseModal={() => setShowPasswordModal(false)}
          classStatus="success"
          title="Feito!"
          text="Senha Alterada com sucesso."
        />
      )}

      {showFailPasswordModal && (
        <Modal
          isOpen={showFailPasswordModal}
          onCloseModal={() => setShowFailPasswordModal(false)}
          classStatus="warning"
          title="Opss!"
          text="Token inválido ou expirado"
        />

      )}
    </ResetPasswordStyle>
  )
}

export default ResetPassword;