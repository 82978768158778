import { useState, useEffect, useRef, useContext } from 'react';
import BasePage from '../../components/BasePage';
import { PlayerTrainingStyle } from './style';
import { AuthContext } from '../../contexts/UserContext';
import Title from '../../components/Title';
import Button from '../../components/Button';
import Modal from "../../components/Modal";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { PlayersTrainingInterface } from "./interface";
import CustomEditor from '../../components/Editor';
import api from '../../services/api';


const PlayersTraining = () => {
    const [contentEditable, setContentEditable] = useState(false);
    const [contentPage, setContentPage] = useState<PlayersTrainingInterface>({ id: 0, title: '', content:'' });
    const [itemId, setItemId] = useState<number | null>(null);
    const [returnMessage, setReturnMessage] = useState("");
    const [returnStatus, setReturnStatus] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const editorRef = useRef<any>();
    const [updatedContent, setUpdatedContent] = useState<PlayersTrainingInterface>({ id: 0, title: '', content: '' });
    const { userInfo, managerStatus } = useContext(AuthContext);

    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        try {
            const response = await api.get('/playersTraining/getLastItem');
            const { id, title, content  } = response.data.lastItem;

            if (content) {
                setItemId(id);
                setContentPage({ id, title, content });
            } else {
                console.error('Dados inválidos retornados pela API:', response.data);
            }

        } catch (error) {
            console.error('Erro ao buscar dados do banco de dados:', error);
        }
    }

    const toggleEditMode = () => {
        setContentEditable(!contentEditable);
    };

    const saveChanges = async () => {
        if(itemId){
            if (editorRef.current && editorRef.current.getData) {
                const updatedContent = { ...contentPage, content: editorRef.current.getData() };
                try {
                    const response = await api.put(`/playersTraining/${itemId}`, updatedContent);
                    setReturnStatus(true);
                    setReturnMessage("Treinamento editado com sucesso");
                    setIsModalOpen(true);
                } catch (err: any) {
                    if (err.response) {
                        setReturnMessage(err.response.data.message);
                        setIsModalOpen(true);
                    } else if (err.request) {
                        console.log(err.request);
                    } else {
                        setReturnMessage("Erro ao cadastrar");
                        setIsModalOpen(true);
                    }
                }
                fetchContent();
                toggleEditMode();
            } else {
                console.error('Editor não encontrado ou método getData() não está disponível.');
            }
        } else {
            try {
                const updatedContent = { ...contentPage, content: editorRef.current.getData() };
                
                const response = await api.post(`/playersTraining/createPlayersTraining`, updatedContent);
                setReturnStatus(true);
                setReturnMessage("Treinamento criado com sucesso");
                setIsModalOpen(true);
            } catch (err: any) {
                if (err.response) {
                    setReturnMessage(err.response.data.message);
                    setIsModalOpen(true);
                } else if (err.request) {
                    console.log(err.request);
                } else {
                    setReturnMessage("Erro ao cadastrar");
                    setIsModalOpen(true);
                }
            }
            fetchContent();
            toggleEditMode();
        }
    };
    
    const handleChange = (data: any) => {
        setUpdatedContent({ ...contentPage, content: data });
    };

    const handleFocus = (data: any) => {
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        
        <BasePage title="Treinamento para Jogadores">
            <PlayerTrainingStyle>
                {contentEditable ? (
                    <CustomEditor
                        data={contentPage?.content}
                        onChange={handleChange}
                        onReady={(editor) => {
                            editorRef.current = editor;
                        }}
                        onFocus={handleFocus}
                    />
                ) : (
                    <div className='intro-block'>
                        <Title>
                            {contentPage?.title}
                        </Title>
                        <div className='ck-content'>
                            <div dangerouslySetInnerHTML={{ __html: contentPage?.content }} />
                        </div>
                    </div>
                )}
                <br />     
                {userInfo().isAdmin && (
                    <div className="edit-buttons">
                        {contentEditable ? (
                            <>
                                <Button label="Cancelar Edição" eventCallback={toggleEditMode} />
                                <Button label="Salvar Alterações" eventCallback={saveChanges} />
                            </>
                        ) : (
                            <Button label="Editar" eventCallback={toggleEditMode} />
                        )}
                        {isModalOpen && (
                            <Modal
                              isOpen={isModalOpen}
                              onCloseModal={handleClose}
                              classStatus={returnStatus ? "success" : "error"}
                              title={returnStatus ? "Sucesso!" : "Opss!"}
                              text={returnMessage}
                            />
                          )}
                    </div>
                    
                )}
                < br/>< br/>< br/>< br/>
            </PlayerTrainingStyle>
        </BasePage>
    );
};


export default PlayersTraining;
