import { useMemo } from "react";

import { useTable, useSortBy, usePagination } from "react-table";
import ArrowDownIcon from "../../assets/svg/ArrowDownIcon";
import ArrowUpIcon from "../../assets/svg/ArrowUpIcon";
import NextPageIcon from "../../assets/svg/NextPageIcon";
import PrevPageIcon from "../../assets/svg/PrevPageIcon";
import TablePaginationStyle from "./style";

interface TableProps {
  columnsWithOutMemo: any;
  dataWithOutMemo: any;
  setPerPage(page: any): any;
  setPage(page: any): any;
  currentpage: any;
  perPage: any;
  totalPage: any;
  handleClick?(row: any): void;
}

const TablePagination = ({
  columnsWithOutMemo,
  dataWithOutMemo,
  handleClick,
  setPerPage,
  setPage,
  currentpage,
  perPage,
  totalPage,
}: TableProps) => {
  const columns = useMemo(() => columnsWithOutMemo, [columnsWithOutMemo]);
  const data = useMemo(() => dataWithOutMemo, [dataWithOutMemo]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state: { pageIndex },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state]
        );
      },
      initialState: { pageIndex: currentpage },
      manualPagination: true,
      pageCount: totalPage,
      sortTypes: useMemo(
        () => ({
          caseInsensitive: (a: any, b: any, id) => {
            const valueA = a.values[id].toLowerCase();
            const valueB = b.values[id].toLowerCase();
            return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
          },
        }),
        []
      ),
    },
    useSortBy,
    usePagination
  );

  return (
    <TablePaginationStyle hasHandleClick={handleClick ? true : false}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownIcon />
                        ) : (
                          <ArrowUpIcon />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleClick && handleClick(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button
          className="first_last"
          onClick={() => {
            setPage(1);
          }}
          disabled={currentpage === 1}
        >
          Primeira
        </button>
        <button
          onClick={() => {
            setPage((s: any) => (s === 0 ? 0 : s - 1));
          }}
          disabled={currentpage === 1}
        >
          <PrevPageIcon />
        </button>
        <span>
          Página{" "}
          <strong>
            {" "}
            {pageIndex} de {pageOptions.length}
          </strong>
        </span>
        <button
          onClick={() => {
            setPage((s: any) => s + 1);
          }}
          disabled={currentpage === totalPage}
        >
          <NextPageIcon />
        </button>
        <button
          className="first_last"
          onClick={() => {
            setPage(totalPage);
          }}
          disabled={currentpage === totalPage}
        >
          Última
        </button>

        {/* <select
                    value={perPage}
                    onChange={(e) => {
                        // setPageSize(Number(e.target.value));
                        setPerPage(Number(e.target.value));
                    }}
                >
                    {[5, 10, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Mostra {pageSize}
                        </option>
                    ))}
                </select> */}
      </div>
    </TablePaginationStyle>
  );
};

export default TablePagination;
