import styled from 'styled-components'

export const InputFieldStyle = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    position: relative;

    

    label{
        color: var(--primary);
        font-size: 1.5rem;
        font-family: var(--font-primary-bold);
        transition: color .3s;
    }

    textarea{
        width: 100%;
        height: 15rem;
        background-color: var(--color-white);
        margin: .9rem 0 .5rem;
        padding: 2rem;
        font-family: var(--font-primary-regular);
        border: 1px solid rgba(204,204,204,.80);
        transition: border-color .3s;
        color: var(--color-gray);
        font-size: 1.5rem;
        font-family: var(--font-primary-regular);

        &::-webkit-input-placeholder {
            color: var(--color-gray);
            font-size: 1.5rem;
            font-family: var(--font-primary-regular);
        }
        &:-moz-placeholder {
            color: var(--color-gray);
            font-size: 1.5rem;
            font-family: var(--font-primary-regular);
        }
        &::-moz-placeholder {
            color: var(--color-gray);
            font-size: 1.5rem;
            font-family: var(--font-primary-regular);
        }
        &:-ms-input-placeholder {
            color: var(--color-gray);
            font-size: 1.5rem;
            font-family: var(--font-primary-regular);
        }

        &:focus{
            border-color: var(--primary);
        }
    }

    .error-message{
        color: var(--danger);
        font-size: 1.2rem;
        height: 0rem;
        opacity: 0;
        transition: height .3s, opacity .3s;
    }

    &.input-error{
        textarea{
            border-color: var(--danger);
        }

        .error-message{
            height: 14px;
            opacity: 1;
        }
    }
`