import React from 'react';

import SocialListStyle from './style';

import { ISocialList, ISocialItem } from '../../../interfaces/GeneralInterfaces';
import SocialItem from '../SocialItem';

const SocialList: React.FC<ISocialList> = ({
    socials
}) => {

    const renderSocialItem = (item: ISocialItem) => {
        return (<SocialItem key={item.label} icon={item.icon} link={item.link} label={item.label} />)
    }

    return (
        <SocialListStyle>
            {socials.map((item: ISocialItem) => renderSocialItem(item))}
        </SocialListStyle>
    );
}

export default SocialList;