import React, {
  forwardRef,
  useContext,
  useRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import TablePagination from "../../../components/TablePagination";
import api from "../../../services/api";
import { AuthContext } from "./../../../contexts/UserContext";
import { PendingPlayersStyle } from "./style";

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  doc: string;
  phone: string;
}
interface IPlayer {
  status: "approved" | "rejected" | "pending" | "banned";
  user: IUser;
}

interface IPendingPlayersProps {
  tournamentId: string;
}

interface DataPendingUserForTable {
  name: string;
  username: string;
  id: string;
  actions: [string, string];
}

const labels = {
  approve: "Aprovar",
  reject: "Recusar",
};

const actions = [
  { value: "approve", label: labels.approve },
  { value: "reject", label: labels.reject },
];

const renderActionsHoc = (
  onActionConfirmed: (action: any, values: any) => Promise<void>
) => {
  const RenderActions = (values: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [actionSelected, setActionSelected] = useState<string>();
    const [actionConfirmed, setActionConfirmed] = useState<string>();

    useEffect(() => {
      if (actionConfirmed === labels.approve) {
        onActionConfirmed(
          actions.find((action) => action.label === labels.approve),
          values
        );
      }
      if (actionConfirmed === labels.reject) {
        onActionConfirmed(
          actions.find((action) => action.label === labels.reject),
          values
        );
      }
    }, [actionConfirmed]);

    return (
      <div>
        <Select
          options={actions}
          placeholder="Selecione uma ação"
          onChange={(action) => {
            // toggle modal passing submit func
            setActionSelected(action?.label);
            setIsModalOpen(true);
          }}
        />
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onCloseModal={() => setIsModalOpen(false)}
            classStatus="warning"
            title="Erro"
          >
            <span>
              Deseja {`${actionSelected?.toLocaleLowerCase()}`} esse jogador?{" "}
            </span>
            <div className="actions">
              <Button
                eventCallback={() => {
                  if (actionSelected === labels.approve) {
                    setActionConfirmed(actionSelected);
                  }
                  if (actionSelected === labels.reject) {
                    setActionConfirmed(actionSelected);
                  }
                }}
                type="submit"
                label="SIM"
              />
              <Button
                eventCallback={() => setIsModalOpen(false)}
                label="CANCELAR"
              />
            </div>
          </Modal>
        )}
      </div>
    );
  };

  return RenderActions;
};

const PendingPlayers: React.FC<IPendingPlayersProps> = ({ tournamentId }) => {
  const { loading, handleLoading } = useContext(AuthContext);
  const [loadingData, setLoadingData] = useState(true);
  const [pendingPlayersForTable, setPendingPlayersForTable] = useState<
    DataPendingUserForTable[]
  >({} as DataPendingUserForTable[]);
  const [pendingPlayers, setPendingPlayers] = useState<IPlayer[]>(
    {} as IPlayer[]
  );
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);

  useEffect(() => {
    async function getPendingPlayers() {
      const pendingPlayersResponse = await api.get(
        `tournaments/${tournamentId}/users?page=${page}&limit=${perPage}&status=pending`
      );
      setPendingPlayers(pendingPlayersResponse.data.subscribedPlayers);
      setTotalPage(pendingPlayersResponse.data.total);
      setLoadingData(false);
    }

    if (!loading) {
      getPendingPlayers();
    }
  }, [loading, page]);

  useEffect(() => {
    const prepareDataForTable = () => {
      let data: DataPendingUserForTable[] = [];
      if (pendingPlayers && pendingPlayers.length > 0) {
        pendingPlayers.map((player: IPlayer) => {
          if (player.status === "pending") {
            data.push({
              name: player.user.firstName + " " + player.user.lastName,
              username: player.user.username,
              id: player.user.id,
              actions: [" ", player.user.id],
            });
          }
          return null;
        });

        setPendingPlayersForTable(data);
      }
    };

    prepareDataForTable();
  }, [pendingPlayers]);

  const handleMessage = (value: string) => {
    if (value === "approve") {
      return "Aprovado";
    } else if (value === "reject") {
      return "Recusado";
    }
  };

  const handleSelect = async (action: any, values: any) => {
    console.log("debug ->", values);
    const users = { userId: values.value[1] };
    handleLoading(true);
    try {
      await api.post(`tournaments/${tournamentId}/${action.value}`, users);
      toast.success(`Usuário ` + handleMessage(action.value), {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (e: any) {
      console.log(e.message);
      toast.error(`Ops, algo de errado aconteceu`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      handleLoading(false);
    }
  };

  const columnsPendingPlayers = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "NOME",
      accessor: "name",
    },
    {
      Header: "USERNAME",
      accessor: "username",
    },
    {
      Header: "AÇÕES",
      accessor: "actions",
      Cell: renderActionsHoc(handleSelect),
    },
  ];

  return (
    <PendingPlayersStyle>
      <ToastContainer />
      {loading && <div className="">Carregando...</div>}
      {!loading && !loadingData && pendingPlayersForTable.length > 0 && (
        <div className="pendingPlayers">
          <TablePagination
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={page}
            totalPage={Math.ceil(totalPage / perPage)}
            columnsWithOutMemo={columnsPendingPlayers}
            dataWithOutMemo={pendingPlayersForTable}
          />
        </div>
      )}
    </PendingPlayersStyle>
  );
};

export default PendingPlayers;
