import { useState, useContext, useEffect } from "react";
import { AuthContext } from "./../../contexts/UserContext";
import { TableAllTournamentsStyle } from "./style";
import SectionTitle from "../../components/SectionTitle";
import TablePagination from "../../components/TablePagination";
import { ITournament } from "../../interfaces/GeneralInterfaces";
import { useHistory } from "react-router-dom";
import { formatDate, formatHour } from "../../utils/formatData";
import tournament_active from "../../assets/images/tournament_active.png";

interface ITournamentsDataForTable {
  date: string;
  category: string;
  state: string;
  hour: string;
  subscribed: string;
  maxParticipants?: string;
  description: [string, number];
  visibility: string;
}

interface ITableTournamentsProps {
  isWithoutRound?: boolean;
  tournaments: ITournament[];
  page?: number;
  perPage?: number;
  total?: number;
  setPage(page: any): any;
  setPerPage(page: any): any;
}

const TableAllTournaments = ({
  isWithoutRound = false,
  tournaments,
  page = 1,
  perPage = 3,
  total = 1,
  setPage,
  setPerPage,
}: ITableTournamentsProps) => {
  const { loading } = useContext(AuthContext);
  const [tournamentsDataForTable, setTournamentsDataForTable] = useState<
    ITournamentsDataForTable[]
  >({} as ITournamentsDataForTable[]);
  const history = useHistory();

  const renderDescription = (values: any) => {
    if (values.value) {
      return values.value[0];
    }
  };

  const handleSubscribed = (values: any) => {
    if (values.value === "approved") {
      return "Inscrito";
    } else if (values.value === "rejected") {
      return "Rejeitado";
    } else if (values.value === "pending") {
      return "Pendente";
    } else if (values.value === "banned") {
      return "Recusado";
    } else {
      return "Não inscrito";
    }
  };

  const renderCategory = (values: any) => {
    if (values.value === "Tournament") {
      return "Torneio";
    } else if (values.value === "TrucoCup") {
      return "Copa Truco";
    } else {
      return "Estadual Copag";
    }
  };

  const renderVisibility = (values: any) => {
    if (values.value === "Public") {
      return "Público";
    } else {
      return "Privado";
    }
  };

  const columnsTournaments = [
    {
      Header: "DATA",
      accessor: "date",
      Cell: formatDate,
    },
    {
      Header: "HORA",
      accessor: "hour",
      Cell: formatHour,
    },
    {
      Header: "LOCAL",
      accessor: "state",
    },
    {
      Header: "NOME DO EVENTO",
      accessor: "description",
      Cell: renderDescription,
    },
    {
      Header: "TIPO",
      accessor: "visibility",
      Cell: renderVisibility,
    },
    {
      Header: "CATEGORIA",
      accessor: "category",
      Cell: renderCategory,
    },
    {
      Header: "PARTICIPANTES",
      accessor: "maxParticipants",
    },
    {
      Header: "INSCRIÇÃO",
      accessor: "subscribed",
      Cell: handleSubscribed,
    },
  ];

  const handleClickRow = (row: any) => {
    isWithoutRound
      ? history.push(`/torneio-ranking/${row.description[1]}`)
      : history.push(`/torneio/${row.description[1]}`);
  };

  useEffect(() => {
    const prepareDataForTable = () => {
      let data: ITournamentsDataForTable[] = [];
      if (tournaments && tournaments.length > 0) {
        tournaments.map((tournament: ITournament) => {
          return data.push({
            date: tournament.date,
            category: tournament.category,
            state: tournament.state?.name ?? "Torneio Online",
            hour: tournament.date,
            subscribed: tournament?.subscribed ?? "Não inscrito",
            maxParticipants: `${tournament.subscribedUsersCount || 0}${tournament.maxParticipants ? "/" + tournament.maxParticipants : ""}`,
            description: [tournament.name, tournament.id],
            visibility: tournament.visibility,
          });
        });

        setTournamentsDataForTable(data);
      } else {
        setTournamentsDataForTable({} as ITournamentsDataForTable[]);
      }
    };

    if (tournaments && tournaments.length > 0) prepareDataForTable();
  }, [tournaments]);

  return (
    <TableAllTournamentsStyle>
      {loading && <div className="">Carregando...</div>}
      {!loading &&
      tournamentsDataForTable &&
      tournamentsDataForTable.length > 0 ? (
        <div className="next-tournaments">
          <SectionTitle
            icon={<img src={tournament_active} alt="torneio" />}
            title={"Torneios"}
          />
          <TablePagination
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={page}
            totalPage={Math.ceil(total / perPage)}
            columnsWithOutMemo={columnsTournaments}
            dataWithOutMemo={tournamentsDataForTable}
            handleClick={handleClickRow}
          />
        </div>
      ) : (
        <span>Nenhum registro encontrado.</span>
      )}
    </TableAllTournamentsStyle>
  );
};

export default TableAllTournaments;
