import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./../../contexts/UserContext";
import { AdminStyle } from "./style";
import BasePage from "../../components/BasePage";
import TabPane from "../../components/Tabs/TabPane";
import Tabs from "../../components/Tabs/Tabs";
import CreateOrEditTournament from "../../components/CreateOrEditTournament";
import User from "./Users";
import Leagues from "./Leagues";
import ManagerBadges from "./ManagerBadges";
import TableManagerApprove from "../../components/TableManagerApprove";
import SectionTitle from "../../components/SectionTitle";
import Cards from "../../assets/svg/Cards";
import TableApprovingCupCopag from "../../components/TableApprovingCupCopag";
import ManuaisDownload from "./ManuaisDownload";
import { useLocation } from "react-router-dom";

const description = () => {
  return (
    <>
      <SectionTitle
        customClass="title-create-tournament"
        title="Agendar Torneio"
        icon={<Cards />}
      />
      <br />
      {/* <Text customClass="league-manager-text">
        Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit. Nullam hendrerit maximus fringilla.</strong> Etiam vitae egestas ante. Proin vestibulum nisl est, at feugiat odio porta vel. In sit amet nisi enim. Cras porta diam a nisi malesuada, vel volutpat mi feugiat. Nulla scelerisque pellentesque nibh, non lacinia tortor dignissim et.
                <br />
                Morbi imperdiet fermentum ex. Pellentesque id nibh mollis, accumsan velit ut, scelerisque quam. Praesent semper felis euismod ante efficitur, ullamcorper tempor ipsum fermentum. Nullam quis ex mi. Maecenas rutrum, lacus id volutpat rutrum, magna ex maximus tortor, quis scelerisque sem nisi eu lorem.
            </Text> */}
    </>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Admin = () => {
  const { userInfo } = useContext(AuthContext);
  const query = useQuery();
  const section = query.get("section");
  const [sectionUrl, setSectionUrl] = useState<string>("USUÁRIOS");

  useEffect(() => {
    if (section === "1") {
      setSectionUrl("USUÁRIOS");
    }
    if (section === "2") {
      setSectionUrl("APROVAÇÃO DE ORGANIZADOR");
    }
    if (section === "3") {
      setSectionUrl("APROVAÇÃO DE COPA COPAG");
    }
    if (section === "4") {
      setSectionUrl("ESTADUAL COPAG");
    }
    if (section === "5") {
      setSectionUrl("LIGAS");
    }
    if (section === "6") {
      setSectionUrl("STATUS DE COLABORADORES");
    }
    if (section === "7") {
      setSectionUrl("MANUAIS");
    }
  }, [section]);

  return (
    <BasePage title={`Olá, ${userInfo().name}`}>
      <AdminStyle>
        <Tabs tabActive={sectionUrl}>
          <TabPane label="USUÁRIOS">
            <User />
          </TabPane>

          <TabPane label="APROVAÇÃO DE ORGANIZADOR">
            <TableManagerApprove />
          </TabPane>

          <TabPane label="APROVAÇÃO DE COPA COPAG">
            <TableApprovingCupCopag />
          </TabPane>

          <TabPane label="ESTADUAL COPAG">
            <CreateOrEditTournament
              category="StateCup"
              Description={description}
              edit={false}
              displayForm={true}
            />
          </TabPane>

          <TabPane label="LIGAS">
            <Leagues />
          </TabPane>

          <TabPane label="STATUS DE COLABORADORES">
            <ManagerBadges />
          </TabPane>
          <TabPane label="LIGAS">
            <Leagues />
          </TabPane>

          <TabPane label="MANUAIS">
            <ManuaisDownload />
          </TabPane>
        </Tabs>
      </AdminStyle>
    </BasePage>
  );
};

export default Admin;
