import styled from 'styled-components'

export const TournamentStyle = styled.div`
    /* > div{
        margin-bottom:30px;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, .16);
    } */
    
    .container-accordion{
      margin-bottom:30px;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, .16);
    }
    .list-infos{
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap:10px;
        margin-top:30px;
    }
    .toggle_ranking_others{
      display:flex;
      justify-content:flex-end;
      margin-bottom:30px;

      button{
        width:41px;
        height:34px;
        
        display:flex;
        align-items:center;
        justify-content:center;
        background:var(--color-green);
        svg{
          fill:var(--color-purple);
        }

        &.active{
          background:var(--color-purple);
          svg{
            fill:var(--color-green);
          }
        }
      }
    }
`
