import styled from 'styled-components'

const UploadImageStyle = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    position: relative;
   
    label{
        color: var(--primary);
        font-size: 1.5rem;
        font-family: var(--font-primary-bold);
        transition: color .3s;
    }
    .dropzone{
        width: 100%;
        height: 5rem;
        background-color: var(--color-white);
        margin: .9rem 0 .5rem;
        padding: 0 2rem;
        font-family: var(--font-primary-regular);
        border: 1px solid rgba(204,204,204,.80);
        transition: border-color .3s;
        color: var(--color-gray);
        font-size: 1.5rem;
        font-family: var(--font-primary-regular);
        position:relative;
        cursor:pointer;
        display:flex;
        align-items:center;

        svg{
            position:absolute;
            top:50%;
            right:20px;
            transform:translateY(-50%);

        }
    }
`

export default UploadImageStyle;