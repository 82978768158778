import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './../../../contexts/UserContext';
import { PlayersStyle } from './style'
import api from '../../../services/api'
import Select from "react-select";
import { selectStyles } from '../../../assets/styles/selectStyles'
import SectionTitle from '../../../components/SectionTitle';
import UserIcon from '../../../assets/svg/UserIcon';
import TablePagination from '../../../components/TablePagination'
import Button from '../../../components/Button';
import useCommonRequisitions from '../../../hooks/useCommonRequisitions';
import { IStates, ICities } from '../../../interfaces/GeneralInterfaces';
import Modal from '../../../components/Modal';

const columnsPlayersTable = [
  {
    Header: 'ID DO JOGADOR',
    accessor: 'id',
  },
  {
    Header: 'NOME',
    accessor: 'name',
  },
  {
    Header: 'CIDADE',
    accessor: 'city',
  },
  {
    Header: 'ESTADO',
    accessor: 'state',
  },
  {
    Header: 'NÚMERO DE PARTIDAS',
    accessor: 'matches',
  },
  {
    Header: 'NÚMERO DE VITÓRIAS',
    accessor: 'victories',
  }
];

interface IPlayerFilter {
  id?: number;
  search?: string;
  state?: string;
  city?: string;
}

interface IPlayersData {
  name: string;
  city: string;
  state: string;
  matches: number;
  victories: number;
}

interface IPlayersProps {
  leagueId: number;
}

const Players = ({ leagueId }: IPlayersProps) => {
  const { loading } = useContext(AuthContext);
  const [players, setPlayers] = useState<IPlayersData[]>({} as IPlayersData[]);
  const [filterOptions, setFilterOptions] = useState<IPlayerFilter>({} as IPlayerFilter);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { getStates, getCities } = useCommonRequisitions();
  const [states, setStates] = useState<IStates[]>([]);
  const [cities, setCities] = useState<ICities[]>([]);
  const [stateSelected, setStateSelected] = useState<any>("");
  const [returnMessage, setReturnMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
}

  useEffect(() => {
    async function getState() {
      const populatedStates = await getStates();
      setStates(populatedStates);
    }
    getState();
  }, [])

  useEffect(() => {
    async function getCity() {
      const cities = await getCities(stateSelected);
      setCities(cities);
    }

    if (stateSelected) {
      getCity();
    }
  }, [stateSelected])

  useEffect(() => {

    if (!loading) {
      getPlayers();
    }

  }, [loading, page])

  const handleSearch = async () => {
    try {
      const response = await api.post(`leagues/${leagueId}/users?page=${page}&limit=${perPage}`);
      if (response.data.leagueUsers && response.data.leagueUsers.length > 0) {
        console.log(response.data)
        setTotal(response.data.total);
        setPlayers(response.data.leagueUsers);
      } else {
      setIsModalOpen(true);
      setReturnMessage('Não há jogadores com essas informações')}
    } catch (e: any) {
      console.log(e);
    }
  }

  const getPlayers = async () => {
    const response = await api.post(`leagues/${leagueId}/users?page=${page}&limit=${perPage}`, { filterOptions });
    setTotal(response.data.total);
    setPlayers(response.data.leagueUsers);
  }

  return (
    <PlayersStyle>
      {loading && (
        <div className="">Carregando...</div>
      )}

      <div className="filter_tournaments">
        <input
          name="id"
          type="text"
          className="search"
          placeholder="Id do Jogador"
          onChange={(e: any) => {
            let newValue = { ...filterOptions }
            newValue.id = e.target.value;
            setFilterOptions(newValue);
          }}
        />
        <input
          name="search"
          type="text"
          className="search"
          placeholder="Pesquise por nome"
          onChange={(e: any) => {
            let newValue = { ...filterOptions }
            newValue.search = e.target.value;
            setFilterOptions(newValue);
          }}
        />

        <div className="filter_selects">
          <Select options={states} placeholder="Estado" styles={selectStyles} onChange={(state: any) => {
            setStateSelected(state.value)
            let newValue = { ...filterOptions }
            newValue.state = state.value;
            setFilterOptions(newValue);
          }} />
          <Select options={cities} placeholder="Cidade" styles={selectStyles} onChange={(city: any) => {
            let newValue = { ...filterOptions }
            newValue.city = city.value;
            setFilterOptions(newValue);
          }} />

        </div>
        <div className="search_button_area">
          <Button label="Pesquisar" eventCallback={handleSearch} />
          {(isModalOpen && (
                        <Modal
                            isOpen={isModalOpen}
                            onCloseModal={handleClose}
                            classStatus={'error'}
                            text={returnMessage}
                        />
                    ))}
        </div>

      </div>


      {!loading && (
        players?.length > 0 && (
          <>
            <SectionTitle icon={<UserIcon />} title={'Jogadores'} />
            {/* <Table columnsWithOutMemo={columnsPlayersTable} dataWithOutMemo={players} /> */}
            <TablePagination perPage={perPage} setPage={setPage} setPerPage={setPerPage} currentpage={page} totalPage={Math.ceil(total / perPage)} columnsWithOutMemo={columnsPlayersTable} dataWithOutMemo={players} />
          </>
        )
      )}
    </PlayersStyle>
  )
}

export default Players;