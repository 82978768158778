import styled from 'styled-components'

export const EditQuizStyle = styled.div`
form{
        display:flex;
        flex-direction:column;
    }

    .form-block{
        display:grid;
        grid-template-columns:1fr;
        grid-gap:10px;
        margin-bottom:30px;
        
        @media(max-width:1024px){
            grid-template-columns:1fr;
        }

        
        input:disabled{
            background:#f0f0f1;
        }

        .align-right{
          width:100%;
          display:flex;
          justify-content:flex-end;
          margin-top:10px;
        }

        .btn_add{
          display:flex;
          align-items:center;
          cursor:pointer;
          border:2px solid var(--primary);
          border-radius:25px;
          padding:10px 15px;
          font-size: 1.5rem;
          font-family: var(--font-primary-bold);
          transition:all 200ms;
          &:hover{
            background:var(--primary);
            color:#FFF;
            svg{
              fill:#FFF;
            }
          }
          svg{
            fill:var(--primary);
            margin-right:10px;
            transition:all 200ms;
          }
        }

        .form-row{
          display:flex;
          justify-content:center;
          width:100%;
          flex-direction:column;
          padding:20px; 
          margin-top:30px;
          border:1px solid var(--color-gray-thin);

           .input-block{
            margin-top:3px;
          }
          
          /* input[type="text"]{
            height:3.5rem;
            padding-left:10px;
          }  */
          /*
           input[type="text"]{
            height:3rem;
            border:none;
            padding-left:0;
            margin:0;
            &:focus{
              border: 1px solid rgba(204,204,204,.80);
              padding-left:10px;
              height:4rem;
            }
          } */
          
          .button-controls{
            width:100%;
            display:flex;
            align-items:center;
            justify-content:space-between;
            margin-top:10px;
            .btn_add_answer{
              display:flex;
              align-items:center;
              cursor:pointer;
              border:2px solid var(--color-green);
              border-radius:25px;
              padding:10px 15px;
              font-size: 1.3rem;
              font-family: var(--font-primary-bold);
              svg{
                margin-right:5px;
                fill:var(--color-green);
              }
            }
            .control-question{
              display:flex;
              align-items:center;
              div{
                margin:0 5px;
              }
              .btn-move{
                svg{
                  fill:var(--color-green);
                }
              }
              .btn-remove-question{
                cursor:pointer;
                display:flex;
                align-items:center;
                justify-content:center;
                border:2px solid var(--danger);
                border-radius:25px;
                padding:10px 15px;
                font-size: 1.3rem;
                font-family: var(--font-primary-bold);
                svg{
                  fill:var(--danger);
                  margin-right:5px;
                }

              }
            }
          }

         .form-row-block{
            display:flex;
            align-items:center;

            &.row-answer{
              .checkIsCorrect{
                margin-right:10px;
                margin-top:9px;
              }

              &:nth-child(2){
                .checkIsCorrect{
                  margin-top:30px;
                }
                .button-content{
                  padding-top:24px;
                }
              }
            }
            
            .input-block{
              margin-right:10px;
              flex:1;
            }
            
            .isCorrect{
              input[type="text"]{
                border:2px solid var(--color-green);
              }
            }

          }
          .button-content{
            display:flex;
            align-items:center; 
            padding-top:8px;
            

            .btn-remove-answer{
              height:20px;
              border-radius:25px;
              padding:0 0.7rem;
              display:flex;
              align-items:center;
              justify-content:center;
              
              cursor:pointer;
              svg{
                fill:red;
                width:10px;
                height:10px;
              }
            }
          }
        }
    }
`