import { createContext, useState } from 'react';
import api from '../../../services/api'
import { ITournament, IRoundResponse, IMatch, IMatchResponse, IRanking } from '../../../interfaces/GeneralInterfaces';
import { TournamentStatus, TournamentTypes } from '../../../enums/GeneralEnums';

interface TournamentContextData {
    handleStartGame(): void;
    handleFinishGame(): any;
    handleMatches(): void;
    handleRanking(): void;
    matchResponse: IMatchResponse;
    tournamentStatus: TournamentStatus;
    ranking: IRanking[];
}

interface TournamentProviderProps {
    tournament: ITournament;
    children: any;
}

const TournamentContext = createContext<TournamentContextData>({} as TournamentContextData);

const TournamentProvider = ({ tournament, children }: TournamentProviderProps) => {
    const [matchResponse, setMatchResponse] = useState<IMatchResponse>({} as IMatchResponse);
    const [tournamentStatus, setTournamentStatus] = useState<TournamentStatus>(TournamentStatus.None);
    const [ranking, setRaking] = useState<IRanking[]>([] as IRanking[]);

    const handleStartGame = async () => {
        try {
            if (tournament.type === TournamentTypes.Swiss) {
                const response = await api.get(`matches/${tournament.id}/start/swiss`);
                handleSetStatus(response.data.tournamentStatus);
            } else {
                const response = await api.get(`matches/${tournament.id}/start/knockout`);
                handleSetStatus(response.data.tournamentStatus);
            }
            handleMatches();
        }
        catch (e: any) {
            console.log("erro", e);
        }
    }

    const handleFinishGame = async () => {
        try {
            const object = { tournamentId: tournament.id };
            await api.post(`matches/finish`, object);
            handleSetStatus('finished');
            handleRanking();
            return {
                status: true,
                message: ''
            }
        }
        catch (e: any) {
            console.log("erro", e);
            return {
                status: false,
                message: e.response.data.message
            }
        }
    }

    const handleRanking = async () => {
        try {
            const response = await api.get(`tournamentRanking/${tournament.id}`);
            setRaking(response.data);
        }
        catch (e: any) {
            console.log(e);
        }
    }

    const handleMatches = async () => {
        try {
            const response = await api.get(`matches/${tournament.id}`)
            if (response) {
                let roundResponse: IRoundResponse[] = [] as IRoundResponse[];
                response.data?.rounds?.map((round: IRoundResponse) => {
                    let matchRresponse: IMatch[] = [] as IMatch[];
                    let currentRound: IRoundResponse = {} as IRoundResponse;
                    currentRound.round = round.round;
                    round.matches?.map((match: IMatch, index: number) => {
                        let currentMatch: IMatch = {} as IMatch;
                        currentMatch = { ...match, table: ++index };

                        return matchRresponse.push(currentMatch);
                    })
                    currentRound.matches = matchRresponse;
                    return roundResponse.push(currentRound);
                })

                const preparedResponse: IMatchResponse = {} as IMatchResponse;
                preparedResponse.userMatchId = response.data.userMatchId;
                preparedResponse.rounds = roundResponse;
                preparedResponse.maxrounds = response.data.maxRounds;
                preparedResponse.tournamentStatus = response.data.tournamentStatus

                setMatchResponse(preparedResponse);
                handleSetStatus(response.data.tournamentStatus);

                let matches: IMatch[] = [];
                response.data.rounds.forEach((round: IRoundResponse) => round.matches.map((match: IMatch) => {
                    return matches.push(match)
                }));
            }
        }
        catch (e: any) {
            setMatchResponse({} as IMatchResponse);
        }
    }

    const handleSetStatus = (currentStatus: string) => {
        let status = TournamentStatus.None;
        if (currentStatus === 'notStarted') {
            status = TournamentStatus.notStarted;
        } else if (currentStatus === 'inProgress') {
            status = TournamentStatus.inProgress;
        } else if (currentStatus === 'finished'){
            status = TournamentStatus.finished;
        }
        setTournamentStatus(status);
    }

    return (
        <TournamentContext.Provider value={{
            handleStartGame,
            handleFinishGame,
            handleMatches,
            handleRanking,
            matchResponse,
            tournamentStatus,
            ranking
        }}>
            {children}
        </TournamentContext.Provider>
    );
}

export { TournamentContext, TournamentProvider };