import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'
import { IoCheckmarkCircle, IoCloseCircle, IoCloseOutline, IoWarningOutline } from "react-icons/io5";
import { CSSTransition } from 'react-transition-group';

import { ModalStyle } from './style';

import { IModalProps } from '../../interfaces/GeneralInterfaces';

const Modal: React.FC<IModalProps> = ({
    children,
    text,
    title,
    subtitle,
    isOpen = false,
    onCloseModal,
    classStatus = 'success',
}) => {
    const [container] = useState(document.createElement('div'));
    const modalRef = React.useRef(null);

    container.classList.add('root-portal');

    useEffect(() => {
        document.body.appendChild(container)

        return () => {
            document.body.removeChild(container)
        }
    }, [container])
    const onClose = () => {
        onCloseModal && onCloseModal();
    }

    return (
        <CSSTransition in={isOpen} nodeRef={modalRef} timeout={300} classNames="modal-anim">
            <>
                {isOpen && ReactDOM.createPortal((
                    <ModalStyle ref={modalRef}>
                        <div className="overlay" onClick={onClose}></div>

                        <div className="modal-body">
                            <button type="button" className="close" onClick={onClose} title="Fechar"><IoCloseOutline size={30} /></button>

                            {children ? (
                                children
                            ) : (
                                <>
                                    <div className={`modal-icon ${classStatus}`}>
                                        {classStatus === 'success' && (
                                            <IoCheckmarkCircle />
                                        )}
                                        {classStatus === 'error' && (
                                            <IoCloseCircle />
                                        )}
                                        {classStatus === 'warning' && (
                                            <IoWarningOutline />
                                        )}
                                    </div>

                                    {title && (
                                        <div className="modal-title">{title}</div>
                                    )}
                                    {subtitle && (
                                        <div className="modal-subtitle">{subtitle}</div>
                                    )}
                                    {text && (
                                        <div className="modal-text">{text}</div>
                                    )}
                                </>
                            )}
                        </div>
                    </ModalStyle>
                ), container)}
            </>
        </CSSTransition>
    );
}

export default Modal;