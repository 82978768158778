import styled from 'styled-components'

const UserInformationStyle = styled.div`
    border: 1px solid var(--color-gray-light);
    padding: 1.5rem;
    //min-width: 300px;
    .user-image {
        position:relative;
        &:hover{
            span{
                opacity:1;
            }
        }    
        span{
            border-radius: 50%;
            position:absolute;
            top:10px;
            right:40px;
            cursor:pointer;
            opacity:0;
            transition:all 200ms;
            svg{
                fill:var(--color-green);
            }
        }
        img {
            border: 10px solid var(--primary);
            border-radius: 50%;
            margin: 0 auto;
            display: block;
        }
        margin-bottom: 2rem;
    }

    .points-info {
        margin-top: 2rem;
        display: flex;
        align-items: baseline;
        svg{
            margin-right: 1rem;
            width: 20px;
            height: 30px;
        }

    }

    @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px)  {
      td:nth-of-type(1):before { content: "Torneio"; }
      td:nth-of-type(2):before { content: "Colocação"; }
    }
`

export default UserInformationStyle;