import { useContext, useEffect, useState } from 'react'
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import InputField from '../InputField';
import Textarea from '../Textarea';
import Button from '../Button';

import { IUser, ITeam } from '../../interfaces/GeneralInterfaces';
import { ModalRemovePlayerStyle } from './style';
import api from '../../services/api';
import { AuthContext } from '../../contexts/UserContext'

interface IRemovePlayer {
    witnessOne: string;
    witnessTwo: string;
    reason: string;
}

const removePlayerInitialValue: IRemovePlayer = {
    witnessOne: "",
    witnessTwo: "",
    reason: "",
};

interface ModalRemovePlayersProps {
    tournamentId: number;
    team?: ITeam;
    user?: IUser;
    userId?: number;
    closeModal(): void;
    visualizeInformations?: boolean;
}

const ModalRemovePlayers = ({ tournamentId, team, user, userId, closeModal, visualizeInformations = false }: ModalRemovePlayersProps) => {
    const { handleLoading, loading } = useContext(AuthContext);
    const [currentKickedUser, setCurrentKickedUser] = useState<IRemovePlayer>({} as IRemovePlayer);
    const removePlayerSchema = Yup.object().shape({
        witnessOne: Yup.string().required('Informe a primeira testemunha'),
        witnessTwo: Yup.string().required('Informe a segunda testemunha'),
        reason: Yup.string().required('Informe o motivo'),
    });

    useEffect(() => {
        const getRemovedPlayerInformation = async () => {
            try {
                const response = await api.post(`/kickUser/${tournamentId}`, { userId });
                setCurrentKickedUser(response.data);
            } catch (e: any) {
                console.log(e);
            }
        }
        if (!loading && visualizeInformations)
            getRemovedPlayerInformation();

    }, [visualizeInformations, loading])

    const initialValuesWhenVisualizeInformations: IRemovePlayer = {
        witnessOne: currentKickedUser.witnessOne ?? '',
        witnessTwo: currentKickedUser.witnessTwo ?? '',
        reason: currentKickedUser.reason ?? '',
    }

    const handleSubmitRemovePlayer = async (values: IRemovePlayer, callback: Function) => {
        handleLoading(true);
        try {
            const object = {
                tournamentId: tournamentId,
                userId: user?.id,
                teamId: team?.id,
                witnessOne: values.witnessOne,
                witnessTwo: values.witnessTwo,
                reason: values.reason
            }

            await api.post(`kickUser`, object);
            closeModal();

        } catch (err: any) {
            console.log(err);
        }
        callback();
        handleLoading(false);
    }

    return (
        <ModalRemovePlayerStyle>
            <Formik
                initialValues={visualizeInformations ? initialValuesWhenVisualizeInformations : removePlayerInitialValue}
                validationSchema={removePlayerSchema}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
                onSubmit={(
                    values: IRemovePlayer,
                    { setSubmitting, resetForm }: FormikHelpers<IRemovePlayer>,
                ) => {
                    handleSubmitRemovePlayer(values, function () {
                        setSubmitting(false);
                        resetForm({})
                    });
                }}
            >
                {formik => {
                    return (
                        <>
                            <Form className="formInvitePlayers">
                                <InputField
                                    name="witnessOne"
                                    label="Primeira Testemunha"
                                    type="text"
                                    disabled={visualizeInformations}
                                />
                                <InputField
                                    name="witnessTwo"
                                    label="Segunda Testemunha"
                                    type="text"
                                    disabled={visualizeInformations}
                                />
                                <Textarea
                                    name="reason"
                                    label="Motivo"
                                    className="description"
                                    disabled={visualizeInformations}
                                />
                                <br />
                                {!visualizeInformations && <Button classButton="submit-button" hasLoader={true} showLoading={formik.isSubmitting} disabled={formik.isSubmitting} label="ENVIAR" type={'submit'} />}
                                {visualizeInformations && <Button label={'FECHAR'} eventCallback={() => closeModal()} />}
                            </Form>
                        </>
                    )
                }}
            </Formik>
            <br />
        </ModalRemovePlayerStyle>
    );
}

export default ModalRemovePlayers;