import { useState, useContext, useEffect, useLayoutEffect } from "react";
import { AuthContext } from "./../../contexts/UserContext";
import { TableNextsTournamentsStyle, NoTournaments } from "./style";
import SectionTitle from "../../components/SectionTitle";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import {
  INextTournamentDataTable,
  INextTournaments,
} from "../../interfaces/GeneralInterfaces";
import api from "../../services/api";
import Button from "../../components/Button";
import { formatHour, formatDate } from "../../utils/formatData";
import tournament_active from "../../assets/images/tournament_active.png";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const TableNextsTournaments = () => {
  const { loading, handleLoading } = useContext(AuthContext);
  const [currentTournament, setCurrentTournament] = useState(0);
  const [currentTournamentDescription, setCurrentTournamentDescription] =
    useState("");
  const [tournaments, setTournaments] = useState<INextTournaments[]>(
    {} as INextTournaments[]
  );
  const [nextsTournamentsData, setNextsTournamentsData] = useState<
    INextTournamentDataTable[]
  >({} as INextTournamentDataTable[]);
  const [loadingData, setLoadingData] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [error, setError] = useState("");
  const [content, setContent] = useState("INSCRIÇÃO");
  const [width] = useWindowSize();
  // useEffect(() => {
  //     function handleResize() {
  //         setDimensions({
  //             height: window.innerHeight,
  //             width: window.innerWidth
  //         })
  //     }
  //     window.addEventListener('resize', handleResize)
  // });

  useEffect(() => {
    // return width < 1024 ? setContent('🡭') : setContent('INSCRIÇÃO')
    if (width >= 1280) {
      setContent("INSCRIÇÃO");
    } else if (width <= 1280 && width >= 1023) {
      setContent("🡥");
    } else if (width <= 1022 && width >= 500) {
      setContent("INSCRIÇÃO");
    } else if (width < 500) {
      setContent("🡥");
    } else {
      setContent("INSCRIÇÃO");
    }
  }, [width]);

  const handleModalSubscribe = async (values: any) => {
    setCurrentTournament(values.value[1]);
    setCurrentTournamentDescription(values?.row?.values?.description);

    setIsModalOpen(true);
  };

  const handleSubscribe = async () => {
    handleLoading(true);
    const tournament = { tournamentId: currentTournament };
    const response = await api.post("/tournaments/subscribe", tournament);

    if (response.data.status !== "error") {
      setIsModalOpen(false);
      setIsModalSuccessOpen(true);
    } else {
      setError(response.data.message);
      setIsModalOpen(false);
      setIsModalErrorOpen(true);
    }
    handleLoading(false);
  };

  const renderButtonRegister = (values: any) => {
    return (
      <>
        {tournaments.filter(
          (tournament) =>
            tournament?.id === values.value[1] &&
            (tournament?.subscribed === undefined ||
              tournament?.subscribed === "notSubscribed")
        ).length > 0 && (
          <Button
            classButton={"small"}
            label={values.value[0]}
            eventCallback={(e: any) => handleModalSubscribe(values)}
          />
        )}
      </>
    );
  };

  const renderCategory = (values: any) => {
    if (values.value === "Tournament") {
      return "Torneio";
    } else if (values.value === "TrucoCup") {
      return "Copa Truco";
    } else {
      return "Estadual Copag";
    }
  };

  const columnsNextTournaments = [
    {
      Header: "DATA",
      accessor: "date",
      Cell: formatDate,
    },
    {
      Header: "HORA",
      accessor: "hour",
      Cell: formatHour,
    },
    {
      Header: "LOCAL",
      accessor: "state",
    },
    {
      Header: "CATEGORIA",
      accessor: "category",
      Cell: renderCategory,
    },
    {
      Header: "LIGA",
      accessor: "league",
    },
    {
      Header: "NOME DO EVENTO",
      accessor: "description",
    },
    {
      Header: "PARTICIPANTES",
      accessor: "maxParticipants",
    },
    {
      Header: " ",
      accessor: "register",
      Cell: renderButtonRegister,
    },
  ];

  useEffect(() => {
    (async () => {
      const prepareDataForTable = async () => {
        let data: INextTournamentDataTable[] = [];
        if (tournaments && tournaments.length > 0) {
          tournaments.sort(
            (tournament: INextTournaments, tournament2: INextTournaments) => {
              const dateA = new Date(tournament.date).getTime();
              const dateB = new Date(tournament2.date).getTime();
              return dateA - dateB;
            }
          );

          tournaments.map((tournament: INextTournaments, index: number) => {
            // if (index < 6) {
            data.push({
              id: tournament.id,
              date: tournament.date,
              category: tournament.category,
              state: tournament.state ?? "Online",
              hour: tournament.date,
              league: tournament.leagueName ?? "",
              description: tournament.name,
              maxParticipants: tournament.maxParticipants + "/" + "9999",
              register: [content, tournament.id, tournament.managerEmail],
              subscribed: tournament.subscribed,
            });
            // }
            return null;
          });
          setNextsTournamentsData(data);
        } else {
          setNextsTournamentsData({} as INextTournamentDataTable[]);
        }
      };

      prepareDataForTable();
    })();
  }, [tournaments, content]);

  useEffect(() => {
    if (!loading) getTournaments();
  }, [loading]);

  async function getTournaments() {
    try {
      const nextTournamentsResponse = await api.post("tournaments/next");
      setTournaments(nextTournamentsResponse.data.tournaments);

      setLoadingData(false);
    } catch (e: any) {
      console.log(e);
    }
  }

  return (
    <TableNextsTournamentsStyle>
      {loading && <div className="">Carregando...</div>}
      {!loading &&
        !loadingData &&
        (nextsTournamentsData.length > 0 ? (
          <div className="next-tournaments">
            <SectionTitle
              icon={<img src={tournament_active} alt="cartas" />}
              title={"Próximos Torneios"}
            />
            <Table
              columnsWithOutMemo={columnsNextTournaments}
              dataWithOutMemo={nextsTournamentsData}
            />
          </div>
        ) : (
          <>
            <NoTournaments>
              <SectionTitle
                icon={<img src={tournament_active} alt="cartas" />}
                title={"Próximos Torneios"}
              />
              <h1>Não há registros de torneios para se inscrever!</h1>
            </NoTournaments>
          </>
        ))}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onCloseModal={() => setIsModalOpen(false)}
          classStatus="warning"
        >
          <SectionTitle title={"INSCRIÇÃO"} />
          <br />
          <span>
            Deseja realizar a inscrição no torneio{" "}
            {currentTournamentDescription ?? ""}?
          </span>
          <br />
          <div className="buttons-style">
            {!loading && (
              <Button
                classButton={"small"}
                eventCallback={() => handleSubscribe()}
                label={"SIM"}
              />
            )}
            {!loading && (
              <Button
                classButton={"small"}
                eventCallback={(e: any) => setIsModalOpen(false)}
                label={"CANCELAR"}
              />
            )}
          </div>
        </Modal>
      )}
      {isModalErrorOpen && (
        <Modal
          isOpen={isModalErrorOpen}
          onCloseModal={() => setIsModalErrorOpen(false)}
          classStatus="error"
          title="Erro"
          text={error}
        />
      )}

      {isModalSuccessOpen && (
        <Modal
          isOpen={isModalSuccessOpen}
          onCloseModal={() => setIsModalSuccessOpen(false)}
          classStatus="success"
          title="Sucesso"
          text={"Inscrição realizada com sucesso!"}
        />
      )}
    </TableNextsTournamentsStyle>
  );
};

export default TableNextsTournaments;
