import { InputProps } from '../../interfaces/GeneralInterfaces';
import React, { ChangeEvent, useCallback } from 'react';
import { ErrorMessage, useField } from 'formik'
import { InputFieldStyle } from './style';
import InputMask from 'react-input-mask';

const InputField: React.FC<InputProps> = ({ label, className, onChangeCallback, mask, money, ...props }) => {

    const [field, meta, helpers] = useField(props);
    const onChangeValue = (e: ChangeEvent<any>) => {
        const val = e.target.value;
        helpers.setValue(val);
        onChangeCallback && onChangeCallback(val);
    }

    const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        let value = e.currentTarget.value;
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d)(\d{2})$/, "$1,$2");
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
        helpers.setValue(value);
    }, [])
    
    if (money) {
        return (
            <InputFieldStyle className={(className ? className : '') + ' input-block' + (meta.error ? ' input-error' : ' ')}>
                <label htmlFor={field.name}>{label}</label>
                <input {...field} {...props} onChange={onChangeValue} onKeyUp={handleKeyUp} />
                { meta.error && <div className="error-message"><ErrorMessage name={field.name} /> </div>}
            </InputFieldStyle>
        )
    }

    return (
        <InputFieldStyle className={(className ? className : '') + ' input-block' + (meta.error ? ' input-error' : ' ')}>
            <label htmlFor={field.name}>{label}</label>
            { mask ? <InputMask {...field} {...props} onChange={onChangeValue} mask={mask} /> : <input {...field} {...props} onChange={onChangeValue} />}
            { meta.error && <div className="error-message"><ErrorMessage name={field.name} /> </div>}
        </InputFieldStyle>
    )
}

export default InputField;