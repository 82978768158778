import styled from 'styled-components'

export const LogoStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        margin: 0 2rem;
        // filter: brightness(0) invert(100%);
    }
    
    .be-a-manager{ 
        margin: 5rem 0;
    }
`