import React from 'react';

import { ITabPane } from '../../../interfaces/GeneralInterfaces';

import TabPaneStyle from './style';

const TabPane: React.FC<ITabPane> = ({
    activeTab,
    label,
    onClick,
    children,
}) => {
    const onClickTab = () => {
        onClick && onClick(label);
    }

    return (
        <TabPaneStyle type="button" onClick={onClickTab} className={`tab-pane${activeTab === label ? ' current-tab' : ''}`}>
            {label}
        </TabPaneStyle>
    );
}

export default TabPane;