const getScript = (src: string, callback: any) => {
    const script = document.createElement('script');

    script.src = src;
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
        callback(script);
    };
}

export {
    getScript,
}