import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ButtonStyle = styled.button`
    position: relative;
    display: inline-block;
    transition: opacity .3s;
    text-decoration: inherit;

    .content{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.3rem 4.5rem;
        background-color: var(--color-white);
        position: relative;
        z-index: 5;
        transition: color .3s, background-color .3s, opacity .3s;
    }

    .left-icon{
        margin-right: 1rem;

        svg{
            transition: fill .3s;
        }
    }

    .text-button{
        font-size: 1.6rem;
        font-family: var(--font-secondary-bold);
        transition: color .3s;
        margin: 0;
    }

    .loader{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        opacity: 0;
        transition: opacity .3s;
    }

    &.loading{
        .content .text-button{
            opacity: 0;
        }

        .loader{
            opacity: 1;
        }
    }

    &:disabled{
        opacity: .9;
        cursor: not-allowed;
    }

    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(5px, 5px);
        transition: transform .3s;
        z-index: 1;
    }

    &.purple{
        border: 2px solid var(--color-purple);

        .left-icon{
            svg{
                color: var(--color-purple);
                fill: var(--color-purple);
            }
        }

        .text-button{
            color: var(--color-purple);
        }

        &:before{
            background-color: var(--color-purple);
        }

        &:not(:disabled):not(.loading):hover{
            .content{
                background: var(--color-purple); //esse aqui   
            }
            .text-button {
                color: var(--color-white);           
            }

        &:before{
            transform: translate(0px, 0px);
        }

            .left-icon{
                svg{
                    fill: var(--color-white);
                }
            }

            .text-button{
                color: var(--color-white);
            }

        &.small{
            box-shadow: none;
        }
        }
    }
    /* @media (max-width: 1200px) {
        // pegar estrutura .content tira span, bota imagem
        display: none; //escrito etc visibility: hidden;
        &.becameIcon {             
            background: url('../../assets/images/logout.png') no-repeat right center;
    }
    } */

    &.green{
        border: 2px solid var(--color-green);

        .left-icon{
            svg{
                color: var(--color-green);
                fill: var(--color-green);
            }
        }
        .text-button{
            color: var(--color-green);
        }

        &:before{
            background-color: var(--color-green);
        }

        &:not(:disabled):not(.loading):hover{
            .content{
                background: var(--color-green);
            }
            .text-button{
            color: var(--color-white);
        }
        &:before{
            transform: translate(0px, 0px);
        }

            .left-icon{
                svg{
                    fill: var(--color-white);
                }
            }

            .text-button{
                color: var(--color-white);
            }

        &.small{
            box-shadow: none;
        }
        }
    }

    &.small{
        .content {
            padding: .3rem 1rem;
        }

        .text-button{
            font-size: 1.2rem;
        }

        .left-icon{
            margin-right: .6rem;

            svg{
                width: .7rem;
            }
        }

        &.purple{
            &:before{
                background-color: var(--color-purple);
            }
        }

        &.green{
            &:before{
                background-color: var(--color-green);
            }
        }
    }

    &:not(:disabled):not(.loading):hover{
        color: var(--color-white);

        &:before{
            transform: translate(0px, 0px);
        }

        .left-icon{
            svg{
                fill: var(--color-white);
            }
        }

        .text-button{
            color: var(--color-white);
        }

        &.small{
            box-shadow: none;
        }
    }
`

export const LinkStyle = styled(Link)`
    position: relative;
    display: inline-block;
    transition: opacity .3s;
    text-decoration: inherit;

    .content{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.3rem 4.5rem;
        background-color: var(--color-white);
        position: relative;
        z-index: 5;
        transition: color .3s, background-color .3s, opacity .3s;
    }

    .left-icon{
        margin-right: 1rem;

        svg{
            transition: fill .3s;
        }
    }

    .text-button{
        font-size: 1.6rem;
        font-family: var(--font-secondary-bold);
        transition: color .3s;
    }

    .loader{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        opacity: 0;
        transition: opacity .3s;
    }

    &.loading{
        .content .text-button{
            opacity: 0;
        }

        .loader{
            opacity: 1;
        }
    }

    &:disabled{
        opacity: .9;
        cursor: not-allowed;
    }

    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(5px, 5px);
        transition: transform .3s;
        z-index: 1;
    }

    &.purple{
        border: 2px solid var(--color-purple);

        .left-icon{
            svg{
                color: var(--color-purple);
                fill: var(--color-purple);
            }
        }

        .text-button{
            color: var(--color-purple);
        }

        &:before{
            background-color: var(--color-purple);
        }

        &:not(:disabled):not(.loading):hover{
            .content{
                background: var(--color-purple);
            }
        }
    }

    &.green{
        border: 2px solid var(--color-green);

        .left-icon{
            svg{
                color: var(--color-green);
                fill: var(--color-green);
            }
        }

        .text-button{
            color: var(--color-green);
        }

        &:before{
            background-color: var(--color-green);
        }

        &:not(:disabled):not(.loading):hover{
            .content{
                background: var(--color-green);
            }
        }
    }

    &.small{
        padding: .5rem 3rem;

        .text-button{
            font-size: 1.2rem;
        }

        .left-icon{
            margin-right: .6rem;

            svg{
                width: .7rem;
            }
        }

        &.purple{
            &:before{
                background-color: var(--color-purple);
            }
        }

        &.green{
            &:before{
                background-color: var(--color-green);
            }
        }
    }

    &:not(:disabled):not(.loading):hover{
        color: var(--color-white);

        &:before{
            transform: translate(0px, 0px);
        }

        .left-icon{
            svg{
                fill: var(--color-white);
            }
        }

        .text-button{
            color: var(--color-white);
        }

        &.small{
            box-shadow: none;
        }
    }
`