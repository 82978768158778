import styled from 'styled-components'

export const UsersStyle = styled.section`
    background:#FFF;
    min-height:500px;

    .container-accordion{
      margin-bottom:30px;
    }

    @media 
    only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px)  {
      word-break: break-all;
      
      /*
      Label the data
      */
      td:nth-of-type(1):before { content: "ID Jogador"; }
      td:nth-of-type(2):before { content: "Username"; }
      td:nth-of-type(3):before { content: "Jogador"; }
      td:nth-of-type(4):before { content: "E-mail"; }
      td:nth-of-type(5):before { content: "Telefone"; }
      td:nth-of-type(6):before { content: "Documento"; }
      td:nth-of-type(7):before { content: "Organizador"; }
}
    
`
