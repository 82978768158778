import styled from 'styled-components'

export const HeaderActionsStyle = styled.div`
    display:flex;
    align-items:center;
    
    button{
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: #FFF;
        position: relative;
        margin-left: 1rem;

        &.auth{
            background-color: var(--color-green);
        }
    }

    img{
        margin-top: 4px;
        margin-left: 1px;
    }
`