import { useState, useEffect, useContext } from 'react'
import { ApprovingManagerStyle } from './style'
import BasePage from '../../components/BasePage'
import SectionTitle from '../../components/SectionTitle'
import Cards from '../../assets/svg/Cards';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import { AuthContext } from '../../contexts/UserContext';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import AnsweredTest from './AnsweredTest';
import { AccordionBase } from '../../components/Accordion';

interface ParamTypes {
    id: string;
}

const ApprovingManager = () => {
    const history = useHistory()
    const [league, setLeague] = useState({} as any);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [isModalFailOpen, setIsModalFailOpen] = useState<boolean>(false);
    const { loading } = useContext(AuthContext)
    let { id } = useParams<ParamTypes>();

    useEffect(() => {
        const getLeague = async () => {
            try {
                const response = await api.get(`leagues/${id}`);
                setLeague(response.data);
            } catch (e: any) {
                history.push('/pagina-nao-encontrada');
            }
        }

        if (!loading) {
            getLeague();
        }

    }, [loading]);

    const handleManager = async (status: string) => {
        try {
            await api.post(`leagues/${id}/changeStatus`, { status });

            if (status === "recused") {
                setSuccessMessage("A liga foi recusada");
            } else if (status === "approved") {
                setSuccessMessage("A liga foi aprovada");
            }

            setIsModalSuccessOpen(true);

        } catch (err: any) {
            setIsModalFailOpen(true);
        }
    }

    const renderForHowLong = (value: string) => {
        if (value === 'lessThen6months') { 
            return 'Menos de 6 meses'
        } else if (value === 'from6To1Year'){ 
                return 'De 6 meses a 1 ano'
        } else if (value === 'from1yearTo3Years') {
                return 'De 1 ano a 3 anos'
        } else if (value === 'moreThen3Years') {
            return 'Mais de 3 anos'
        }
    }

    const handleCloseModal = () => {
        setIsModalSuccessOpen(false);
        history.push('/administrador?section=2');
    }
    return (

        <BasePage title={'Aprovação de organizador'} subtitle={`Formulário do usuário: ${league?.leagueManagerName}`}>
            <ApprovingManagerStyle>
                {league &&
                    <>
                        <AccordionBase sectionTitle={<SectionTitle title="Questionário" />} isOpen={true}>
                            <AnsweredTest userId={league?.leagueManagerId} />
                        </AccordionBase>
                        <br /><br />
                    </>
                }

                {
                    league && league?.leagueName && (
                        <>
                            <div className="playerData">
                                <SectionTitle title="Dados do Jogador" icon={<Cards />} />
                                <ul className="list-infos">
                                    <li><strong>Capacidade de jogadores no espaço: </strong> {league.maxPlayers} jogadores</li>
                                    <li><strong>Nome da Liga: </strong> {league.leagueName}</li>
                                    <li><strong>Já possuiu uma liga de truco? </strong> {league.alreadyManager ? 'Sim' : 'Não'}</li>
                                    {league.alreadyManager && <li><strong>Por quanto tempo? </strong> {renderForHowLong(league.timeManaging)} </li>}
                                    <li><strong>Estado:</strong> {league.state.name}</li>
                                    <li><strong>Cidade:</strong> {league.city.name}</li>
                                    <li><strong>Bairro:</strong> {league.neighborhood}</li>
                                    <li><strong>CEP:</strong> {league.zipCode}</li>
                                    <li><strong>Rua:</strong> {league.street}</li>
                                    <li><strong>Número:</strong> {league.streetNumber}</li>
                                    <li><strong>Descrição:</strong> {league.description}</li>
                                    <li><strong>Nome do organizador:</strong> {league.leagueManagerName}</li>
                                    <li><strong>E-mail do organizador:</strong> {league.leagueManagerEmail}</li>
                                    <li><strong>Telefone do organizador:</strong> {league.leagueManagerPhone}</li>
                                </ul>
                                <br /><br /><br />
                                <h2>Imagens</h2>
                                <div className="photos">
                                    {league.fileNames && league.fileNames.map((file: any, index: number) => {
                                        return (
                                            <div className="item" key={index}>
                                                <img src={`${process.env.REACT_APP_API_URL}/media/league/${file}`} alt="truco"/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="actions_admin">
                                <Button classButton="finish-button" eventCallback={() => handleManager("recused")} label="REPROVAR" type={'button'} />
                                {/* <Button classButton="finish-button" eventCallback={() => handleManager("approved")} label="APROVAR" type={'button'} /> */}
                            </div>
                        </>
                    )
                }
                {isModalSuccessOpen && (
                    <Modal
                        isOpen={isModalSuccessOpen}
                        onCloseModal={handleCloseModal}
                        classStatus='success'
                        title="Sucesso"
                        text={successMessage}
                    />
                )}

                {isModalFailOpen && (
                    <Modal
                        isOpen={isModalFailOpen}
                        onCloseModal={() => setIsModalFailOpen(false)}
                        classStatus='error'
                        title="Erro"
                        text={'Liga já analisada ou algo de errado aconteceu!'}
                    />
                )}

            </ApprovingManagerStyle>
        </BasePage>

    )
}

export default ApprovingManager;