import styled from 'styled-components'

export const TableRankingMatchesStyle = styled.div`
    margin-top: 2rem;

    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
        td:nth-of-type(1):before { content: "Time"; }
        td:nth-of-type(2):before { content: "Jogadores"; }
        td:nth-of-type(3):before { content: "Colocação"; }
        td:nth-of-type(3):before { content: "Pontos"; }
        td:nth-of-type(3):before { content: "Votórias"; }
        td:nth-of-type(3):before { content: "Derrotas"; }
        td:nth-of-type(3):before { content: "Empates"; }
    }
    
`
