import React from 'react';
import TimelineStyle from './style';

import { ITimeline, ITimelineStep } from '../../interfaces/GeneralInterfaces';

const Timeline: React.FC<ITimeline> = ({
    steps,
}) => {
    const renderStep = (step: ITimelineStep, key: number) => {
        return (
            <div key={key} className={`step-item${step.active ? ' current' : ''}`}>
                <span className="step-dot"></span>
                <span className="name">{step.name}</span>
            </div>
        );
    }

    return (
        <TimelineStyle>
            {steps.map((step, key) => renderStep(step, key))}
        </TimelineStyle>
    );
}

export default Timeline;