import { useState, useEffect, useContext, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom';
import BasePage from '../../components/BasePage';
import Button from '../../components/Button';
import SectionTitle from '../../components/SectionTitle'
import TablePagination from '../../components/TablePagination';
import { AuthContext } from '../../contexts/UserContext';
import api from '../../services/api';
import { QuizStyle } from './style';
import ModalConfirm from '../../components/ModalConfirm'
import TabPane from '../../components/Tabs/TabPane';
import Tabs from '../../components/Tabs/Tabs';
import Answers from './Answers';


interface IQuizResponse {
    id: string;
    name: string;
    category: string;
    status: boolean;
}

interface IQuizResponseForTable {
    name: string;
    status: string;
    category: string;
    actions: any;
}



const QuizPageAdmin = () => {
    const history = useHistory();
    const [quiz, setQuiz] = useState<IQuizResponse[]>([]);
    const [quizForTable, setQuizForTable] = useState<IQuizResponseForTable[]>([])
    const [total, setTotal] = useState<number>(0);
    const [quizId, setQuizId] = useState<number>(0);
    const [showDeleteQuizModal, setShowDeleteQuizModal] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const { userInfo, loading } = useContext(AuthContext);

    const renderButtonActions = (values: any) => {

        return (
            <>
                {values.value[3] === "BeManagerTest" &&
                    <Button
                        classButton={'small'}
                        label={values.value[4]}
                        eventCallback={(e: any) => { history.push(`reportQuestionario/${values.value[2]}`) }}
                    />
                }
                <Button
                    classButton={'small'}
                    label={values.value[0]}
                    eventCallback={(e: any) => { history.push(`/questionario-admin/${values.value[2]}`) }}
                />
                {values.value[3] !== "BeManagerTest" &&
                    <Button
                        classButton={'small'}
                        label={values.value[1]}
                        eventCallback={(e: any) => handleConfirmRemoveModal(values.value[2])}
                    />
                }
            </>
        )

    }
    const columnsTicketsTable = [
        {
            Header: 'NOME',
            accessor: 'name',
            sortType: 'caseInsensitive'
        },
        {
            Header: 'CATEGORIA',
            accessor: 'category',
            sortType: 'caseInsensitive'
        },
        {
            Header: 'STATUS',
            accessor: 'status',
            sortType: 'caseInsensitive'
        },
        {
            Header: '',
            accessor: 'actions',
            Cell: renderButtonActions
        }

    ];

    const getStatus = (status: boolean) => {
        return status ? 'Ativo' : 'Oculto'
    }

    const translateCategory = (category: string): string => {

        let translatedCategory: string = '';
        switch (category) {
            case 'Test':
                translatedCategory = "Prova";
                break;
            case 'Quiz':
                translatedCategory = "Questionário";
                break;
            case 'ManagerQuiz':
                translatedCategory = "Questionário de Organizador";
                break;
            case 'BeManagerTest':
                translatedCategory = "Prova de Organizador";
                break;
            default:
                translatedCategory = "Questionário";
        }


        return translatedCategory;
    }

    const handleConfirmRemoveModal = (quizId: number) => {
        setQuizId(quizId);
        setShowDeleteQuizModal(true)
    }

    const handleRemoveQuiz = async (quizId: number) => {
        const response = await api.delete(`forms/${quizId}`)
        if (response.data.length > 0) {
            setQuiz(response.data)
        } else {
            setQuiz([])
            setTotal(0);
        }
        setShowDeleteQuizModal(false)
    }

    const handleCloseConfirmModal = () => {
        setShowDeleteQuizModal(false)
    }

    const getQuiz = useCallback(async () => {
        const response = await api.get('forms')
        setQuiz(response.data);
        setTotal(response.data.length);
    }, [page])

    useEffect(() => {
        if (!loading)
            getQuiz()

    }, [loading, getQuiz])

    useEffect(() => {
        const prepareDateForTicketsTable = () => {
            let data: IQuizResponseForTable[] = [];
            if (quiz) {
                quiz.map((quiz: IQuizResponse) => {
                    return data.push({
                        status: getStatus(quiz.status),
                        name: quiz.name,
                        category: translateCategory(quiz.category),
                        actions: ['Editar', 'Excluir', quiz.id, quiz.category, 'Report'],
                    })
                })

                setQuizForTable(data);
            }
        }

        if (userInfo().isAdmin) {
            prepareDateForTicketsTable();
        }

    }, [quiz])

    return (
        <BasePage title="Questionários" subtitle={``}>
            <QuizStyle>
                {quiz.length > 0 && (
                    <Tabs>
                        <TabPane label="QUESTIONÁRIOS">
                            <>
                                <SectionTitle title="Questionários" />
                                <TablePagination
                                    perPage={perPage}
                                    setPage={setPage}
                                    setPerPage={setPerPage}
                                    currentpage={page}
                                    totalPage={Math.ceil(total / perPage)}
                                    columnsWithOutMemo={columnsTicketsTable}
                                    dataWithOutMemo={quizForTable} />
                                <Link to={"/cria-questionario"}>
                                    <Button label="Novo Questionário" type="button" />
                                </Link>

                                <ModalConfirm
                                    title="Deseja excluir o questionário?"
                                    isOpen={showDeleteQuizModal}
                                    handleConfirm={() => handleRemoveQuiz(quizId)}
                                    handleCloseConfirm={() => handleCloseConfirmModal()}
                                />
                            </>
                        </TabPane>
                        <TabPane label="RESPOSTAS">
                            <Answers />
                        </TabPane>
                    </Tabs>
                )}
            </QuizStyle>
        </BasePage>
    )
}

export default QuizPageAdmin;