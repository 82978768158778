import React, { useEffect, useContext, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { ILeagueFormValues } from "../interface";
import useCommonRequisitions from "../../../hooks/useCommonRequisitions";
import "react-toastify/dist/ReactToastify.css";

import { AuthContext } from "./../../../contexts/UserContext";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import SelectField from "../../../components/SelectField";
import Modal from "../../../components/Modal";
import Textarea from "../../../components/Textarea";
import { IStates, ICities } from "../../../interfaces/GeneralInterfaces";
import BeManagerRequisitions from "../services";
import UploadImageField from "../../../components/UploadImage";
import { Step2Style } from "./style";
import ModalBeAManager from "../ModalBeAManager";
import { useHistory } from "react-router-dom";
import ErrorFocus from "../../../components/ErrorFocus";

const LeagueSchema = Yup.object().shape({
  maxPlayers: Yup.number()
    .integer()
    .min(1, "O número máximo de jogadores na liga deve ser maior que um")
    .required("Informe a capacidade de jogadores"),
  alreadyManager: Yup.string().required("Informe se já foi moderador"),
  timeManaging: Yup.string(),
  leagueName: Yup.string().required("Informe o nome da liga"),
  zipCode: Yup.string(),
  uf: Yup.string().required("Informe seu estado"),
  city: Yup.string().required("Informe sua cidade"),
  neighborhood: Yup.string().required("Informe seu bairro"),
  street: Yup.string().required("Informe sua rua"),
  streetNumber: Yup.string().required("Informe o número do seu endereço"),
  additionalAddressInfo: Yup.string(),
  description: Yup.string().required("Informe a descrição"),
});

const Step2 = () => {
  const { handleManagerStatus } = useContext(AuthContext);
  const { getStates, getCities, getAddressByZipcode } = useCommonRequisitions();
  const { SubmitNewLeague } = BeManagerRequisitions();
  const [states, setStates] = useState<IStates[]>([]);
  const [cities, setCities] = useState<ICities[]>([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [stateSelected, setStateSelected] = useState<any>("");
  const history = useHistory();

  const handleRemoveImage = (file: any, formik: any) => {
    const newPhotos = uploadedImages.filter((item) => item !== file);
    setUploadedImages(newPhotos);
    formik.setFieldValue("photos", newPhotos);
  };

  const registerInitialValues: ILeagueFormValues = {
    maxPlayers: 0,
    alreadyManager: "",
    timeManaging: "",
    leagueName: "",
    zipCode: "",
    uf: "",
    stateId:0,
    city: "",
    neighborhood: "",
    street: "",
    streetNumber: 0,
    additionalAddressInfo: "",
    photos: [],
    description: "",
  };

  const submitLeague = async (values: any, callback: Function) => {
    const response = await SubmitNewLeague(values);

    if (response.status) {
      setIsModalOpen(true);
      handleManagerStatus("pending");
      history.push(`/organizador`);
    } else {
      setIsModalErrorOpen(true);
      setError(response.message);
    }

    callback();
  };

  useEffect(() => {
    async function getState() {
      const populatedStates = await getStates();
      setStates(populatedStates);
    }
    getState();
  }, []);

  useEffect(() => {
    async function getCity() {
      const cities = await getCities(stateSelected);
      setCities(cities);
    }

    if (stateSelected) {
      getCity();
    }
  }, [stateSelected]);

  return (
    <Step2Style>
      <Formik
        initialValues={registerInitialValues}
        validationSchema={LeagueSchema}
        enableReinitialize
        onSubmit={(
          values: ILeagueFormValues,
          { setSubmitting, resetForm }: FormikHelpers<ILeagueFormValues>
        ) => {
          let data = new FormData();

          Object.entries(values).forEach((item) => {
            if (item[0] === "photos") {
              item[1].forEach((e: any) => {
                data.append("files", e);
              });
            } else {
              data.append(item[0], item[1]);
            }
          });

          submitLeague(data, function () {
            setSubmitting(false);
            // resetForm({})
          });
        }}
      >
        {(formik) => {
          return (
            <div id="league-form">
              <Form className="common-form">
                <ErrorFocus />
                <InputField
                  name="maxPlayers"
                  label="Qual a capacidade de jogadores no espaço?"
                  type="number"
                  className="twoColumns"
                />

                <InputField
                  name="leagueName"
                  label="Nome da Liga"
                  type="text"
                  className="twoColumns"
                />

                <SelectField
                  label="Você já realizava liga de truco (encontro de jogadores de truco)?"
                  name="alreadyManager"
                  options={[
                    {
                      value: "",
                      label: "Selecione",
                    },
                    {
                      value: true,
                      label: "Sim",
                    },
                    {
                      value: false,
                      label: "Não",
                    },
                  ]}
                  placeholder="Selecione"
                />

                {formik.values.alreadyManager && (
                  <SelectField
                    label="Quanto tempo?"
                    name="timeManaging"
                    options={[
                      {
                        value: "lessThen6months",
                        label: "Menos de 6 meses",
                      },
                      {
                        value: "from6To1year",
                        label: "De 6 meses a 1 ano",
                      },
                      {
                        value: "from1yearTo3Years",
                        label: "De 1 ano a 3 ano",
                      },
                      {
                        value: "moreThen3Years",
                        label: "Mais de 3 anos",
                      },
                    ]}
                    placeholder="Selecione"
                  />
                )}

                <InputField
                  name="zipCode"
                  label="CEP"
                  type="text"
                  mask="99999-999"
                  onChangeCallback={async (value) => {
                    const zipcodeClean = value.replace(/\D/g, "");
                    formik.setFieldValue("zipCode", zipcodeClean);
                    if (zipcodeClean.length === 8) {
                      let res = await getAddressByZipcode(zipcodeClean);
                      if (res.logradouro) {
                        const state = states.find(
                          (state) => state.label.includes(res.uf) === true
                        );
                        formik.setFieldValue("uf", res.uf);
                        formik.setFieldValue("stateId", state?.value);
                        formik.setFieldValue("city", res.localidade);
                        formik.setFieldValue("neighborhood", `${res.bairro}`);
                        formik.setFieldValue("street", `${res.logradouro}`);
                        setStateSelected(state?.value);
                      }
                    }
                  }}
                />

                {/* <SelectField
                  label="Estado"
                  name="uf"
                  options={states}
                  placeholder="Selecione seu Estado"
                  onChangeCallback={(value: string) => {
                    setStateSelected(value);
                    formik.setFieldValue("city", null);
                  }}
                /> */}
                <SelectField
                  label="Estado"
                  name="stateId"
                  options={states}
                  placeholder="Selecione seu Estado"
                />

                <SelectField
                  id="city"
                  label="Cidade"
                  name="city"
                  options={cities}
                  placeholder="Selecione sua cidade"
                />

                <InputField name="neighborhood" label="Bairro" type="text" />

                <InputField name="street" label="Rua" type="text" />
                <InputField name="streetNumber" label="Número" type="number" />
                <InputField
                  name="additionalAddressInfo"
                  label="Complemento"
                  type="text"
                />

                <UploadImageField
                  name="photos"
                  label="Fotos do local"
                  type="file"
                  onChangeCallbackImage={(photos: any) => {
                    setUploadedImages(photos);
                  }}
                />

                <Textarea
                  name="description"
                  label="Descrição"
                  className="description"
                />

                <div className="grid-photos">
                  {formik.values.photos &&
                    formik.values?.photos.map((file: any, i: number) => (
                      <div className="item" key={i}>
                        <img src={URL.createObjectURL(file)} alt="" />
                        <span onClick={() => handleRemoveImage(file, formik)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
                          </svg>
                        </span>
                      </div>
                    ))}
                </div>

                {isModalErrorOpen && (
                  <Modal
                    isOpen={isModalErrorOpen}
                    onCloseModal={() => setIsModalErrorOpen(false)}
                    classStatus="error"
                    title="Erro"
                    text={error}
                  />
                )}

                {isModalOpen && (
                  <Modal
                    isOpen={isModalOpen}
                    onCloseModal={() => setIsModalOpen(false)}
                    classStatus="error"
                    title="Erro"
                  >
                    <ModalBeAManager />
                  </Modal>
                )}
                {/* <Button classButton="submit-button" hasLoader={true} label="FAZER CADASTRO" type={'submit'} /> */}
                <Button
                  classButton="submit-button"
                  hasLoader={true}
                  showLoading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  label="ENVIAR"
                  type={"submit"}
                />
              </Form>
            </div>
          );
        }}
      </Formik>
    </Step2Style>
  );
};

export default Step2;
