import React from 'react';
import TitleStyle from './style';

import { ITitle } from '../../interfaces/GeneralInterfaces';

const Title: React.FC<ITitle> = ({ customClass, children }) => {
    return (
        <TitleStyle className={customClass}>
            {children}
        </TitleStyle>
    );
}

export default Title;