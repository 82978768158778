import React, { useState, useContext, useEffect, ChangeEvent } from 'react';
import { AuthContext } from './../../../contexts/UserContext';
import { DownloadStyle } from './style';
import * as Yup from "yup";
import axios from 'axios';
import api from '../../../services/api'
import { Formik, Form, Field, ErrorMessage, FormikValues } from 'formik';
// import Button from "../Button";
import SelectField from "../../../components/SelectField";
import ErrorFocus from "../../../components/ErrorFocus"; 
import Button from '../../../components/Button';
import Modal from "../../../components/Modal";

const ManuaisSchema = Yup.object().shape({
    type: Yup.string().required("Informe o usuário que o arquivo vai ser referenciado"),
});

const opcaoDoTipoUsuario = [
    { value: 'jogador', label: "Jogador" },
    { value: 'organizador', label: "Organizador" },
];

const ManuaisDownload = () => {
    const { loading } = useContext(AuthContext);
    const [type, setType] = useState('');
    const [content, setContent] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [returnMessage, setReturnMessage] = useState("");
    const [returnStatus, setReturnStatus] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [initialFile, setInitialFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any) => void) => {
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
            const selectedFile = event.currentTarget.files[0];
            setFile(selectedFile);
            setFieldValue('file', selectedFile); 
            setSelectedFileName(selectedFile.name); 
        }
    };

    const handleSubmit = async (values: FormikValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        if (file) {
            const formData = new FormData();
            const fileName = `${type}-${file.name}`;
            formData.append('type', values.type);
            formData.append('file_name', file.name);
            formData.append('file_path', file, fileName);

            try {
                if (values.type === 'jogador') {
                    await api.post('manuais/uploadPdf/jogador', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                
                } else if (values.type === 'organizador') {
                    await api.post('manuais/uploadPdf/organizador', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                }
                
                setReturnStatus(true);
                setReturnMessage("Campos salvos com sucesso!");
                setIsModalOpen(true);
            } catch (error) {
                setReturnMessage(" erro: " + error);
                setIsModalOpen(true);
            }
        } else {
            setReturnMessage(" Nenhum arquivo selecionado! ");
            setIsModalOpen(true);
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
      };
    return (
        <DownloadStyle>
            {loading && (
                <div className="">Carregando...</div>
            )}
            <div>
                <h1>Formulário para inserir manuais de Boas Práticas</h1><br /> <br />
                <Formik
                    initialValues={{ type: '', file: initialFile }}
                    validationSchema={ManuaisSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <ErrorFocus />
                            <div className="filter_selects">
                            <SelectField
                                label="Selecione o tipo de usuário"
                                name="type"
                                options={opcaoDoTipoUsuario}
                                placeholder="Selecione o tipo de usuários"
                            />
                            </div>
                            <br />
                            <label className='custom-title-campos'>  Upload de arquivo: </label>
                                <label className="custom-file-upload">
                                    <input type="file" onChange={(event) => handleFileChange(event, setFieldValue)}  />
                                    <span className="file-btn"></span>
                                    <span className="file-selected">{selectedFileName || 'Nenhum arquivo escolhido'}</span>
                                </label>
                            <ErrorMessage name="file" component="div" />
                            {isModalOpen && (
                            <Modal
                              isOpen={isModalOpen}
                              onCloseModal={handleClose}
                              classStatus={returnStatus ? "success" : "error"}
                              title={returnStatus ? "Sucesso!" : "Opss!"}
                              text={returnMessage}
                            />
                          )}
                            <br />
                            <br />
                            <Button type="submit" label="Enviar" disabled={isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </div>
        </DownloadStyle>
    )
}

export default ManuaisDownload;
