import { useState, useEffect, useContext } from "react";
import FidelityProgramStyle from "./style";
import ReactLoading from "react-loading";
import PointsWidget from "../../../components/PointsWidget";
import LoyaltyPoints from "../../../assets/svg/LoyaltyPoints";
import RedemPoints from "../../../assets/svg/RedemPoints";
import { AccordionBase } from "../../../components/Accordion";
import SectionTitle from "../../../components/SectionTitle";
import Text from "../../../components/Text";
import TableEarnedPoints from "../../../components/TableEarnedPoints";
import TableRedeemedPoints from "../../../components/TableRedeemedPoints";
import { AuthContext } from "../../../contexts/UserContext";
import api from "../../../services/api";
import fidelity_active from "../../../assets/images/fidelity_active.png";

const FidelityProgram = () => {
  const { loading } = useContext(AuthContext);
  const [orders, setOrders] = useState<number>();
  const [userPoints, setUserPoints] = useState<number>();
  const [fetched, setFetched] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     if (!loading) {
  //       try {
  //         const responseUserPoints = await api.get("users/trocapontos");
  //         const responseOrders = await api.get("users/trocapontos/orders");

  //         let count = 0;

  //         if (responseOrders.data) {
  //           count = responseOrders.data.data
  //             .map((items: any) => items.order_total)
  //             .reduce((acc: number, value: number) => {
  //               return acc + value;
  //             });
  //         }
  //         setOrders(count);
  //         setUserPoints(
  //           responseUserPoints?.data?.balance
  //             ? responseUserPoints.data.balance
  //             : 0
  //         );
  //       } catch (e: any) {
  //         setOrders(0);
  //         setUserPoints(0);
  //         console.log(e);
  //       }
  //     }
  //   })();
  // }, [loading]);

  useEffect(() => {
    const ac = new AbortController();
    if (!loading) {
      Promise.all([
        api.get("users/trocapontos"),
        api.get("users/trocapontos/orders"),
      ]).then((data) => {  
                let count = 0;
                let responseOrders = data[1];
                let responseUserPoints = data[0];
                if (responseOrders.data) {
                  count = responseOrders.data.data
                    .map((items: any) => items.order_total)
                    .reduce((acc: number, value: number) => {
                      return acc + value;
                    });
                }
                setOrders(count);
                setUserPoints(
                            responseUserPoints?.data?.balance
                              ? responseUserPoints.data.balance
                              : 0
                          );            
              })
      .catch(ex => {
        setOrders(0);
        setUserPoints(0);
        console.error(ex)});
    }
    
    return () => ac.abort(); // Abort both fetches on unmount
  }, []);

  return (
    <FidelityProgramStyle>
      <div className="points-widgets">
        <PointsWidget
          icon={<LoyaltyPoints />}
          points={
            userPoints ?? (
              <ReactLoading
                type="bars"
                color="#2c1266"
                height={50}
                width={50}
              />
            )
          }
          text="Pontos Programa de Recompensa"
        />
        <PointsWidget
          icon={<RedemPoints />}
          points={
            orders ?? (
              <ReactLoading
                type="bars"
                color="#2c1266"
                height={50}
                width={50}
              />
            )
          }
          text="Pontos Resgatados"
        />
      </div>
      <br />
      <AccordionBase
        sectionTitle={
          <SectionTitle
            title="Tarefa Bônus"
            icon={<img src={fidelity_active} alt="tarefa" />}
          />
        }
        isOpen={true}
      >
        <br />
        <Text>
          <strong>Complete desafios para ganhar mais pontos</strong>
        </Text>
        <Text>
          Quer acumular mais pontuação e acelerar o resgate daquele produto dos
          sonhos? Então, realize as tarefas propostas para seu perfil e
          conquiste mais pontos a cada ação concluída. Participe de nossas
          pesquisas de opinião, ações e testes relacionados à comunidade de
          Truco. Neste espaço, você encontrará todos os desafios disponíveis
          para realização, em <strong>Desafios Disponíveis</strong>, bem como
          prazo limite para realizá-los e pontuação atribuída para cada um. No{" "}
          <strong>Histórico de Programa de Recompensa</strong> é possível
          visualizar todas as tarefas realizadas e pontos acumulados. Realize as
          tarefas, destrave mais pontos e aproveite todos os benefícios da
          plataforma.
          <br />
          <br />
        </Text>
      </AccordionBase>

      <br />

      <div className="pointsHistoric">
        <TableEarnedPoints />
        <TableRedeemedPoints />
      </div>
    </FidelityProgramStyle>
  );
};

export default FidelityProgram;
