import api from "../../../../services/api";

interface IAccountFormValues {
  firstName: string;
  lastName: string;
  zipCode?: string;
  uf: string;
  city: string;
  neighborhood: string;
  street: string;
  streetNumber: string;
  additionalAddressInfo?: string;
  username: string;
  email: string;
  doc: string;
  birthDate: string;
  phone: string;
  gender: string;
  appUsername?: string;
}

export default function UserRequisitions() {
  async function SubmitUpdateUser(user: IAccountFormValues, userId: number) {
    try {
      const response = await api.put(`users/user/${userId}`, user);

      if (response.status !== 200) {
        return {
          status: false,
          message: response.data.message,
        };
      } else {
        return {
          status: true,
          message: "Usuário editado com sucesso",
        };
      }
    } catch (err: any) {
      if (err.response) {
        return {
          status: false,
          message: err.response.data.message,
        };
      } else if (err.request) {
        console.log(err.request);
      }
      return {
        status: false,
        message: "Erro ao alterar",
      };
    }
  }

  return { SubmitUpdateUser };
}
