import styled from 'styled-components'

export const ReportQuizStyle = styled.div`
    .quiz{
        display:flex;
        flex-direction:column;
        margin-top:30px;
        h3 {
            color: var(--color-pink);
        }
        .reproved {
            padding-bottom: 15px;
        }
        .questions{
            margin-bottom:30px;
            padding:20px;
            border:1px solid rgba(0,0,0,0.2);
            h2{
                margin-bottom:10px;
                color:var(--primary);
            }
            .answers{
                display:flex;
                flex-direction:column;
                
               > div{
                    margin-bottom:10px;
                    display:flex;
                    align-items:center;
                    span {
                        color: var(--color-pink);
                        font-weight: bold;
                        font-size: 2rem;
                        padding-left: 1rem;
                    }
                }
            }
        }
    }
`