import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import BadgeUser from '../../assets/svg/BadgeUser';
import { PrintStyle } from "./style";
import SectionTitle from "../SectionTitle";
import Text from "../Text";
import Table from "../Table";
import tournament_active from "../../assets/images/tournament_active.png";
import api from "../../services/api";

interface IPrintInformation {
    name: string;
    fullname: string;
    id: string;
    image: string;
    points: number;
    columns?: any;
    data?: any;
}

export const Print: React.FC<IPrintInformation> = ({
    name,
    fullname,
    id,
    points,
    columns,
    data,
    image,
}) => {
    const cardPrint = useRef(null);
    const [defaultUserImage, setDefaultUserImage] = useState(image);
    const [selectedFile, setSelectedFile] = useState();

    useEffect(() => {
        const submitAvatar = async (selectedFile: File) => {
            let formData = new FormData();
            formData.append("avatar", selectedFile, "test");
            api.post("users/avatar", formData);
        };
        if (selectedFile) {
            const objectURL = URL.createObjectURL(selectedFile);
            setDefaultUserImage(objectURL);
            //submitAvatar(selectedFile);
            return () => URL.revokeObjectURL(objectURL);
        }
    }, [selectedFile]);

    return (
        <PrintStyle>
            <div className="div-badge-user">
                <div className="circle-image">
                    <img className="user-image" src={defaultUserImage} alt={name} />
                </div>
                <SectionTitle customClass="user-name" title={name} />
                <SectionTitle customClass="user-fullname" title={name} />
                <Text customClass="user">ID: {id?.toString().padStart(6, "0")}</Text>
                <div className="ranking">
                    <span className="span-ranking">
                        <img className="icon-ranking" src={tournament_active} alt="torneio" />
                        {data.length > 0 ? (
                            <span>{data.length}º no ranking</span>
                        ) : (
                            <span>Sem ranking</span>
                        )}</span>
                </div>
                <div className="badge-user" ref={cardPrint}><BadgeUser /></div>
            </div>

        </PrintStyle>
    )
}
