import { ITournament } from '../../../interfaces/GeneralInterfaces';
import Title from '../../../components/Title';
import Text from '../../../components/Text';
import SectionTitle from '../../../components/SectionTitle'
import { WaitingForCopagAnswerStyle } from './style';
import Timeline from '../../../components/Timeline';
import { renderDateFormated, renderFormatedHour } from '../../../utils/formatData';

interface IWaitingForCopagAnswerProps {
    tournament: ITournament;
}

const WaitingForCopagAnswer = ({ tournament }: IWaitingForCopagAnswerProps) => {
    const renderCategory = (values: any) => {
        if (values === 'Tournament') {
            return 'Torneio';
        } else if (values === 'TrucoCup') {
            return 'Copa Truco';
        } else {
            return 'Estadual Copag';
        }
    }

    const timelineSteps = [
        {
            name: '3 meses ou 8 torneios com no mínimo 4 duplas e no máximo 4 Copas Truco por mês.',
            active: true,
        },
        {
            name: 'Todos torneios agendados devem ter realizado o report correto durante este período.',
            active: true,
        },
        {
            name: 'Envio de notificação para avaliação da solicitação de Copa Truco.',
            active: true,
        },
        {
            name: 'Resposta da Copag.',
            active: false,
        }
    ];

    return (
        <WaitingForCopagAnswerStyle>
            <Title customClass={'tournament-title'}>{tournament?.name ?? "Copa Truco"}</Title>
            <Text>
                {tournament?.leagueName ?? ""}
            </Text>
            <br />
            <Text>
                {tournament?.description ?? ''}
            </Text>
            <br />
            <div className="tournaments-informations">
                <ul className="list-infos">
                    <li><strong>DATA:</strong> {renderDateFormated(tournament.date)} </li>
                    <li><strong>HORÁRIO:</strong> {renderFormatedHour(tournament.date)} </li>
                    <li><strong>TIPO DE PARTIDA:</strong> {tournament.modality} </li>
                    <li><strong>ESTADO:</strong> {tournament?.state?.name ?? "Torneio Online"} </li>
                    <li><strong>CIDADE:</strong> {tournament?.city?.name ?? "Torneio Online"} </li>
                    <li><strong>CATEGORIA:</strong> {renderCategory(tournament.category)} </li>
                    <li><strong>DATA CHECKIN:</strong> {renderDateFormated(tournament.checkin)} </li>
                    <li><strong>HORÁRIO CHECKIN:</strong> {renderFormatedHour(tournament.checkin)} </li>
                    <li><strong>FORMATO:</strong> {tournament.format === 'Presential' ? 'Presencial' : 'Online'} </li>
                    <li><strong>MODALIDADE:</strong> {tournament.modality === 'TorneioMineiro' ? 'Truco Mineiro' : 'Truco Paulista'} </li>
                    <li><strong>PREÇO:</strong> R$ {tournament.price} </li>
                    <li><strong>DUPLA OU TRIO:</strong> {tournament.teamCount === 2 ? 'Dupla' : 'Trio'} </li>
                    <li><strong>VISIBILIDADE:</strong> {tournament.visibility === 'Public' ? 'Público' : 'Privado'} </li>
                    <li><strong>PRÊMIO:</strong> {tournament?.prize ?? 'Nenhum prêmio informado.'} </li>
                </ul>
            </div>
            <br />
            <SectionTitle customClass="title-create-tournament" title="Condições necessárias para liberar os torneios" />
            <br />
            <Timeline steps={timelineSteps} />
            <br />

        </WaitingForCopagAnswerStyle>
    )
}

export default WaitingForCopagAnswer;