import styled from 'styled-components'

export const TablePastTournamentsStyle = styled.div`
    margin-top: 2rem;

    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
            td:nth-of-type(1):before { content: "Descrição"; }
            td:nth-of-type(2):before { content: "Data"; }
            td:nth-of-type(3):before { content: "Hora"; }
            td:nth-of-type(4):before { content: "Categoria"; }
            td:nth-of-type(5):before { content: "Modalidade"; }
            td:nth-of-type(6):before { content: "Estado"; }
            
        }
`
