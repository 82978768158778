import styled from 'styled-components'

export const TableSubscribedTournamentsStyle = styled.div`
    margin-top:30px;

    @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px)  {
      td:nth-of-type(1):before { content: "Id"; }
      td:nth-of-type(2):before { content: "Data"; }
      td:nth-of-type(3):before { content: "Hora"; }
      td:nth-of-type(4):before { content: "Local"; }
      td:nth-of-type(5):before { content: "Categoria"; }
      td:nth-of-type(6):before { content: "Liga"; }
      td:nth-of-type(7):before { content: "Nome do Evento"; }
    }
`
