import { useState, useContext, useEffect } from 'react';
import { AuthContext } from './../../contexts/UserContext';
import { TableEarnedPointsStyle } from './style';
import Table from '../../components/Table';
import { IActionTrocaPontos, ICreditsTrocaPontos, IResponseExtractTrocaPontos } from '../../interfaces/GeneralInterfaces';
import api from '../../services/api';
import LoyaltyPoints from '../../assets/svg/LoyaltyPoints';
import SectionTitle from '../SectionTitle';

const columnsEarnedPoints = [
    {
        Header: 'DESCRIÇÃO',
        accessor: 'description',
    },
    {
        Header: 'PONTOS GANHOS',
        accessor: 'earnedPoints',
    }
];

interface IExtractDataForTable {
    description: string;
    earnedPoints: number;
}

const TableEarnedPoints = () => {
    const { loading } = useContext(AuthContext);
    const [earnedPoints, setEarnedPoints] = useState<IResponseExtractTrocaPontos[]>({} as IResponseExtractTrocaPontos[])
    const [extractDataForTable, setExtractDataForTable] = useState<IExtractDataForTable[]>({} as IExtractDataForTable[]);

    useEffect(() => {
        const prepareDataForHistoricTable = () => {
            let data: IExtractDataForTable[] = [];
            if (earnedPoints[0] && earnedPoints[0].acoes && earnedPoints[0].acoes.length > 0) {
                earnedPoints[0].acoes.map((action: IActionTrocaPontos) => {
                   return action.creditos.map((credit: ICreditsTrocaPontos) => {
                        return data.push({
                            description: credit.api_orign_name,
                            earnedPoints: Number(credit?.valor ?? 0) * 10
                        })
                    })
                })

                setExtractDataForTable(data);
            }
        }

        prepareDataForHistoricTable();
    }, [earnedPoints])

    useEffect(() => {
        async function getExtract() {
            try{
                const response = await api.get('users/trocapontos/extract')
                setEarnedPoints(response.data)
            } catch(e: any) {
                console.log(e);
            }
        }

        if (!loading)
            getExtract();
    }, [loading])

    return (
        <TableEarnedPointsStyle>
            {loading && (
                <div className="">Carregando...</div>
            )}
            {!loading && (
                extractDataForTable.length > 0 && (
                    <div className="eaner-points">
                        <SectionTitle icon={<LoyaltyPoints color={'var(--primary)'} />} title={'Histórico de Pontos Trocados'} />
                        <Table columnsWithOutMemo={columnsEarnedPoints} dataWithOutMemo={extractDataForTable} />
                    </div>
                )
            )}
        </TableEarnedPointsStyle>
    )
}

export default TableEarnedPoints;