import styled from 'styled-components'

const ResetPasswordStyle = styled.section`
  width:100%;
  height:100vh;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color: var(--primary);

  .title-form{
            color: var(--color-white);
            text-align: center;
            font-size: 4rem;
            font-family: var(--font-secondary-bold);
        }

  .form-content{
            flex-direction: column;
            align-items: center;
            display: flex;
            position: relative;
            padding-right: .5rem;
            transition: transform .3s, opacity .3s;
            form{
              display:flex;
              flex-direction:column;
              align-items:center;
            }
            img{
              margin-bottom:50px;
            }
            h2{
              margin-bottom:50px;
            }
            .input-block{
              width:100%;
              margin-bottom:30px;
                label{
                    color: var(--color-white);
                }
                 &.input-error{
                    color: var(--danger);
                 } 
            }

          
        }

`;

export default ResetPasswordStyle;