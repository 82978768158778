//Assets
import logo from './../../assets/images/copag.png';
import logoTruco from './../../assets/images/plataforma_truco.png';
import { RegisterStyle } from './style';

//Form
import RegisterForm from './RegisterForm'

const Register = () => {
    return (
        <RegisterStyle>
            <main className={`wrapper-limiter show-form`}>
                <div className="register-header">
                    <div className="logos">
                        <img src={logoTruco} width="256" height="80" alt="Plataforma de Truco" />
                        <img src={logo} width="72" height="46" alt="Copag" />
                    </div>

                    <h2 className="title-form">Novo cadastro</h2>
                    <h3 className="subtitle-form">*Plataforma para maiores de 16 anos</h3>

                    {/* <div className="social-login"> */}
                        {/* <Button label="ENTRAR PELO FACEBOOK" classButton="social-facebook" leftIcon={<FaFacebookF />} eventCallback={() => socialLogin('facebook')} />
                        <Button label="ENTRAR PELO GOOGLE" classButton="social-google" leftIcon={<FaGoogle />} eventCallback={() => socialLogin('google')} /> */}
                    {/* </div> */}

                    {/* <div className="or-text">
                        <div className="bar"></div>
                        <span>Ou</span>
                        <div className="bar"></div>
                    </div>

                    <Button label="ENTRE COM O SEU EMAIL" classButton="register-email" eventCallback={() => { setShowRegisterForm(!showRegisterForm) }} /> */}
                </div>

                {/* <div id="container-form-register" ref={nodeRef}> */}
                <div id="container-form-register">
                    <RegisterForm />
                </div>
            </main>
        </RegisterStyle>
    );
}

export default Register;