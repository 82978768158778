import styled from 'styled-components'

export const TableGlobalRankingStyle = styled.div`

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {
    td:nth-of-type(1):before { content: "Usuário"; }
    td:nth-of-type(2):before { content: "Pontuação"; }
    td:nth-of-type(3):before { content: "Cidade"; }
    td:nth-of-type(4):before { content: "Estado"; }
}
`