import { EditLeagueStyle } from "./style";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/UserContext";
import * as Yup from "yup";
import BasePage from "../../../components/BasePage";
import SectionTitle from "../../../components/SectionTitle";
import Cards from "../../../assets/svg/Cards";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import ErrorFocus from "../../../components/ErrorFocus";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";
import Textarea from "../../../components/Textarea";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import { ILeagueFormValues } from "../../BeManager/interface";
import api from "../../../services/api";
import { ICities, IStates } from "../../../interfaces/GeneralInterfaces";
import useCommonRequisitions from "../../../hooks/useCommonRequisitions";

const EditLeagueSchema = Yup.object().shape({
  leagueName: Yup.string().required("Informe o nome da liga"),
  description: Yup.string().required("Informe a descrição da liga"),
  maxPlayers: Yup.number().required("Informe a quantidade máxima de jogadores"),
  timeManaging: Yup.string(),
  zipCode: Yup.string().required("Informe o CEP"),
  uf: Yup.string().required("Informe o Estado da liga"),
  city: Yup.string().required("Informe a cidade da liga"),
  street: Yup.string().required("Informe a rua da liga"),
  streetNumber: Yup.string().required("Informe o número"),
  neighborhood: Yup.string().required("Informe o bairro"),
  additionalAddressInfo: Yup.string(),
  photos: Yup.array(),
});

interface ParamTypes {
  id: string;
}

interface ILeague {
  maxPlayers: number;
  alreadyManager: boolean | string;
  timeManaging: string;
  leagueName: string;
  zipCode: string;
  state: {
    id: number;
    name: string;
    uf: string;
  };
  city: {
    id: number;
    name: string;
    stateId: number;
  };
  neighborhood: string;
  street: string;
  streetNumber: number;
  additionalAddressInfo?: string;
  photos?: any[];
  description: string;
}

const EditLeague = () => {
  let { id } = useParams<ParamTypes>();
  const { user, userInfo, loading } = useContext(AuthContext);
  const [league, setLeague] = useState<ILeague>({} as ILeague);
  const { getStates, getCities, getAddressByZipcode } = useCommonRequisitions();
  const [returnMessage, setReturnMessage] = useState("");
  const [returnStatus, setReturnStatus] = useState(false);
  const [states, setStates] = useState<IStates[]>([]);
  const [cities, setCities] = useState<ICities[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateSelected, setStateSelected] = useState<any>("");
  const history = useHistory();

  useEffect(() => {
    async function getState() {
      const populatedStates = await getStates();
      setStates(populatedStates);
    }
    getState();
  }, []);

  useEffect(() => {
    async function getCity() {
      const cities = await getCities(stateSelected);
      setCities(cities);
    }

    if (stateSelected) {
      getCity();
    }
  }, [stateSelected]);

  useEffect(() => {
    const getLeagueInformations = async () => {
      const response = await api.get(`leagues/${Number(id)}`);
      setLeague(response.data);
      
      if (response.data.state && response.data.state.id)
      setStateSelected(response.data.state.id);

      if (!userInfo().isAdmin) history.push("/organizador?section=1");
    };

    if (!loading) {
      getLeagueInformations();
    }
  }, [loading]);

  const submitEditLeague = async (
    values: ILeagueFormValues,
    callback: Function
  ) => {
    try {
      await api.put(`leagues/${id}`, values);
      setReturnStatus(true);
      setReturnMessage("Torneio editado com sucesso");
      setIsModalOpen(true);
    } catch (err: any) {
      if (err.response) {
        setReturnMessage(err.response.data.message);
        setIsModalOpen(true);
      } else if (err.request) {
        console.log(err.request);
      } else {
        setReturnMessage("Erro ao cadastrar");
        setIsModalOpen(true);
      }
    }

    callback();
  };

  const handleClose = () => {
    setIsModalOpen(false);
    if (returnStatus) {
      history.push("/organizador?section=1");
    }
  };

  const editLeagueInitialValues: ILeagueFormValues = {
    id: id,
    leagueName: league?.leagueName,
    description: league?.description,
    maxPlayers: league?.maxPlayers,
    zipCode: league?.zipCode,
    uf: league?.state?.uf,
    stateId: league?.state?.id,
    city: league?.city?.name,
    street: league?.street,
    streetNumber: league?.streetNumber,
    neighborhood: league?.neighborhood,
    additionalAddressInfo: league?.additionalAddressInfo,
    alreadyManager: league?.alreadyManager,
    timeManaging: league?.timeManaging,
    photos: league?.photos,
  };

  return (
    <BasePage title={"Liga"}>
      <EditLeagueStyle>
        <SectionTitle
          customClass="title-edit-league"
          title="Editar Liga"
          icon={<Cards />}
        />
        <Formik
          initialValues={editLeagueInitialValues}
          validationSchema={EditLeagueSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
          onSubmit={(
            values: ILeagueFormValues,
            { setSubmitting }: FormikHelpers<ILeagueFormValues>
          ) => {
            submitEditLeague(values, function () {
              setSubmitting(false);
            });
          }}
        >
          {(formik) => {
            return (
              <div id="register-form">
                <Form className="common-form">
                  <ErrorFocus />
                  <InputField name="leagueName" label="Nome" type="text" />

                  <SelectField
                    label="Tempo como organizador"
                    name="timeManaging"
                    options={[
                      {
                        value: "lessThen6months",
                        label: "Menos de 6 meses",
                      },
                      {
                        value: "from6To1year",
                        label: "De 6 meses a 1 ano",
                      },
                      {
                        value: "from1yearTo3Years",
                        label: "De 1 ano a 3 ano",
                      },
                      {
                        value: "moreThen3Years",
                        label: "Mais de 3 anos",
                      },
                    ]}
                    placeholder="Selecione"
                  />

                  <InputField
                    name="zipCode"
                    label="CEP"
                    type="tel"
                    mask="99999-999"
                    onChangeCallback={async (value) => {
                      const zipcodeClean = value.replace(/\D/g, "");
                      formik.setFieldValue("zipCode", zipcodeClean);
                      if (zipcodeClean.length === 8) {
                        let res = await getAddressByZipcode(zipcodeClean);
                        if (res.logradouro) {
                          const state = states.find(
                            (state) => state.label.includes(res.uf) === true
                          );
                          formik.setFieldValue("uf", res.uf);
                          formik.setFieldValue("stateId", state?.value);
                          formik.setFieldValue("city", `${res.localidade}`);
                          formik.setFieldValue("neighborhood", `${res.bairro}`);
                          formik.setFieldValue("street", `${res.logradouro}`);
                          setStateSelected(state?.value);
                        }
                      }
                    }}
                  />

                  {/* <SelectField
                                        label="Estado"
                                        name="uf"
                                        options={states}
                                        placeholder="Selecione seu Estado"
                                        onChangeCallback={(value: string) => {
                                            setStateSelected(value)
                                            formik.setFieldValue("city", null);
                                        }}
                                    /> */}

                  <SelectField
                    label="Estado"
                    name="stateId"
                    options={states}
                    placeholder="Selecione seu Estado"
                  />

                  <SelectField
                    id="city"
                    label="Cidade"
                    name="city"
                    options={cities}
                    placeholder="Selecione sua cidade"
                  />

                  <InputField name="neighborhood" label="Bairro" type="text" />

                  <InputField name="street" label="Rua" type="text" />
                  <InputField name="streetNumber" label="Número" type="text" />
                  <InputField
                    name="additionalAddressInfo"
                    label="Complemento"
                    type="text"
                  />

                  <Textarea
                    name="description"
                    label="Descrição"
                    className="description"
                  />

                  {isModalOpen && (
                    <Modal
                      isOpen={isModalOpen}
                      onCloseModal={handleClose}
                      classStatus={returnStatus ? "success" : "error"}
                      title={returnStatus ? "Sucesso!" : "Opss!"}
                      text={returnMessage}
                    />
                  )}
                  <Button
                    classButton="submit-button"
                    hasLoader={true}
                    showLoading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                    label="SALVAR"
                    type={"submit"}
                  />
                </Form>
              </div>
            );
          }}
        </Formik>
      </EditLeagueStyle>
    </BasePage>
  );
};

export default EditLeague;
