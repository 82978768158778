import styled from 'styled-components'

export const TableManagerApproveStyle = styled.div`

@media 
    only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px)  {
      
      /*
      Label the data
      */
      td:nth-of-type(1):before { content: "Nome da Liga"; }
      td:nth-of-type(2):before { content: "Organizador"; }
      td:nth-of-type(3):before { content: "Capacidade"; }
      td:nth-of-type(4):before { content: "Estado"; }
      td:nth-of-type(5):before { content: "Cidade"; }
      td:nth-of-type(6):before { content: "Status"; }
}
`
