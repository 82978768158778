import styled from 'styled-components'

export const ModalInvitePlayersStyle = styled.div`
    min-height: 200px;
    display: flex;
    flex-direction: column;

    .formInvitePlayers {
        display: flex;
        flex-direction: center;
        align-items: flex-end;
        input {
            margin-right: 5px;
            height:47px;
        }

        .error-message{
          position:absolute;
          bottom:-16px;
        }
        button {
            max-height: 47px;
            margin-bottom: 5px;
            margin-left: 10px;
        }
    }

    ul {
        margin: 20px 0;
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
        li {
            .item {
                display: flex;
                flex-direction: center;
                align-items: center;
                height: 40px;
                background-color: var(--color-gray-thin);
                border-radius: 15px;
                margin: 10px 0;
                width: fit-content;
                padding: 5px;
            }
            .removeEmail {
                display: flex;
                flex-direction: center;
                align-items: center;
                svg {
                    height: 15px;
                }
            }
        }
    }

`