import styled from 'styled-components'

export const AccordionChampStyle = styled.div`
    .accordion-title{
        color: $color-primary;
        font-size: 3rem;
        font-family: $font-primary-bold;
    }

    .accordion-text{
        margin-top: 2.5rem;
    }

    .about-champ{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3rem;
        padding-bottom: 2rem;

        .list-infos{
            width: 70%;
            display: flex;
            flex-wrap: wrap;

            li{
                width: 50%;
                color: $color-primary;
            }
        }
    }
`