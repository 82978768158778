import React from 'react'

import "./style.scss";

interface DotsProps {
    quantity: number,
    selected: number,
    onClick: (index: number) => any
}

const Dots = ({ quantity, selected, onClick = (index: number) => console.log('Clicked on dot', index)}: DotsProps) => {
    const renderDots = (count: number) => {
        console.log(count)
        return [...Array(count)].map((x, index) => {
            return (<div key={index} className={`dots ${selected == index ? 'selected' : ''}`} onClick={() => onClick(index)}></div>)
        })
    }

    return (    
        <div className="Dots dots-container">
            { quantity > 0 && (
                renderDots(quantity)
            )}
        </div>
    )
}

export default Dots;