import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './../contexts/UserContext';
import { IRoutes } from './../interfaces/GeneralInterfaces';

const PublicRoute: React.FC<IRoutes> = ({
    component: Component,
    restricted,
    path,
    exact = true
}) => {
    const { authed } = useContext(AuthContext);

    return (
        <Route path={path} exact={exact} render={props => (
            authed() && restricted ?
                <Redirect to="/home" />
                : <Component {...props} />
        )} />
    );
};

export default PublicRoute;