import SectionTitle from '../../../components/SectionTitle';
import RolesStyle from './style';
import Text from '../../../components/Text';

const Roles = () => {
    return (
        <RolesStyle>
            <SectionTitle title="Regras de Pontuação" />
            <br />
            <Text>
                Toda pontuação acumulada pela participação em eventos da Truco Oficial e cumprimento dos desafios propostos na plataforma poderão ser resgatados no site de nossa parceira <strong>Troca Pontos.</strong> <br />
                A pontuação adquirida tem validade de 1 (um) ano*, contabilizado a partir da data de crédito. <br />
                Na seção <strong>Troca de Pontos</strong> você confere sua pontuação total, além de acessar os produtos disponíveis e realizar os resgates.
            </Text>
        </RolesStyle>
    )
}

export default Roles;