import styled from 'styled-components'

export const PlayersStyle = styled.section`
    background:#FFF;
    min-height:500px;

    .container-accordion {
        margin-bottom: 30px;
    }
    .filter_tournaments {
      margin-bottom:30px;
    }
    @media 
    only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px)  {
      
      /*
      Label the data
      */
      td:nth-of-type(1):before { content: "ID Jogador"; }
      td:nth-of-type(2):before { content: "Nome"; }
      td:nth-of-type(3):before { content: "Cidade"; }
      td:nth-of-type(4):before { content: "Estado"; }
      td:nth-of-type(5):before { content: "N° de Partidas"; }
      td:nth-of-type(6):before { content: "N° de Vitórias"; }
}
`
