import styled from "styled-components";

export const AccountStyle = styled.section`
  background: #fff;
  min-height: 500px;

  form {
    display: flex;
    flex-direction: column;
  }

  .form-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 30px;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }

    .description {
      grid-column: 2 span;
    }
    input:disabled {
      background: #f0f0f1;
    }
  }

  .buttons_account {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 0 8px;
      @media (max-width: 1024px) {
        display: block;
        width: 50%;
      }
    }
  }

  .image-change {
    display: flex;
  }
  .user-image {
    position: relative;
    &:hover {
      span {
        opacity: 1;
      }
    }
    span {
      border-radius: 50%;
      position: absolute;
      top: 10px;
      right: 40px;
      cursor: pointer;
      opacity: 0;
      transition: all 200ms;
      svg {
        fill: var(--color-green);
      }
    }
    img {
      border: 10px solid var(--primary);
      border-radius: 50%;
      margin: 0 auto;
      display: block;
    }
    margin-bottom: 2rem;
  }
`;

export const ActionButtonsStyle = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;
