import EditingTextsStyle from './style';
import BasePage from '../../components/BasePage';
import { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/UserContext';
import { Editor } from "@tinymce/tinymce-react";

const EditingTexts = () => {
    const { userInfo, user } = useContext(AuthContext);
    const [text2, setText2] = useState('');

    const handlechange = (e: any) => {
        setText2(e);
    }

    // const handleSubmit = (e: any) => {
    //     e.preventDefault();
    // }

    return (
        <BasePage title={`Olá, ${userInfo().name}`}>
            <EditingTextsStyle>
                <form>
                    <label>Texto</label>
                    <Editor
                        init={{ directionality: "ltr" }}
                        onEditorChange={(e: any) => handlechange(e)}
                        //tinymceScriptSrc={'http://localhost:3000/plugins/tinymce.min.js'}
                        value={text2}
                    />
                </form>

            </EditingTextsStyle>
        </BasePage>
    )
}

export default EditingTexts