import styled from 'styled-components'

export const ApprovingManagerStyle = styled.div`
    background:#FFF;
    min-height:500px;    

    .playerData{
      display:flex;
      flex-direction:column;
    }
    .list-infos{
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap:10px;
        margin-top:30px;
    }

    .photos{
      width:100%;
      display:grid;
      margin-top:30px;
      grid-template-columns:1fr 1fr;
      grid-gap:30px;
      .item{
        width:100%;
        border:1px solid rgba(0,0,0,0.5);
        padding:10px;
        img{
          width:100%;
        }
      }
    }
    .actions_admin{
      display:flex;
      justify-content:center;
      align-items:center;
      width:100%;
      margin-top:50px;
      button{
        margin:0 10px;
      
      }
    }
    @media(max-width:800px){
        .list-infos{
            display: flex;
            flex-direction: column;
        }
    }
   
`
