
import { useState, useEffect, useContext } from 'react'
import BasePage from '../../components/BasePage';
import FidelityStyle from './style';
import Tabs from '../../components/Tabs/Tabs';
import TabPane from '../../components/Tabs/TabPane';
import FidelityProgram from './FidelityProgram';
import Roles from './Roles';
import Text from '../../components/Text';
import api from '../../services/api';
import { AuthContext } from '../../contexts/UserContext';
import Button from '../../components/Button';


const Fidelity = () => {
    const { loading, user } = useContext(AuthContext);
    const [tokenTrocapontos, setTokenTrocapontos] = useState();
    const [redirectTrocapontos, setRedirectTrocaPontos] = useState();

    const getUserTrocaPontos = async () => {
        try {
            const response = await api.get('users/login-trocapontos');
            setTokenTrocapontos(response.data.token);
            setRedirectTrocaPontos(response.data.url);

        } catch (e: any) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (!loading)
            getUserTrocaPontos();

    }, [loading])

    return (
        <BasePage title={'Programa de Recompensa'}>
            <FidelityStyle>
                <Tabs>
                    <TabPane label="PROGRAMA DE RECOMPENSA">
                        <FidelityProgram />
                    </TabPane>

                    <TabPane label="TROCA DE PONTOS">
                        <>
                            <Text>Aproveite a pontuação acumulada e conheça todos os produtos disponíveis para troca. </Text>
                            <br />
                            {user && user.doc && tokenTrocapontos && redirectTrocapontos && <form action={redirectTrocapontos} method="post" target="_blank">
                                <input type="hidden" name="token" value={tokenTrocapontos} />
                                <input type="hidden" name="cpf" value={user?.doc} />
                                <Button classButton="submit-button" label="Acessar Troca Pontos" type={'submit'} />
                            </form>}
                        </>
                    </TabPane>

                    <TabPane label="REGRAS">
                        <Roles />
                    </TabPane>
                </Tabs>
            </FidelityStyle>
        </BasePage>
    )
}

export default Fidelity;