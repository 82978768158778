import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import styled from 'styled-components';

interface DateTimePickerProps {
  label: string;
  name: string;
  id?: string;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <DateTimePickerWrapper>
      <label htmlFor={props.id || props.name}>{label}</label>
      <StyledInputWrapper>
        <StyledInput type="datetime-local" {...field} {...props} />
        <StyledCalendarIcon />
      </StyledInputWrapper>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </DateTimePickerWrapper>
  );
};

export default DateTimePicker;

const DateTimePickerWrapper = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #2C1266;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .error {
    color: red;
    font-size: 0.875rem;
  }
`;

const StyledInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0 2rem;
  min-height: 50px;
  font-size: 1.5rem;
  color: #333333;
  border: 1px solid #CCCCCC;

  ::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0; /* Esconde o ícone */
  }
`;

const StyledCalendarIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 1.5em;
  height: 1.5em;
  // background: url('data:image/svg+xml;base64,...') no-repeat center center; /* Caso queira adicionar um icone */
  // pointer-events: none; /* Caso queira clicar no ícone */
`;
