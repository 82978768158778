import styled from "styled-components";

const SectionTitleStyle = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-right: 1rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
      fill: var(--color-purple);
    }
  }

  &.quiz-title {
    margin-bottom: 1.5rem;
  }

  span {
    color: var(--primary);
    font-size: 2rem;
    font-family: var(--font-secondary-bold);
    margin-right: 0.5rem;
  }

  &.user-name {
    span {
      font-size: 2.8rem;
    }
  }
`;

export default SectionTitleStyle;
