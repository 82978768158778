import styled from 'styled-components'

export const ModalConfirmStyle = styled.div`
    .actions-buttons{
        display:flex;
        align-items:center;
        margin-top: 10px;
        button{
            margin:0 10px;
        } 
    }
`