import React from 'react';
import { useHistory } from 'react-router';
import Page404Style from './style';
import Button from '../../components/Button';


const Page404 = () => {
    const history = useHistory();

    const handleGoBackToPlataform = () => {
        history.push('/home');
    }

    return (
        <Page404Style>
            <h1>Página não encontrada!</h1>
            <Button label="Voltar para aplicação" eventCallback={handleGoBackToPlataform} />
        </Page404Style>
    );
}

export default Page404;