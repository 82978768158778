import styled from 'styled-components'

export const HeaderStyle = styled.header`
    width: 100%;
    padding: 3rem 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    #container-page.sidebar-relative &{
        grid-area: header;
    }
    
    @media(max-width:1024px){
        width:100%;
        background:var(--primary);
        z-index: 9;
        position:fixed;
        top:0px;
        left:0px;
        padding:10px;
        justify-content:flex-end;
        box-shadow: 1rem 1rem 7rem rgba(0, 0, 0, .16);
    }
`