enum ResponseStatus {
    Success = 200,
    Error = 201
}

enum TournamentStatus {
    notStarted = "notStarted",
    inProgress = "inProgress",
    finished = "finished",
    None = 3
}

enum TournamentTypes {
    Swiss = "Swiss",
    Knockout = "Knockout"
}

export { ResponseStatus, TournamentStatus, TournamentTypes };