import styled from 'styled-components'

const LoginStyle = styled.section`
    width: 100vw;
    min-height: 100vh;
    background-color: var(--primary);

    .wrapper-limiter{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 800px) {
            flex-direction: column;
            height: 100%;
        }
    }

    .description{
        flex-basis: 50%;
        padding-right: 4.5rem;
        @media screen and (max-width: 800px) {
            flex-basis: 100%;
            margin-top: 3rem;
            padding-right: 0rem;
        }

        .logos{
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 1024px) {
              flex-direction:column;
            }

            img{
                margin: 0 2rem;
                filter: brightness(0) invert(100%);

                @media screen and (max-width: 1024px) {
                  margin-bottom:25px;
                }
            }
        }

        .container-text{
            margin-top: 9rem;
            @media screen and (max-width: 1024px) {
                  margin-top:30px;
                }

            .description-title{
                color: var(--color-white);
                font-size: 3rem;
                font-family: var(--font-secondary-bold);
                @media(max-width:1024px){
                  text-align:center;
                }
            }

            .description-text{
                color: var(--color-white);
                margin-top: 4rem;
                @media(max-width:1024px){
                  text-align:center;
                }
            }
        }
    }

    .container-forms{
        flex-basis: 50%;
        padding-left: 9rem;
        padding-bottom: .5rem;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 800px) {
            flex-basis: 100%;
            padding-left: 0rem;
            padding-bottom: 2rem;
            padding-top: 2rem;
        }

        &.open-recover{
            #container-form-recover{
                transform: translate(0%, -50%);
                opacity: 1;
            }

            #container-form-login{
                transform: translate(-100%, 0);
                opacity: 0;
            }
        }

        #container-form-recover{
            width: 100%;
            padding-left: 5rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(100%, -50%);
            opacity: 0;
            @media(max-width:1024px){
              padding-left:0;
            }

            .title-form{
                font-size: 3rem;
                line-height: 2.8rem;
                margin-bottom: 3rem;
            }
        }

        .title-form{
            color: var(--color-white);
            text-align: center;
            font-size: 4rem;
            font-family: var(--font-secondary-bold);
        }

        .form-content{
            flex-direction: column;
            align-items: center;
            display: flex;
            position: relative;
            padding-right: .5rem;
            transition: transform .3s, opacity .3s;

            .input-block{
                label{
                    color: var(--color-white);
                }
                 &.input-error{
                    color: var(--danger);
                 } 
            }

            #login-form, #forgot-form{
                width: 100%;
            }
        }

        .submit-button{
            width: 100%;
            margin-top: 3rem;
            transition:all 200ms;

            &.error-login-btn{
              animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
              transform: translate3d(0, 0, 0);
              backface-visibility: hidden;
            }
        }

        .register-link{
            margin-top: 2rem;
            text-align: center;
            color: var(--color-white);
            font-size: 1.4rem;

            .register-button{
                color: var(--color-green);
            }
            
            a:hover {
                color: var(--color-purple); 
                transition: 0.3s;
            }
            a:visited {
                color: var(--color-purple);
            }
        }

        .error-login{
          color: var(--danger);
          font-size: 1.6rem;
          
        }
    }

    .forgot-pass-link{
        background-color: transparent;
        color: var(--color-green);
        font-size: 1.4rem;
        text-decoration: none;
        margin-top: 1rem;
        width: 100%;
        text-align: center;
    }

    .forgot-pass-link:hover {
        color: var(--color-purple); 
        transition: 0.3s;
    }

    @keyframes shake {
        10%,
        90% {
          transform: translate3d(-1px, 0, 0);
        }
        20%,
        80% {
          transform: translate3d(2px, 0, 0);
        }
        30%,
        50%,
        70% {
          transform: translate3d(-4px, 0, 0);
        }
        40%,
        60% {
          transform: translate3d(4px, 0, 0);
        }
      }

`
export default LoginStyle;
