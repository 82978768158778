import { useState, useEffect, useContext } from 'react'

import BasePage from '../../components/BasePage';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle'
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { TicketStyle } from './style';
import Cards from '../../assets/svg/Cards';
import api from '../../services/api'
import { AuthContext } from '../../contexts/UserContext';
import { IIterations, ITicket, ITicketChatFormValues } from '../../interfaces/GeneralInterfaces';
import { ResponseStatus } from '../../enums/GeneralEnums';
import { renderDateFormated } from '../../utils/formatData';

const TicketAnwserSchema = Yup.object().shape({
    message: Yup.string().required('Digite uma mensagem'),
});

interface ParamTypes {
    id: string;
}

const Ticket = () => {
    let { id } = useParams<ParamTypes>();
    const { loading, handleLoading, userInfo, user } = useContext(AuthContext)
    const history = useHistory();
    const [isModalFailOpen, setIsModalFailOpen] = useState<boolean>(false);
    const [isModalFinishTicketOpen, setIsModalFinishTicketOpen] = useState<boolean>(false);
    const [ticket, setTicket] = useState<ITicket>({} as ITicket)

    const accountInitialValues: ITicketChatFormValues = {
        message: "",
    };

    const submitIteration = async (values: ITicketChatFormValues, callback: Function) => {
        handleLoading(true);
        const response = await api.post(`/tickets/${id}`, values);

        if (response.status !== ResponseStatus.Error) {
            setIsModalFailOpen(true);
        }

        handleLoading(false);
        callback();
    }

    const finishTicket = async () => {
        try {
            const response = await api.put('tickets/finish', { ticketId: Number(id) });
            if (response.status === ResponseStatus.Success) {
                setIsModalFinishTicketOpen(true);
            }
        }
        catch (e: any) {
            console.log(e);
        }
    }

    useEffect(() => {
        const getIterations = async (id: number) => {
            try {
                const response = await api.get(`tickets/${id}`)
                if (response) {
                    setTicket(response.data);
                }
            }
            catch (e: any) {
                history.push('/pagina-nao-encontrada');
                setTicket({} as ITicket);
            }
        }

        if (!loading) {
            getIterations(Number(id));
        }
    }, [loading, isModalFinishTicketOpen])

    return (
        <BasePage title="Tickets">
            <TicketStyle>
                <SectionTitle title={`Ticket - ${id} - ${ticket.subject}`} icon={<Cards />} />
                <div className="iterations">
                    {ticket && ticket.iterations?.map((iteration: IIterations, index: number) => {
                        return (
                            <div className="item" key={index}>
                                <div className="avatar">

                                </div>
                                <div className="content">
                                    <div className="name_date">
                                        <span>{iteration.user.firstName} {iteration.user.lastName} </span>
                                        <span>{renderDateFormated(iteration.date)}</span>
                                    </div>
                                    <div className="description">
                                        {iteration.message}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {ticket.status !== 'finished' &&
                    <Formik
                        initialValues={accountInitialValues}
                        validationSchema={TicketAnwserSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize
                        onSubmit={(
                            values: ITicketChatFormValues,
                            { setSubmitting, resetForm }: FormikHelpers<ITicketChatFormValues>,
                        ) => {
                            submitIteration(values, function () {
                                setSubmitting(false);
                                resetForm({})
                            });
                        }}
                    >
                        {formik => {
                            return (
                                <Form>
                                    <div className="item">
                                        <div className="avatar"></div>
                                        <div className="description">
                                            <Textarea
                                                name="message"
                                                label="Digite"
                                                placeholder="Responder"
                                            />
                                        </div>
                                    </div>
                                    <div className="buttons">
                                        <Button classButton="submit-button" hasLoader={true} showLoading={formik.isSubmitting} disabled={formik.isSubmitting} label="ENVIAR" type={'submit'} />
                                        {userInfo().isAdmin && <Button classButton="finish-button" eventCallback={() => finishTicket()} label="FINALIZAR" type={'button'} />}
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>}

                {isModalFailOpen && (
                    <Modal
                        isOpen={isModalFailOpen}
                        onCloseModal={() => setIsModalFailOpen(false)}
                        classStatus='error'
                        title="Erro"
                        text={'Algo de errado aconteceu!'}
                    />
                )}

                {isModalFinishTicketOpen && (
                    <Modal
                        isOpen={isModalFinishTicketOpen}
                        onCloseModal={() => setIsModalFinishTicketOpen(false)}
                        classStatus='success'
                        title="Ticket Finalizado"
                        text={'Ticket finalizado com sucesso!'}
                    />
                )}
            </TicketStyle>
        </BasePage>

    )
}

export default Ticket;