import api from "../../services/api";
import { useState, useEffect, useCallback } from "react";
import {
  IRegisterFormValues,
  ILoginFormValues,
} from "../../interfaces/GeneralInterfaces";

export const localToken = "@Copag:token";
export const localUser = "@Copag:user";

export default function UserHooks() {
  const [loading, setLoading] = useState(true);
  const [managerStatus, setManagerStatus] = useState<string>("");
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const token = localStorage.getItem(localToken);
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      getUser();
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (userInfo().leagueStatus === "approved") setManagerStatus("approved");
  }, [loading, user]);

  const getUser = async () => {
    const response = await api.get("users/profile");
    const user = response?.data;
    setUser(user);
  };

  const handleLogin = async (userData: ILoginFormValues) => {
    try {
      const response = await api.post("session", userData);
      setManagerStatus(response.data.user.leagueStatus);
      localStorage.setItem(localToken, response.data.token);
      console.log("response.data.user", response.data.user);
      localStorage.setItem(localUser, JSON.stringify(response.data.user));
      api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      getUser();
      return true;
    } catch (e: any) {
      console.log("mensagem " + e.message);
      return false;
    }
  };

  const handleManagerStatus = (status: string) => {
    setManagerStatus(status);
  };

  const handleLoading = (status: boolean) => {
    setLoading(status);
  };

  const handleRegister = useCallback(async (userData: IRegisterFormValues) => {
    const response = await api.post("users", userData);
    if (response.status === 201) {
      setManagerStatus(response.data.user.leagueStatus);
      localStorage.setItem(localToken, response.data.token);
      localStorage.setItem(localUser, JSON.stringify(response.data.user));
      api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      getUser();

      return {
        status: true,
        message: "Usuário criado com sucesso",
      };
    } else {
      return {
        status: false,
        message: response.data.message,
      };
    }
  }, []);

  const authorized = useCallback(() => {
    const token = localStorage.getItem(localToken);
    return token ? true : false;
  }, []);

  const userInfo = useCallback(() => {
    const userInfo = localStorage.getItem(localUser);
    return userInfo ? JSON.parse(userInfo) : {};
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(localToken);
    localStorage.removeItem(localUser);
    api.defaults.headers.Authorization = undefined;
  }, []);

  const handleIsLeagueManagerOfThisTournament = (managerEmail: string) =>
    userInfo().email === managerEmail;

  return {
    authed: authorized,
    loading,
    managerStatus,
    handleManagerStatus,
    handleLoading,
    userInfo,
    user,
    handleLogin,
    handleLogout,
    handleRegister,
    handleIsLeagueManagerOfThisTournament,
  };
}
