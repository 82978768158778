export const selectStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        fontSize: state.isSelected ? 'bold' : 'regular',
        padding: 10,
    }),
    singleValue: (provided: any, state: any) => {
        const opacity = 1;
        const borderRadius = 0;

        return { ...provided, opacity, borderRadius };
    },
    control: (provided: any, state: any) => {
        const minHeight = 40;

        return { ...provided, minHeight};
    },
    indicatorSeparator: (provided: any, state: any) => {
        const display = 'none';

        return { ...provided, display};
    },
    noOptionsMessage: (provided: any, state: any) => {
        const color = 'var(--primary)';

        return { ...provided, color};
    },
    dropdownIndicator: (provided: any, state: any) => {
        const color = 'var(--primary)';

        return { ...provided, color};
    },
}