import React from 'react';

import { LinkButtonStyle } from './style';

import { ILink } from '../../interfaces/GeneralInterfaces';

const LinkButton: React.FC<ILink> = ({
    label,
    link,
    classButton,
}) => {
    return (
        <LinkButtonStyle to={link} className={`link-button ${classButton}`} title={label}>
            {label}
        </LinkButtonStyle>
    );
}

export default LinkButton;