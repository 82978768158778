import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BasePage from '../../../components/BasePage';
import SectionTitle from '../../../components/SectionTitle';
import { AuthContext } from '../../../contexts/UserContext';
import api from '../../../services/api';
import { ReportQuizStyle } from './style';


interface ParamTypes {
    quizId: string;
}

interface IFormReport {
    formId: number;
    name: string;
    totalFailed: number;
    totalApproved: number;
    report: [{
        question: string;
        questionId: string;
        answers: [{
            name: string;
            timesChosen: number
        }]
    }];
    category: string;
}

const ReportQuiz = () => {
    const { quizId } = useParams<ParamTypes>();
    const { loading } = useContext(AuthContext);
    const [quizReport, setQuizReport] = useState<IFormReport>();
    const history = useHistory();

    useEffect(() => {
        const getFormReport = async () => {
            const { data } = await api.post('forms/report', {quizId});

            if(data.category !== "BeManagerTest") {
                history.push('/questionarios-admin')
            }

            setQuizReport(data);
        }

        if (!loading) {
            getFormReport();
        }
    }, [loading]);

    return (
        <BasePage title="Questionário" subtitle={`Report do questionário ${quizReport?.formId}`}>
            <ReportQuizStyle>
            <SectionTitle title={quizReport?.name}/>
            {!loading && quizReport && quizReport.report.length > 0 && 
                <div className="quiz">
                    <h3>Total de aprovados: {quizReport?.totalApproved}</h3>
                    <h3 className="reproved">Total de reprovados: {quizReport?.totalFailed}</h3>
                    {quizReport && quizReport.report.map((question) => {
                        return (
                            <div className="questions" key={question.questionId}>
                                <h2>{question.question}</h2>
                                <div className="answers">
                                    {question.answers.map((answer, index) => {
                                        return (
                                            <div key={index}>
                                                {answer.name}
                                                <span>Quantidade de vezes escolhida: {answer.timesChosen}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            </ReportQuizStyle>
        </BasePage>
    )
}

export default ReportQuiz;