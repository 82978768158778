import React, { ChangeEvent } from 'react';
import { ErrorMessage, useField } from 'formik'
import { InputProps } from '../../interfaces/GeneralInterfaces';
import { CheckboxFieldStyle } from './style';

const CheckboxField: React.FC<InputProps> = ({ label, labelWithLink, onChangeCallbackBoolean, ...props }) => {
    const [field, meta] = useField(props);
    
    const onChangeValue = (e: ChangeEvent<any>) => {
        const val = e.target.checked;
        onChangeCallbackBoolean && onChangeCallbackBoolean(val);
    }

    return (
        <CheckboxFieldStyle>
            <input {...field} {...props} onChange={onChangeValue} />
            <label htmlFor={field.name}>{label}</label>
            <label className="labelWithLink" htmlFor={field.name}>{labelWithLink}</label>
            { meta.error && <div className="error-message"><ErrorMessage name={field.name} /></div>}
        </CheckboxFieldStyle>
    );
}

export default CheckboxField;