import React, { useState } from 'react';

import { BasePageStyle } from './style';

import { Header } from '../Header';
import { MenuSidebar } from '../MenuSidebar';
import Title from '../Title';
import Text from '../Text';
import Menu from '../../assets/svg/Menu';

import waves1 from '../../assets/images/waves1.png';
import waves2 from '../../assets/images/waves2.png';
import waves3 from '../../assets/images/waves3.png';

import { IBasePage } from '../../interfaces/GeneralInterfaces';

const BasePage: React.FC<IBasePage> = ({
    title,
    subtitle,
    menuSidebarType = 'fixed',
    children,
}) => {
    const [menuOpen,setMenuOpen] = useState<boolean>(false);

    const handleMenu = () => {
        setMenuOpen(!menuOpen);
    }
    return (
        <BasePageStyle>
        <section id="container-page" className={`sidebar-${menuSidebarType}`}>
            <div className="btnMobile" onClick={handleMenu}>
                <Menu />
            </div>
            <MenuSidebar customClass={menuOpen ? 'active' : ''} />
            <Header />

            <div id="page-content">
                <Title>{title}</Title>
                <Text customClass="page-subtitle">{subtitle}</Text>

                <div className="page-content">
                    {children}
                </div>

                <img src={waves1} className="wave1" alt=""/>
                <img src={waves2} className="wave2" alt=""/>
                <img src={waves3} className="wave3" alt=""/> 
            </div>
        </section>
        </BasePageStyle>
    );
}

export default BasePage;