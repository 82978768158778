import styled from "styled-components";

export const PendingPlayersStyle = styled.div`
  .pendingPlayers {
    > div {
      overflow-y: visible;
      overflow-x: visible;
    }
  }
`;
